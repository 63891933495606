/** @module             React */

import { AuthContext }  from "../../Contexts/AuthContext";

import { useContext }   from "react";

const 

/**
 * 
 * @returns authorization context
 * 
 */

useAuthorization = () => { const 

    context = useContext ( AuthContext );

    /** @throws */

    if ( ! context ) {

        throw Error ( "useAuthorization must be used inside an AuthContextProvider." );

    }

    /** @returns */

    return context;

};

/** @exports useAuthorization */

export default useAuthorization;