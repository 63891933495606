import styles from "./Tabs.module.css";

import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { ORDER_TYPE_ENUM } from "../../../../Queries/Orders";

import CenterLoader from "../../Loaders/CenterLoader";

/**
 * 
 * @exports OrderTabs
 * 
 * @description The component that displays the order tabs.
 * 
 * @param { Object } props
 * 
 * @param { Object } props.variables
 * 
 * @param { Function } props.refetch
 * 
 * @returns { JSX.Element }
 * 
 */

export default function OrderTabs ( { variables, refetch } ) {

    const 

    { t : __ } = useTranslation (),

    { loading : loading_types, data : data_types } = useQuery ( ORDER_TYPE_ENUM ),

    [ currentType, setCurrentType ] = useState ( variables?.orderType || undefined ),

    isActiveTab = ( types ) => {

        for ( const type of types ) {

            if ( Array.isArray ( currentType ) ) {

                if ( currentType.includes ( type ) ) return true;

            } else {

                if ( currentType === type ) return true;

            }

        }

        return false;

    }

    /**
     * 
     * @description
     * 
     * If the current type is different from the variables order type, refetch the data.
     * 
     * @returns { void }
     * 
     * @event useEffect
     * 
     */

    useEffect ( () => {

        if ( currentType === variables.orderType ) return;

        refetch ( { ...variables, orderType: currentType } );

    }, [ currentType ] );

    /**
     * 
     * @description
     * 
     * If the data is loading, display the center loader.
     * 
     * @returns { JSX.Element }
     * 
     */

    if ( loading_types ) return <CenterLoader />;

    /**
     * 
     * @description
     * 
     * Render the order tabs.
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <div className={ styles.container }>

            <div 
            
                className={ [ styles.selector, currentType === undefined ? styles.active : styles.default ].join ( " " ) } 
            
                onClick={ e => setCurrentType ( undefined ) }>

                    { __ ( "all" ) }

            </div>

            <div 
            
                className={ [ styles.selector, isActiveTab ( [ "manager_order", "user_b2c_order", "user_b2b_order" ] ) ? styles.active : styles.default ].join ( " " ) } 
            
                onClick={ e => setCurrentType ( [ "manager_order", "user_b2c_order", "user_b2b_order" ] ) }>

                    { __ ( "orders" ) }

            </div>

            <div 
            
                className={ [ styles.selector, isActiveTab ( [ "offer" ] ) ? styles.active : styles.default ].join ( " " ) } 
            
                onClick={ e => setCurrentType ( [ "offer" ] ) }>

                    { __ ( "offer" ) }

            </div>

            {/* { 
            
                data_types.__type.enumValues.map ( ( type, index ) => 

                    <div 
                    
                        key={ index } 
                    
                        className={ [ styles.selector, currentType === type?.name ? styles.active : styles.default ].join ( " " ) } 

                        data-item={ type?.name || undefined }
                    
                        onClick={ e => setCurrentType ( e.currentTarget.getAttribute ( "data-item" ) ) }>

                            { __ ( `order_tabs_${ type?.name }` ) }

                    </div>

                ) 
            
            } */}

        </div>

    );

};