/** @module                     Contexts */

import { AuthContextProvider }  from '../Contexts/AuthContext';

/** @module                     Configs */

import Routing                  from '../Configs/Routes';

const 

/**
 * 
 * @returns main application
 * 
 */

Application = () => {

  return ( <main className='application'>

      <Routing />

  </main> );

}

/** @export Application */

export default Application;
