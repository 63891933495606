/** @import modules */

import { useState }     from "react";

import { NavLink }      from "react-router-dom";

/** @import icons */

import { TbCaretLeft }  from "react-icons/tb";

/**
 * 
 * @param { nav } param0 
 * 
 * @returns Dashboard submenu component
 * 
 */

const Submenu = ( { nav } ) => { const

    [ state, setState ] = useState ( false ),

    Action = event => { const 
        
        chevron = event.target.querySelector ( ".chevron" ),

        subnav = event.target.parentElement.querySelector ( ".subnav" );

        if ( state ) {

            chevron.classList.remove ( "submenu-active" );

            subnav.classList.remove ( "subnav-active" );

            setState ( false );

        } else {

            chevron.classList.add ( "submenu-active" );

            subnav.classList.add ( "subnav-active" );

            setState ( true );

        }

    };

    /** @returns component */

    return ( <div className="submenu">

        <div className="selector" onClick={ e => Action ( e ) }>

            <div className="icon">{ nav.icon }</div>

            <div className="name">{ nav.name }</div>

            <div className="chevron"><TbCaretLeft /></div>

        </div>

        <div className="subnav">
            
            { nav.collection.map ( ( item, index ) => <NavLink end

                key={ index }
            
                to={ item.URI } 
            
                className={({ isActive }) => (isActive ? 'active' : 'inactive')}>

                    <span>{ item.icon }</span>

                    { item.name }

            </NavLink> ) } 
        
        </div>
        
    </div> );

}

/** @export Submenu */
 
export default Submenu;