/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { useEffect, useState }      from "react";

import { useNavigate }              from "react-router-dom";

import useCreate                    from "../../../Hooks/Mutations/useCreate";

import useFetch                     from "../../../Hooks/useFetch";

/** @module                         GQL */

import { CREATE_MANUFACTURER }      from "../../../Mutations/Manufacturers";

import { MANUFACTURER_PRODUCT_ENUM } from "../../../Queries/Manufacturers";

/** @module                         Utilities */

import { enumOptions }              from "../../../Components/Dashboard/Utils/enumOptions";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import SelectSpread                 from "../../../Components/Dashboard/Global/Forms/Select_Spread";

import { Input }                    from "../../../Components/Dashboard/Global/Forms/Inputs_Spread";

const 

/**
 * 
 * @returns create mutation
 * 
 */

CreateManufacturer = ( { name } ) => { 

    /** @event usePagename */

    usePagename ( name );
    
    const 
    
    { t }       = useTranslation (),

    navigate    = useNavigate (),

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors, 
        
        setErrors 
    
    } = useCreate ( CREATE_MANUFACTURER ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        name            : "",

        productType     : "default",

        markup          : 0

    } ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( ! state.name ) { return setErrors ( true ); }

        if ( ! state.productType || state.productType === "default" ) { return setErrors ( true ); }
        
        createMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( name === "markup" ) return setState ( prev => ( { ...prev, [ name ]: parseFloat ( value ) } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( `/app/shop/manufacturers/edit/${ returnData.adminCreateManufacturer.manufacturer.id }` );

    }, [ complete ] );

    /** @returns */

    return (

        <section className="mutations create_supplier">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "title" ) }>

                        <div className="input-group">

                            <input
                            
                                type="text"

                                value={ state.name }

                                onChange={ handleChange }

                                name="name"
                            
                            />

                        </div>

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

Sidebar = ( { 
    
    __, 
    
    state = "", 
    
    changeAction = () => { return },

    errors = false,

    pending = false

} ) => { const 

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading, data } = useFetch ( MANUFACTURER_PRODUCT_ENUM );

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                { ! loading &&

                    <div className={ `mutation-actions default` }>

                        <button className="button add" disabled={ pending }>

                            { __ ( "submit" ) }

                        </button>

                    </div>
                
                }

            </Fieldset>

            <Fieldset legend={ __ ( "options" ) }>

                <SelectSpread 
                
                    id="productType"

                    i18n={ true }

                    def={ true }
                    
                    action={ changeAction }
                    
                    options={ loading ? [] : enumOptions ( data.__type.enumValues ) }
                    
                    value={ state.productType } />

            </Fieldset>

            <Fieldset legend={ __ ( "markup" ) }>

                 <Input
                    
                        value={ state.markup } 

                        action={ changeAction }

                        name="markup"
                        
                    />

            </Fieldset>

        </aside>

    );

}

/** @exports CreateManufacturer */
 
export default CreateManufacturer;