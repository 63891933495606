import React from "react";

import styles from "./styles.module.css";


const SelectOption = (props) => {
    const optionClassName = [styles.option];

    if (props.isSelected) {
        optionClassName.push(styles.optionSelected);
    }

    return (
        <div
            className={optionClassName.join(" ")}
            onClick={() => {
                props.onSelect(props.name);
            }}
            role="button"
            tabIndex="-1"
        >
            {props.label}
        </div>
    );
};

SelectOption.defaultProps = {
    name: "",
    label: "",
    onSelect: () => { },
    isSelected: false,
};

export default SelectOption;
