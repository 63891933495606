import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { sortData } from "../Utilities";

/**
 * 
 * @exports FilterSelect
 * 
 * @description This component renders a select input for filtering the tire collection 
 * 
 * @param { Object } props
 * 
 * @param { Array } props.data The data to be used to render the select options 
 * 
 * @param { String } props.parse The type of data to parse the value to before setting it to the state 
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection 
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection 
 * 
 * @param { String } props.name The name of the variable to be used to refetch the tire collection
 * 
 * @param { Boolean } props.textValue The value to be used as the text value for the select options 
 * 
 * @param { String } props.label The label to be used for the select input 
 * 
 * @returns { JSX.Element } FilterSelect 
 * 
 */

export default function FilterSelect ( { data, parse = false, refetch, variables, name, textValue = false, label = false, loader } ) { const 

    { t } = useTranslation (),

    [ value, setValue ] = useState ( variables[ name ] ),

    /**
     * 
     * @function handleChange
     * 
     * @param { Object } e The event object
     * 
     * @returns set the value to the state
     * 
     */

    handleChange = e => {

        e.target.value === "reset" ? setValue ( "" ) : 
        
        setValue ( parse === "number" ? parseInt ( e.target.value ) : parse === "float" ? parseFloat ( e.target.value ) : e.target.value );

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined 
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && value === "reset" ) return;

        if ( typeof variables [ name ] == "undefined" && value === "" ) return;

        if ( variables [ name ] == value ) return;

        refetch ( 

            { 
            
                [ name ] : value ? value : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ value ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setValue ( "" );
        
        }

    }, [ variables [ name ] ] );

    /** @returns { JSX.Element } FilterSelected */

    return (

        <div className={ `input-group default` }>

            <select onChange={ handleChange } value={ value } disabled={ loader }>

                <option value="reset">
                    
                    { label ? t ( label ) : t ( name ) }
                    
                </option>

                { /** Map the data to render the select options */
                
                    sortData ( data, textValue ).map ( ( item, index ) => 
                    
                        <option key={ index } value={ textValue ? item.text : item.value }>
                            
                            { item.text }
                            
                        </option> 
                        
                    ) 
                    
                }

            </select>

        </div>

    );

};