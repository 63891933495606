import { Fragment, useEffect, useState } from "react";

import useFetch from "../../../../../../Hooks/useFetch";

import { PRODUCT_TYPES_ENUM, PRODUCTS } from "../../../../../../Queries/Products";

import CenterLoader from "../../../../Loaders/CenterLoader";

import Pagination from "../../../../Global/Table/Pagination";

import Checkbox from "../../../../Global/Forms/Checkbox";

import ProductFilters from "../../Filters/Container";

import { useTranslation } from "react-i18next";

/** @module                     React-Icons */

import { AiOutlineCloseCircle }      from "react-icons/ai";

const 

OrderProductTable = ( { setPreview, preview, persistantPreview, setPersistantPreview, updateData, products, setProducts, updateDataVars, __, orderData } ) => { const 

    /** 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { data : typeData, loading: typeLoading } = useFetch ( PRODUCT_TYPES_ENUM ),

    /** @state */

    [ type, setType ] = useState ( "" ),

    /**
     * 
     * @param { String } productType
     * 
     * @returns product type
     * 
     * @description sets product type
     * 
     * @event onClick
     * 
     */

    changeType = productType => {

        setType ( productType );

        setPersistantPreview ( false );

        setPreview ( false );

    };

    /** @description sets default product type */

    useEffect ( () => {

        if ( ! typeLoading ) {
                
            setType ( typeData.__type.enumValues[ 0 ].name );

        }

    }, [ typeLoading ] );

    /** @returns */

    return (

        <div className="body__pane">

            { typeLoading ? <CenterLoader /> : 

                <Fragment>
                
                    <div className="tabs">

                        {

                            typeData.__type.enumValues.map ( ( { name } ) => {

                                return (

                                    <span 
                                    
                                        key={ name } 
                                    
                                        className={ type === name ? "active" : "inactive" } 
                                    
                                        onClick={ () => changeType ( name ) }>
                                        
                                            { __ ( name ) }
                                        
                                    </span>

                                );

                            } )

                        }

                    </div>

                    { updateDataVars &&

                        <ProductFilters 
                    
                            category={ updateDataVars.productType }
                            
                            refetch={ updateDataVars.refetch }
                            
                            variables={ updateDataVars.variables }

                            loading={ false }
                            
                        />

                    }

                    <Products 
                    
                        productType={ type } 

                        setPreview={ setPreview }

                        preview={ preview }

                        persistantPreview={ persistantPreview }

                        setPersistantPreview={ setPersistantPreview }

                        updateData={ updateData }

                        products={ products }

                        setProducts={ setProducts }

                        orderData={ orderData }
                        
                    />

                </Fragment>

            }

        </div>

    );

},

Products = ( { productType, setPreview, preview, persistantPreview, setPersistantPreview, updateData, products, setProducts, orderData } ) => { const 

    { t } = useTranslation (),

    vendorQty = [ "qty", "qtyDays", "qtyHour", "qtyWeeks" ],

    /** 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { data, loading, variables, refetch } = useFetch ( PRODUCTS, { first: 9, productType }, "cache-first", true ),

    /**
     * 
     * @param { Object } node
     * 
     * @returns product preview
     * 
     * @description sets persistant product preview
     * 
     * @event onClick
     * 
     */

    onMouseClickProduct = ( node, e ) => {

        setPreview ( node );

        if ( e.target.tagName === "INPUT" ) return;

        if ( node.id === preview.id ) setPersistantPreview ( ! persistantPreview );

    },

    /**
     * 
     * @param { Object } node
     * 
     * @returns product preview
     * 
     * @description sets product preview
     * 
     * @event onMouseEnter
     * 
     */

    onMouseEnterProduct = node => {

        if ( ! persistantPreview ) setPreview ( node );

    },

    /**
     * 
     * @returns product preview
     * 
     * @description resets product preview
     * 
     * @event onMouseLeave
     * 
     */

    onMouseLeaveProduct = () => {

        if ( ! persistantPreview ) setPreview ( false );;

    },

    handleCheckbox = ( e, node ) => {

        const { name } = e.target;

        console.log ( node );

        if ( products.some ( el => el.productId === name ) ) {

            setProducts ( products.filter ( product => product.productId != name ) );

        } else {

            setProducts ( [ ...products, { 
                
                count: 1,

                price: node.priceList.retail_price,

                discount: 0,

                product: {

                    currentImage: node.currentImage,

                    id: node.id,

                    manufacturer: node.manufacturer,

                    model: node.model,

                    priceList: node.priceList,

                    productType: node.productType,

                    productVendors: node.productVendors,

                    vendorNames: node.vendors.map ( vendor => vendor.name ),

                    capacity: node.capacity,

                    diameter: node.diameter,

                    height: node.height,

                    width: node.width,

                    voltage: node.voltage,

                    current: node.current,

                    centreBore: node.centreBore,

                    boltPattern: node.boltPattern,

                    offset: node.offset,

                    isCTire: node.isCTire,

                    loadIndex: node.loadIndex,

                    pitchCircleDiameter: node.pitchCircleDiameter,

                    speedRating: node.speedRating,

                    year: node.year,

                    season: node.season,

                    runFlat: node.runFlat,

                    xl: node.xl,

                    specifications: node.specifications,

                    studs: node.studs,

                    prices: node.prices

                },

                productId: name
            
            } ] );

        }

    };

    useEffect ( () => {

        if ( ! loading ) {

            updateData ( {

                productType,

                variables,

                refetch,

            } );

        }

    }, [ loading, data ] );

    return (

        loading ? <CenterLoader /> :

        <Fragment>

            { ( typeof data != "undefined" ) &&
                
                <Pagination 
                    
                    pageinfo={ data.adminMultiFieldSearch.pageInfo } 

                    fallbackCount={ 8 }

                    cookie={ "orderProductsCollectionCount" }
                    
                    action={ refetch } /> 
            
            }

            <table>

                <thead>

                    <tr>

                        <th>#</th>

                        <th>{ t ( "product" ) }</th>

                        <th>{ t ( "qty" ) }</th>

                        <th>{ t ( "qtyDays" ) }</th>

                        <th>{ t ( "qtyHour" ) }</th>

                        <th>{ t ( "qtyWeeks" ) }</th>

                        <th>{ t ( "price" ) }</th>

                    </tr>

                </thead>

                <tbody>

                    {

                        data.adminMultiFieldSearch.edges.map ( ( { node } ) => { 
                            
                            return (

                                <tr 

                                    className={ persistantPreview ? preview.id === node.id ? "browsing" : "default" : "default" }

                                    key={ node.id }

                                    onClick={ e => { onMouseClickProduct ( node, e ) } }
                                
                                    onMouseEnter={ () => { onMouseEnterProduct ( node ) } } 
                                
                                    onMouseLeave={ onMouseLeaveProduct }
                                    
                                    >

                                        <td>

                                            {

                                                /** Check if node.id exists in orderData.view.orderProductVendors object */
                                                
                                                orderData.view.orderProductVendors.some ( el => el.productId === node.id ) ?

                                                    <AiOutlineCloseCircle 
                                                        
                                                        size={ 16 } />

                                                :

                                                <Checkbox
                                            
                                                    id={ node.id }

                                                    state={ products.some ( el => el.productId == node.id ) ? true : false }

                                                    action={ e => handleCheckbox ( e, node ) }

                                                    hidelabel={ true }
                                                
                                                />

                                            }

                                        </td>

                                        <td>{ `${ node.manufacturer.name.toUpperCase () } ${ node.model }` }</td>

                                        {

                                            vendorQty.map ( ( qty, index ) => {

                                                return (

                                                    <td key={ index }>

                                                        { calculateTotal ( node, qty ) ? calculateTotal ( node, qty ) : "-" }

                                                    </td>

                                                )

                                            } )

                                        }

                                        <td>&euro; { node.priceList.retail_price }</td>

                                </tr>

                            );

                        } )

                    }

                </tbody>

            </table>

            { ( typeof data != "undefined" ) &&
                
                <Pagination 
                    
                    pageinfo={ data.adminMultiFieldSearch.pageInfo } 

                    fallbackCount={ 8 }

                    cookie={ "orderProductsCollectionCount" }
                    
                    action={ refetch } /> 
            
            }

        </Fragment>

    );

},

calculateTotal = ( data, qty ) => {

    return data.productVendors.reduce ( ( acc, cur ) => acc + cur[ qty ], 0 );

};

/** @exports OrderProductTable */
 
export default OrderProductTable;