import styles from "../ProductPreview.module.css";

export default function ContentSection ( { children, title } ) {

    return (
    
        <div className={ styles.contentSection }>
        
            <div className={ styles.contentTitle }>

                { title }

            </div>

            <div className={ styles.contentChildren }>

                { children }

            </div>
            
        </div>
        
    );

};