/** @module                             Hooks */

import { useTranslation }               from "react-i18next";

import { usePagename }                  from "../../Hooks/usePagename";

import { useState, useEffect }          from "react";

import useFetch                         from "../../Hooks/useFetch";

import { useParams, useNavigate }       from "react-router-dom";

import useUpdate                        from "../../Hooks/Mutations/useUpdate";

import useRemove                        from "../../Hooks/Mutations/useRemove";

/** @module                             GQL */

import { PAGE }                         from "../../Queries/Pages";

import { UPDATE_PAGE }                  from "../../Mutations/Pages";

/** @module                             Components */

import Breadcrumbs                      from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                        from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                         from "../../Components/Dashboard/Global/Forms/Fieldset";

import ComponentLoader                  from "../../Components/Dashboard/Loaders/ComponentLoader";

import Attributes                       from "../../Components/Dashboard/Global/Forms/Fieldset/Attributes";

import { Input }                        from "../../Components/Dashboard/Global/Forms/Inputs_Spread";

import { Editor }                       from "@tinymce/tinymce-react";

const 

/** @constant DEFINITIONS */

DEFINITIONS = {

    query                           : PAGE,

    update_query                    : UPDATE_PAGE,

    paramId                         : "pageId",

    entryName                       : "adminFrontendPage",

    state_collection                : [ 
        
        "pageType", 

        "language",

        "content",
    
        "id" 
    
    ]

},

/**
 * 
 * @param { String } name
 * 
 * @returns update mutation
 * 
 */

EditPage = ( { name } ) => { usePagename ( name ); const 

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( DEFINITIONS.query, { 

        id: useParams () [ DEFINITIONS.paramId ]

    } );

    /** @returns */

    return (

        loading ? <section>

            <ComponentLoader />

        </section> 
        
        :

        <UpdateEntry entry={ data [ DEFINITIONS.entryName ] } />

    );

},

/**
 * 
 * 
 * @param { Object } entry
 * 
 * @returns update mutation
 * 
 */

UpdateEntry = ( { entry } ) => { const 

    { type, language } = useParams (),
    
    { t } = useTranslation (),

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading, 
        
        complete, 
        
        errors, 
        
        setErrors 
    
    } = useUpdate ( DEFINITIONS.update_query ),

    /** @event useState form fields */

    [ state, setState ] = useState ( generateEntryState ( entry, DEFINITIONS.state_collection ) ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( 

            ! state.language ||

            ! state.pageType ||

            ! state.content ||

            ! state.id
            
        ) { return setErrors ( true ); }

        updateMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @returns */

    return (

        <section className="mutations update_entry">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 

                        entry={ entry }
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }

                        success={ complete }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "content" ) }>

                        <Editor 

                            apiKey="2nr6f475ij0nazn91dwmt3x6fpevnv36no701uj3ap6wgzfn"
                        
                            onEditorChange={ ( newValue ) => setState ( prev => ( { ...prev, content: newValue } ) ) } 

                            value={ state.content }

                            plugins={ [

                                "charmap",

                                "emoticons",

                                "image",

                                "insertdatetime",

                                "link",

                                "lists",

                                "media",

                                "preview",

                                "searchreplace",

                                "table",

                                "visualblocks",

                                "visualchars",

                                "wordcount"

                            ] }

                            init={ {

                                selector:'textarea',

                                skin: "oxide-dark",

                                content_css: "dark",

                                image_title: true,

                                automatic_uploads: true,

                                file_picker_types: 'image',

                                file_picker_callback: function (cb, value, meta) {

                                    var input = document.createElement('input');

                                    input.setAttribute('type', 'file');

                                    input.setAttribute('accept', 'image/*');

                                    input.onchange = function () {

                                    var file = this.files[0];

                                    var reader = new FileReader();

                                    reader.onload = function () {

                                        var id = 'blobid' + (new Date()).getTime();
                                        var blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
                                        var base64 = reader.result.split(',')[1];
                                        var blobInfo = blobCache.create(id, file, base64);
                                        blobCache.add(blobInfo);

                                        cb(blobInfo.blobUri(), { title: file.name });
                                    };

                                    reader.readAsDataURL(file);
                                    
                                    };

                                    input.click();
                                },

                            } }
                            
                        />

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

/**
 * 
 * @param { Object } props
 * 
 * @returns sidebar component
 * 
 */

Sidebar = ( { 
    
    __, 
    
    state           = "", 

    entry           = {},
    
    changeAction    = () => { return },

    errors          = false,

    pending         = false,

    success         = false

} ) => {

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { success && ! errors &&
                
                    <div className="success">

                        <div className="success_message">

                            { __ ( "successfully_updated" ) }

                        </div>

                    </div> 
                
                }

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending }>

                        { __ ( "submit" ) }

                    </button>

                </div>

            </Fieldset>

        </aside>

    );

},

/**
 * 
 * @param { Object } entry
 * 
 * @param { Array } collection
 * 
 * @returns state object
 * 
 */

generateEntryState = ( entry, collection ) => { let 

    state = {};

    collection.forEach ( item => {

        state [ item ] = entry [ item ];

    } );

    return state;

};

/** @exports EditPage */
 
export default EditPage;