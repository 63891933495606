/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { useEffect, useState }      from "react";

import { useNavigate }              from "react-router-dom";

import useCreate                    from "../../../Hooks/Mutations/useCreate";

/** @module                         GQL */

import { CREATE_VENDOR }            from "../../../Mutations/Vendors";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import Checkbox                     from "../../../Components/Dashboard/Global/Forms/Checkbox";

import { Input }                    from "../../../Components/Dashboard/Global/Forms/Inputs_Spread";

const 

/**
 * 
 * @returns create mutation
 * 
 */

CreateVendor = ( { name } ) => { 

    /** @event usePagename */

    usePagename ( name );
    
    const 
    
    { t }       = useTranslation (),

    navigate    = useNavigate (),

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors, 
        
        setErrors 
    
    } = useCreate ( CREATE_VENDOR ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        name        : "",

        addPvn      : false,

        visible     : true,

        markup      : 0

    } ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( ! state.name ) { return setErrors ( true ); }
        
        createMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( name === "markup" ) return setState ( prev => ( { ...prev, [ name ]: parseInt ( value ) } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( `/app/shop/suppliers/edit/${ returnData.adminCreateVendor.vendor.id }` );

    }, [ complete ] );

    /** @returns */

    return (

        <section className="mutations create_supplier">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "title" ) }>

                        <div className="input-group">

                            <input
                            
                                type="text"

                                value={ state.name }

                                onChange={ handleChange }

                                name="name"
                            
                            />

                        </div>

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

Sidebar = ( { 
    
    __, 
    
    state = "", 
    
    changeAction = () => { return },

    errors = false,

    pending = false

} ) => {

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending }>

                        { __ ( "submit" ) }

                    </button>

                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "options" ) }>

                <Checkbox 
                
                    id="addPvn" 
                
                    text={ __ ( "addPvn" ) } 
                
                    state={ state.addPvn } 
                
                    action={ changeAction } />

                <Checkbox 
                
                    id="visible" 
                
                    text={ __ ( "visible" ) } 
                
                    state={ state.visible } 
                
                    action={ changeAction } />

            </Fieldset>

            <Fieldset legend={ __ ( "markup" ) }>

                 <Input
                    
                        value={ state.markup } 

                        action={ changeAction }

                        name="markup"
                        
                    />

            </Fieldset>

        </aside>

    );

}

/** @exports CreateVendor */
 
export default CreateVendor;