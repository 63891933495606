import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function DocumentNotices
 * 
 * @description Export the notices of the document as a React Component
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentNotices ( { type = "invoice" } ) {

    /**
     * 
     * @description 
     * 
     * Render the notices of the document as a React Component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        type === "offer" ?

            <View>

                <Text>Lai apstiprinātu piedāvājumu sazinaties ar savu klientu menedžeri, kurš Jums nosūtijis šo piedāvājumu.</Text>

                <Text><Text style={ { fontWeight: 500 } }>Piezīme!</Text> Piedāvājums, ir spēkā 24 stundas. Norādītajām cenām ir ilustratīvs raksturs. Šis dokuments nav rēķins.</Text>

            </View>

        :

            <View>

                <Text>Lai paātrinātu pasūtījuma apstrādes procesu,</Text>

                <Text>lūgums, pēc iespējas ātrāk nosūtīt mums maksājuma uzdevumu!</Text>

            </View>

    );

};