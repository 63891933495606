/** @module                             Components */

import Fieldset                         from "../../Global/Forms/Fieldset";

import { LabeledInput }                 from "../../Global/Forms/Inputs_Spread";

const 

BatterySettings = ( { __, state, action, loading } ) => { const 

    /** @constant fields */

    fields = [ "voltage", "capacity", "current" ];

    /** @returns BatterySettings component */

    return (

        <Fieldset legend={ __ ( "battery_information" ) }>

            <div className="grid grid_template-columns-3">

            { 
            
                fields.map ( 
                    
                    ( quantity, index ) => 

                    <LabeledInput

                        key={ index }

                        type="number"

                        label={ __ ( quantity ) }
                            
                        value={ state [ quantity ] } 

                        action={ action }

                        name={ quantity }

                        disabled={ loading }
                        
                    />

                ) 
            
            }

            </div>

        </Fieldset>

    );

}

/** @exports BatterySettings */
 
export default BatterySettings;