/** @module                 React */

import { 
    
    useEffect, 
    
    useContext }            from "react";

import { PagenameContext }  from "../Contexts/Pagename";

/**
 * 
 * @param { String } name 
 * 
 * @returns hook that updates current page name
 * 
 */

export const usePagename = ( name ) => { const 

    /** fetch current page context */

    { setPagename } = useContext ( PagenameContext );

    /** @event useEffect */

    useEffect ( () => { setPagename ( name ) } );

}