import "../../../Styles/Scheduler.css";

import { Calendar, momentLocalizer, Views } from "react-big-calendar";

import moment from "moment";

import { useNavigate } from "react-router-dom";

const 

localizer = momentLocalizer ( moment ),

Schedule = ( { appointments } ) => { const 

    navigate = useNavigate (),

    openAppointment = event => navigate ( `/app/montage/edit/${ event.id }` );

    /** @returns */

    return ( <div className="scheduler">

        <Calendar

            localizer={localizer}

            events={ eventList ( appointments ) }

            defaultView={Views.DAY}

            min={ new Date( 1972, 0, 1, 9, 0, 0, 0 ) }

            max={ new Date( 1972, 0, 1, 18, 0, 0, 0 ) }

            startAccessor="start"

            endAccessor="end"

            onSelectEvent={ openAppointment }

        />

    </div> );

},

/**
 * 
 * @param { Object } data
 * 
 * @returns array object of calendar events
 * 
 */

eventList = data => { const events = [];

    data.edges.forEach ( element => {

        events.push ( { 

            id: element.node.id,
            
            start: new Date ( element.node.startTime ), 
            
            end: new Date ( element.node.endTime ), 
            
            title: `${ element.node.autoBrand } ${ element.node.autoModel } - ${ element.node.autoNumber } [${ element.node.transportType }]`
        
        } );

    } );

    /** @returns */

    return events;

}
 
/** @exports Schedule */

export default Schedule;