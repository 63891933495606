/** @module                     Hooks */

import { usePagename }          from "../../Hooks/usePagename";

import { useTranslation }       from "react-i18next";

import { 
    
    useEffect, 
    
    useRef, 
    
    useState }                  from "react";

import useFetch                 from "../../Hooks/useFetch";

import useCreateManager         from "../../Hooks/Managers/useCreateManager";

/** @module                     Queries */

import { MANAGERS_ROLE_ENUM }   from "../../Queries/Managers";

/** @module                     Components */

import Breadcrumbs              from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import ComponentLoader          from "../../Components/Dashboard/Loaders/ComponentLoader";

import { 
    
    LabeledInput }              from "../../Components/Dashboard/Global/Forms/Inputs";

import Fieldset                 from "../../Components/Dashboard/Global/Forms/Fieldset";

import Sidebar                  from "../../Components/Dashboard/Managers/Add/Sidebar";

import Notice                   from "../../Components/Dashboard/Global/Notice";

/** @module                     Utilities */

import { disableAttribute }     from "../../Components/Dashboard/Utils/disableAttribute";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns add manager page component
 * 
 */

AddManagers = ( { name } ) => { const 

    /** @module         Hooks */

    { t }               = useTranslation (),

    { data, loading }   = useFetch ( MANAGERS_ROLE_ENUM );

    /** @event usePagename */

    usePagename ( t ( name ) );

    /** @returns */

    return ( <section className="mutations add manager">

        <Breadcrumbs data={ [

            { 
                
                name    : t ( "managers" ), 
                
                uri     : "/app/managers/active"       
            
            },

            { 
                
                name    : t ( name ), 
                
                uri     : `/app/managers/add`    
            
            }

        ] } />

        { loading ? 
        
            <ComponentLoader /> 
            
            : 
            
            <Component data={ data } __={ t } /> 
            
        }

    </section> );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Function } __
 * 
 * @returns add manager page content component
 * 
 */

Component = ( { data, __ } ) => { const

    /** @module                 States */

    [ email,                    setEmail                    ] = useState ( "" ),

    [ firstName,                setFirstName                ] = useState ( "" ),

    [ lastName,                 setLastName                 ] = useState ( "" ),

    [ password,                 setPassword                 ] = useState ( "" ),

    [ passwordConfirmation,     setPasswordConfirmation     ] = useState ( "" ),

    [ role,                     setRole                     ] = useState ( data.__type.enumValues[0].name ),

    [ blocked,                  setBlocked                  ] = useState ( false ),

    [ blockedReason,            setBlockedReason            ] = useState ( "" ),

    /** @module                 References */

    emailRef                    = useRef ( null ),

    passwordRef                 = useRef ( null ),

    passwordConfirmationRef     = useRef ( null ),

    firstNameRef                = useRef ( null ),

    lastNameRef                 = useRef ( null ),

    roleRef                     = useRef ( null ),
    
    submitRef                   = useRef ( null ),

    blockedRef                  = useRef ( null ),

    blockedReasonRef            = useRef ( null ),

    /** @module                 Hooks */

    { 
        
        createManager, 
        
        loading, 
        
        errors 
    
    } = useCreateManager (),

    /** @event onSubmit */

    submitManager = () => createManager (

        email,

        firstName,

        lastName,

        password,

        passwordConfirmation,

        role,

        blocked,

        blockedReason

    );

    /** @event useEffect */

    useEffect ( () => {

        if ( loading ) {

            disableAttribute ( [ emailRef, roleRef, passwordRef, passwordConfirmationRef, submitRef, firstNameRef, lastNameRef ], "add" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = `${ __ ( "submitting" ) }...`;

            }

        } else { 

            disableAttribute ( [ emailRef, roleRef, passwordRef, passwordConfirmationRef, submitRef, firstNameRef, lastNameRef ], "remove" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = __ ( "submit" );

            }
    
        }

    }, [ loading ] );
    
    /** @returns */

    return ( 
    
        <AsideEdit aside={ 
        
            <Sidebar 
            
                data={ data.__type.enumValues } 
                
                details={ 
                    
                    {

                        role : { 
                            
                            view        : role, 
                            
                            set         : setRole, 
                            
                            reference   : roleRef 
                        
                        },
                        
                        blocked : {

                            view        : blocked,

                            set         : setBlocked,

                            reference   : blockedRef

                        },

                        blockedReason : {

                            view        : blockedReason,

                            set         : setBlockedReason,

                            reference   : blockedReasonRef

                        },

                        submit : { 
                            
                            reference   : submitRef, 
                            
                            action      : submitManager 
                        
                        }
            
                    } 
            
                } 
            
            /> 
            
        }>

            { errors && <Notice type="danger">

                { __ ( "error_ocurred" ) }

            </Notice> }

            <Fieldset legend={ __ ( "user_information" ) }>

                <div className="grid .grid_template-columns-3">

                    <LabeledInput
                        
                        value={ email }

                        label={ __ ( "email" ) }
            
                        action={ e => setEmail ( e ) }
            
                        ref={ emailRef }
                    
                    />

                    <LabeledInput
                        
                        value={ firstName }

                        label={ __ ( "firstName" ) }
            
                        action={ e => setFirstName ( e ) }
            
                        ref={ firstNameRef }
                    
                    />

                    <LabeledInput
                        
                        value={ lastName }

                        label={ __ ( "lastName" ) }
            
                        action={ e => setLastName ( e ) }
            
                        ref={ lastNameRef }
                    
                    />

                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "security" ) }>

                <LabeledInput

                    type="password"

                    label={ __ ( "new_password" ) }
                    
                    value={ password }
        
                    action={ e => setPassword ( e ) }
        
                    ref={ passwordRef }
                
                />

                <LabeledInput

                    type="password"

                    label={ __ ( "confirm_new_password" ) }
                    
                    value={ passwordConfirmation }
        
                    action={ e => setPasswordConfirmation ( e ) }
        
                    ref={ passwordConfirmationRef }
                
                />

            </Fieldset>
            
        </AsideEdit>  
        
    )

}
 
/** @exports AddManagers */

export default AddManagers;