import styles from "./RetailPrice.module.css";

import { useTranslation } from "react-i18next";

export default function RetailPrice ( { product } ) {

    const 
    
    { t : __ } = useTranslation (),

    retailPrice = parseFloat ( product.originalPrice || product.product.priceList.retail_price ).toFixed ( 2 );

    return (

        <td className={ styles.container }>

            <div className={ styles.price }>
                
                &euro; { retailPrice }
                
            </div>

            <div className={ styles.description }>
                
                { __ ( "vat_included" ) }
                
            </div>

        </td>

    );

}