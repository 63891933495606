import { createContext, useState } from "react";

export const CartContext = createContext ( null );

/**
 * 
 * @exports CartContextProvider
 * 
 * @description Cart context provider.
 * 
 * @example <CartContextProvider />
 * 
 */

export function CartContextProvider ( { children } ) { const 

    [ cart, setCart ] = useState ( JSON.parse ( localStorage.getItem ( "cart" ) ) || [] ),

    /**
     * 
     * @param { object } item
     * 
     * @returns { void }
     * 
     * @description The addToCart function adds an item to the cart.
     * 
     * @example addToCart ( { id: 1, qty: 1 } )
     * 
     */

    addToCart = ( item ) => {

        setCart ( [ ...cart, item ] );

        localStorage.setItem ( "cart", JSON.stringify ( [ ...cart, item ] ) );

    },

    /**
     * 
     * @param { number } productId
     * 
     * @returns { void }
     * 
     * @description The removeFromCart function removes an item from the cart.
     * 
     * @example removeFromCart ( 1 )
     * 
     */

    removeFromCart = ( productId ) => {

        setCart ( cart.filter ( ( i ) => i.id !== productId ) );

        localStorage.setItem ( "cart", JSON.stringify ( cart.filter ( ( i ) => i.id !== productId ) ) );

    },

    updateQuantity = ( productId, quantity ) => {

        setCart ( cart.map ( ( item ) => item.id === productId ? { ...item, count: quantity } : item ) );

        localStorage.setItem ( "cart", JSON.stringify ( cart.map ( ( item ) => item.id === productId ? { ...item, count: quantity } : item ) ) );

    },

    /**
     * 
     * @returns { void }
     * 
     * @description The clearCart function clears the cart.
     * 
     * @example clearCart ()
     * 
     */

    clearCart = () => {

        setCart ( [] );

        localStorage.removeItem ( "cart" );

    };

    /** @returns { JSX.Element } CartContextProvider */

    return ( 
    
        <CartContext.Provider value={ { cart, addToCart, removeFromCart, updateQuantity, clearCart } }>
            
            { children }
            
        </CartContext.Provider> 
        
    );

}