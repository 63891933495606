export const 

/**
 * 
 * @param { Array } references 
 * 
 * @description array contains the element references
 * 
 * @param { String } action 
 * 
 * @returns disable or enable HTML form elements
 * 
 */

disableAttribute = ( 
    
    references  = [], 
    
    action      = "default" 
    
) => {

    switch ( action ) {

        /** @returns disabled elements */

        case "add" :
                
            return references.forEach ( 

                element => { 
                    
                    if ( element.current ) element.current.setAttribute ( "disabled", "disabled" ) 
                
                } 
                
            );

        /** @returns enabled elements */

        case "remove" : 
        
            return references.forEach ( 
                
                element => { 
                    
                    if ( element.current ) element.current.removeAttribute ( "disabled" ) 
                
                } 
                
            );

        /** @returns */

        default : return;

    }

}