import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Countries = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Countries page */

    return ( <section className="countries">

        Countries page

    </section> );

}

/** @exports Countries */
 
export default Countries;