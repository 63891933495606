import styles from "../ProductPreview.module.css";

/**
 * 
 * @exports ProductImage component
 * 
 * @param product 
 * 
 * @returns product image component layout. 
 * 
 */

export default function ProductImage ( { product } ) {

    /**
     * 
     * @returns product image component layout.
     * 
     */

    return (

        <div className={ styles.image }>

            <img src={ product?.currentImage } alt={ product.name } />

        </div>

    );

};