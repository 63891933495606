const Footer = () => {

    /** @return Footer page */

    return ( <footer>

        All rights reserved &copy; Tire Market SIA { new Date ().getFullYear () } - Build: 0.1.2

    </footer> );

}

/** @exports Footer */
 
export default Footer;