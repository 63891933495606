import styles from "../ProductPreview.module.css";

import { useTranslation } from "react-i18next";

import ContentSection from "./ContentSection";

import Item from "./Item";

export default function ProductOptions ( { product } ) { const 

    { t } = useTranslation (),

    items = {

        tire: [

            { title: "speedRating", value: product.speedRating },

            { title: "noiseIndex", value: product.noiseIndex },

            { title: "loadIndex", value: product.loadIndex },

            { title: "fuelEfficiency", value: product.fuelEfficiency },

            { title: "wetBreakingEfficiency", value: product.wetBreakingEfficiency },

            { title: "year", value: product.year },

            { title: "runFlat", value: product.runFlat },

            { title: "studs", value: product.studs },

        ],

        rim: [

            { title: "width", value: product.width },

            { title: "diameter", value: parseInt ( product.diameter ) },

            { title: "offset", value: product.offset },

            { title: "pitchCircleDiameter", value: product.pitchCircleDiameter },

        ],

        battery: [

            { title: "voltage", value: product.voltage },

            { title: "current", value: product.current },

            { title: "capacity", value: product.capacity }

        ]

    };

    /**
     * 
     * @returns product preview options component layout.
     * 
     */

    return (

        <ContentSection title={ "Options" }>

            <div className={ styles.optionsGrid }>
                
                { items[ product.productType ].map ( ( item, index ) =>

                    <Item title={ t ( item.title ) } key={ index }>
                        
                        { typeof item.value === "boolean" ? ( item.value ) ? t ( "yes" ) : t ( "no" ) : ( ! item.value || item.value === null ) ? "-" : t ( item.value.toString ().toUpperCase () ) }
                        
                    </Item>

                ) }

            </div>

        </ContentSection>

    );

};