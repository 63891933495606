import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Delivery = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Delivery page */

    return ( <section className="delivery">

        Delivery page

    </section> );

}

/** @exports Delivery */
 
export default Delivery;