/** @module                 Router */

import { 
    
    Link, 
    
    useLocation }           from "react-router-dom";

/** @module                 React-Icons */

import { TbCaretRight       }  from "react-icons/tb";

/** @module                 i18n */

import { useTranslation }   from "react-i18next";

const 

/**
 * 
 * @returns application breadcrumbs
 * 
 */

Breadcrumbs = () => { let currentLink = ""; const 

    { t } = useTranslation (),

    location = useLocation (),
    
    crumbs = location.pathname.split ( "/" ).filter ( crumb => crumb !== "" ).map ( ( crumb, index, { length } ) => {

        currentLink = currentLink + `/${ crumb }`;

        return ( ( length - 1 === index ) ? 
        
            <div className="crumb active-page" key={ `breadcrumb-${ index }` }>

                { t ( crumb ) }

            </div> 
        
        : 
        
            <div className="crumb" key={ `breadcrumb-${ index }` }>

                <Link to={ currentLink }>{ t ( crumb ) }</Link>

                <span>

                    <TbCaretRight />

                </span>

            </div> );

    } );

    return ( <div className="breadcrumbs">

        { crumbs }

    </div> );

}

/** @exports Breadcrumbs */
 
export default Breadcrumbs;