/** @module                         Hooks */

import { Fragment }                 from "react";

import { useTranslation }           from "react-i18next";

/** @module                         Apollo */

import useFetch                     from "../../../../../Hooks/useFetch";

/** @module                         Components */

import CenterLoader                 from "../../../Loaders/CenterLoader";

import { productCleaner }           from "../../../Utils/NullCleaner";

const 

/**
 * 
 * @param { Object } atts 
 * 
 * @returns single entry data modal component
 * 
 */

Information = ( { atts } ) => { const

    { t } = useTranslation (),

    /** @event useFetch */

    { data, loading } = useFetch ( atts.query, { id: atts.id } );

    /** @returns */

    return ( ( loading ) ? <CenterLoader /> : <Fields entryData={ data[ Object.keys ( data )[ 0 ] ] } __={ t } /> );

},

/**
 * 
 * @param { Object } entryData
 * 
 * @param { Function } __
 * 
 * @returns attribute list component
 * 
 */

Fields = ( { entryData, __ } ) => {

    return ( <div className="modal-settings-container">

        { productCleaner ( entryData ).map ( ( [ key, value ], index ) => <Fragment key={ index }>

            <TextAttribute atts={ [ key, value ] } __={ __ } />

        </Fragment> ) }
    
    </div> )

},

/**
 * 
 * @param { Array } atts
 * 
 * @param { Function } __
 * 
 * @returns single attribute component
 * 
 */

TextAttribute = ( { atts, __ } ) => { const [ key, value ] = atts;

    return ( ( key !== "__typename" && typeof value !== "object" ) && 
    
        <div className="modal-attribute">

            <div className="modal-attribute-name">

                { __ ( key ) }

            </div>

            <div className="modal-attribute-value">

                { typeof value === "boolean" ? ( value ) ? __ ( "yes" ) : __ ( "no" ) : 
                
                    ( key === "createdAt" || key === "updatedAt" ) ? new Date ( value ).toLocaleString () : value

                }

            </div>
        
        </div> 
    
    )

}

export default Information;