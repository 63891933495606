const 

/**
 * 
 * @param { Object } price_list
 * 
 * @returns product table price list component
 * 
 */

PriceList = ( { price_list } ) => { const 

    keys = [ 
        
        "original_price", 
        
        "retail_price" 
    
    ];

    /** @returns */

    return ( 
        
        keys.map ( ( price, index ) => {

            return (

                <td key={ index }>

                    &euro; { price_list [ price ] }

                </td>

            );

        } ) 

    );

}

/** @exports PriceList */
 
export default PriceList;