/** @module             Apollo */

import { 
  
  ApolloClient, 
  
  createHttpLink, 
  
  InMemoryCache }       from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';

import { setContext }   from '@apollo/client/link/context';

const 

/**
 * 
 * @returns apollo authorization context
 * 
 */

authLink = setContext ( ( _, { headers } ) => { const 

  token = localStorage.getItem ( "token" );

  return {

    headers : {

      ...headers,

      authorization : token ? `Bearer ${ token }` : ""

    }

  }

} ),

/**
 * 
 * @returns apollo configuration
 * 
 */

Apollo = new ApolloClient ( {

  link  : authLink.concat ( 
    
    createUploadLink ( {

      uri         : process.env.REACT_APP_GQL_URI,
    
      credentials : process.env.REACT_APP_GQL_CRED
  
    } ) 
  
  ),

  cache : new InMemoryCache ()

} );

/** @exports Apollo */

export default Apollo;