/** @module                     Hooks */

import { usePagename }          from "../../../Hooks/usePagename";

import { useTranslation }       from "react-i18next";

import { 
    
    useEffect, 
    
    useRef, 
    
    useState }                  from "react";

import { useParams }            from "react-router-dom";

import useFetch                 from "../../../Hooks/useFetch";

import useEditPost              from "../../../Hooks/Posts/useUpdatePost";

import useDeletePost            from "../../../Hooks/Posts/useDeletePost";

/** @module                     Queries */

import { POST }                 from "../../../Queries/Posts";

/** @module                     Components */

import Breadcrumbs              from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import ComponentLoader          from "../../../Components/Dashboard/Loaders/ComponentLoader";

import { 
    
    Input, 
    
    LabeledInput }              from "../../../Components/Dashboard/Global/Forms/Inputs";

import Fieldset                 from "../../../Components/Dashboard/Global/Forms/Fieldset";

import Sidebar                  from "../../../Components/Dashboard/Content/Posts/Edit/Sidebar";

import Notice                   from "../../../Components/Dashboard/Global/Notice";

import { Editor }               from "@tinymce/tinymce-react";

/** @module                     Utilities */

import { disableAttribute }     from "../../../Components/Dashboard/Utils/disableAttribute";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns edit manager page component
 * 
 */

EditPost = ( { name } ) => { usePagename ( name ); const 

    /** @module         Hooks */

    { postId }          = useParams (),

    { t }               = useTranslation (),

    { data, loading }   = useFetch ( POST, { id: postId } );

    /** @returns */

    return ( <section className="mutations edit manager">

        <Breadcrumbs data={ [

            { name: t ( "posts"     ), uri: "/app/content/posts"                         },

            { name: t ( name        ), uri: `/app/content/posts/edit/${ postId }`       }

        ] } />

        { loading ? <ComponentLoader /> : <Component data={ data } __={ t } /> }

    </section> );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Function } __
 * 
 * @returns edit manager page content component
 * 
 */

Component = ( { data, __ } ) => { const

    /** @module                 States */

    [ title,                    setTitle            ] = useState ( data.adminBlogItem.title ),

    [ content,                  setContent          ] = useState ( data.adminBlogItem.content ),

    [ description,              setDescription      ] = useState ( data.adminBlogItem.description ),

    [ image,                    setImage            ] = useState ( data.adminBlogItem.image ),

    [ isPublished,              setIsPublished      ] = useState ( true ),

    [ tiremarketOnly,           setTiremarketOnly   ] = useState ( false ),

    [ language,                 setLanguage         ] = useState ( data.adminBlogItem.language || "" ),

    /** @module                 References */

    titleRef                    = useRef ( null ),

    contentRef                  = useRef ( null ),

    imageRef                    = useRef ( null ),

    descriptionRef              = useRef ( null ),

    isPublishedRef              = useRef ( null ),
    
    tiremarketOnlyRef           = useRef ( null ),

    languageRef                 = useRef ( null ),

    submitRef                   = useRef ( null ),

    removeRef                   = useRef ( null ),

    /** @module                 Hooks */

    { updatePost, loading, errors, success } = useEditPost (),

    { deletePost, loading : deleteLoading, errors : deleteErrors } = useDeletePost (),

    /** @event onSubmit */

    submitPost = () => {

        updatePost (

            data.adminBlogItem.id,

            title,

            content,

            image,

            description,

            isPublished,

            tiremarketOnly,

            language

        );

    },

    /** @event onDelete */

    submitRemoval = () => {

        deletePost ( data.adminBlogItem.id );

    };

    /** @event useEffect */

    useEffect ( () => {

        if ( loading ) {

            disableAttribute ( [ titleRef, descriptionRef, isPublishedRef, tiremarketOnlyRef, languageRef ], "add" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = `${ __ ( "submitting" ) }...`;

            }

        } else { 

            disableAttribute ( [ titleRef, descriptionRef, isPublishedRef, tiremarketOnlyRef, languageRef ], "remove" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = __ ( "submit" );

            }
    
        }

    }, [ loading ] );
    
    /** @returns */

    return ( <AsideEdit aside={ <Sidebar data={ data.adminBlogItem } details={ {

        isPublished     : { view: isPublished, set: setIsPublished, reference: isPublishedRef },
        
        tiremarketOnly  : { view: tiremarketOnly, set: setTiremarketOnly, reference: tiremarketOnlyRef },

        language        : { view: language, set: setLanguage, reference: languageRef },

        image           : { view: image, set: setImage, reference: imageRef },

        submit          : { reference: submitRef, action: submitPost },

        remove          : { reference: removeRef, action: submitRemoval }
        
        } } /> }>

            { errors && <Notice type="danger">

                { __ ( "failed_save" ) }

            </Notice> }

            { deleteErrors && <Notice type="danger">

                { __ ( "failed_delete" ) }

            </Notice> }

            { success && <Notice type="success">

                { __ ( "save_success" ) }

            </Notice> }

            <Fieldset legend={ __ ( "title" ) }>

                <Input
                    
                    value={ title }

                    action={ e => setTitle ( e ) }

                    ref={ titleRef }

                />

            </Fieldset>

            <Fieldset legend={ __ ( "content" ) }>

                <Editor 

                    apiKey="2nr6f475ij0nazn91dwmt3x6fpevnv36no701uj3ap6wgzfn"

                    onEditorChange={ ( newValue ) => setContent ( newValue ) } 

                    value={ content }

                    plugins={ [

                        "charmap",

                        "emoticons",

                        "image",

                        "insertdatetime",

                        "link",

                        "lists",

                        "media",

                        "preview",

                        "searchreplace",

                        "table",

                        "visualblocks",

                        "visualchars",

                        "wordcount"

                    ] }

                    init={ {

                        selector:'textarea',

                        skin: "oxide-dark",

                        content_css: "dark",

                        image_title: true,

                        automatic_uploads: true,

                        file_picker_types: 'image',

                        file_picker_callback: function (cb, value, meta) {

                            var input = document.createElement('input');

                            input.setAttribute('type', 'file');

                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {

                            var file = this.files[0];

                            var reader = new FileReader();

                            reader.onload = function () {

                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                cb(blobInfo.blobUri(), { title: file.name });
                            };

                            reader.readAsDataURL(file);
                            
                            };

                            input.click();
                        },

                    } }
                    
                />

            </Fieldset>

            <Fieldset legend={ __ ( "description" ) }>

                <Input
                    
                    value={ description }

                    action={ e => setDescription ( e ) }

                    ref={ descriptionRef }

                />

            </Fieldset>
        
        </AsideEdit>  )

}
 
/** @exports EditPost */

export default EditPost;