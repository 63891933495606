/** @module                     Hooks */

import { useState }             from "react";

import { useTranslation }       from "react-i18next";

/** @module                     Components */

import SelectSpread             from "../Forms/Select_Spread";

import { Input }                from "../Forms/Inputs_Spread";

/** @module                     React-Icons */

import { 
    
    BiChevronRight, 
    
    BiChevronLeft,

    BiChevronsLeft }            from "react-icons/bi";

    import { FaSearch }         from "react-icons/fa";

const 

/**
 * 
 * @param { Object } pageinfo
 * 
 * @param { Function } action
 * 
 * @returns table pagination
 * 
 */

Pagination = ( { 
    
    pageinfo, 
    
    action, 
    
    cookie, 
    
    fallbackCount = 12,

    stringSearch = false

} ) => { const { t } = useTranslation (),

    /** @event useState */

    [ stringValue, setStringValue ] = useState ( "" ),

    /** @returns refetch for the first catalog page */

    first = e => { e.preventDefault (); action ( 
        
        { 
        
            first   : JSON.parse ( localStorage.getItem ( cookie ) ) || fallbackCount, 
            
            last    : undefined, 
            
            before  : undefined, 
            
            after   : undefined 
    
        } 
        
    ) },  

    /** @returns refetch for the next catalog page */

    next = e => { e.preventDefault (); action ( 
        
        { 
        
            first   : JSON.parse ( localStorage.getItem ( cookie ) ) || fallbackCount, 
            
            last    : undefined, 
            
            before  : undefined, 
            
            after   : pageinfo.endCursor 
    
        } 
        
    ) },

    /** @returns refetch for the previous catalog page */

    previous = e => { e.preventDefault (); action ( 
        
        { 
        
            first   : undefined, 
            
            last    : JSON.parse ( localStorage.getItem ( cookie ) ) || fallbackCount, 
            
            before  : pageinfo.startCursor, 
            
            after   : undefined 
    
        } 
        
    ) },

    /** @returns refetch for the search catalog page */

    handleSubmit = ( e ) => { 
        
        e.preventDefault (); 
        
        action ( { 
            
            first: JSON.parse ( localStorage.getItem ( cookie ) ) || fallbackCount, 
            
            last: undefined, 
            
            before: undefined, 
            
            after: undefined, 
            
            stringSearch: ( stringValue === "" ) ? undefined : stringValue.toLowerCase ()
        
        } ); 
        
    };

    /** @returns */

    return ( <div className="pagination">

        <div className="optionals">

            <SelectSpread
            
                id="resultCount"

                value={ JSON.parse ( localStorage.getItem ( cookie ) ) || fallbackCount }

                action={ ( e ) => { localStorage.setItem ( cookie, e.target.value ); first ( e ); } }

                options={ [

                    { name: "4", value: 4 },

                    { name: "8", value: 8 },

                    { name: "12", value: 12 },

                    { name: "20", value: 20 },

                    { name: "28", value: 28 },

                    { name: "36", value: 36 },

                    { name: "44", value: 44 },

                    { name: "52", value: 52 },

                    { name: "60", value: 60 },

                    { name: "68", value: 68 },

                    { name: "76", value: 76 },

                    { name: "84", value: 84 },

                    { name: "92", value: 92 },

                    { name: "100", value: 100 }

                ] }

                style="pagination"
            
            />

            { stringSearch &&

                <div className="string_search">

                    <Input
                    
                        name="string_search"

                        id="string_search"

                        value={ stringValue }

                        style="body"

                        placeholder={ t ( "search" ) }

                        onKeyDown={ e => e.key === "Enter" && handleSubmit ( e ) }

                        action={ ( e ) => setStringValue ( e.target.value ) }
                    
                    />

                    <button onClick={ handleSubmit }>

                        <FaSearch />

                    </button>

                </div>

            }

        </div>

        <div className="pageinfo">

            { pageinfo.hasPreviousPage &&
            
                <button 
                
                    onClick={ first }>

                        <BiChevronsLeft />

                </button> 
                
            }

            <button 
            
                onClick={ previous } 
            
                disabled={ ! pageinfo.hasPreviousPage }>

                    <BiChevronLeft />

            </button>

            <button 
            
                onClick={ next } 
            
                disabled={ ! pageinfo.hasNextPage }>

                    <BiChevronRight />

            </button>

        </div>

    </div> );

}

/** @exports Pagination */
 
export default Pagination;