// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.styles_filters__RAear {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n\n    justify-content: start;\n    align-items: center;\n}\n\n.styles_filtersOptions__MQGXE {\n    display: grid;\n    grid-gap: 15px;\n\n    min-width: 270px;\n}\n\n.styles_filtersControls__\\+naQm {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n\n    margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/Business/table-clients/filters/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,eAAe;IACf,SAAS;;IAET,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;;IAEd,gBAAgB;AACpB","sourcesContent":["\n.filters {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n\n    justify-content: start;\n    align-items: center;\n}\n\n.filtersOptions {\n    display: grid;\n    grid-gap: 15px;\n\n    min-width: 270px;\n}\n\n.filtersControls {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "styles_filters__RAear",
	"filtersOptions": "styles_filtersOptions__MQGXE",
	"filtersControls": "styles_filtersControls__+naQm"
};
export default ___CSS_LOADER_EXPORT___;
