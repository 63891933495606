/** @module                     Hooks */

import useFetch                 from "../../../../Hooks/useFetch";

import { useEffect }            from "react";

import { useTranslation }       from "react-i18next";

/** @module Queries */

import { MANAGERS_ROLE_ENUM }   from "../../../../Queries/Managers";

/** @module                     Utilities */

import { enumOptions }          from "../../Utils/enumOptions";

/** @module                     Components */

import Attributes               from "../../Global/Forms/Fieldset/Attributes";

import Fieldset                 from "../../Global/Forms/Fieldset";

import Select                   from "../../Global/Forms/Select";

import CenterLoader             from "../../Loaders/CenterLoader";

import MutationActions          from "../../Global/Layouts/Mutations/MutationActions";

import Checkbox                 from "../../Global/Forms/Checkbox";

const

/**
 * 
 * @param { Object } data 
 * 
 * @param { Object } details
 * 
 * @returns edit manager sidebar component
 * 
 */ 

Sidebar = ( { data, details } ) => { const { t } = useTranslation (),

    { data : enumData, loading } = useFetch ( MANAGERS_ROLE_ENUM );

    useEffect ( () => {

        if ( ! loading ) { details.role.set ( enumData.__type.enumValues[ 0 ].name ) }

    }, [ loading ] );

    return ( <aside>

        <Fieldset legend={ t ( "actions" ) }>

            <Attributes details={ [

                { name: t ( "createdAt" ), value: new Date ( data.createdAt ).toLocaleString () },

                { name: t ( "updatedAt" ), value: new Date ( data.updatedAt ).toLocaleString () },

                { name: t ( "role" ), value: data.role },

            ] } />

            <MutationActions 

                remove={ true }
                
                submitAction={ details.submit.action }

                removeAction={ details.remove.action }
                
                references={ [ details.submit.reference, details.remove.reference ] } />

        </Fieldset>

        <Fieldset legend={ t ( "options" ) }>

            <Checkbox

                id="blocked"

                text={ t ( "blocked" ) }

                state={ details.blocked.view }

                action={ e => details.blocked.set ( ! details.blocked.view ) }

                ref={ details.blocked.reference } />

            { loading ? <CenterLoader /> : 

                <Select 
                    
                    id="role"
                    
                    action={ e => details.role.set ( e ) }
                    
                    options={ enumOptions ( enumData.__type.enumValues ) }
                    
                    value={ details.role.view }
                    
                    ref={ details.role.reference } />

            }

        </Fieldset>

        { details.blocked.view &&

            <Fieldset legend={ t ( "blocked_reason" ) }>

                <textarea

                    id="blockedReason"

                    name="blockedReason"

                    value={ details.blockedReason.view }

                    onChange={ e => details.blockedReason.set ( e.target.value ) }

                    ref={ details.blockedReason.reference } />

            </Fieldset>

        }

    </aside> );

}

/** @exports Sidebar */
 
export default Sidebar;