export const newDate = (dateStr) => {
    const date = new Date(dateStr);

    if (date.toString() !== "Invalid Date") {
        return date;
    }

    const date2 = new Date(dateStr.replace(/-/g, "/"));

    if (date2.toString() !== "Invalid Date") {
        return date2;
    }

    return null;
};

export const newDateUTC = (dateStr) => {
    const d = newDate(dateStr);

    if (!d) {
        return null;
    }

    return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
};

/* --- */

export const tryFormatDate = (dateStr, formatFn) => {
    const date = newDate(dateStr);

    if (!date) {
        return "";
    }

    return formatFn(date);
};

export const tryFormatDateUTC = (dateStr, formatFn) => {
    const date = newDateUTC(dateStr);

    if (!date) {
        return "";
    }

    return formatFn(date);
};

/* --- */

export const formatDayMonthYearHoursMinutes = (date) => {
    if (!date) {
        return "";
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    return `${day}.${month}.${year} ${hours}:${minutes}`;
};

/* --- */

export default {
    newDate,
    newDateUTC,
    tryFormatDate,
    tryFormatDateUTC,
    formatDayMonthYearHoursMinutes,
};
