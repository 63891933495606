/** @module                     Hooks */

import { usePagename }          from "../../Hooks/usePagename";

import { useTranslation }       from "react-i18next";

import { usePaginationLength }  from "../../Hooks/usePaginationLength";

/** @module                     Components */

import Fieldset                 from "../../Components/Dashboard/Global/Forms/Fieldset";

import Select                   from "../../Components/Dashboard/Global/Forms/Select";

import Tabs                     from "../../Components/Dashboard/Global/Tabs";

import Breadcrumbs              from "../../Components/Dashboard/Breadcrumbs";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns table settings page component
 * 
 */

TableSettings = ( { name } ) => { const { t } = useTranslation (),

    /** @event Hooks */

    { itemsCount : montage,     setItemsCount : setMontage  } = usePaginationLength ( "appointmentsCollectionCount" ),

    { itemsCount : managers,    setItemsCount : setManagers } = usePaginationLength ( "managersCollectionCount"     ),

    { itemsCount : posts,       setItemsCount : setPosts    } = usePaginationLength ( "postsCollectionCount"        ),

    { itemsCount : services,    setItemsCount : setServices } = usePaginationLength ( "servicesCollectionCount"     ),
    
    { itemsCount : pages,       setItemsCount : setPages    } = usePaginationLength ( "pagesCollectionCount"        ),
    
    { itemsCount : products,    setItemsCount : setProducts } = usePaginationLength ( "productsCollectionCount"     );

    /** @event usePagename */

    usePagename ( name );

    /** @return Settings page */

    return ( <section className="settings table-settings">

        <Breadcrumbs />

        <Tabs data={ [

            { name: t ( "general" ), uri: "/app/settings" },

            { name: t ( "tables" ), uri: "/app/settings/tables" }

        ] } />

        <Fieldset 
        
            legend={ t ( "table_pagination_count" ) }
        
            description={ t ( "table_settings_desc" ) }
            
        >

            <Select 

                label={ t ( "products" ) }
                        
                id="productsCollectionCount"
                
                action={ e => setProducts ( parseInt ( e ) ) }
                
                options={ countOptions }
                
                value={ products } />

            <Select 

                label={ t ( "montage" ) }
                        
                id="appointmentsCollectionCount"
                
                action={ e => setMontage ( parseInt ( e ) ) }
                
                options={ countOptions }
                
                value={ montage } />

            <Select 

                label={ t ( "managers" ) }
                        
                id="managersCollectionCount"
                
                action={ e => setManagers ( parseInt ( e ) ) }
                
                options={ countOptions }
                
                value={ managers } />

            <Select 

                label={ t ( "posts" ) }
                        
                id="postsCollectionCount"
                
                action={ e => setPosts ( parseInt ( e ) ) }
                
                options={ countOptions }
                
                value={ posts } />

            <Select 

                label={ t ( "services" ) }
                        
                id="servicesCollectionCount"
                
                action={ e => setServices ( parseInt ( e ) ) }
                
                options={ countOptions }
                
                value={ services } />

            <Select 

                label={ t ( "pages" ) }
                        
                id="pagesCollectionCount"
                
                action={ e => setPages ( parseInt ( e ) ) }
                
                options={ countOptions }
                
                value={ pages } />

        </Fieldset>

    </section> );

},

/**
 * 
 * @return options object for table count
 * 
 */

countOptions = [

    { name: "4", value: 4 },

    { name: "8", value: 8 },

    { name: "12", value: 12 },

    { name: "20", value: 20 },

    { name: "28", value: 28 },

    { name: "36", value: 36 },

    { name: "44", value: 44 },

    { name: "52", value: 52 },

    { name: "60", value: 60 },

    { name: "68", value: 68 },

    { name: "76", value: 76 },

    { name: "84", value: 84 },

    { name: "92", value: 92 },

    { name: "100", value: 100 },

];

/** @exports TableSettings */
 
export default TableSettings;