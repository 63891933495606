/** @module                         Fonts */

import PoppinsBlack                 from "../../../../Assets/Fonts/Poppins-Black.ttf";

import PoppinsBold                  from "../../../../Assets/Fonts/Poppins-Bold.ttf";

import PoppinsExtraBold             from "../../../../Assets/Fonts/Poppins-ExtraBold.ttf";

import PoppinsExtraLight            from "../../../../Assets/Fonts/Poppins-ExtraLight.ttf";

import PoppinsLight                 from "../../../../Assets/Fonts/Poppins-Light.ttf";

import PoppinsMedium                from "../../../../Assets/Fonts/Poppins-Medium.ttf";

import PoppinsRegular               from "../../../../Assets/Fonts/Poppins-Regular.ttf";

import PoppinsSemiBold              from "../../../../Assets/Fonts/Poppins-SemiBold.ttf";

import PoppinsThin                  from "../../../../Assets/Fonts/Poppins-Thin.ttf";

/** @module                         Components */

import { 
    
    Page, 
    
    Text, 
    
    View, 
    
    Document, 
    
    Image, 
    
    Font }                          from '@react-pdf/renderer';

import DocumentHeader from "./Components/Header";

import DocumentFooter from "./Components/Footer";

import DocumentParameters from "./Components/Parameters";

import DocumentEntities from "./Components/Entities";

import DocumentProducts from "./Components/Products";

import DocumentFAQ from "./Components/QnA";

import DocumentNotices from "./Components/Notices";

import DocumentManager from "./Components/Manager";

import DocumentUserNotes from "./Components/Notes";

import DocumentCategories from "./Components/Categories";

/** @description register PDF font */

Font.register( {

    family: 'Poppins',

    formtat: 'truetype',

    fonts: [

        { src: PoppinsRegular, fontWeight: 400 },

        { src: PoppinsMedium, fontWeight: 500 },

        { src: PoppinsSemiBold, fontWeight: 600 },

        { src: PoppinsBold, fontWeight: 700 },

        { src: PoppinsExtraBold, fontWeight: 800 },

        { src: PoppinsBlack, fontWeight: 900 },

        { src: PoppinsThin, fontWeight: 100 },

        { src: PoppinsExtraLight, fontWeight: 200 },

        { src: PoppinsLight, fontWeight: 300 }

    ]

} );

export default function DocumentInvoice ( { type = "invoice", state, order, contacts, hours, manager, __ } ) {

    return (

        <Document>

            <Page size="A4" style={ { flexDirection: "column", gap: 10, fontFamily: "Poppins", fontWeight: 400 } }>

                <View style={ { flexDirection: "column", gap: 5, minHeight: "100vh", padding: 10, fontSize: 9 } }>

                    <DocumentHeader />

                    <View style={ { flex: 1, gap: 10 } }>

                        <Text style={ { fontSize: 12, fontWeight: 600 } }>

                            { type === "invoice" ? "Pavadzīme" : type === "receipt" ? "Avansa Rēķins" : "Piedāvājums" }

                        </Text>

                        <DocumentParameters order={ order } type={ type } />

                        <DocumentEntities order={ order } state={ state } contacts={ contacts.adminShopContacts } />

                        <DocumentProducts order={ order } products={ order?.orderProductVendors || [] } />

                        { type === "offer" && <DocumentUserNotes message={ order?.userNotes } /> }

                        { type === "offer" && <DocumentManager manager={ manager } /> }

                        <DocumentNotices type={ type } />

                        { type !== "offer" && <DocumentFAQ contacts={ contacts } /> }
                        
                    </View>

                    <DocumentFooter contacts={ contacts?.adminShopContacts || {} } hours={ hours } />

                    { type === "offer" && <DocumentCategories /> }

                </View>

            </Page>

        </Document>

    );

};