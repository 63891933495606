import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Sizes = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Sizes page */

    return ( <section className="sizes">

        Sizes page

    </section> );

}

/** @exports Sizes */
 
export default Sizes;