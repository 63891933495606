import React, { useRef } from "react";

import useGlobalClose from "./../../../Hooks/use-global-close.js";

import stylesButton from "./../Styles/Button/styles.module.css";
import styles from "./styles.module.css";


const ButtonDropdown = (props) => {
    const containerRef = useRef(null);

    /* --- */

    const onClick = () => {
        if (props.isDisabled) {
            return;
        }

        props.onToggleMenu(!props.isVisibleMenu);
    };

    /* --- */

    useGlobalClose((evt) => {
        if (!props.isVisibleMenu
            || containerRef.current.contains(evt.target)) {
            return;
        }

        props.onToggleMenu(false);
    }, [
        props.isVisibleMenu,
        props.onToggleMenu,
    ]);

    /* --- */

    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <props.icon
                className={styles.icon}
            />
        );
    };

    const renderMenu = () => {
        if (!props.isVisibleMenu) {
            return null;
        }

        return (
            <div className={styles.menu}>
                {props.children}
            </div>
        );
    };

    const renderButton = () => {
        const buttonClassName = [stylesButton.button];

        if (props.isBlue) {
            buttonClassName.push(stylesButton.blue);
        }

        return (
            <div
                className={buttonClassName.join(" ")}
                onClick={onClick}
                tabIndex="-1"
                role="button"
            >
                {renderIcon()}
                {props.label}
            </div>
        );
    };

    /* --- */

    return (
        <div
            ref={containerRef}
            className={styles.container}
        >
            {renderButton()}
            {renderMenu()}
        </div>
    );
};

ButtonDropdown.defaultProps = {
    icon: null,
    label: "",
    children: null,
    onToggleMenu: () => { },
    isVisibleMenu: false,
    isDisabled: false,
    isBlue: false,
};

export default ButtonDropdown;
