import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import Input from "./../../Components/Common/Forms/input/index.js";
import Select from "./../../Components/Common/Select/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";

import styles from "./styles.module.css";

const PREFIX = "generalInfo";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    creditLimit: getName("CreditLimit"),
    termOfPayment: getName("TermOfPayment"),
    creditLimitBalance: getName("CreditLimitBalance"),
    unpaidInvoices: getName("UnpaidInvoices"),
    debt: getName("Debt"),
    contractNumber: getName("ContractNumber"),
    dateOfContract: getName("DateOfContract"),
    manager: getName("Manager"),
    discountGroup: getName("DiscountGroup"),
    paymentOfGoods: getName("PaymentOfGoods"),
    deliveryPlan: getName("DeliveryPlan"),
};

const INITIAL_VALUES = {
    [FIELDS.creditLimit]: "",
    [FIELDS.termOfPayment]: "",
    [FIELDS.creditLimitBalance]: "",
    [FIELDS.unpaidInvoices]: "",
    [FIELDS.debt]: "",
    [FIELDS.contractNumber]: "",
    [FIELDS.dateOfContract]: "",
    [FIELDS.manager]: "",
    [FIELDS.discountGroup]: "",
    [FIELDS.paymentOfGoods]: "",
    [FIELDS.deliveryPlan]: "",
};

/* --- */

const FormClientEditGeneralInfo = (props) => {
    const [isBillingInfoVisible, setIsBillingInfoVisible] = useState(false);

    const { t } = useTranslation();

    /* --- */

    const onToggleBillingInfo = () => {
        setIsBillingInfoVisible((prev) => !prev);
    };

    /* --- */

    const validateForm = (values) => {
        const errors = {};

        if (!values[FIELDS.manager]) {
            errors[FIELDS.manager] = "Manager is required";
        }

        if (!values[FIELDS.discountGroup]) {
            errors[FIELDS.discountGroup] = "Discount group is required";
        }

        if (!values[FIELDS.paymentOfGoods]) {
            errors[FIELDS.paymentOfGoods] = "Payment of good is required";
        }

        if (!values[FIELDS.deliveryPlan]) {
            errors[FIELDS.deliveryPlan] = "Delivery plan is required";
        }

        return errors;
    };

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const renderBillingForm = (formMethods) => {
        if (!isBillingInfoVisible) {
            return null;
        }

        const {
            values,
            errors,
            handleChange,
            handleBlur,
        } = formMethods;

        const {
            creditLimit,
            termOfPayment,
            creditLimitBalance,
            unpaidInvoices,
            debt,
            paymentOfGoods,
            deliveryPlan,
        } = FIELDS;

        return (
            <>
                <div className={styles.column2}>
                    <Input
                        name={creditLimit}
                        label={t("credit_limit")}
                        placeholder="......"
                        value={values[creditLimit]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <div className={styles.column2TwoFields}>
                        <Input
                            name={termOfPayment}
                            label={t("term_of_payment")}
                            placeholder="......"
                            value={values[termOfPayment]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabled={isDisabled()}
                        />
                        <Input
                            name={creditLimitBalance}
                            label={t("credit_limit_balance")}
                            placeholder="......"
                            value={values[creditLimitBalance]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isDisabled={isDisabled()}
                        />
                    </div>
                    <Input
                        name={unpaidInvoices}
                        label={t("unpaid_invoices")}
                        placeholder="......"
                        value={values[unpaidInvoices]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={debt}
                        label={t("debt")}
                        placeholder="......"
                        value={values[debt]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
                <div className={styles.column3}>
                    <Select
                        label={t("payment_of_goods")}
                        options={props.paymentOfGoods.options}
                        selected={values[paymentOfGoods]}
                        error={errors[paymentOfGoods]}
                        onSelect={(value) => {
                            formMethods.setFieldValue(paymentOfGoods, value);
                        }}
                        isDisabled={isDisabled(props.paymentOfGoods.isLoading)}
                        isFixedHeight
                    />
                    <Select
                        label={t("delivery_plan")}
                        options={props.deliveryPlan.options}
                        selected={values[deliveryPlan]}
                        error={errors[deliveryPlan]}
                        onSelect={(value) => {
                            formMethods.setFieldValue(deliveryPlan, value);
                        }}
                        isDisabled={isDisabled(props.deliveryPlan.isLoading)}
                        isFixedHeight
                    />
                    <div />
                    <div />
                </div>
            </>
        );
    };

    const renderForm = (formMethods) => {
        const {
            handleSubmit,
            values,
            errors,
            handleChange,
            handleBlur,
        } = formMethods;

        const {
            contractNumber,
            dateOfContract,
            manager,
            discountGroup,
        } = FIELDS;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <div className={styles.column0}>
                    <Input
                        name={contractNumber}
                        label={t("contract_number")}
                        placeholder="......"
                        value={values[contractNumber]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={dateOfContract}
                        label={t("date_of_contract")}
                        placeholder="......"
                        value={values[dateOfContract]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Select
                        options={props.managers.options}
                        selected={values[manager]}
                        label={t("manager")}
                        error={errors[manager]}
                        onSelect={(value) => {
                            formMethods.setFieldValue(manager, value);
                        }}
                        isDisabled={isDisabled(props.managers.isLoading)}
                        isFixedHeight
                    />
                </div>
                <div className={styles.column1}>
                    <Select
                        options={props.discountGroups.options}
                        selected={values[discountGroup]}
                        onSelect={(value) => {
                            formMethods.setFieldValue(discountGroup, value);
                        }}
                        label={t("discount_group")}
                        error={errors[discountGroup]}
                        isDisabled={isDisabled(props.discountGroups.isLoading)}
                        isFixedHeight
                    />
                    <ButtonUnderline
                        label={t("open_full_billing_information")}
                        onClick={onToggleBillingInfo}
                        isClicked={isBillingInfoVisible}
                        isClickable
                        isRight
                    />
                </div>
                {renderBillingForm(formMethods)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            innerRef={props.formRef}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditGeneralInfo.defaultProps = {
    formRef: null,
    initialValues: INITIAL_VALUES,
    discountGroups: {
        options: [],
        isLoading: false,
    },
    managers: {
        options: [],
        isLoading: false,
    },
    paymentOfGoods: {
        options: [],
        isLoading: false,
    },
    deliveryPlan: {
        options: [],
        isLoading: false,
    },
    onSubmit: () => { },
    isEditable: false,
};

export {
    PREFIX,
    INITIAL_VALUES,
    FIELDS,
};

export default FormClientEditGeneralInfo;
