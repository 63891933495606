/** @module                 Hooks */

import { usePagename }      from "../../../Hooks/usePagename";

import { useTranslation }   from "react-i18next";

/** @module                 Components */

import Breadcrumbs          from "../../../Components/Dashboard/Breadcrumbs";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns add new tire product page component
 * 
 */

AddTire = ( { name } ) => { usePagename ( name ); const 

    { t } = useTranslation ();

    /** @returns */

    return ( <section className="mutations add tire">

        <Breadcrumbs data={ [

            { name: t ( "products"  ), uri: "/app/products/tires"       },

            { name: t ( name        ), uri: "/app/products/add/tire"    }

        ] } />

        <div>

            Add tire

        </div>

    </section> );

}
 
/** @exports AddTire */

export default AddTire;