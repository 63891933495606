/** @module         Router */

import { Link }     from "react-router-dom";

const 

/**
 * 
 * @param { String } text
 * 
 * @param { String } route
 * 
 * @returns table link component
 * 
 */

Routing = ( { text, route } ) => {

    /** @returns */

    return ( <td data-title="link">

        <Link to={ route }>
            
            { text }
            
        </Link>

    </td> );

}

/** @exports Routing */
 
export default Routing;