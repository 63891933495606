import Bool from "./_Bool";

import Tag from "./_Tag";

import { Link } from "react-router-dom";

import { BiLinkExternal } from "react-icons/bi";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table tag component
 * 
 */

ObjectCount = ( { data, link = false, tab = false, route = "/" } ) => {

    /** @returns */

    return ( <td data-title="text" className="object_count">

        {

            ( 
                
                data.length 
                
                ?

                    link 
                    
                    ? 
                    
                        <div className="object_count_link">
                            
                            <Link to={ route } target={ tab ? "_blank" : "_self" } rel="noopener noreferrer">

                                { data.length }

                                <span>

                                    <BiLinkExternal />

                                </span>

                            </Link>
                            
                        </div> 
                        
                    : 
                    
                        <Tag text={ data.length } />

                : <Bool state={ false } />
                
            )

        }

    </td> );

}

/** @exports ObjectCount */
 
export default ObjectCount;