import React from "react";

import stylesInput from "./../Common/styles.module.css";
import styles from "./styles.module.css";


const Input = (props) => {
    const renderLabels = () => {
        if (!props.label) {
            return null;
        }

        return (
            <div className={styles.labels}>
                <label
                    htmlFor={props.name}
                    className={stylesInput.label}
                >
                    {props.label}
                </label>
                {props.label2}
            </div>
        );
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <div className={stylesInput.inputError}>
                {props.error}
            </div>
        );
    };

    const containerClassName = [stylesInput.container];
    const inputClassName = [stylesInput.input];

    if (!props.isAutoHeight) {
        containerClassName.push(stylesInput.containerFixedHeight);
    }

    if (props.error) {
        inputClassName.push(stylesInput.inputWithError);
    }

    if (props.isDisabled) {
        inputClassName.push(stylesInput.inoutDisabled);
    }

    return (
        <div className={containerClassName.join(" ")}>
            {renderLabels()}
            <input
                className={inputClassName.join(" ")}
                id={props.name}
                name={props.name}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={props.isDisabled}
            />
            {renderError()}
        </div>
    );
};

Input.defaultProps = {
    label: "",
    label2: null,
    name: "",
    type: "text",
    value: "",
    placeholder: "",
    error: "",
    onChange: () => { },
    onBlur: () => { },
    isAutoHeight: false,
    isDisabled: false,
};

export default Input;
