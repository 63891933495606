/** @module                 React */

import {    useContext, 
    
            useEffect }     from "react";

import { PagenameContext }  from "../../../Contexts/Pagename";

/** @module                 i18n */

import { useTranslation }   from "react-i18next";

/** @module                 Components */

import Breadcrumbs          from "../../../Components/Dashboard/Breadcrumbs";

import Tabs                 from "../../../Components/Dashboard/Global/Tabs";

import Table                from "../../../Components/Dashboard/Products/Collection/Tables/Tires/Table";

import Pagination           from "../../../Components/Dashboard/Products/Collection/Tables/Shared/Pagination";

import Actions              from "../../../Components/Dashboard/Products/Collection/Actions";

import useTiresContext      from "../../../Hooks/Products/useProductsContext";

import ComponentLoader      from "../../../Components/Dashboard/Loaders/ComponentLoader";

/**
* 
* @param { name } 
* 
* @returns product tires page component
* 
*/

const Tires = ( { name } ) => { const 

    { data, loading, refetch } = useTiresContext (),

    { t } = useTranslation (),

    { setPagename } = useContext ( PagenameContext );

    /** @event */

    useEffect ( () => setPagename ( name ) );

    /** @return */

    return ( <section className="collection">

        <Breadcrumbs data={ [

            { name: t ( "products"  ), uri: "/app/products/collection"  },

            { name: t ( name        ), uri: "/app/products/tires"       }

        ] } />

        <Tabs data={ [

            { name: t ( "tires"     ), uri: "/app/products/tires"       },

            { name: t ( "rims"      ), uri: "/app/products/rims"        },

            { name: t ( "batteries" ), uri: "/app/products/batteries"   }

        ] } />

        { ! loading &&  <Actions type="tire" /> }

        { ! loading && <Pagination pageinfo={ data.adminMultiFieldSearch.pageInfo } action={ refetch } pending={ loading } /> }

        { ( loading ) ? <ComponentLoader /> : <Table products={ data } /> }

        { ! loading && <Pagination pageinfo={ data.adminMultiFieldSearch.pageInfo } action={ refetch } pending={ loading } /> }

    </section> );

}

/** @exports Tires */

export default Tires;