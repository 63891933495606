import React from "react";

import Clients from "./../../Project/clients.js";

import Checkbox from "./../../Components/Common/Forms/checkbox/index.js";

import styles from "./styles.module.css";


const TYPES = Clients.getTypes();

const ClientTypeFilter = (props) => {
    const { selected } = props;

    return (
        <div className={styles.container}>
            <Checkbox
                name={TYPES.onlineStoreB2C}
                label="B2C - Online store"
                onChange={() => {
                    props.onToggle(TYPES.onlineStoreB2C);
                }}
                isSelected={selected.indexOf(TYPES.onlineStoreB2C) !== -1}
            />
            <Checkbox
                name={TYPES.B2B}
                label="B2B"
                onChange={() => {
                    props.onToggle(TYPES.B2B);
                }}
                isSelected={selected.indexOf(TYPES.B2B) !== -1}
            />
        </div>
    );
};

ClientTypeFilter.defaultProps = {
    selected: [],
    onToggle: () => { },
};

export default ClientTypeFilter;
