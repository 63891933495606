/** @module                         Components */

import Fieldset                     from "../../Global/Forms/Fieldset";

import Checkbox                     from "../../Global/Forms/Checkbox";

const 

/**
 * 
 * @returns Options
 * 
 * @exports Options
 * 
 * @param { Object } props
 * 
 * @param { Object } props.__
 * 
 * @param { Object } props.state
 * 
 * @param { Object } props.action
 * 
 */

Options = ( { __, state, action } ) => { const 

    /** @constant options */

    options = [

        { name: "studs", productType: "tire" },

        { name: "runFlat", productType: "tire" },

        { name: "boltsIncluded", productType: "rim" },

    ];

    /** @returns Options */

    return (

        <Fieldset legend={ __ ( "options" ) }>

            {

                options.map (

                    ( option, index ) =>

                        state.productType === option.productType &&

                        <Checkbox

                            key={ index }

                            id={ option.name }

                            text={ __ ( option.name ) }

                            state={ state [ option.name ] }

                            action={ action }

                            name={ option.name }

                        />

                )

            }

        </Fieldset>

    );

}
 
/** @exports Options */

export default Options;