import styles from "./BeforeOrders.module.css";

import ComponentLoader from "../../Loaders/ComponentLoader";

import OrderActions from "./Actions";

import OrderTabs from "./Tabs";

/**
 * 
 * @exports BeforeOrders
 * 
 * @description The component that displays the orders before they are processed.
 * 
 * @param { Object } props
 * 
 * @param { Boolean } props.loading
 * 
 * @param { Object } props.data
 * 
 * @param { Function } props.refetch
 * 
 * @param { Object } props.variables
 * 
 * @returns { JSX.Element }
 * 
 */

export default function BeforeOrders ( { loading, refetch, variables } ) {

    /**
     * 
     * @description 
     * 
     * If the data is loading, display the component loader.
     * 
     * @returns { JSX.Element }
     * 
     */

    if ( loading ) return <ComponentLoader />;

    /**
     * 
     * @description 
     * 
     * The component that displays the orders before they are processed.
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <div className={ styles.container }>

            <OrderTabs variables={ variables } refetch={ refetch } />

            <OrderActions variables={ variables } />

        </div>

    );

};