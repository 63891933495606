/** @module                 React */

import { useState }         from "react";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

const 

/** 
 * 
 * @returns useUpdate hook for updating mutation
 * 
 */

useUpdate = ( MUTATION ) => { const 

    /** @hooks */

    [ loading,  setLoading  ]       = useState ( false ),

    [ errors,   setErrors   ]       = useState ( {} ),

    [ complete, setComplete ]       = useState ( false ),

    [ returnData, setReturnData ]   = useState ( {} ),

    /** @mutations */

    [ UpdateEntry ] = useMutation ( MUTATION, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            setReturnData ( data );

            setComplete ( true );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( { message : "Internal Server Error" } ); }

    } ),

    /** 
     * 
     * @param { String } title
     * 
     * @param { String } content
     * 
     * @param { String } description
     * 
     * @param { Boolean } isPublished
     * 
     * @param { Boolean } tiremarketOnly
     * 
     * @param { String } language
     * 
     * @returns create post action
     * 
     */

    updateMutation = ( data ) => {

        /** @override hook states */

        setErrors ( {} ); setLoading ( true ); setComplete ( false ); setComplete ( false );

        /** @mutation */

        return UpdateEntry ( { variables: data } );

    }

    /** @returns */

    return { updateMutation, loading, complete, returnData, errors, setErrors }

};

/** @exports useUpdate */

export default useUpdate;