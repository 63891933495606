/** @module                             Hooks */

import { useTranslation }               from "react-i18next";

import { usePagename }                  from "../../../Hooks/usePagename";

import { useState, useEffect }          from "react";

import useFetch                         from "../../../Hooks/useFetch";

import { useParams, useNavigate }       from "react-router-dom";

import useUpdate                        from "../../../Hooks/Mutations/useUpdate";

import useRemove                        from "../../../Hooks/Mutations/useRemove";

/** @module                             GQL */

import { 
    
    DELETE_MANUFACTURER, 
    
    UPDATE_MANUFACTURER }               from "../../../Mutations/Manufacturers";

import { MANUFACTURER }                 from "../../../Queries/Manufacturers";

import { MANUFACTURER_PRODUCT_ENUM }    from "../../../Queries/Manufacturers";

/** @module                             Utilities */

import { enumOptions }                  from "../../../Components/Dashboard/Utils/enumOptions";

/** @module                             Components */

import Breadcrumbs                      from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                        from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                         from "../../../Components/Dashboard/Global/Forms/Fieldset";

import ComponentLoader                  from "../../../Components/Dashboard/Loaders/ComponentLoader";

import Attributes                       from "../../../Components/Dashboard/Global/Forms/Fieldset/Attributes";

import { Input }                        from "../../../Components/Dashboard/Global/Forms/Inputs_Spread";

import SelectSpread                     from "../../../Components/Dashboard/Global/Forms/Select_Spread";

const 

/** @constant DEFINITIONS */

DEFINITIONS = {

    query                           : MANUFACTURER,

    update_query                    : UPDATE_MANUFACTURER,

    delete_query                    : DELETE_MANUFACTURER,

    paramId                         : "manufacturerId",

    entryName                       : "adminManufacturer",

    collection_url                  : "/app/shop/manufacturers",

    state_collection                : [ 
        
        "name", 

        "markup",
    
        "id" 
    
    ]

},

/**
 * 
 * @param { String } name
 * 
 * @returns update mutation
 * 
 */

UpdateManufacturer = ( { name } ) => { usePagename ( name ); const 

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( DEFINITIONS.query, { 

        id: useParams () [ DEFINITIONS.paramId ]

    } );

    /** @returns */

    return (

        loading ? <section>

            <ComponentLoader />

        </section> 
        
        :

        <UpdateEntry entry={ data [ DEFINITIONS.entryName ] } />

    );

},

/**
 * 
 * 
 * @param { Object } entry
 * 
 * @returns update mutation
 * 
 */

UpdateEntry = ( { entry } ) => { const 
    
    { t } = useTranslation (),

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading, 
        
        complete, 
        
        errors, 
        
        setErrors 
    
    } = useUpdate ( DEFINITIONS.update_query ),

    /** @event useState form fields */

    [ state, setState ] = useState ( generateEntryState ( entry, DEFINITIONS.state_collection ) ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( ! state.name ) { return setErrors ( true ); }

        state.markup = parseFloat ( state.markup );
        
        updateMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( name === "markup" ) return setState ( prev => ( { ...prev, [ name ]: parseFloat ( value ) } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @returns */

    return (

        <section className="mutations update_entry">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 

                        entry={ entry }
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }

                        success={ complete }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "title" ) }>

                        <div className="input-group">

                            <input
                            
                                type="text"

                                value={ state.name }

                                onChange={ handleChange }

                                name="name"
                            
                            />

                        </div>

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

/**
 * 
 * @param { Object } props
 * 
 * @returns sidebar component
 * 
 */

Sidebar = ( { 
    
    __, 
    
    state           = "", 

    entry           = {},
    
    changeAction    = () => { return },

    errors          = false,

    pending         = false,

    success         = false

} ) => { const 
    
    navigate = useNavigate (),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading: loadingEnum, data } = useFetch ( MANUFACTURER_PRODUCT_ENUM ),

    /** @callback useUpdate update mutation hook */

    { 
        
        removeMutation, 
        
        loading, 
        
        complete
    
    } = useRemove ( DEFINITIONS.delete_query ),

    /**
     * 
     * @param { Object } e
     * 
     * @description remove entry action
     * 
     * @returns redirect to previous page
     * 
     */

    removeAction = e => { e.preventDefault ();

        if ( ! state.id ) { return; }
        
        removeMutation ( { id: state.id } );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( DEFINITIONS.collection_url );

    }, [ complete ] );

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { success && ! errors &&
                
                    <div className="success">

                        <div className="success_message">

                            { __ ( "successfully_updated" ) }

                        </div>

                    </div> 
                
                }

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <Attributes details={ [

                    { name: __ ( "createdAt" ), value: new Date ( entry.createdAt ).toLocaleString () },

                    { name: __ ( "updatedAt" ), value: new Date ( entry.updatedAt ).toLocaleString () },

                ] } />

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending || loading }>

                        { __ ( "submit" ) }

                    </button>

                    <button 
        
                        className="button remove" 

                        type="button"
                    
                        disabled={ pending || loading } 
                    
                        onClick={ removeAction } >

                            { __ ( "delete" ) }

                    </button> 

                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "markup" ) }>

                 <Input
                    
                        value={ state.markup } 

                        action={ changeAction }

                        name="markup"
                        
                    />

            </Fieldset>

        </aside>

    );

},

/**
 * 
 * @param { Object } entry
 * 
 * @param { Array } collection
 * 
 * @returns state object
 * 
 */

generateEntryState = ( entry, collection ) => { let 

    state = {};

    collection.forEach ( item => {

        state [ item ] = entry [ item ];

    } );

    return state;

};

/** @exports UpdateManufacturer */
 
export default UpdateManufacturer;