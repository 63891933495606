/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { useEffect, useState }      from "react";

import { useNavigate }              from "react-router-dom";

import useCreate                    from "../../../Hooks/Mutations/useCreate";

/** @module                         GQL */

import { CREATE_MEMBER }            from "../../../Mutations/Contacts";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import Checkbox                     from "../../../Components/Dashboard/Global/Forms/Checkbox";

import { Input, LabeledInput }      from "../../../Components/Dashboard/Global/Forms/Inputs_Spread";

const 

/**
 * 
 * @returns create mutation
 * 
 */

AddMember = ( { name } ) => { 

    /** @event usePagename */

    usePagename ( name );
    
    const 
    
    { t }       = useTranslation (),

    navigate    = useNavigate (),

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors, 
        
        setErrors 
    
    } = useCreate ( CREATE_MEMBER ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        firstName: "",

        lastName: "",

        title: "",

        image: undefined,

        phone: "",

        email: ""

    } ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( 
            
            ! state.firstName ||

            ! state.lastName ||

            ! state.title ||

            ! state.phone ||

            ! state.email
            
        ) { return setErrors ( true ); }
        
        createMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked, files } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( type === "file" ) return setState ( prev => ( { ...prev, [ name ]: files[ 0 ] } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( `/app/contacts/team/edit/${ returnData.adminCreateContact.contact.id }` );

    }, [ complete ] );

    /** @returns */

    return (

        <section className="mutations create_supplier">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "position" ) }>

                        <Input
                    
                            label={ t ( "title" ) }
                        
                            value={ state.title } 

                            action={ handleChange }

                            name="title"
                        
                        />

                    </Fieldset>

                    <Fieldset legend={ t ( "person" ) }>

                        <LabeledInput
                    
                            label={ t ( "firstName" ) }
                        
                            value={ state.firstName } 

                            action={ handleChange }

                            name="firstName"
                        
                        />

                        <LabeledInput
                    
                            label={ t ( "lastName" ) }
                        
                            value={ state.lastName } 

                            action={ handleChange }

                            name="lastName"
                        
                        />

                    </Fieldset>

                    <Fieldset legend={ t ( "contacts" ) }>

                        <LabeledInput
                    
                            label={ t ( "phone" ) }
                        
                            value={ state.phone } 

                            action={ handleChange }

                            name="phone"
                        
                        />

                        <LabeledInput
                    
                            label={ t ( "email" ) }
                        
                            value={ state.email } 

                            action={ handleChange }

                            name="email"
                        
                        />

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

Sidebar = ( { 
    
    __, 
    
    state = "", 
    
    changeAction = () => { return },

    errors = false,

    pending = false

} ) => {

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending }>

                        { __ ( "submit" ) }

                    </button>

                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "image" ) }>

                <input 
            
                    type="file" 

                    name="image"
                
                    defaultValue={ state.image } 
                
                    onChange={ changeAction } />

            </Fieldset>

        </aside>

    );

}

/** @exports AddMember */
 
export default AddMember;