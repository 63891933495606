import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function Address
 * 
 * @description Export the address of the company in the footer of the document as a React Component
 * 
 * @param { Object } contacts - contact information of the company 
 * 
 * @returns { JSX.Element }
 * 
 */

export default function Address ( { contacts } ) {

    /**
     * 
     * @description
     * 
     * Render the address of the company in the footer of the document as a React Component 
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View>

            <Text style={ { marginBottom: 5, fontWeight: 500 } }>
                
                Adrese
                
            </Text>

            <Text>{ contacts.address }</Text>

        </View>

    );

};