const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table model component
 * 
 */

Model = ( { product } ) => {

    /** @returns */

    return ( <td data-title="model">

        { product.model }

    </td> );

}

/** @exports Model */
 
export default Model;