const COUNTRIES = {
    latvia: "latvia",
    sweden: "sweden",
};

const COUNTRY_LABELS = {
    [COUNTRIES.latvia]: "Latvia",
    [COUNTRIES.sweden]: "Sweden",
};

/* --- */

const getCountriesOptions = () => {
    const keys = Object.keys(COUNTRIES);

    return keys.map((name) => ({
        label: COUNTRY_LABELS[name],
        name,
    }));
};

const getDefaultCounty = () => {
    return COUNTRIES.latvia;
};

/* --- */

export default {
    getCountriesOptions,
    getDefaultCounty,
};
