import { useTranslation } from "react-i18next";

/**
 * 
 * @param { String } style 
 * 
 * @param { Boolean } remove
 * 
 * @param { String } submitText
 * 
 * @param { Boolean } disable
 * 
 * @param { Function } submitAction
 * 
 * @param { Function } removeAction
 * 
 * @param { Array } references
 * 
 * @returns mutation actions component (mutation submit & delete)
 * 
 */

const 

MutationActions = ( { 
    
    style           = "default", 

    remove          = false, 

    submitText      = "submit",

    disable         = false,

    submitAction    = () => {},

    removeAction    = () => {},

    references      = [ null, null ]

} ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className={ `mutation-actions ${ style }` }>
                    
        <button 
        
            className="button add" 
        
            disabled={ disable } 
        
            onClick={ submitAction } 
        
            ref={ references[ 0 ] }>
            
                { t ( submitText ) }
            
        </button>

        { remove && 
        
            <button 
            
                className="button remove" 
            
                disabled={ disable } 
            
                onClick={ removeAction } 
            
                ref={ references[ 1 ] }>

                    { t ( "delete" ) }

            </button> 
        
        }
        
    </div> );

}
 
/** @exports MutationActions */

export default MutationActions;