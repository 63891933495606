/** @module                         Components */

import { Link }                     from "react-router-dom";

const 

/**
 * 
 * @param { Object } vendor
 * 
 * @returns product table vendors component
 * 
 */

Vendors = ( { vendor } ) => {

    /** @returns */

    return (

        <td>

            <Link to={ `/app/shop/suppliers/edit/${ vendor.id }` }>

                { vendor.name }

            </Link>

        </td>

    );

}

/** @exports Vendors */
 
export default Vendors;