/** @module             Icons */

import { CgSpinner }    from "react-icons/cg";

const 

/** 
 * 
 * @description initializes loading placeholder before loading a page
 * 
 * @returns load page component
 * 
 */

LoadPage = () => {

    /** @returns */

    return ( <section className="load-page">

        <div className="loader">

            <CgSpinner size={ 64 } className="spinner" />

        </div>

    </section> );

}
 
/** @exports LoadPage */

export default LoadPage;