import styles from "./Filters.module.css";

import { SEARCH_ATTRIBUTES } from "../../../../../../Queries/Products";

import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import FilterText from "../Elements/FilterText";

import FilterBoolean from "../Elements/Boolean";

import { FilterMultiple } from "../Elements/FilterMultiple";

import FilterSelect from "../Elements/FilterSelect";

import FiltersReset from "../Elements/Reset";

import CenterLoader from "../../../../Loaders/CenterLoader";

/**
 * 
 * @exports RimsFilters
 * 
 * @description This component renders the filters for the tire collection 
 * 
 * @param { Object } props
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection
 * 
 * @returns { JSX.Element } RimsFilters
 * 
 */

export default function RimsFilters ( { refetch, variables } ) { const 

    { t } = useTranslation (),

    { data, loading } = useQuery ( SEARCH_ATTRIBUTES ),

    /**
     * 
     * @constant { Object } componentData
     * 
     * @description This object contains the data for the filters to be rendered in the tire collection.
     * 
     */

    componentData = {

        FilterTextClient: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "client", placeholder : t ( "client" ) },

            dataName: false,
            
            gridOptions: { gridColumn: "1 / 3" }

        },

        FilterTextProductId: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "id", parse : "number", placeholder : t ( "ID" ) },

            dataName: false,

            gridOptions: { gridColumn: "4 / 6" }

        },

        FilterTextEan: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "ean", parse : "number", placeholder : t ( "EAN" ) },

            dataName: false,

            gridOptions: { gridColumn: "6 / 8" }

        },

        FilterTextDiameter: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "diameter", parse : false, placeholder : t ( "diameter" ) },

            dataName: false,

            gridOptions: { gridColumn: "1 / 2" }

        },
        
        FilterMultiplePCD: {

            component: FilterMultiple,

            props: { refetch : refetch, variables : variables, name : "pitchCircleDiameter" },

            dataName: "pitch_circle_diameters",

            gridOptions: { gridColumn: "2 / 3" }

        },

        FilterTextBolts: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "bolts", parse : "number", placeholder : t ( "bolts" ) },

            dataName: false,

            gridOptions: { gridColumn: "3 / 4" }

        },

        FilterTextWidth: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "width", parse : "number", placeholder : t ( "width" ) },

            dataName: false,

            gridOptions: { gridColumn: "4 / 5" }

        },

        FilterMultipleOffset: {

            component: FilterMultiple,

            props: { refetch : refetch, variables : variables, parse: "number", name : "offset" },

            dataName: "offsets",

            gridOptions: { gridColumn: "5 / 6" }

        },

        FilterMultipleCB: {

            component: FilterMultiple,

            props: { refetch : refetch, variables : variables, name : "centreBores" },

            dataName: "centre_bores",

            gridOptions: { gridColumn: "6 / 7" }

        },

        FilterMultipleManufacturer: {

            component: FilterMultiple,

            props: { refetch : refetch, variables : variables, name : "manufacturer", textValue : true },

            dataName: "manufacturers",

            gridOptions: { gridColumn: "1 / 3" }

        },

        FilterMultipleModel: {

            component: FilterMultiple,

            props: { refetch : refetch, variables : variables, name : "model", textValue : true },

            dataName: "models",

            gridOptions: { gridColumn: "3 / 6" }

        },

        FilterSelectVendorId: {

            component: FilterSelect,

            props: { refetch : refetch, variables : variables, name : "vendorId", parse : "number", label : "vendor" },

            dataName: "vendors",

            gridOptions: { gridColumn: "6 / 8" }

        },

        FilterBooleanBoltsIncluded: {

            component: FilterBoolean,

            props: { refetch : refetch, variables : variables, name : "boltsIncluded" },

            dataName: false,

            gridOptions: { gridColumn: "4 / 6" }

        },

        FilterTextColor: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "color", placeholder : t ( "color" ) },

            dataName: false,

            gridOptions: { gridColumn: "6 / 8" }

        },

        FilterTextOEM: {

            component: FilterText,

            props: { refetch : refetch, variables : variables, name : "specifications", parse : "number", placeholder : t ( "OEM" ) },

            dataName: false,

            gridOptions: { gridColumn: "9 / 12" }

        }

    };

    /** @returns { JSX.Elements } TiresFilters */

    return (

        loading ? <CenterLoader /> :

        <div className={ styles.container }>

            <div className={ styles.content }>

                {
                
                    Object.keys ( componentData ).map
                    
                    ( ( componentKey, index ) => {

                            const { component: Component, props, gridOptions, dataName } = componentData[componentKey];

                            return (

                                <div style={ gridOptions } key={ index }>

                                    <Component { ...props } data={ dataName ? data.searchAttributes.rims[0][dataName] : null } />

                                </div>

                            );

                        }
                    
                    )
                    
                }

            </div>

            <FiltersReset variables={ variables } refetch={ refetch } />

        </div>

    );

};