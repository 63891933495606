import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { FaRegTrashAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdUndo } from "react-icons/io";

import Input from "./../../Components/Common/Forms/input/index.js";
import Checkbox from "./../../Components/Common/Forms/checkbox/index.js";
import Textarea from "./../../Components/Common/Forms/Textarea/index.js";
import Select from "./../../Components/Common/Select/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";
import ButtonIconV2 from "../../Components/Common/ButtonIconV2/index.js";

import styles from "./styles.module.css";
import countries from "../../Lib/countries.js";


const PREFIX = "branchDetails";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    branchName: getName("BranchName"),
    country: getName("Country"),
    deliveryPlan: getName("DeliveryPlan"),
    address: getName("Address"),
    commentShippingAddress: getName("CommentShippingAddress"),
};

const ROW = {
    [FIELDS.branchName]: "",
    [FIELDS.country]: "",
    [FIELDS.deliveryPlan]: "",
    [FIELDS.address]: "",
    [FIELDS.commentShippingAddress]: "",
};

/* --- */

const FormClientEditBranchDetails = (props) => {
    const [isFormVisible, setIsFormVisible] = useState(false);

    const [rows, setRows] = useState(() => props.initialRows);

    const { t } = useTranslation();

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const onToggleForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const onAddRow = () => {
        setRows((prev) => {
            return [...prev].concat(ROW);
        });
    };

    const onRemoveRow = (index) => {
        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            isDeleted: true,
        };

        setRows(newRows);
    };

    const onUnlockRow = (index) => {
        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            isDeleted: false,
        };

        setRows(newRows);
    };

    const onChangeField = (index, field, evt) => {
        const value = evt.target.value || "";

        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            [field]: value,
        };

        setRows(newRows);
    };

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const renderRowRemoveLine = (index) => {
        let removeOrUndo = null;

        if (rows[index].isDeleted) {
            removeOrUndo = (
                <ButtonIconV2
                    icon={IoMdUndo}
                    onClick={() => {
                        onUnlockRow(index);
                    }}
                    isDisabled={isDisabled()}
                    isBlue
                />
            );
        } else {
            removeOrUndo = (
                <ButtonIconV2
                    icon={FaRegTrashAlt}
                    onClick={() => {
                        onRemoveRow(index);
                    }}
                    isDisabled={isDisabled()}
                    isRed
                />
            );
        }

        return (
            <div className={styles.rowRemoveLine}>
                <ButtonUnderline
                    label={`${t("branch")} ${index + 1}`}
                />
                {removeOrUndo}
            </div>
        );
    };

    const renderFormBranch = (index, data, formMethods) => {
        const { handleBlur } = formMethods;

        const {
            country,
            deliveryPlan,
            branchName,
            address,
            commentShippingAddress,
        } = FIELDS;

        const columnClassName = [styles.columnsWithMargin];

        if (data.isDeleted) {
            columnClassName.push(styles.columnDeleted);
        }

        return (
            <div className={columnClassName.join(" ")}>
                <div className={styles.column0}>
                    <Input
                        name={`${index}-${branchName}`}
                        label={t("branch_name")}
                        placeholder="......"
                        value={data[branchName]}
                        onChange={(evt) => {
                            onChangeField(index, branchName, evt);
                        }}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <div className={styles.message}>
                        {t("additional_information")}
                    </div>
                </div>
                <div>
                    <div className={styles.columns}>
                        <Select
                            label={t("country")}
                            options={countries.getCountriesOptions()}
                            selected={data[country]}
                            onSelect={(value) => {
                                const evt = { target: { value } };
                                onChangeField(index, country, evt);
                            }}
                            isDisabled={isDisabled()}
                            isFixedHeight
                        />
                        <Select
                            label={t("deliveryPlan")}
                            options={props.deliveryPlan.options}
                            selected={data[deliveryPlan]}
                            onSelect={(value) => {
                                const evt = { target: { value } };
                                onChangeField(index, deliveryPlan, evt);
                            }}
                            isDisabled={isDisabled(props.deliveryPlan.isLoading)}
                            isFixedHeight
                        />
                    </div>
                    <Input
                        name={`${index}-${address}`}
                        label={t("address")}
                        placeholder="......"
                        value={data[address]}
                        onChange={(evt) => {
                            onChangeField(index, address, evt);
                        }}
                        isDisabled={isDisabled()}
                        onBlur={handleBlur}
                    />
                    <Textarea
                        name={`${index}-${commentShippingAddress}`}
                        label={t("comment_shipping_address")}
                        placeholder="darba laiks no 9:00 -17:00 zvanīt pie vārtiem  pirms piegādes 30min..."
                        value={data[commentShippingAddress]}
                        onChange={(evt) => {
                            onChangeField(index, commentShippingAddress, evt);
                        }}
                        isDisabled={isDisabled()}
                        onBlur={handleBlur}
                    />
                </div>
            </div>
        );
    };

    const renderRows = (formMethods) => {
        const forms = [];

        for (let i = 0; i < rows.length; i += 1) {
            const data = rows[i];

            let header = null;

            if (i === 0) {
                header = (
                    <div className={styles.columnsWithMargin}>
                        <ButtonUnderline
                            label={`${t("branch")} 1`}
                        />
                        <ButtonUnderline
                            label={t("delivery_address")}
                        />
                    </div>
                );
            } else {
                header = renderRowRemoveLine(i);
            }

            forms.push(
                <div
                    key={`additional-row-${i}`}
                    className={styles.row}
                >
                    {header}
                    {renderFormBranch(i, data, formMethods)}
                </div>,
            );
        }

        return forms;
    };

    const renderFormContent = (formMethods) => {
        if (!isFormVisible) {
            return null;
        }

        const {
            values,
            setFieldValue,
        } = formMethods;

        const nameIsBranch = getName("IsBranch");

        return (
            <>
                <div className={styles.columnsWithMargin}>
                    <div>
                        <div className={styles.checkbox}>
                            <Checkbox
                                name={nameIsBranch}
                                label={t("this_is_a_branch")}
                                onChange={(value) => {
                                    setFieldValue(nameIsBranch, value);
                                }}
                                isSelected={values[nameIsBranch]}
                                isDisabled={isDisabled()}
                            />
                        </div>
                        <Select
                            label={t("company_name")}
                            isDisabled={isDisabled()}
                        />
                    </div>
                    <div />
                </div>

                {renderRows(formMethods)}

                <ButtonUnderline
                    label={t("add_branch")}
                    icon={FiPlusCircle}
                    onClick={onAddRow}
                    isDisabled={isDisabled()}
                    isClickable
                    isBlue
                />
            </>
        );
    };

    const renderForm = (formMethods) => {
        const { handleSubmit } = formMethods;

        if (props.formRef) {
            props.formRef.current = formMethods;
            props.formRef.current.values = {
                ...formMethods.values,
                rows,
            };
        }

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <ButtonUnderline
                    label={t("branch_details")}
                    onClick={onToggleForm}
                    isClicked={isFormVisible}
                    isClickable
                />
                {renderFormContent(formMethods)}
            </form>
        );
    };

    return (
        <Formik
            validate={validateForm}
            initialValues={{}}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditBranchDetails.defaultProps = {
    formRef: null,
    initialRows: [],
    deliveryPlan: {
        options: [],
        isLoading: false,
    },
    onSubmit: () => { },
    isEditable: false,
};

export {
    FIELDS,
    ROW,
};

export default FormClientEditBranchDetails;
