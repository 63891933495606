import { gql } from "@apollo/client";


const FETCH_DELIVERY_PLANS = gql`
query {
    deliveryPlans {
        id,
        value
    }
}
`;

export default {
    FETCH_DELIVERY_PLANS,
};
