import { sortable_fields } from "../SortableFields";

import ThSortable from "./ThSortable";

/**
 * 
 * @exports Th
 * 
 * @param { Object } props
 * 
 * @param { String } props.name
 * 
 * @param { Function } props.__
 * 
 * @description component validates if the field is sortable or not and returns the appropriate component
 * 
 * @returns { JSX.Element }
 * 
 */

export default function Th ( { name, __, refetch, variables } ) {

    /**
     * 
     * @returns { JSX.Element }
     * 
     */

    return ( 
        
        sortable_fields.includes ( name ) ? 
        
            <ThSortable name={ name } __={ __ } refetch={ refetch } variables={ variables } /> 
            
        : <th> { __ ( `product_table_${ name }` ) } </th> 
    
    );

};