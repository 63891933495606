const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table tag component
 * 
 */

Tag = ( { text } ) => {

    /** @returns */

    return ( <div data-title="text" className="tag">

        <span>
            
            { text }
            
        </span>

    </div> );

}

/** @exports Tag */
 
export default Tag;