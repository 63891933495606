/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import { useState }                 from "react";

import useFetch                     from "../../Hooks/useFetch";

import useUpdate                    from "../../Hooks/Mutations/useUpdate";

/** @module                         GQL */

import { UPDATE_SHOP_CONTACTS }     from "../../Mutations/Contacts";

import { SHOP_CONTACTS }            from "../../Queries/Contacts";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../Components/Dashboard/Global/Forms/Fieldset";

import ComponentLoader              from "../../Components/Dashboard/Loaders/ComponentLoader";

import Tabs                         from "../../Components/Dashboard/Global/Tabs";

import { LabeledInput }             from "../../Components/Dashboard/Global/Forms/Inputs_Spread";

const 

/** @constant DEFINITIONS */

DEFINITIONS = {

    query                           : SHOP_CONTACTS,

    update_query                    : UPDATE_SHOP_CONTACTS,

    entryName                       : "adminShopContacts",

    state_collection                : [ 

        "id",
        
        "address",

        "bank",

        "bankAccountNumber",

        "email",

        "id",

        "mobilePhoneNr",

        "regNr",

        "serviceNumber"
    
    ]

},

/**
 * 
 * @param { String } name
 * 
 * @returns update mutation
 * 
 */

ShopContacts = ( { name } ) => { usePagename ( name ); const { t } = useTranslation (),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( DEFINITIONS.query );

    /** @returns */

    return (

        <section className="mutations update_entry">

            <Breadcrumbs />

            <Tabs data={ [

                { name: t ( "shop_contacts"     ), uri: "/app/contacts" },

                { name: t ( "team"              ), uri: "/app/contacts/team" }

            ] } />

            { loading ? 

                <ComponentLoader />:

                <UpdateEntry entry={ data [ DEFINITIONS.entryName ] } __={ t } />

            }

        </section>

    );

},

/**
 * 
 * 
 * @param { Object } entry
 * 
 * @returns update mutation
 * 
 */

UpdateEntry = ( { entry, __ } ) => { const 

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading, 
        
        complete, 
        
        errors, 
        
        setErrors 
    
    } = useUpdate ( DEFINITIONS.update_query ),

    /** @event useState form fields */

    [ state, setState ] = useState ( generateEntryState ( entry, DEFINITIONS.state_collection ) ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( ! state.id ) { return setErrors ( true ); }
        
        updateMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @returns */

    return (

        <form onSubmit={ onSubmit }>

            <AsideEdit aside={ 
            
                <Sidebar 
                
                    __={ __ } 
                    
                    state={ state } 

                    entry={ entry }
                    
                    changeAction={ handleChange } 

                    errors={ errors }

                    pending={ loading }

                    success={ complete }
                    
                /> 
                
            }>

                <Fieldset legend={ __ ( "contacts_information" ) }>

                    <LabeledInput
                    
                        label={ __ ( "regNr" ) }
                    
                        value={ state.regNr } 

                        action={ handleChange }

                        name="regNr"
                        
                    />

                    <LabeledInput
                    
                        label={ __ ( "address" ) }
                    
                        value={ state.address } 

                        action={ handleChange }

                        name="address"
                        
                    />

                    <LabeledInput
                    
                        label={ __ ( "email" ) }
                    
                        value={ state.email } 

                        action={ handleChange }

                        name="email"
                        
                    />

                    <LabeledInput
                    
                        label={ __ ( "mobilePhoneNr" ) }
                    
                        value={ state.mobilePhoneNr } 

                        action={ handleChange }

                        name="mobilePhoneNr"
                        
                    />

                    <LabeledInput
                    
                        label={ __ ( "serviceNumber" ) }
                    
                        value={ state.serviceNumber } 

                        action={ handleChange }

                        name="serviceNumber"
                        
                    />

                    <LabeledInput
                    
                        label={ __ ( "bank" ) }
                    
                        value={ state.bank } 

                        action={ handleChange }

                        name="bank"
                        
                    />

                    <LabeledInput
                    
                        label={ __ ( "bankAccountNumber" ) }
                    
                        value={ state.bankAccountNumber } 

                        action={ handleChange }

                        name="bankAccountNumber"
                        
                    />

                </Fieldset>

            </AsideEdit>

        </form>

    );

},

/**
 * 
 * @param { Object } props
 * 
 * @returns sidebar component
 * 
 */

Sidebar = ( { 
    
    __, 
    
    state           = "", 

    entry           = {},
    
    changeAction    = () => { return },

    errors          = false,

    pending         = false,

    success         = false

} ) => {

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { success && ! errors &&
                
                    <div className="success">

                        <div className="success_message">

                            { __ ( "successfully_updated" ) }

                        </div>

                    </div> 
                
                }

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending }>

                        { __ ( "submit" ) }

                    </button>

                </div>

            </Fieldset>

        </aside>

    );

},

/**
 * 
 * @param { Object } entry
 * 
 * @param { Array } collection
 * 
 * @returns state object
 * 
 */

generateEntryState = ( entry, collection ) => { let 

    state = {};

    collection.forEach ( item => {

        state [ item ] = entry [ item ];

    } );

    return state;

};

/** @exports ShopContacts */
 
export default ShopContacts;