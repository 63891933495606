import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Languages = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Languages page */

    return ( <section className="languages">

        Languages page

    </section> );

}

/** @exports Languages */
 
export default Languages;