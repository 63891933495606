import { useTranslation } from "react-i18next";

import Fieldset from "../../Global/Forms/Fieldset";

/**
 * 
 * @exports ConfirmationAction
 * 
 * @description ConfirmationAction component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Boolean } props.errors - The errors state.
 * 
 * @param { Boolean } props.loading - The loading state.
 * 
 * @param { Boolean } props.complete - The complete state.
 * 
 * @example <ConfirmationAction errors={ errors } loading={ loading } complete={ complete } />
 * 
 * @returns { JSX.Element } ConfirmationAction
 * 
 */

export default function ConfirmationAction ( { errors = false, loading = false, complete = false } ) {

    const 

    { t : __ } = useTranslation ();

    /**
     * 
     * @description 
     * 
     * Render the ConfirmationAction component.
     * 
     * @returns { JSX.Element } ConfirmationAction
     * 
     */

    return (

        <Fieldset legend={ __ ( "actions" ) }>

            { complete && ! errors?.message &&
                
                <div className="success">

                    <div className="success_message">

                        { __ ( "successfully_updated" ) }

                    </div>

                </div> 
            
            }

            {

                errors?.message &&

                <div className="errors">

                    <div className="error">

                        { errors?.message || "Internal Server Error" }

                    </div>

                </div>

            }

            <button className="button" disabled={ loading }>

                { __ ( "submit" ) }

            </button>

        </Fieldset>

    );

};