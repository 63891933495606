import styles from "./VehicleType.module.css";

import { useState, useEffect } from "react";

import ControlledCheckbox from "../../../../Global/Forms/ControlledCheckbox";

/**
 * 
 * @exports FilterVehicleType
 * 
 * @description This component renders a checkbox input to filter the vehicle type collection 
 * 
 * @param { Object } props
 * 
 * @param { Array } props.data The data to be used to render the checkbox options
 * 
 * @param { Function } props.refetch The refetch function to refetch the vehicle type collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the vehicle type collection
 * 
 * @param { string } props.name The name of the variable to be filtered
 * 
 * @returns { JSX.Element } FilterVehicleType
 * 
 */

export default function FilterVehicleType ( { data, variables, name, refetch, loader } ) { const 

    [ vehicle_types, setVehicleTypes ] = useState ( variables [ name ] || [] ),

    /**
     * 
     * @function handleChange
     * 
     * @param { Object } event The event object
     * 
     * @returns set the value to the state
     * 
     */

    handleChange = ( event ) => {

        if ( event.target.checked ) {

            setVehicleTypes ( [...vehicle_types, event.target.value] );

        } else {

            setVehicleTypes ( vehicle_types.filter ( ( vehicle_type ) => vehicle_type != event.target.value ) );

        }

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns refetch the vehicle type collection after 1 second of inactivity
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && ! vehicle_types.length ) return;

        if ( variables [ name ] == vehicle_types ) return;

        refetch ( 

            { 
            
                [ name ] : vehicle_types.length ? vehicle_types : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ vehicle_types ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setVehicleTypes ( [] ); 
        
        }

    }, [ variables [ name ] ] );

    /** @returns { JSX.Element } FilterVehicleType */

    return (

        <div className={ styles.container }>

            {

                data.map ( ( item, index ) => {

                    return (

                        <ControlledCheckbox 
                        
                            key={ index } 
                        
                            name={ item.value } 
                        
                            id={ item.value } 
                        
                            value={ item.value } 

                            action={ handleChange }

                            text={ item.text }

                            disabled={ loader }

                            state={ vehicle_types.find ( ( vehicle_type ) => vehicle_type == item.value ) ? true : false }
                            
                        />

                    );

                } )

            }

        </div>

    );

};