import { usePagename } from "../../Hooks/usePagename";

import Table from "../../Components/Dashboard/Customers/Table";

import Pagination from "../../Components/Dashboard/Global/Table/Pagination";

const 

Customers = ( { name } ) => {

    /** @event usePagename */

    usePagename ( name );

    /** @returns */

    return ( <section className="customers">

        <Pagination pageinfo={ [] } action={ () => {} } cookie="servicesCollectionCount" />

        <Table />

        <Pagination pageinfo={ [] } action={ () => {} } cookie="servicesCollectionCount" />

    </section> );

}

/** @exports Customers */
 
export default Customers;