import React from "react";

import stylesInput from "./../Common/styles.module.css";
import styles from "./styles.module.css";


const Textarea = (props) => {
    const textareaClassName = [stylesInput.input, styles.textarea];

    if (props.isDisabled) {
        textareaClassName.push(stylesInput.inoutDisabled);
    }

    return (
        <div className={stylesInput.container}>
             <label
                htmlFor={props.name}
                className={stylesInput.label}
            >
                {props.label}
            </label>
            <textarea
                id={props.name}
                name={props.name}
                className={textareaClassName.join(" ")}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(evt) => {
                    if (props.isDisabled) {
                        return;
                    }

                    props.onChange(evt);
                }}
                onBlur={props.onBlur}
            />
        </div>
    );
};

Textarea.defaultProps = {
    name: "",
    value: "",
    placeholder: "",
    onChange: () => { },
    onBlur: () => { },
    isDisabled: false,
};

export default Textarea;
