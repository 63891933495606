import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { BsBasket } from "react-icons/bs";

import CartItem from "./CartItem";

/**
 * 
 * @exports CartTable
 * 
 * @description CartTable component.
 * 
 * @param { Object } props - The properties of the component.
 * 
 * @param { Array } props.cart - The cart array.
 * 
 * @param { function } props.remove - The remove function.
 * 
 * @example <CartTable cart={ cart } remove={ removeFromCart } />
 * 
 */

export function CartTable ( { cart, remove, update, reset, close } ) { const 
    
    { t } = useTranslation (),

    navigate = useNavigate (),

    handleOrder = () => { navigate ( "/app/orders/add/checkout" ); close (); },

    handleOffer = () => { navigate ( "/app/orders/add/checkout?order=offer" ); close (); };

    /** @returns { JSX.Element } CartTable */

    return (

        <div className="cartContainer">

            <div className="header">

                <span>

                    <BsBasket />

                </span>

                <span className="title">

                    { t ( "cart" ) }

                </span>

            </div>

            <div className="body">

                {

                    cart.length > 0 ?

                        <table>

                            <tbody>

                                { 
                                
                                    cart.map ( item => <CartItem key={ item.id } item={ item } remove={ remove } update={ update } /> ) 
                                    
                                }

                            </tbody>

                        </table> 
                    
                    :

                        <div className="emptyCart">

                            { t ( "cart_empty" ) }

                        </div>

                }

            </div>

            <div className="footer">

                {

                    cart.length > 0 &&

                    <span className="clearItems" onClick={ reset }>

                        { t ( "clear_cart" ) }

                    </span>

                }

                <div className="actionButtons">

                    <button onClick={ handleOrder } disabled={ cart.length === 0 }>

                        { t ( "create_order" ) }

                    </button>

                    <button onClick={ handleOffer } disabled={ cart.length === 0 }>

                        { t ( "create_offer" ) }

                    </button>

                </div>

            </div>

        </div>
        
    );

};