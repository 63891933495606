/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { useEffect, useState }      from "react";

import { useNavigate }              from "react-router-dom";

import useCreate                    from "../../../Hooks/Mutations/useCreate";

import useFetch                     from "../../../Hooks/useFetch";

/** @module                         GQL */

import { CREATE_POST }              from "../../../Mutations/Posts";

import { POSTS_LANGUAGE_ENUM }      from "../../../Queries/Posts";

/** @module                         Utilities */

import { enumOptions }              from "../../../Components/Dashboard/Utils/enumOptions";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import SelectSpread                 from "../../../Components/Dashboard/Global/Forms/Select_Spread";

import Checkbox                     from "../../../Components/Dashboard/Global/Forms/Checkbox";

import { Editor }                   from "@tinymce/tinymce-react";

const 

/**
 * 
 * @returns create mutation
 * 
 */

AddPosts = ( { name } ) => { 

    /** @event usePagename */

    usePagename ( name );
    
    const 
    
    { t }       = useTranslation (),

    navigate    = useNavigate (),

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors, 
        
        setErrors 
    
    } = useCreate ( CREATE_POST ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        title: "",

        image: undefined,

        content: "",

        description: "",

        isPublished: true,

        tiremarketOnly: false,

        language: "default"

    } ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( 
            
            ! state.title ||
            
            ! state.content ||

            ! state.description ||

            ! state.language ||

            state.language === "default"
            
            
        ) { return setErrors ( true ); }
        
        createMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked, files } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( type === "file" ) return setState ( prev => ( { ...prev, [ name ]: files[ 0 ] } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( `/app/content/posts/edit/${ returnData.adminCreateBlogItem.blogItem.id }` );

    }, [ complete ] );

    /** @returns */

    return (

        <section className="mutations create_post">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "title" ) }>

                        <div className="input-group">

                            <input
                            
                                type="text"

                                value={ state.title }

                                onChange={ handleChange }

                                name="title"
                            
                            />

                        </div>

                    </Fieldset>

                    <Fieldset legend={ t ( "content" ) }>

                        <Editor 

                            apiKey="2nr6f475ij0nazn91dwmt3x6fpevnv36no701uj3ap6wgzfn"
                        
                            onEditorChange={ ( newValue ) => setState ( prev => ( { ...prev, content: newValue } ) ) } 

                            value={ state.content }

                            plugins={ [

                                "charmap",

                                "emoticons",

                                "image",

                                "insertdatetime",

                                "link",

                                "lists",

                                "media",

                                "preview",

                                "searchreplace",

                                "table",

                                "visualblocks",

                                "visualchars",

                                "wordcount"

                            ] }

                            init={ {

                                selector:'textarea',

                                skin: "oxide-dark",

                                content_css: "dark",

                                image_title: true,

                                automatic_uploads: true,

                                file_picker_types: 'image',

                                file_picker_callback: function (cb, value, meta) {

                                    var input = document.createElement('input');

                                    input.setAttribute('type', 'file');

                                    input.setAttribute('accept', 'image/*');

                                    input.onchange = function () {

                                    var file = this.files[0];

                                    var reader = new FileReader();

                                    reader.onload = function () {

                                        var id = 'blobid' + (new Date()).getTime();
                                        var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                        var base64 = reader.result.split(',')[1];
                                        var blobInfo = blobCache.create(id, file, base64);
                                        blobCache.add(blobInfo);

                                        cb(blobInfo.blobUri(), { title: file.name });
                                    };

                                    reader.readAsDataURL(file);
                                    
                                    };

                                    input.click();
                                },

                            } }
                            
                        />

                    </Fieldset>

                    <Fieldset legend={ t ( "description" ) }>

                        <div className="input-group">

                            <input
                            
                                type="text"

                                value={ state.description }

                                onChange={ handleChange }

                                name="description"
                            
                            />

                        </div>

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

Sidebar = ( { 
    
    __, 
    
    state = "", 
    
    changeAction = () => { return },

    errors = false,

    pending = false

} ) => { const 

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading, data } = useFetch ( POSTS_LANGUAGE_ENUM );

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                { ! loading &&

                    <div className={ `mutation-actions default` }>

                        <button className="button add" disabled={ pending }>

                            { __ ( "submit" ) }

                        </button>

                    </div>
                
                }

            </Fieldset>

            <Fieldset legend={ __ ( "image" ) }>

                <input 
            
                    type="file" 

                    name="image"
                
                    defaultValue={ state.image } 
                
                    onChange={ changeAction } />

            </Fieldset>

            <Fieldset legend={ __ ( "options" ) }>

                <SelectSpread 
                
                    id="language"

                    i18n={ true }

                    def={ true }
                    
                    action={ changeAction }
                    
                    options={ loading ? [] : enumOptions ( data.__type.enumValues ) }
                    
                    value={ state.language } />

                <Checkbox 
                
                    id="isPublished" 
                
                    text={ __ ( "isPublished" ) } 
                
                    state={ state.isPublished } 
                
                    action={ changeAction } />

                <Checkbox 
                
                    id="tiremarketOnly" 
                
                    text={ __ ( "tiremarketOnly" ) } 
                
                    state={ state.tiremarketOnly } 
                
                    action={ changeAction } />

            </Fieldset>

        </aside>

    );

}

/** @exports AddPosts */
 
export default AddPosts;