import React from "react";
import ReactPaginate from "react-paginate";

import stylesButton from "./../Styles/Button/styles.module.css";
import styles from "./styles.module.css";


const Pagination = (props) => {
    if (props.totalPages <= 1) {
        return null;
    }

    const PAGE_OFFSET = props.isStartsWithZero ? 0 : 1;

    return (
        <div className={styles.container}>
            <ReactPaginate
                previousLabel="Front page"
                breakLabel="..."
                nextLabel="Next Page"
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={props.totalPages}
                forcePage={props.currentPage - PAGE_OFFSET}

                breakLinkClassName={stylesButton.button}
                nextLinkClassName={stylesButton.button}
                previousLinkClassName={stylesButton.button}
                pageLinkClassName={stylesButton.button}
                activeLinkClassName={stylesButton.blue}
                disabledClassName={styles.buttonHidden}

                onPageChange={({ selected }) => {
                    props.onSelect(selected + PAGE_OFFSET);
                }}
            />
        </div>
    );
};

Pagination.defaultProps = {
    currentPage: 1,
    totalPages: 0,
    onSelect: () => { },
    isStartsWithZero: false,
};

export default Pagination;
