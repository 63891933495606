/** @module                 React */

import { useState }         from "react";

/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { UPDATE_USER }      from "../../Mutations/Managers";

const 

/** 
 * 
 * @returns useUpdateManager hook for updating existing manager
 * 
 */

useUpdateManager = () => { const 

    /** @hooks */

    [ loading,  setLoading  ] = useState ( false ),

    [ errors,   setErrors   ] = useState ( false ),

    [ success,  setSuccess  ] = useState ( false ),

    /** @mutations */

    [ UpdateUser ] = useMutation ( UPDATE_USER, {

        /** @event onComplete */

        onCompleted ( data ) {

            setSuccess ( true );

            setLoading ( false );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { String } title
     * 
     * @param { String } content
     * 
     * @param { Boolean } isActive
     * 
     * @param { Boolean } tiremarketOnly
     * 
     * @param { String } language
     * 
     * @returns create service action
     * 
     */

    updateManager = ( id, email, firstName, lastName, role, password, passwordConfirmation, blocked, blockedReason ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true ); setSuccess ( false );

        if ( ! email || ! role || ! id ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        if ( blocked && ! blockedReason ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        if ( password !== passwordConfirmation ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        }

        /** @mutation */

        UpdateUser ( { variables: mutationObject (

            id,

            email,

            firstName,

            lastName,

            password,

            passwordConfirmation,

            role,

            blocked,

            blockedReason
    
        ) } );

    }

    /** @returns */

    return { updateManager, loading, errors, success }

},

mutationObject = ( id, email, firstName, lastName, password, passwordConfirmation, role, blocked, blockedReason ) => { const 

    object = {}

    if ( id ) { object.id = id; }

    if ( email ) { object.email = email; }

    if ( firstName ) { object.firstName = firstName; }

    if ( lastName ) { object.lastName = lastName; }

    if ( password ) { object.password = password; }

    if ( passwordConfirmation ) { object.passwordConfirmation = passwordConfirmation; }

    if ( role ) { object.role = role; }

    if ( blocked ) { object.blocked = blocked; }

    if ( blockedReason ) { object.blockedReason = blockedReason; }

    return object;

}

/** @exports useUpdateManager */

export default useUpdateManager;