// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__smrIF {\n    display: grid;\n    grid-gap: 20px;\n}\n\n/* --- */\n\n.styles_column0__6Hqli,\n.styles_column1__iTTEt,\n.styles_column2__M7Meh,\n.styles_column2TwoFields__5jKhD,\n.styles_column3__Lo9Nu {\n    display: grid;\n    grid-gap: 20px;\n\n    align-items: center;\n}\n\n.styles_column0__6Hqli {\n    grid-template-columns: 1fr 1fr 2fr;\n}\n\n.styles_column1__iTTEt {\n    grid-template-columns: 1fr 3fr;\n}\n\n.styles_column2__M7Meh {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n\n.styles_column2TwoFields__5jKhD {\n    grid-template-columns: 1fr 1fr;\n}\n\n.styles_column3__Lo9Nu {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-general-info/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA,QAAQ;;AAER;;;;;IAKI,aAAa;IACb,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 20px;\n}\n\n/* --- */\n\n.column0,\n.column1,\n.column2,\n.column2TwoFields,\n.column3 {\n    display: grid;\n    grid-gap: 20px;\n\n    align-items: center;\n}\n\n.column0 {\n    grid-template-columns: 1fr 1fr 2fr;\n}\n\n.column1 {\n    grid-template-columns: 1fr 3fr;\n}\n\n.column2 {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n\n.column2TwoFields {\n    grid-template-columns: 1fr 1fr;\n}\n\n.column3 {\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__smrIF",
	"column0": "styles_column0__6Hqli",
	"column1": "styles_column1__iTTEt",
	"column2": "styles_column2__M7Meh",
	"column2TwoFields": "styles_column2TwoFields__5jKhD",
	"column3": "styles_column3__Lo9Nu"
};
export default ___CSS_LOADER_EXPORT___;
