/** @module         Assets */

import noImage      from "../../../../../../Assets/no-image.png";

import { Link }     from "react-router-dom";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table image component
 * 
 */

Image = ( { id, src, alt } ) => {

    /** @returns */

    return ( 

        <td>

            <div className="table-image">

                <Link to={ `/app/products/edit/${ id }` }>

                    <img alt={ alt } src={ src } onError={ image => image.currentTarget.src = noImage } />

                </Link>

                <div className="table-image-zoom">
                    
                    <img alt={ alt } src={ src } onError={ image => image.currentTarget.src = noImage } />

                </div>

            </div>

        </td>

    );

}

/** @exports Image */
 
export default Image;