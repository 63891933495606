import styles from "./OrderHistory.module.css";

import { useTranslation } from "react-i18next";

import { convertDatetime } from "../../Utils/Datetime";

import Tag from "../../Global/Table/Tag";

import { FaUser } from "react-icons/fa";


/**
 * 
 * @description
 * 
 * Renders order history table component for single order page in admin dashboard.
 * 
 * @param { Object } order
 * 
 * @example
 * 
 * return <OrderHistory order={ order } />
 * 
 */


export default function OrderHistory ( { order } ) {
    

    const { t } = useTranslation ();

    if ( ! order?.orderHistories?.length ) return null;

    const header = [ "createdAt", "status", "deliveryMethod", "paymentMethod", "comment" ];


    return (

        <div className={ styles.container }>
            
            <p className={ styles.title }>{ t ( "orderHistories" ) }</p>

            <table>


                <thead>

                    <tr>

                        { header.map ( ( title, index ) => <th key={ index }>{ t ( title ) }</th> ) }

                    </tr>

                </thead>


                <tbody>

                    { order.orderHistories.map( ( history, index ) =>

                        <tr key={ index }>

                            <td className={ styles.content }>


                                <div className={ styles.date }>
                                    
                                    { convertDatetime ( history.createdAt ) }
                                    
                                </div>


                                <div className={ styles.author }>

                                    <span className={ styles.authorIcon }>

                                        <FaUser />

                                    </span>

                                    <span className={ styles.manager }>

                                        { history.editedBy }

                                    </span>

                                </div>


                            </td>


                            <Tag text={ t ( history?.status ) } style={ history?.status } />


                            <td>{ t ( history?.deliveryMethod ) || "-" }</td>


                            <td>{ t ( history?.paymentMethod ) || "-" }</td>


                            <td className="order_admin_notes">


                                { history?.comments == null ? "-" : 

                                    history?.comments.length > 32 ? 

                                        <span>

                                            <div className="extension">

                                                { history?.comments }

                                            </div>

                                            { history?.comments?.substring ( 0, 32 ) + "..." }

                                        </span>

                                    : history?.comments

                                }


                            </td>

                        </tr>

                    ) }

                </tbody>

            </table>

        </div>

    );

};