import styles from "./Boolean.module.css";

import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

/**
 * 
 * @exports FilterBoolean
 * 
 * @description This component renders a boolean filter to filter the product collection by a boolean variable
 * 
 * @param { Object } props
 * 
 * @param { Function } props.refetch The refetch function to refetch the product collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the product collection
 * 
 * @param { string } props.name The name of the variable to be filtered
 * 
 * @returns { JSX.Element } FilterBoolean
 * 
 */

export default function FilterBoolean ( { refetch, variables, name, loader } ) { const 

    { t } = useTranslation (),

    [ value, setValue ] = useState ( variables[ name ] ),

    handleChange = () => { 

        if ( loader ) return;
        
        setValue ( ! value ) 
    
    };

    useEffect ( () => {

        if ( ! variables [ name ] && value === false ) return;

        if ( variables [ name ] == value ) return;

        refetch ( 

            { 
            
                [ name ] : value,
    
                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,
    
                last: undefined,
    
                before: undefined,
    
                after: undefined
            
            }
            
        );

    }, [ value ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to the state after the variables are updated 
     * 
     */

    useEffect ( () => {

        setValue ( variables [ name ] );

    }, [ variables [ name ] ] );

    /** @returns { JSX.Elements } FilterBoolean */

    return (

        <div onClick={ handleChange } className={ styles.boolean } aria-checked={ value } style={ { opacity : loader ? "0.5" : 1 } }>

            <span className={ styles.text }>

                { t ( name ) }

            </span>

        </div>

    );

};