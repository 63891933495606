import { forwardRef } from "react";

import { BsCheck } from "react-icons/bs";

const 

ControlledCheckbox = forwardRef ( ( { 
    
    id = "default", 
    
    text = "Checkbox", 

    value = false,

    state = false,

    action = () => {},

    hidelabel = false,

    disabled = false

}, ref ) => {

    /** @returns */

    return ( <label htmlFor={ id } className="checkbox" onChange={ e => action ( e ) }>

        <input type="checkbox" id={ id } name={ id } checked={ state } value={ value } ref={ ref } onChange={ e => { return } } disabled={ disabled } />

        <span className="icon">

            <span className="selected">

                <BsCheck />

            </span>

        </span>

        { hidelabel ? "" : <span className="name">{ text }</span> }

    </label> );

} );

/** @exports ControlledCheckbox */
 
export default ControlledCheckbox;

