import { useTranslation } from "react-i18next";

import PageActions from "../../Global/PageActions";

import { AiOutlinePlusCircle } from "react-icons/ai";

/**
 * 
 * @exports OrderActions
 * 
 * @description This module exports the OrderActions component.
 * 
 * @returns { JSX.Element }
 * 
 */

export default function OrderActions ( { variables } ) {

    const 

    { t : __ } = useTranslation (),

    actions = [];

    if ( 
        
        variables.orderType === "manager_order" 
        
        || 
        
        variables.orderType === undefined 
        
        || 
        
        variables.orderType === "user_b2c_order" 
        
        || 
        
        variables.orderType === "user_b2b_order" 
    
    ) {

        actions.push ( {

            name: __ ( "create_new" ),

            uri: "/app/orders/add",

            icon: <AiOutlinePlusCircle />,

        } );

    }

    if ( 
        
        variables.orderType === "offer" 
        
        || 
        
        variables.orderType === undefined 
    
    ) {

        actions.push ( { 
            
            name: __ ( "new_offer" ), 
            
            uri: "/app/orders/add?order=offer", 
            
            icon: <AiOutlinePlusCircle />,
        
        } );

    }

    /**
     * 
     * @description 
     * 
     * This component is responsible for rendering the actions for the Orders page.
     * 
     * @returns { JSX.Element}
     * 
     */

    return <PageActions actions={ actions } />

};