/** @module                             Components */

import Fieldset                         from "../../Global/Forms/Fieldset";

import { LabeledInput }                 from "../../Global/Forms/Inputs_Spread";

const 

RimOptions = ( { __, state, action, loading } ) => { const 

    /** @constant fields */

    fields = [

        { name: "pitchCircleDiameter", type: "number" },

        { name: "boltPattern", type: "text" },

        { name: "centreBore", type: "number" }

    ];

    /** @returns Size component */

    return (

        <Fieldset legend={ __ ( "rim_options" ) }>

            <div className="grid grid_template-columns-3">

            { 
            
                fields.map ( 
                    
                    ( field, index ) => 

                    <LabeledInput

                        key={ index }

                        type={ field.type }

                        label={ __ ( field.name ) }
                            
                        value={ state [ field.name ] } 

                        action={ action }

                        name={ field.name }

                        disabled={ loading }
                        
                    />

                ) 
            
            }

            </div>

        </Fieldset>

    );

}

/** @exports RimOptions */
 
export default RimOptions;