/** @module         Assets */

import noImage      from "../../../../../../Assets/no-image.png";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table image component
 * 
 */

Image = ( { product } ) => {

    /** @returns */

    return ( <td data-title="image">

        <div className="table-image">

            <img alt={ product.model } src={ product.currentImage } onError={ image => image.currentTarget.src = noImage } />

        </div>

    </td> );

}

/** @exports Image */
 
export default Image;