// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_controls__HgMji {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 30px;\n}\n\n.styles_controlsButtons__329uN {\n    display: grid;\n    grid-gap: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Clients/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uCAAuC;IACvC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".controls {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 30px;\n}\n\n.controlsButtons {\n    display: grid;\n    grid-gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "styles_controls__HgMji",
	"controlsButtons": "styles_controlsButtons__329uN"
};
export default ___CSS_LOADER_EXPORT___;
