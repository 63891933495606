// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__qPRTB {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 10px;\n\n    align-items: center;\n    justify-content: center;\n\n    color: var(--button-color);\n    background-color: var(--button-bg-color);\n\n    height: 45px;\n    padding: 0 15px;\n\n    border-radius: 10px;\n\n    -webkit-user-select: none;\n\n            user-select: none;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.styles_button__qPRTB:hover {\n    filter: brightness(105%);\n}\n\n.styles_blue__L9W2t {\n    background-color: #3269F5;\n    color: white;\n}\n\n.styles_grey__0shbj {\n    background-color: #D9D9D9;\n    color: black;\n}\n\n.styles_red__xxJjE {\n    background-color: #E75555;\n    color: black;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/Styles/Button/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,uBAAuB;;IAEvB,0BAA0B;IAC1B,wCAAwC;;IAExC,YAAY;IACZ,eAAe;;IAEf,mBAAmB;;IAEnB,yBAAiB;;YAAjB,iBAAiB;;IAEjB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".button {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 10px;\n\n    align-items: center;\n    justify-content: center;\n\n    color: var(--button-color);\n    background-color: var(--button-bg-color);\n\n    height: 45px;\n    padding: 0 15px;\n\n    border-radius: 10px;\n\n    user-select: none;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.button:hover {\n    filter: brightness(105%);\n}\n\n.blue {\n    background-color: #3269F5;\n    color: white;\n}\n\n.grey {\n    background-color: #D9D9D9;\n    color: black;\n}\n\n.red {\n    background-color: #E75555;\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__qPRTB",
	"blue": "styles_blue__L9W2t",
	"grey": "styles_grey__0shbj",
	"red": "styles_red__xxJjE"
};
export default ___CSS_LOADER_EXPORT___;
