import { gql } from '@apollo/client';

const 

/** @description create new user */

CREATE_USER = gql`mutation CreateUser (

    $email: String!

    $password: String

    $passwordConfirmation: String

    $role: RolesEnum!

    $firstName: String

    $lastName: String

    $blocked: Boolean

    $blockedReason: String

) {

    adminCreateUser (

        input: {

            userInput: {

                email: $email,

                password: $password,

                passwordConfirmation: $passwordConfirmation,

                role: $role

                firstName: $firstName

                lastName: $lastName

                blocked: $blocked

                blockedReason: $blockedReason

            }

        }

    ) {

        user {

            id

        }

    }

}`,

/** @description update existing user */

UPDATE_USER = gql`mutation UpdateUser (

    $id: ID!

    $email: String!

    $password: String

    $passwordConfirmation: String

    $firstName: String

    $lastName: String

    $role: RolesEnum!

    $blocked: Boolean

    $blockedReason: String

) {

    adminUpdateUser (

        input: {

            id: $id

            userInput: {

                email: $email,

                password: $password,

                firstName: $firstName,

                lastName: $lastName,

                passwordConfirmation: $passwordConfirmation,

                role: $role

                blocked: $blocked

                blockedReason: $blockedReason

            }

        }

    ) {

        user {

            address

            bankIban

            bankNr

            bankSwift

            blocked

            blockedReason

            city

            firstName

            lastName

            companyName

            createdAt

            deleted

            email

            encryptedPassword

            faxNr

            hashId

            id

            isCompany

            locale

            markupId

            mobilePhoneNr

            permissions {

                adminPermissions

            }

            phoneNr

            postCode

            productsInCart

            pvnRegNr

            regNr

            role

            updatedAt

        }

    }

}`,

/** @description delete existing user */

DELETE_USER = gql`mutation DeleteUser (

    $id: ID!

) {

    adminDeleteUser (

        input: {

            id: $id

        }

    ) {

        user {

            id

        }

    }

}`;

/** @export mutations */

export { 
    
    CREATE_USER,

    UPDATE_USER,

    DELETE_USER

}