import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Placeholder from "../../Components/Dashboard/Placeholder";

const Search = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Contacts page */

    return ( <section className="product-search">

        <Breadcrumbs data={ [

            { name: "Products", uri: "/app/products/search" },

            { name: "Search", uri: "/app/products/search" }

        ] } />

        <Placeholder text="Placeholder for 'Products > Search' end-points." />

    </section> );

}

/** @exports Search */
 
export default Search;