const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table text component
 * 
 */

Text = ( { text } ) => {

    /** @returns */

    return ( <td data-title="text">

        { text }

    </td> );

}

/** @exports Text */
 
export default Text;