// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderPayment_content__oYgwN {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: nowrap;\n\n    align-items: center;\n\n}\n\n.OrderPayment_status__SbSeV {\n\n    margin-right: 0.5rem;\n\n}\n\n.OrderPayment_data__h3hbl {\n\n    flex: 1 1;\n\n}\n\n.OrderPayment_data__h3hbl > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.OrderPayment_payment__530ZM {\n\n    text-wrap: nowrap;\n\n    font-size: 0.75rem;\n\n}\n\n.OrderPayment_price__dTlTv {\n\n    text-wrap: nowrap;\n\n    color: var( --invert-solid );\n\n    font-weight: 500;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Global/Table/Cells/OrderPayment.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,mBAAmB;;IAEnB,iBAAiB;;IAEjB,mBAAmB;;AAEvB;;AAEA;;IAEI,oBAAoB;;AAExB;;AAEA;;IAEI,SAAO;;AAEX;;AAEA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,iBAAiB;;IAEjB,kBAAkB;;AAEtB;;AAEA;;IAEI,iBAAiB;;IAEjB,4BAA4B;;IAE5B,gBAAgB;;AAEpB","sourcesContent":[".content {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: nowrap;\n\n    align-items: center;\n\n}\n\n.status {\n\n    margin-right: 0.5rem;\n\n}\n\n.data {\n\n    flex: 1;\n\n}\n\n.data > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.payment {\n\n    text-wrap: nowrap;\n\n    font-size: 0.75rem;\n\n}\n\n.price {\n\n    text-wrap: nowrap;\n\n    color: var( --invert-solid );\n\n    font-weight: 500;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "OrderPayment_content__oYgwN",
	"status": "OrderPayment_status__SbSeV",
	"data": "OrderPayment_data__h3hbl",
	"payment": "OrderPayment_payment__530ZM",
	"price": "OrderPayment_price__dTlTv"
};
export default ___CSS_LOADER_EXPORT___;
