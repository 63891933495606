/** @module                     Hooks */

import { usePagename }          from "../../Hooks/usePagename";

import { useParams }            from "react-router-dom";

import { useTranslation }       from "react-i18next";

import useFetch                 from "../../Hooks/useFetch";

/** @module                     Apollo */

import { PAGES }                from "../../Queries/Pages";

import { PAGES_LANGUAGE_ENUM }  from "../../Queries/Pages";

/** @module                     React-Icons */

import { AiOutlinePlusCircle }  from "react-icons/ai";

/** @module                     Components */

import Breadcrumbs              from "../../Components/Dashboard/Breadcrumbs";

import Tabs                     from "../../Components/Dashboard/Pages/Tabs";

import ComponentLoader          from "../../Components/Dashboard/Loaders/ComponentLoader";

import { Link }                 from "react-router-dom";

import Tag                      from "../../Components/Dashboard/Global/Table/Tag";

import Text                     from "../../Components/Dashboard/Global/Table/Text";

import Edit                     from "../../Components/Dashboard/Global/Table/Actions/Edit";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns pagelist for pages component
 * 
 */

Pagelist = ( { name } ) => { usePagename ( name ); const { t } = useTranslation (),

    { language, type }      = useParams (),

    { loading: loading_languages, data: languages_list }       = useFetch ( PAGES_LANGUAGE_ENUM ),

    { loading: loading_pages, data: pages } = useFetch ( PAGES, {

        language: ( language ) ? language : undefined,

        pageType: ( type ) ? type : undefined

    }, "cache-and-network", true );

    /** @returns */

    return ( 
    
        <section className="services">
        
            <Breadcrumbs />

            <Tabs 
            
                loading={ loading_languages } 
                
                data={ languages_list } 
                
                pageType={ type } 
                
            />

            { ! loading_pages && language && pages.adminFrontendPages.edges.length === 0 &&

                <div className="product-actions">

                    <Link to={ `/app/pages/${ type }/${ language }/add` }>

                        <AiOutlinePlusCircle />
                        
                        <span>

                            { t ( "add_new" ) }

                        </span>
                        
                    </Link>

                </div>
                
            }

            { ( loading_pages ) ? <ComponentLoader /> : 
            
                <table>

                    <thead>
            
                        <tr>
            
                            <th>{ t ( "language" ) }</th>
            
                            <th>{ t ( "abbreviation" ) }</th>
            
                            <th>{ t ( "actions" ) }</th>
            
                        </tr>
            
                    </thead>
            
                    { ( typeof pages != "undefined" ) && 
                    
                        <tbody>
            
                            { pages.adminFrontendPages.edges.map ( ( page, index ) => 
                            
                                <tr key={ index }>
                
                                    <Text text={ t ( page.node.language ) } />
                    
                                    <Tag text={ page.node.language } /> 
                    
                                    <td data-title="actions" className="actions">
                    
                                        <Edit route={ `/app/pages/${ type }/${ page.node.language }/edit/${ page.node.id }` } />
                    
                                    </td>
                
                                </tr> 
                                
                            ) }
            
                        </tbody> 
                        
                    }
            
                </table>
            
            }

        </section> 
    
    );

}

/** @exports Pagelist */
 
export default Pagelist;