// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1He7z {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    width: 50px;\n    height: 50px;\n\n    border-radius: 100%;\n    color: rgb(117, 117, 117);\n\n    -webkit-user-select: none;\n\n            user-select: none;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.styles_containerPurple__LtMul {\n    color: #896ACC;\n}\n\n.styles_containerBlue__TAEBN {\n    color: #5F9DD7;\n}\n\n.styles_containerRed__T5UQO {\n    color: #D56A69;\n}\n\n.styles_containerGreenDark__3K4HB {\n    color: #568324;\n}\n\n.styles_containerGreen__kGFu5 {\n    color: #87C444;\n}\n\n.styles_container__1He7z:hover {\n    filter: brightness(110%);\n}\n\n/* --- */\n\n.styles_icon__I56\\+3 {\n    width: 25px;\n    height: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/ButtonIconV2/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,WAAW;IACX,YAAY;;IAEZ,mBAAmB;IACnB,yBAAyB;;IAEzB,yBAAiB;;YAAjB,iBAAiB;;IAEjB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA,QAAQ;;AAER;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    width: 50px;\n    height: 50px;\n\n    border-radius: 100%;\n    color: rgb(117, 117, 117);\n\n    user-select: none;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.containerPurple {\n    color: #896ACC;\n}\n\n.containerBlue {\n    color: #5F9DD7;\n}\n\n.containerRed {\n    color: #D56A69;\n}\n\n.containerGreenDark {\n    color: #568324;\n}\n\n.containerGreen {\n    color: #87C444;\n}\n\n.container:hover {\n    filter: brightness(110%);\n}\n\n/* --- */\n\n.icon {\n    width: 25px;\n    height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1He7z",
	"containerPurple": "styles_containerPurple__LtMul",
	"containerBlue": "styles_containerBlue__TAEBN",
	"containerRed": "styles_containerRed__T5UQO",
	"containerGreenDark": "styles_containerGreenDark__3K4HB",
	"containerGreen": "styles_containerGreen__kGFu5",
	"icon": "styles_icon__I56+3"
};
export default ___CSS_LOADER_EXPORT___;
