import { gql } from "@apollo/client";


const FETCH_PAYMENT_OF_GOOD = gql`
query {
    paymentOfGood {
        id,
        value
    }
}
`;

export default {
    FETCH_PAYMENT_OF_GOOD,
};
