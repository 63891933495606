export default function TireSize ( { product } ) { const 

    width = product.width,

    height = product.height,

    diameter = parseInt ( product.diameter ),

    isCTire = product.isCTire;

    return (

        <td style={ { textWrap: "nowrap" } }>

            { `${ width } x ${ height } x R${ diameter }${ isCTire ? "C" : "" }` }

        </td>

    );

};