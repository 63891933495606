import React, { useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import useGlobalClose from "../../../Hooks/use-global-close.js";

import SelectOption from "./option.js";

import stylesInput from "./../Forms/Common/styles.module.css";

import styles from "./styles.module.css";


const Select = (props) => {
    const containerRef = useRef(null);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    /* --- */

    const toggleMenu = () => {
        if (props.isDisabled) {
            return;
        }

        setIsVisibleMenu((prev) => !prev);
    };

    const onSelect = (name) => {
        props.onSelect(name);
        setIsVisibleMenu(false);
    };

    /* --- */

    useGlobalClose((evt) => {
        if (!isVisibleMenu
            || containerRef.current.contains(evt.target)) {
            return;
        }

        setIsVisibleMenu(false);
    }, [isVisibleMenu]);

    /* --- */

    const renderSelectedOption = () => {
        let selected = "Select...";

        for (let i = 0; i < props.options.length; i += 1) {
            const opt = props.options[i];

            if (opt.name === props.selected) {
                selected = opt.label;
                break;
            }
        }

        return (
            <div className={styles.optionSelectedLabel}>
                {selected}
            </div>
        );
    };

    const renderCaret = () => {
        const caretClassName = [styles.caret];

        if (isVisibleMenu) {
            caretClassName.push(styles.caretUp);
        }

        return (
            <MdKeyboardArrowDown
                className={caretClassName.join(" ")}
            />
        );
    };

    const renderOptions = () => {
        if (!props.options.length) {
            return (
                <div className={styles.noOption}>
                    No Options
                </div>
            );
        }

        return props.options.map((opt) => {
            return (
                <SelectOption
                    key={`option-${opt.name}`}
                    name={opt.name}
                    label={opt.label}
                    onSelect={onSelect}
                    isSelected={opt.name === props.selected}
                />
            );
        });
    };

    const renderMenu = () => {
        const menuClassName = [styles.menu];

        if (!isVisibleMenu) {
            menuClassName.push(styles.menuHidden);
        }

        return (
            <div className={menuClassName.join(" ")}>
                {renderOptions()}
            </div>
        );
    };

    const renderLabel = () => {
        if (!props.label) {
            return null;
        }

        return (
            <div className={styles.label}>
                {props.label}
            </div>
        );
    };

    const renderSelect = () => {
        const selectClassName = [styles.select];

        if (props.isDisabled) {
            selectClassName.push(styles.selectDisabled);
        }

        if (props.error) {
            selectClassName.push(stylesInput.inputWithError);
        }

        return (
            <div
                className={styles.selectContainer}
                ref={containerRef}
            >
                <div
                    className={selectClassName.join(" ")}
                    onClick={toggleMenu}
                    role="button"
                    tabIndex="-1"
                >
                    {renderSelectedOption()}
                    {renderCaret()}
                </div>
                {renderMenu()}
            </div>
        );
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <div className={stylesInput.inputError}>
                {props.error}
            </div>
        );
    };

    const containerClassName = [styles.container];

    if (props.isFixedHeight) {
        containerClassName.push(stylesInput.containerFixedHeight);
    }

    return (
        <div className={containerClassName.join(" ")}>
            {renderLabel()}
            {renderSelect()}
            {renderError()}
        </div>
    );
};

Select.defaultProps = {
    options: [],
    selected: null,
    error: "",
    onSelect: () => { },
    isDisabled: false,
    isFixedHeight: false,
};

export default Select;
