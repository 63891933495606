/** @module                         React */

import { useState, useEffect }     from "react";

/**
 * 
 * @returns products collection item count and hook to update this number
 * 
 */

export const usePaginationLength = ( entry = false ) => { const

    /** @description user's product collection length */

    collectionLength = JSON.parse ( 
        
        localStorage.getItem ( entry ) ) 
        
        || 
        
        JSON.parse ( "12" 
        
    ),

    /** @event useState */

    [ itemsCount, setItemsCount ] = useState ( collectionLength );

    /** 
     * 
     * @description update user's collection length in local storage
     * 
     * @event useEffect 
     * 
     */

    useEffect ( () => {

        localStorage.setItem ( entry, JSON.stringify ( itemsCount ) );

    }, [ itemsCount, setItemsCount ] );

    if ( ! entry ) return false;

    /** @returns hook data */

    return { itemsCount, setItemsCount };

};