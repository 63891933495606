import { useNavigate } from "react-router-dom";

import useAuthorization from "./useAuthorization";
import { SIGNOUT } from "../../Mutations/Authentication";
import { useState } from "react";
import { useMutation } from "@apollo/client";

const 

useLogout = () => { const 

    /** @hooks */

    navigate                    = useNavigate (),

    [ loading, setLoading   ]   = useState ( false ),

    [ errors, setErrors     ]   = useState ( false ),

    { dispatch              }   = useAuthorization (),

    /** @mutations */

    [ SignOut ] = useMutation ( SIGNOUT, {

        onCompleted () {

            setLoading ( false );

            dispatch ( { type: "LOGOUT" } );

            setTimeout ( () => { navigate ( "/" ) }, 100 );

        },

        onErrors () { setLoading ( false ); setErrors ( true ); dispatch ( { type: "LOGOUT", state: false } ); }

    } ),

    /**
     * 
     * @returns logout mutation
     * 
     */

    logout = () => {

        /** @validation */

        setErrors ( false ); setLoading ( true );

        /** @mutation */

        SignOut ();

    }

    /** @returns */

    return [ logout, loading, errors ];

}

/** @exports useLogout */
 
export default useLogout;