import { useTranslation } from "react-i18next";

import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import { PAYMENT_METHODS_ENUM } from "../../../../../Queries/Orders";

import Fieldset from "../../../Global/Forms/Fieldset";

import SelectSpread from "../../../Global/Forms/Select_Spread";

import Checkbox from "../../../Global/Forms/Checkbox";

/**
 * 
 * @exports PaymentSettings
 * 
 * @description PaymentSettings component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.state - The state object.
 * 
 * @param { Function } props.update - The update function.
 * 
 * @example <PaymentSettings state={ state } update={ update } />
 * 
 * @returns { JSX.Element } PaymentSettings
 * 
 */

export default function PaymentSettings ( { state, update, orderType } ) { const 

    { t : __ } = useTranslation (),

    { data, loading } = useQuery ( PAYMENT_METHODS_ENUM );

    /**
     * 
     * @event useEffect 
     * 
     * @description If not loading, update status to first enum value.
     * 
     */

    useEffect ( () => {

        if ( ! loading && state.paymentMethod === "" ) {

            if ( orderType === "offer" ) {

                update ( { ...state, paymentMethod: "invoice" } );

                return;

            }

            update ( { ...state, paymentMethod: data.__type.enumValues[ 0 ].name } );

        }

    }, [ data ] );

    /** @returns { JSX.Element } PaymentSettings */

    return (

        <Fieldset legend={ __ ( "paymentMethod" ) }>

            <Checkbox

                id={ "vatIncluded" }

                text={ __ ( "showVat" ) }

                state={ state.vatIncluded }

                action={ e => update ( { ...state, vatIncluded: e.target.checked } ) }

                name={ "vatIncluded" }

            />

            {

                ! loading &&

                <SelectSpread 
                
                    id="paymentMethod"

                    i18n={ true }
                    
                    action={ e => update ( { ...state, paymentMethod: e.target.value } ) }
                    
                    options={ data.__type.enumValues.map ( ( { name } ) => ( { value: name, name } ) ) }
                    
                    value={ state.paymentMethod } 
                    
                />

            }

            <textarea
                    
                name="orderPaymentNotes"

                id="orderPaymentNotes"

                onChange={ e => update ( { ...state, orderPaymentNotes: e.target.value } ) }

                placeholder={ `${ __ ( "payment_notes" ) }...` }

                value={ state.orderPaymentNotes }
            
            />

        </Fieldset>

    );

};