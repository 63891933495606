// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_message__nv2Or {\n\n    margin: 0;\n    \n    text-transform: uppercase;\n    \n    letter-spacing: 1px;\n    \n    font-size: 0.8rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Filters/Container.module.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;;IAET,yBAAyB;;IAEzB,mBAAmB;;IAEnB,iBAAiB;;AAErB","sourcesContent":[".message {\n\n    margin: 0;\n    \n    text-transform: uppercase;\n    \n    letter-spacing: 1px;\n    \n    font-size: 0.8rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "Container_message__nv2Or"
};
export default ___CSS_LOADER_EXPORT___;
