import Checkbox from "../../../Global/Forms/Checkbox";

import Attributes from "../../../Global/Forms/Fieldset/Attributes";

import Fieldset from "../../../Global/Forms/Fieldset";

import MutationActions from "../../../Global/Layouts/Mutations/MutationActions";

import CenterLoader from "../../../Loaders/CenterLoader";

import useFetch from "../../../../../Hooks/useFetch";

import Select from "../../../Global/Forms/Select";

import { SERVICES_LANGUAGE_ENUM } from "../../../../../Queries/Services";

import { enumOptions } from "../../../Utils/enumOptions";

import { useTranslation } from "react-i18next";

const Sidebar = ( { data, details } ) => { const 

    { t } = useTranslation (),

    { data : enumData, loading } = useFetch ( SERVICES_LANGUAGE_ENUM ),

    /** @event onClick */

    updateIsActive          = e => details.isActive.set         ( e.target.checked ),

    updateTiremarketOnly    = e => details.tiremarketOnly.set   ( e.target.checked );

    return ( <aside>

        <Fieldset legend={ t ( "actions" ) }>

            <Attributes details={ [

                { name: t ( "createdAt" ), value: new Date ( data.createdAt ).toLocaleString () },

                { name: t ( "updatedAt" ), value: new Date ( data.updatedAt ).toLocaleString () },

                { name: t ( "language" ), value: t ( data.language ) },

            ] } />

            <MutationActions 

                remove={ true }

                submitAction={ details.submit.action }

                removeAction={ details.remove.action }

                references={ [ details.submit.reference, details.remove.reference ] } />

        </Fieldset>

        <Fieldset legend={ t ( "image" ) }>

            <div className="entry-image">

                <img src={ details.image.view } />

            </div>

            <input 
            
                type="file" 
            
                defaultValue="" 
            
                onChange={ e => details.image.set ( e.target.files[ 0 ] ) } />

        </Fieldset>

        <Fieldset legend={ t ( "options" ) }>

            { loading ? <CenterLoader /> : 

                <Select 
                    
                    id="language"
                    
                    action={ e => details.language.set ( e ) }
                    
                    options={ enumOptions ( enumData.__type.enumValues ) }
                    
                    value={ details.language.view }
                    
                    ref={ details.language.reference } />

            }

            <Checkbox 
                
                id="isActive" 
            
                text={ t ( "isActive" ) } 
            
                value={ data.isActive } 
            
                state={ data.isActive } 
            
                action={ updateIsActive } />

            <Checkbox 
            
                id="tiremarketOnly" 
            
                text={ t ( "tiremarketOnly" ) } 
            
                value={ data.tiremarketOnly } 
            
                state={ data.tiremarketOnly } 
            
                action={ updateTiremarketOnly } />

        </Fieldset>

    </aside> );

}

/** @exports Sidebar */
 
export default Sidebar;