/** @module                             Components */

import Fieldset                         from "../../Global/Forms/Fieldset";

import { LabeledInput }                 from "../../Global/Forms/Inputs_Spread";

const 

Quantity = ( { __, state, action, loading } ) => { const 

    /** @constant quantities */

    quantities = [ "qty", "qtyHours", "qtyDays", "qtyWeeks" ];

    /** @returns Quantity component */

    return (

        <Fieldset legend={ __ ( "quantity" ) }>

            <div className="grid grid_template-columns-4">

            { 
            
                quantities.map ( 
                    
                    ( quantity, index ) => 

                    <LabeledInput

                        key={ index }

                        type="number"

                        label={ __ ( quantity ) }
                            
                        value={ state [ quantity ] } 

                        action={ action }

                        name={ quantity }

                        disabled={ loading }
                        
                    />

                ) 
            
            }

            </div>

        </Fieldset>

    );

}

/** @exports Quantity */
 
export default Quantity;