// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__Vf9OC {}\n\n.styles_header__NQn5x {\n    margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-company-info/styles.module.css"],"names":[],"mappings":"AAAA,qBAAO;;AAEP;IACI,mBAAmB;AACvB","sourcesContent":[".form {}\n\n.header {\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__Vf9OC",
	"header": "styles_header__NQn5x"
};
export default ___CSS_LOADER_EXPORT___;
