import { gql } from '@apollo/client';

const 

/** @description create new post */

CREATE_POST = gql`mutation CreatePost (

    $language: BlogItemsLanguagesEnum!

    $title: String!

    $image: Upload

    $content: String!

    $description: String!

    $isPublished: Boolean!

    $tiremarketOnly: Boolean!

) {

    adminCreateBlogItem (

        input: {

            blogItemsInput: {

                language: $language

                title: $title

                image: $image

                content: $content

                description: $description

                isPublished: $isPublished

                tiremarketOnly: $tiremarketOnly

            }

        }

    ) {

        blogItem {

            id

        }

    }

}`,

/** @description update existing post */

UPDATE_POST = gql`mutation UpdatePost (

    $id: ID!

    $language: BlogItemsLanguagesEnum!

    $image: Upload

    $title: String!

    $content: String!

    $description: String!

    $isPublished: Boolean!

    $tiremarketOnly: Boolean!

) {

    adminUpdateBlogItem (

        input: {

            id: $id

            blogItemsInput: {

                language: $language

                title: $title

                image: $image

                content: $content

                description: $description

                isPublished: $isPublished

                tiremarketOnly: $tiremarketOnly

            }

        }

    ) {

        blogItem {

            content

            createdAt

            description

            id

            image

            isPublished

            language

            tiremarketOnly

            title

            updatedAt

        }

    }

}`,

/** @description delete existing post */

DELETE_POST = gql`mutation DeletePost (

    $id: ID!

) {

    adminDeleteBlogItem (

        input: {

            id: $id

        }

    ) {

        blogItem {

            id

        }

    }

}`;

/** @export mutations */

export { 
    
    CREATE_POST,

    UPDATE_POST,

    DELETE_POST

}