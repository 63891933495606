const Placeholder = ( { text } ) => {

    /** @return component */

    return ( <div className="endpoint-placeholder">

        { text }

    </div> );

}

/** @export Placeholder */
 
export default Placeholder;