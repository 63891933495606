import React from "react";
import { Formik } from "formik";

import { useTranslation } from "react-i18next";

import currencies from "../../Lib/currencies.js";

import Select from "./../../Components/Common/Select/index.js";
import Switch from "../../Components/Common/Switch/index.js";
import styles from "./styles.module.css";


const PREFIX = "currencySettings";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    currency: getName("Currency"),
    showVAT: getName("ShowVAT"),
};

const INITIAL_VALUES = {
    [FIELDS.currency]: "",
    [FIELDS.showVAT]: false,
};

/* --- */

const FormClientEditCompanyContacts = (props) => {
    const { t } = useTranslation();

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const renderSetting = (params = {}) => {
        return (
            <div className={styles.setting}>
                <div className={styles.settingLabel}>
                    {params.label}
                </div>
                <div className={styles.settingController}>
                    {params.controller}
                </div>
            </div>
        );
    };

    const renderForm = (formMethods) => {
        const {
            handleSubmit,
            setFieldValue,
            values,
        } = formMethods;

        const {
            currency,
            showVAT
        } = FIELDS;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                {renderSetting({
                    label: `${t("currency")}:`,
                    controller: (
                        <Select
                            options={currencies.getCurrencyOptions()}
                            selected={values[currency]}
                            onSelect={(value) => {
                                setFieldValue(currency, value);
                            }}
                            isDisabled={!props.isEditable}
                        />
                    ),
                })}
                {renderSetting({
                    label: `${t("show_prices_with_VAT")}:`,
                    controller: (
                        <Switch
                            onChange={(value) => {
                                setFieldValue(showVAT, value);
                            }}
                            isDisabled={!props.isEditable}
                            isSelected={values[showVAT]}
                        />
                    ),
                })}
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            innerRef={props.formRef}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditCompanyContacts.defaultProps = {
    formRef: null,
    initialValues: INITIAL_VALUES,
    onSubmit: () => { },
    isEditable: false,
};

export {
    PREFIX,
    INITIAL_VALUES,
    FIELDS,
};

export default FormClientEditCompanyContacts;
