import React from "react";
import { FaRegEye, FaRegTrashAlt, FaRegUser } from "react-icons/fa";
import { MdOutlineModeEdit, MdAdd } from "react-icons/md";

import Checkbox from "./../../../Components/Common/Forms/checkbox/index.js";
import ButtonIcon from "./../../../Components/Common/ButtonIcon/index.js";

import TableClientsColumnName from "./../column-name/index.js";

import { getColumns } from "./../columns.jsx";

import styles from "./styles.module.css";


const COLUMNS = getColumns();

const TableClientsTable = (props) => {
    const renderColumnClientIds = () => {
        const rows = props.clients.map((client) => {
            return (
                <div
                    className={styles.rowSingle}
                    key={`row-ids-${client.id}`}
                >
                    <Checkbox
                        label={client.id}
                        name={`client-row-${client.id}`}
                        onChange={() => {
                            props.onToggleRow(client.id);
                        }}
                        isSelected={props.selectedClients.indexOf(client.id) !== -1}
                    />
                </div>
            );
        });

        const clientsCount = props.clients.length;

        return (
            <div className={styles.columnClientIds}>
                <div className={styles.rowHeader}>
                    <Checkbox
                        name="rows-toggler-all"
                        onChange={() => {
                            const ids = props.clients.map((client) => client.id);
                            props.onToggleAllRows(ids);
                        }}
                        isSelected={clientsCount && clientsCount === props.selectedClients.length}
                        isDisabled={!clientsCount}
                    />
                    <TableClientsColumnName
                        name="Client ID"
                        onClick={() => {
                            props.onSort("id");
                        }}
                        isSortable
                    />
                </div>
                {rows}
            </div>
        );
    };

    const renderColumnData = () => {
        const visibleColumnIndexes = [];

        const header = (
            <div className={styles.row}>
                {COLUMNS.map((column, i) => {
                    const isVisibleColumn = props.visibleColumns.length === 0
                        || props.visibleColumns.indexOf(column.field) !== -1;

                    if (!isVisibleColumn) {
                        return null;
                    }

                    visibleColumnIndexes.push(i);

                    return (
                        <TableClientsColumnName
                            key={`row-data-${column.field}`}
                            name={column.label}
                            width={column.width}
                            onClick={() => {
                                props.onSort(column.sortBy);
                            }}
                            isSortable={column.sortBy}
                        />
                    );
                })}
            </div>
        );
        
        const rows = props.clients.map((client, i) => {
            const columns = COLUMNS.map((column, j) => {
                if (visibleColumnIndexes.length && visibleColumnIndexes.indexOf(j) === -1) {
                    return null;
                }
                

                return (
                    <div
                        key={`row-${i}-data-${j}-${client.id}`}
                        style={{
                            minWidth: `${column.width}px`,
                        }}
                    >
                        {typeof client[column.field] === 'object' ? client[column.field].value : client[column.field]}
                    </div>
                );
            });

            return (
                <div
                    className={styles.row}
                    key={`row-data-${i}`}
                >
                    {columns}
                </div>
            );
        });

        return (
            <div className={styles.columnData}>
                <div className={styles.columnDataRows}>
                    {header}
                    {rows}
                </div>
            </div>
        );
    };

    const renderColumnActions = () => {
        const rows = props.clients.map((client) => {
            return (
                <div
                    className={styles.rowSingle}
                    key={`row-action-${client.id}`}
                >
                    <div className={styles.columnActionsContent}>
                        <ButtonIcon
                            icon={FaRegEye}
                            onClick={() => {
                                props.actions.onPreview(client.id);
                            }}
                            isPurple
                        />
                        <ButtonIcon
                            icon={MdOutlineModeEdit}
                            onClick={() => {
                                props.actions.onEdit(client.id);
                            }}
                            isBlue
                        />
                        <ButtonIcon
                            icon={FaRegTrashAlt}
                            isRed
                        />
                        <ButtonIcon
                            icon={FaRegUser}
                            isGreenDark
                        />
                        <ButtonIcon
                            icon={MdAdd}
                            isGreen
                        />
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.columnActions}>
                <div className={styles.rowSingle}>
                    <TableClientsColumnName
                        name="Actions"
                    />
                </div>
                {rows}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {renderColumnClientIds()}
            {renderColumnData()}
            {renderColumnActions()}
        </div>
    );
};

TableClientsTable.defaultProps = {
    clients: [],
    selectedClients: [],
    visibleColumns: [],
    actions: {
        onEdit: () => { },
        onPreview: () => { },
    },
    onToggleRow: () => { },
    onSort: () => { },
    onToggleAllRows: () => { },
};

export default TableClientsTable;
