/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

/** @module                         Queries */

import { MANUFACTURERS }            from "../../../Queries/Manufacturers";

import { MANUFACTURER_PREVIEW }     from "../../../Queries/Manufacturers";

/** @module                         React-Icons */

import { AiOutlinePlusCircle }      from "react-icons/ai";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import FetchTable                   from "../../../Components/Dashboard/Global/Table/FetchTable";

import PageActions                  from "../../../Components/Dashboard/Global/PageActions";

/**
 * 
 * @param { String } name 
 * 
 * @returns initial page component
 * 
 */

const Manufacturers = ( { name } ) => { const { t } = useTranslation ();

    /** @event usePagename */

    usePagename ( name );

    /** @return Manufacturers page */

    return ( <section className="manufacturers">

        <Breadcrumbs />

        <PageActions actions={ [

            { name: t ( "create_new" ), uri: "/app/shop/manufacturers/create", icon: <AiOutlinePlusCircle /> }

        ] } />

        <FetchTable
        
            query={ MANUFACTURERS }

            identifier="manufacturers"

            stringSearch={ true }

            header={ [ 
                
                "name", 

                "products",

                "markup",
                
                "visible", 
                
                "published"
            
            ] }

            contents={ [

                { 
                    
                    type: "target_key_link_name", 
                
                    route: "/app/shop/manufacturers/edit/%PARAM%", 
                
                    link_param: "id",
                    
                    text_param: "name" 
                
                },

                { 
                    
                    type: "object_count", 

                    link: true,

                    route: "/app/shop/manufacturers/%PARAM%/products",

                    tab: true,
                
                    key: "products"
                
                },

                { 
                    
                    type: "keytag", 
                
                    key: "markup"
                
                },

                { 
                    
                    type: "boolean", 
                
                    key: "visible" 
                
                },

                { 
                    
                    type: "date", 
                
                    key: "createdAt" 
                
                }

            ] }

            actions={ [

                { 
                    
                    type: "edit", 
                
                    permalink: "/app/shop/manufacturers" 
                
                },

                { 
                    
                    type: "preview", 
                
                    query: MANUFACTURER_PREVIEW 
                
                }

            ] }
        
        />

    </section> );

}

/** @exports Manufacturers */
 
export default Manufacturers;