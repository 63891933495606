// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderCustomer_name__T4jLp, \n\n.OrderCustomer_phone__--h\\+y {\n\n    text-wrap: nowrap;\n\n}\n\n.OrderCustomer_name__T4jLp {\n\n    color: var( --invert-solid );\n\n}\n\n.OrderCustomer_phone__--h\\+y {\n\n    font-size: 0.75rem;\n\n}\n\n.OrderCustomer_container__Q6TiY > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Global/Table/Cells/OrderCustomer.module.css"],"names":[],"mappings":"AAAA;;;;IAII,iBAAiB;;AAErB;;AAEA;;IAEI,4BAA4B;;AAEhC;;AAEA;;IAEI,kBAAkB;;AAEtB;;AAEA;;IAEI,sBAAsB;;AAE1B","sourcesContent":[".name, \n\n.phone {\n\n    text-wrap: nowrap;\n\n}\n\n.name {\n\n    color: var( --invert-solid );\n\n}\n\n.phone {\n\n    font-size: 0.75rem;\n\n}\n\n.container > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "OrderCustomer_name__T4jLp",
	"phone": "OrderCustomer_phone__--h+y",
	"container": "OrderCustomer_container__Q6TiY"
};
export default ___CSS_LOADER_EXPORT___;
