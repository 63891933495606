/** @module         Hooks */

import { forwardRef, useId }    from "react";

const 

/**
 * 
 * @param { String } id 
 * 
 * @param { String } label
 * 
 * @param { Function } action
 * 
 * @param { Array } options
 * 
 * @param { String } value
 * 
 * @returns select input component
 * 
 */

Select = forwardRef ( ( {

    id,

    label = false,

    action = false,

    options = [],

    value = 0

}, ref ) => { const generateId = useId ();

    /** @returns */
        
    return ( <div className="input-group">

        { ! label === false && <label htmlFor={ ( ! id ) ? generateId : id }>

            { label }

        </label> }

        <select 
        
            id={ ( ! id ) ? generateId : id }

            name={ ( ! id ) ? generateId : id }

            onChange={ ( action === false ) ? e => e.target.value : e => action ( e.target.value ) }

            value={ value }

            ref={ ref }
            
        >

            { options.map ( ( option, index ) => <option key={ index } value={ option.value }>

                { option.name }

            </option> ) }

        </select>

    </div> );

} );

/** @exports Select */
 
export default Select;