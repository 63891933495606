/** @module                     React */

import { useState }             from "react";

/** @module                     React-Icons */

import { FaShoppingBasket }     from "react-icons/fa";

/** @module                     Components */

import { Fragment }             from "react";

import { Link }                 from "react-router-dom";

import ModalBox                 from "../../Modals/Overlay/ModalBox";


const 

/**
 * 
 * @param { Object } product
 * 
 * @returns table edit entry action
 * 
 */

PreviewProducts = ( { data } ) => { const 

    /** @event useState modal controller */

    [ modal, setModal ] = useState ( false );

    /** @returns */

    return ( 

        <Fragment>

            {

                modal && 

                <ModalBox
                
                    controller={ { modal, setModal } }

                    size="large"
                
                >

                    <table>

                        <thead>

                            <tr>

                                <th> { "Product" } </th>

                                <th> { "Quantity" } </th>

                                <th> { "Price" } </th>

                                <th> { "Total" } </th>

                            </tr>

                        </thead>

                        <tbody>

                            {

                                data.orderProductVendors.map ( 
                                    
                                    ( product, index ) => {

                                            return (

                                                <tr key={ index }>

                                                    <td>

                                                        <Link to={ `/app/products/edit/${ product.product.id }` }>
                                                        
                                                        { `${ product.product.manufacturer.name.toUpperCase () } ${ product.product.model }` } 

                                                        </Link>
                                                        
                                                    </td>

                                                    <td> { product.count } </td>

                                                    <td> { product.price } </td>

                                                    <td> { parseFloat ( product.price ).toFixed ( 2 ) * parseInt ( product.count ) } </td>

                                                </tr>

                                            );

                                    }

                                )

                            }
                        
                        </tbody>

                    </table>

                </ModalBox>

            }
    
            <button className="edit" onClick={ () => setModal ( ! modal ) }>
            
                <FaShoppingBasket />
            
            </button> 

        </Fragment>
    
    );

};

/** @exports PreviewProducts */
 
export default PreviewProducts;