import { gql } from '@apollo/client';

const 

/** @description create new service */

CREATE_SERVICE = gql`mutation CreateService (

    $title: String!

    $content: String!

    $image: Upload

    $isActive: Boolean!

    $tiremarketOnly: Boolean!

    $language: ServicesLanguagesEnum!

) {

    adminCreateService (

        input: {

            serviceInput: {

                title: $title,

                image: $image

                content: $content,

                isActive: $isActive,

                tiremarketOnly: $tiremarketOnly,

                language: $language

            }

        }

    ) {

        service {

            id

        }

    }

}`,

/** @description update existing service */

UPDATE_SERVICE = gql`mutation UpdateService (

    $id: ID!

    $title: String!

    $content: String!

    $image: Upload

    $isActive: Boolean!

    $tiremarketOnly: Boolean!

    $language: ServicesLanguagesEnum!

) {

    adminUpdateService (

        input: {

            id: $id,

            serviceInput: {

                title: $title,

                image: $image,

                content: $content,

                isActive: $isActive,

                tiremarketOnly: $tiremarketOnly,

                language: $language

            }

        }

    ) {

        service {

            id

            title

            image

            content

            isActive

            tiremarketOnly

            language

            createdAt

            updatedAt

        }

    }

}`,

/** @description delete existing service */

DELETE_SERVICE = gql`mutation DeleteService (

    $id: ID!

) {

    adminDeleteService (

        input: {

            id: $id

        }

    ) {

        service {

            id

        }

    }

}`;

/** @export mutations */

export { CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE }