const 

/**
 * 
 * @param { Array } details 
 * 
 * @returns attributes list for fieldset content
 * 
 */

Attributes = ( { details } ) => {

    /** @returns */

    return ( details.map ( 
        
        ( attribute, index ) => 
        
            <div className="attribute" key={ index }>

                <div className="name">

                    { attribute.name }

                </div>

                <div className="value">

                    { attribute.value }

                </div>

            </div> 
    
        ) 
    
    );
    
}

/** @exports Attributes */
 
export default Attributes;