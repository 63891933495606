// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__Sny2s {}\n\n.styles_header__mpuAX {\n    margin-bottom: 30px;\n}\n\n/* --- */\n\n.styles_column__DUX0d,\n.styles_addressColumn__umi1b {\n    display: grid;\n    grid-gap: 20px;\n    grid-template-columns: 1fr 1fr;\n}\n\n.styles_addressColumn__umi1b {\n    grid-template-columns: auto 100px;\n}\n\n/* --- */\n\n.styles_sameAddressButton__i8zRx {\n    display: grid;\n    justify-content: end;\n}\n", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-legal-address/styles.module.css"],"names":[],"mappings":"AAAA,qBAAO;;AAEP;IACI,mBAAmB;AACvB;;AAEA,QAAQ;;AAER;;IAEI,aAAa;IACb,cAAc;IACd,8BAA8B;AAClC;;AAEA;IACI,iCAAiC;AACrC;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,oBAAoB;AACxB","sourcesContent":[".form {}\n\n.header {\n    margin-bottom: 30px;\n}\n\n/* --- */\n\n.column,\n.addressColumn {\n    display: grid;\n    grid-gap: 20px;\n    grid-template-columns: 1fr 1fr;\n}\n\n.addressColumn {\n    grid-template-columns: auto 100px;\n}\n\n/* --- */\n\n.sameAddressButton {\n    display: grid;\n    justify-content: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__Sny2s",
	"header": "styles_header__mpuAX",
	"column": "styles_column__DUX0d",
	"addressColumn": "styles_addressColumn__umi1b",
	"sameAddressButton": "styles_sameAddressButton__i8zRx"
};
export default ___CSS_LOADER_EXPORT___;
