// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__pDsxJ { }\n\n/* --- */\n\n.styles_columns__sLVmT,\n.styles_columnsWithMargin__Q8se1 {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.styles_columnsWithMargin__Q8se1 {\n    margin-top: 30px;\n}\n\n.styles_columnDeleted__gVut5 {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n.styles_row__FvzqO {\n    margin-top: 30px;\n}\n\n.styles_column0__j-Nkc {}\n\n.styles_message__laBmt {\n    color: #428BCA;\n    text-align: end;\n}\n\n.styles_checkbox__PScLl {\n    margin-bottom: 20px;\n}\n\n.styles_additionalRow__\\+E0Cy {\n    display: grid;\n    grid-gap: 20px;\n}\n\n.styles_rowRemoveLine__BtKMK {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-branch-details/styles.module.css"],"names":[],"mappings":"AAAA,sBAAQ;;AAER,QAAQ;;AAER;;IAEI,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,wBAAU;;AAEV;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,cAAc;;IAEd,mBAAmB;AACvB","sourcesContent":[".form { }\n\n/* --- */\n\n.columns,\n.columnsWithMargin {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.columnsWithMargin {\n    margin-top: 30px;\n}\n\n.columnDeleted {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n.row {\n    margin-top: 30px;\n}\n\n.column0 {}\n\n.message {\n    color: #428BCA;\n    text-align: end;\n}\n\n.checkbox {\n    margin-bottom: 20px;\n}\n\n.additionalRow {\n    display: grid;\n    grid-gap: 20px;\n}\n\n.rowRemoveLine {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__pDsxJ",
	"columns": "styles_columns__sLVmT",
	"columnsWithMargin": "styles_columnsWithMargin__Q8se1",
	"columnDeleted": "styles_columnDeleted__gVut5",
	"row": "styles_row__FvzqO",
	"column0": "styles_column0__j-Nkc",
	"message": "styles_message__laBmt",
	"checkbox": "styles_checkbox__PScLl",
	"additionalRow": "styles_additionalRow__+E0Cy",
	"rowRemoveLine": "styles_rowRemoveLine__BtKMK"
};
export default ___CSS_LOADER_EXPORT___;
