import { CartContext } from "../../Contexts/CartContext";

import { useContext } from "react";

/**
 * 
 * @exports useCart
 * 
 * @description The useCart hook.
 * 
 * @example const { cart, addToCart, removeFromCart } = useCart ();
 * 
 */

export default function useCart () { const 

    context = useContext ( CartContext );
    
    /** @throws error */

    if ( ! context ) {

        throw Error ( "useCart must be used inside a CartContextProvider." );

    }

    /** @returns { object } context */

    return context;

};