/** @module                         Hooks */

import { usePagename }              from "../../Hooks/usePagename";

import { useTranslation }           from "react-i18next";

/** @module                         Queries */

import { TEAM }                     from "../../Queries/Contacts";

import { TEAM_PREVIEW }             from "../../Queries/Contacts";

/** @module                         React-Icons */

import { AiOutlinePlusCircle }      from "react-icons/ai";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import Tabs                         from "../../Components/Dashboard/Global/Tabs";

import FetchTable                   from "../../Components/Dashboard/Global/Table/FetchTable";

import PageActions                  from "../../Components/Dashboard/Global/PageActions";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns initial page component
 * 
 */

Team = ( { name } ) => { usePagename ( name ); const 
    
    { t } = useTranslation ();

    /** @return Contacts page */

    return ( 
    
        <section className="contacts">

            <Breadcrumbs />

            <Tabs data={ 
                
                [

                    { name: t ( "shop_contacts"     ), uri: "/app/contacts" },

                    { name: t ( "team"              ), uri: "/app/contacts/team" }

                ] 
            
            } />

            <PageActions actions={ 
                
                [

                    { 
                        
                        name: t ( "create_new" ), 
                        
                        uri: "/app/contacts/team/add", 
                        
                        icon: <AiOutlinePlusCircle /> 
                    
                    }

                ] 
            
            } />

            <FetchTable
            
                query={ TEAM }

                identifier="adminContacts"

                header={ [ 
                    
                    "firstName", 
                    
                    "lastName", 
                    
                    "title", 
                    
                    "phone",

                    "email"
                
                ] }

                contents={ [

                    { 
                        
                        type: "key_text", 
                        
                        key: "firstName"
                    
                    },

                    { 
                        
                        type: "key_text", 
                    
                        key: "lastName" 
                    
                    },

                    { 
                        
                        type: "keytag", 
                    
                        key: "title" 
                    
                    },

                    { 
                        
                        type: "key_text", 
                    
                        key: "phone" 
                    
                    },

                    { 
                        
                        type: "key_text", 
                    
                        key: "email" 
                    
                    },

                ] }

                actions={ [

                    { 
                        
                        type: "edit", 
                    
                        permalink: "/app/contacts/team" 
                    
                    },

                    { 
                        
                        type: "preview", 
                    
                        query: TEAM_PREVIEW 
                    
                    }

                ] }
            
            />

        </section>
    
    );

}

/** @exports Team */
 
export default Team;