import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Placeholder from "../../Components/Dashboard/Placeholder";

import Tabs from "../../Components/Dashboard/Global/Tabs";

const ManagerContacts = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Managers contacts page */

    return ( <section className="managers">

        <Breadcrumbs data={ [

            { name: "Managers", uri: "/app/managers/active" },

            { name: "Contacts", uri: "/app/managers/contacts" }

        ] } />

        <Tabs data={ [

            { name: "Active", uri: "/app/managers/active" },

            { name: "Blocked", uri: "/app/managers/blocked" },

            { name: "Contacts", uri: "/app/managers/contacts" }

        ] } />

        <Placeholder text="Placeholder for 'Manager Contacts' end-points." />

    </section> );

}

/** @exports ManagerContacts */
 
export default ManagerContacts;