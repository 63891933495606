import styles from "./productName.module.css";

import { Link } from "react-router-dom";

export default function ProductName ( { product } ) { const 

    productTypes = {

        tire: `${ product.width } x ${ product.height } x R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" }`,

        rim: `${ product.width }' | R${ parseInt ( product.diameter ) }`,

        battery: `${ product.voltage } V | ${ product.current } A | ${ product.capacity } Ah`,

    };

    return (

        <td>

            <Link to={ `/app/products/edit/${ product.id }` } className={ styles.container }>

                <div className={ styles.content }>

                    {

                        product.productType === "tire" &&

                        <div className={ styles.season }>

                            <span className={ styles.outline }>

                                { product.season }

                            </span>

                        </div>

                    }

                    <div className={ styles.productDetails }>
                
                        <div className={ styles.title }>
                            
                            { product.manufacturer.name.toUpperCase () }
                            
                        </div>

                        <div className={ styles.description }>
                            
                            { product.model }
                            
                        </div>

                        <div className={ styles.properties }>

                            { productTypes [ product.productType ] }

                        </div>

                    </div>

                </div>
            
            </Link>

        </td>

    );

};