import { gql } from '@apollo/client';

const 

/** @description create new post */

CREATE_PAGE = gql`mutation CreatePage (

    $language: FrontendPagesLanguagesEnum!

    $content: String!

    $pageType: FrontendPagesTypesEnum!

) {

    adminCreateFrontendPage (

        input: {

            pagesInput: {

                language: $language

                content: $content

                pageType: $pageType

            }

        }

    ) {

        page {

            id

        }

    }

}`,

/** @description update existing post */

UPDATE_PAGE = gql`mutation UpdatePage (

    $id: ID!

    $language: FrontendPagesLanguagesEnum!

    $content: String!

    $pageType: FrontendPagesTypesEnum!

) {

    adminUpdateFrontendPage (

        input: {

            id: $id

            pageInput: {

                language: $language

                content: $content

                pageType: $pageType

            }

        }

    ) {

        page {

            id

            content

            language

            pageType

        }

    }

}`;

/** @export mutations */

export { 
    
    CREATE_PAGE,

    UPDATE_PAGE

}