/** @import @module */

import i18n                     from "i18next";

import { initReactI18next }     from "react-i18next";

import LanguageDetector         from "i18next-browser-languagedetector";

import HttpApi                  from "i18next-http-backend";

/** @returns */

const Locales = () => {

    i18n.use ( initReactI18next ).use ( LanguageDetector ).use ( HttpApi ).init ( {

        supportedLngs : [ 'lv', 'en', 'ru' ],
    
        fallbackLng : "lv",
    
        detection : {
    
        order : [ 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain' ],
    
        caches : [ 'cookie' ]
    
        },
    
        backend : { loadPath : "/i18n/locales/{{lng}}/translation.json" },
    
        react : { useSuspense : false }
    
    } );

}

/** @exports Locales */

export default Locales;