import { gql } from '@apollo/client';

const 

/** @description update global markup */

UPDATE_GLOBAL_MARKUP = gql`mutation UpdateGlobalMarkup (

    $markupPercent: Float!

    $markupPercentGroupA: Float!

    $markupPercentGroupB: Float!

    $markupPercentGroupC: Float!

) {

    adminUpdateGlobalMarkup (

        input: {

            globalMarkupInput: {

                markupPercent: $markupPercent

                markupPercentGroupA: $markupPercentGroupA

                markupPercentGroupB: $markupPercentGroupB

                markupPercentGroupC: $markupPercentGroupC

            }

        }

    ) {

        globalMarkup {

            createdAt

            deleted

            id

            markupPercent

            updatedAt

        }

    }

}`;

/** @export mutations */

export { UPDATE_GLOBAL_MARKUP }