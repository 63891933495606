/** @module                         Components */

import { Link }                     from "react-router-dom";

const 

/**
 * 
 * @param { Object } manufacturer
 * 
 * @returns product table manufacturer component
 * 
 */

Manufacturer = ( { id, manufacturer } ) => {

    /** @returns */

    return ( 

        <td>

            <Link to={ `/app/products/edit/${ id }` }>

                { manufacturer.name.toUpperCase () }

            </Link>

        </td>

    );

}

/** @exports Manufacturer */
 
export default Manufacturer;