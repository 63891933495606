import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { parser, useMutation }      from "@apollo/client";

import { PagenameContext } from "../../../Contexts/Pagename";

import currencies from "../../../Lib/currencies.js";
import languages from "../../../Lib/languages.js";

import useFetch from "./../../../Hooks/useFetch.js";

import Breadcrumbs from "../../../Components/Dashboard/Breadcrumbs";
import Button from "../../../Components/Common/Button/index.js";

import FormClientEditGeneralInfo, {
    INITIAL_VALUES as GENERAL_INFO_INITIAL_VALUES,
    FIELDS as GENERAL_INFO_FIELDS,
} from "./../../../Forms/client-edit-general-info/index.js";
import FormClientEditCompanyInfo, {
    INITIAL_VALUES as COMPANY_INFO_INITIAL_VALUES,
    FIELDS as COMPANY_INFO_FIELDS,
} from "./../../../Forms/client-edit-company-info/index.js";
import FormClientEditContactInfo, {
    INITIAL_VALUES as CONTACT_INFO_INITIAL_VALUES,
    FIELDS as CONTACT_INFO_FIELDS,
} from "./../../../Forms/client-edit-contact-info/index.js";
import FormClientEditLegalAddress, {
    INITIAL_VALUES as LEGAL_ADDRESS_INITIAL_VALUES,
    FIELDS as LEGAL_ADDRESS_FIELDS,
} from "./../../../Forms/client-edit-legal-address/index.js";
import FormClientEditActualAddress, {
    INITIAL_VALUES as ACTUAL_ADDRESS_INITIAL_VALUES,
    FIELDS as ACTUAL_ADDRESS_FIELDS,
} from "./../../../Forms/client-edit-actual-address/index.js";
import FormClientEditCompanyContacts, {
    ROW as COMPANY_CONTACTS_ROW,
} from "./../../../Forms/client-edit-company-contacts/index.js";
import FormClientEditBranchDetails, {
    FIELDS as BRANCH_DETAILS_FIELDS,
    ROW as BRANCH_DETAILS_ROW,
} from "./../../../Forms/client-edit-branch-details/index.js";
import FormClientEditBankDetails, {
    FIELDS as BANK_DETAILS_FIELDS,
    ROW as BANK_DETAILS_ROW,
} from "./../../../Forms/client-edit-bank-details/index.js";
import FormClientEditAdditionalInfo, {
    FIELDS as ADDITIONAL_INFO_FIELDS,
    INITIAL_VALUES as ADDITIONAL_INFO_INITIAL_VALUES,
} from "./../../../Forms/client-edit-additional-info/index.js";
import FormClientEditCurrencySettings, {
    FIELDS as CURRENCY_SETTINGS_FIELDS,
} from "./../../../Forms/client-edit-currency-settings/index.js";

import QueriesAdminClients from "./../../../Queries/AdminClients.js";
import QueriesDiscountGroups from "./../../../Queries/DiscountGroups.js";
import QueriesPaymentOfGood from "./../../../Queries/PaymentOfGood.js";
import QueriesDeliveryPlans from "./../../../Queries/DeliveryPlans.js";
import QueriesBusinessSpheres from "./../../../Queries/BusinessSpheres.js";
import QueriesAdminManagers from "./../../../Queries/AdminManagers.js";
import QueriesSuppliers from "./../../../Queries/Suppliers.js";

import styles from "./styles.module.css";


const ClientsEdit = (props) => {
    const pageContext = useContext(PagenameContext);

    const generalInfoRef = useRef(null);
    const companyInfoRef = useRef(null);
    const contactInfoRef = useRef(null);
    const legalAddressRef = useRef(null);
    const actualAddressRef = useRef(null);
    const companyContactsRef = useRef(null);
    const branchDetailsRef = useRef(null);
    const bankDetailsRef = useRef(null);
    const additionalInfoRef = useRef(null);
    const currencySettingsRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    /* --- */

    const navigate = useNavigate();
    const urlParams = useParams();
    const clientId = parseInt(urlParams.clientId, 10);

    const { isPreview } = props;

    const isEditable = !isPreview;

    /* --- */

    const [updateClient] = useMutation(QueriesAdminClients.EDIT_CLIENT);

    const fetchClient = useFetch(QueriesAdminClients.FETCH_ADMIN_CLIENT, {
        id: clientId,
    });

    const fetchManagers = useFetch(QueriesAdminManagers.FETCH_ADMIN_MANAGERS, {});
    const fetchDiscountGroups = useFetch(QueriesDiscountGroups.FETCH_DISCOUNT_GROUPS);
    const fetchPaymentOfGood = useFetch(QueriesPaymentOfGood.FETCH_PAYMENT_OF_GOOD);
    const fetchDeliveryPlans = useFetch(QueriesDeliveryPlans.FETCH_DELIVERY_PLANS);
    const fetchBusinessSpheres = useFetch(QueriesBusinessSpheres.FETCH_BUSINESS_SPHERES);
    const fetchSuppliers = useFetch(QueriesSuppliers.SUPPLIERS);

    /* --- */

    const getClientData = () => {
        return fetchClient?.data?.adminClient || {};
    };

    const getOption = (opt) => ({
        name: opt?.id || null,
        label: opt?.value || opt?.fullName || "",
    });

    const getOptions = (data, fieldName) => {
        return (data?.[fieldName] || []).map((opt) => getOption(opt));
    };

    const getManagers = () => {
        const { loading, data } = fetchManagers;

        return {
            options: getOptions(data, "adminManagers"),
            isLoading: loading,
        };
    };

    const getDiscountGroups = () => {
        const { loading, data } = fetchDiscountGroups;

        return {
            options: getOptions(data, "discountGroups"),
            isLoading: loading,
        };
    };

    const getDeliveryPlan = () => {
        const { loading, data } = fetchDeliveryPlans;

        return {
            options: getOptions(data, "deliveryPlans"),
            isLoading: loading,
        };
    };

    const getBusinessSpheres = () => {
        const { loading, data } = fetchBusinessSpheres;

        return {
            options: getOptions(data, "businessSpheres"),
            isLoading: loading,
        };
    };

    const getDirectSuppliers = () => {
        const { loading, data } = fetchSuppliers;

        const options = (data?.edges || []).map((edge) => {
            const opt = edge?.node || {};

            return {
                name: opt?.id || null,
                label: opt?.name || "",
            };
        });

        return {
            options,
            isLoading: loading,
        };
    };

    const getPaymentOfGoods = () => {
        const { loading, data } = fetchPaymentOfGood;

        return {
            options: getOptions(data, "paymentOfGood"),
            isLoading: loading,
        };
    };

    const getAddresses = () => {
        const clientData = getClientData();
        const addresses = clientData?.addresses || [];

        let legalAddress = null;
        let actualAddress = null;

        for (let i = 0; i < addresses.length; i += 1) {
            const address = addresses[i];

            if (address.isLegal) {
                legalAddress = address;
            } else {
                actualAddress = address;
            }
        }

        return {
            legalAddress,
            actualAddress,
        };
    };

    /* --- */

    const navigateClients = () => {
        navigate("/app/clients");
    };

    /* --- */

    const getErrors = () => {
        const formsErrors = [
            generalInfoRef.current?.errors || {},
            companyInfoRef.current?.errors || {},
            contactInfoRef.current?.errors || {},
            legalAddressRef.current?.errors || {},
            actualAddressRef.current?.errors || {},
            additionalInfoRef.current?.errors || {},
            currencySettingsRef.current?.errors || {},
        ];

        const errors = [];

        for (let i = 0; i < formsErrors.length; i += 1) {
            const formErrors = formsErrors[i];

            for (const key in formErrors) {
                errors.push(formErrors[key]);
            }
        }

        return errors;
    };

    const getFilteredCompanyContacts = () => {
        const companyContacts = companyContactsRef.current.values.rows || [];
        const filtered = [];

        for (let i = 0; i < companyContacts.length; i += 1) {
            const contacts = companyContacts[i];

            const params = {
                firstName: contacts.companyContactsFirstName,
                lastName: contacts.companyContactsLastName,
                phone: contacts.companyContactsMobilePhone,
                email: contacts.companyContactsEmail,
                isMain: i === 0,
            }

            if (contacts.id) {
                params.id = contacts.id;
            }

            if (contacts.isDeleted) {
                if (!contacts.id) {
                    continue;
                }

                params._destroy = true;
            }

            filtered.push(params);
        }

        return filtered;
    };

    const getFilteredBranchDetails = () => {
        const branchDetails = branchDetailsRef.current.values.rows || [];
        const filtered = [];

        for (let i = 0; i < branchDetails.length; i += 1) {
            const branch = branchDetails[i];

            const params = {
                name: branch.branchDetailsBranchName,
                country: branch.branchDetailsCountry,
                deliveryPlanId: branch.branchDetailsDeliveryPlan,
                address: branch.branchDetailsAddress,
                comment: branch.branchDetailsCommentShippingAddress,
            }

            if (branch.id) {
                params.id = branch.id;
            }

            if (branch.isDeleted) {
                if (!branch.id) {
                    continue;
                }

                params._destroy = true;
            }

            filtered.push(params);
        }

        return filtered;
    };

    const getFilteredBankDetails = () => {
        const bankDetails = bankDetailsRef.current.values.rows || [];
        const filtered = [];

        for (let i = 0; i < bankDetails.length; i += 1) {
            const bank = bankDetails[i];

            const params = {
                name: bank.bankDetailsBankName,
                swift: bank.bankDetailsSwift,
                number: bank.bankDetailsAccountNumber,
                address: bank.bankDetailsBankAddress,
            }

            if (bank.id) {
                params.id = bank.id;
            }

            if (bank.isDeleted) {
                if (!bank.id) {
                    continue;
                }

                params._destroy = true;
            }

            filtered.push(params);
        }

        return filtered;
    };

    const getEditClientParams = () => {
        const generalInfo = generalInfoRef.current.values;
        const companyInfo = companyInfoRef.current.values;
        const contactInfo = contactInfoRef.current.values;
        const legalAddress = legalAddressRef.current.values;
        const actualAddress = actualAddressRef.current.values;
        const additionalInfo = additionalInfoRef.current.values;
        const currencySettings = currencySettingsRef.current.values;

        const params = {
            id: clientId,
            generalInfoCreditLimit: parseFloat(generalInfo.generalInfoCreditLimit || 0) || 0,
            generalInfoTermOfPayment: generalInfo.generalInfoTermOfPayment || "",
            generalInfoCreditLimitBalance: parseFloat(generalInfo.generalInfoCreditLimitBalance || 0) || 0,
            generalInfoUnpaidInvoices: parseInt(generalInfo.generalInfoUnpaidInvoices || 0) || 0,
            generalInfoDebt: parseFloat(generalInfo.generalInfoDebt || 0) || 0,
            generalInfoContractNumber: generalInfo.generalInfoContractNumber || "",
            generalInfoDateOfContract: generalInfo.generalInfoDateOfContract || "",
            generalInfoManager: parseInt(generalInfo.generalInfoManager || 0) || 0,
            generalInfoDiscountGroup: parseInt(generalInfo.generalInfoDiscountGroup || 0) || 0,
            generalInfoPaymentOfGoods: parseInt(generalInfo.generalInfoPaymentOfGoods || 0) || 0,
            generalInfoDeliveryPlan: parseInt(generalInfo.generalInfoDeliveryPlan || 0) || 0,
            companyInfoCompanyName: companyInfo.companyInfoCompanyName || "",
            companyInfoRegistrationName: companyInfo.companyInfoRegistrationName || "",
            companyInfoRegistrationNumber: companyInfo.companyInfoRegistrationNumber || "",
            companyInfoVATRegistrationNumber: companyInfo.companyInfoVATRegistrationNumber || "",
            companyInfoHomepage: companyInfo.companyInfoHomepage || "",
            contactInfoFirstName: contactInfo.contactInfoFirstName || "",
            contactInfoLastName: contactInfo.contactInfoLastName || "",
            contactInfoMobilePhone: contactInfo.contactInfoMobilePhone || "",
            contactInfoEmail: contactInfo.contactInfoEmail || "",
            contactInfoLandlinePhone: contactInfo.contactInfoLandlinePhone || "",
            contactInfoFax: contactInfo.contactInfoFax || "",
            contactInfoAccountingPhone: contactInfo.contactInfoAccountingPhone || "",
            contactInfoAccountingEmail: contactInfo.contactInfoAccountingEmail || "",
            legalAddressCountry: legalAddress.legalAddressCountry || "",
            legalAddressCity: legalAddress.legalAddressCity || "",
            legalAddressAddress: legalAddress.legalAddressAddress || "",
            legalAddressPostCode: legalAddress.legalAddressPostCode || "",
            actualAddressCountry: actualAddress.actualAddressCountry || "",
            actualAddressCity: actualAddress.actualAddressCity || "",
            actualAddressAddress: actualAddress.actualAddressAddress || "",
            actualAddressPostCode: actualAddress.actualAddressPostCode || "",
            actualAddressComment: actualAddress.actualAddressComment || "",
            additionalInfoLanguage: additionalInfo.additionalInfoLanguage || "",
            additionalInfoBusinessSphere: parseInt(additionalInfo.additionalInfoBusinessSphere || 0) || 0,
            additionalInfoComment: additionalInfo.additionalInfoComment || "",
            additionalInfoDirectSuppliers: [parseInt(additionalInfo.additionalInfoDirectSuppliers || 0)],
            additionalInfoLogoFile: additionalInfo.additionalInfoLogoFile || null,
            additionalInfoIsAPIAccess: additionalInfo.additionalInfoIsAPIAccess || false,
            additionalInfoAPIAccess: additionalInfo.additionalInfoAPIAccess || "",
            additionalInfoIsDiscounts: additionalInfo.additionalInfoIsDiscounts || false,
            additionalInfoIsNews: additionalInfo.additionalInfoIsNews || false,
            additionalInfoIsOnSale: additionalInfo.additionalInfoIsOnSale || false,
            additionalInfoIsTheNews: additionalInfo.additionalInfoIsTheNews || false,
            companyContacts: getFilteredCompanyContacts(),
            branchDetails: getFilteredBranchDetails(),
            bankDetails: getFilteredBankDetails(),
            currency: currencySettings.currency || "",
            showVAT: currencySettings.showVAT || false,
        };

        return params;
    };

    const validateAllForms = async () => {
        const forms = [
            generalInfoRef.current,
            companyInfoRef.current,
            contactInfoRef.current,
            legalAddressRef.current,
            actualAddressRef.current,
            additionalInfoRef.current,
            currencySettingsRef.current,
        ];

        for (let i = 0; i < forms.length; i += 1) {
            const form = forms[i];

            if (form?.validateForm) {
                await form.validateForm();
            }
        }
    };

    const onSave = async () => {
        setIsLoading(true);

        await validateAllForms();

        const formErrors = getErrors();

        if (formErrors.length) {
            setIsLoading(false);
            return;
        }

        const variables = getEditClientParams();

        let error = "";

        try {
            const res = await updateClient({ variables });

            const err = res?.errors?.[0]?.message || "";

            if (err) {
                error = err;
            }
        } catch (err) {
            error = err?.message || "";
        }

        if (error) {
            alert(`Failed to add client: ${error}`);
        }

        setIsLoading(false);
    };

    /* --- */

    useEffect(() => {
        if (props.name && pageContext.setPagename) {
            pageContext.setPagename(props.name);
        }
    }, []);

    /* --- */

    const renderControls = () => {
        let saveButton = null;

        if (!isPreview) {
            saveButton = (
                <Button
                    onClick={onSave}
                    isDisabled={isLoading}
                    isBlue
                >
                    {isLoading ? "Loading..." : "Save"}
                </Button>
            );
        }

        const errors = getErrors();

        let errorList = null;

        if (errors.length) {
            errorList = errors.map((err, i) => (
                <div
                    key={`err-${i}`}
                    className={styles.error}
                >
                    {err}
                </div>
            ));
        }

        return (
            <div>
                <div className={styles.controls}>
                    <Button
                        isGrey
                        isDisabled={isLoading}
                        onClick={navigateClients}
                    >
                        Back
                    </Button>
                    {saveButton}
                </div>
                <div className={styles.errorList}>
                    {errorList}
                </div>
            </div>
        );
    };

    /* --- */

    const renderFormCurrencySettings = () => {
        const clientData = getClientData();

        const initialValues = {
            currency: clientData?.currency || currencies.getDefaultCurrency(),
            showVAT: !!clientData?.showVAT,
        };

        return (
            <FormClientEditCurrencySettings
                formRef={currencySettingsRef}
                initialValues={initialValues}
                isEditable={isEditable}
            />
        );
    };

    const renderFormAdditionalInfo = () => {
        const clientData = getClientData();

        const initialValues = {
            ...ADDITIONAL_INFO_INITIAL_VALUES,
            additionalInfoLanguage: languages.getDefaultLanguage(),
            additionalInfoBusinessSphere: clientData?.businessSphereId || null,
            additionalInfoComment: clientData?.comment || "",
            // TODO: multi-select
            additionalInfoDirectSuppliers: clientData?.directSupplierIds?.[0] || null,
            additionalInfoLogoFile: clientData?.logo || null,
            additionalInfoIsAPIAccess: !!clientData?.apiAccess,
            additionalInfoAPIAccess: clientData?.apiAccessComment || "",
            additionalInfoIsDiscounts: !!clientData?.discountCommunication,
            additionalInfoIsNews: !!clientData?.newsCommunication,
            additionalInfoIsOnSale: !!clientData?.onSaleCommunication,
            additionalInfoIsTheNews: !!clientData?.theNewsCommunication,
        };

        const businessSpheres = getBusinessSpheres();
        const directSuppliers = getDirectSuppliers();

        return (
            <FormClientEditAdditionalInfo
                formRef={additionalInfoRef}
                businessSpheres={{
                    options: businessSpheres.options,
                    isLoading: businessSpheres.isLoading,
                }}
                directSuppliers={{
                    options: directSuppliers.options,
                    isLoading: directSuppliers.isLoading,
                }}
                initialValues={initialValues}
                isEditable={isEditable}
            />
        );
    };

    const renderFormBankDetails = () => {
        const clientData = getClientData();

        const initialRows = (clientData?.banks || []).map((bank) => ({
            ...BANK_DETAILS_ROW,
            id: bank?.id || null,
            bankDetailsAccountNumber: bank?.number || "",
            bankDetailsBankAddress: bank?.address || "",
            bankDetailsBankName: bank?.name || "",
            bankDetailsSwift: bank?.swift || "",
        }));

        return (
            <FormClientEditBankDetails
                initialRows={initialRows}
                formRef={bankDetailsRef}
                isEditable={isEditable}
            />
        );
    };

    const renderFormBranchDetails = () => {
        const clientData = getClientData();

        const initialRows = (clientData?.affiliates || []).map((branch) => ({
            ...BRANCH_DETAILS_ROW,
            id: branch?.id || null,
            branchDetailsAddress: branch?.address || "",
            branchDetailsBranchName: branch?.name || "",
            branchDetailsCommentShippingAddress: branch?.comment || "",
            branchDetailsCountry: branch?.country || "",
            branchDetailsDeliveryPlan: branch?.deliveryPlanId || "",
        }));

        const deliveryPlan = getDeliveryPlan();

        return (
            <FormClientEditBranchDetails
                initialRows={initialRows}
                formRef={branchDetailsRef}
                deliveryPlan={{
                    options: deliveryPlan.options,
                    isLoading: deliveryPlan.isLoading,
                }}
                isEditable={isEditable}
            />
        );
    };

    const renderFormCompanyContacts = () => {
        const clientData = getClientData();

        const initialRows = (clientData?.companyContacts || []).map((company) => ({
            id: company?.id || null,
            companyContactsBranchOffice: company?.id,
            companyContactsEmail: company?.email || "",
            companyContactsFirstName: company?.firstName || "",
            companyContactsLastName: company?.lastName || "",
            companyContactsMobilePhone: company?.phone || "",
        }));

        return (
            <FormClientEditCompanyContacts
                initialRows={initialRows}
                formRef={companyContactsRef}
                isEditable={isEditable}
            />
        );
    };

    const renderFormActualAddress = () => {
        const { actualAddress } = getAddresses();

        const initialValues = {
            ...ACTUAL_ADDRESS_INITIAL_VALUES,
            actualAddressAddress: actualAddress?.address || "",
            actualAddressCity: actualAddress?.city || "",
            actualAddressComment: actualAddress?.comment || "",
            actualAddressCountry: actualAddress?.country || "",
            actualAddressPostCode: actualAddress?.postalCode || "",
        };

        return (
            <FormClientEditActualAddress
                formRef={actualAddressRef}
                initialValues={initialValues}
                isEditable={isEditable}
            />
        );
    };

    const renderFormLegalAddress = () => {
        const { legalAddress } = getAddresses();

        const initialValues = {
            ...LEGAL_ADDRESS_INITIAL_VALUES,
            legalAddressCountry: legalAddress?.country || "",
            legalAddressCity: legalAddress?.city || "",
            legalAddressAddress: legalAddress?.address || "",
            legalAddressPostCode: legalAddress?.postalCode || "",
        };

        return (
            <FormClientEditLegalAddress
                formRef={legalAddressRef}
                initialValues={initialValues}
                onSameAsLegal={() => {
                    const { values } = legalAddressRef.current;

                    actualAddressRef.current.setValues({
                        [ACTUAL_ADDRESS_FIELDS.country]: values[LEGAL_ADDRESS_FIELDS.country],
                        [ACTUAL_ADDRESS_FIELDS.city]: values[LEGAL_ADDRESS_FIELDS.city],
                        [ACTUAL_ADDRESS_FIELDS.address]: values[LEGAL_ADDRESS_FIELDS.address],
                        [ACTUAL_ADDRESS_FIELDS.postCode]: values[LEGAL_ADDRESS_FIELDS.postCode],
                    });
                }}
                isEditable={isEditable}
            />
        );
    };

    const renderFormContactInfo = () => {
        const clientData = getClientData();

        const initialValues = {
            ...CONTACT_INFO_INITIAL_VALUES,
            contactInfoFirstName: clientData?.firstName || "",
            contactInfoLastName: clientData?.lastName || "",
            contactInfoMobilePhone: clientData?.mobilePhoneNr || "",
            contactInfoEmail: clientData?.email || "",
            contactInfoLandlinePhone: clientData?.landlinePhone || "",
            contactInfoFax: clientData?.faxNr || "",
            contactInfoAccountingPhone: clientData?.accountingPhone || "",
            contactInfoAccountingEmail: clientData?.accountingEmail || "",
        };

        return (
            <FormClientEditContactInfo
                formRef={contactInfoRef}
                initialValues={initialValues}
                isEditable={isEditable}
            />
        );
    };

    const renderFormCompanyInfo = () => {
        const clientData = getClientData();

        const initialValues = {
            ...COMPANY_INFO_INITIAL_VALUES,
            companyInfoCompanyName: clientData?.companyName || "",
            companyInfoRegistrationNumber: clientData?.regNr || "",
            companyInfoVATRegistrationNumber: clientData?.pvnRegNr || "",
            companyInfoHomepage: clientData?.homepage || "",
        };

        return (
            <FormClientEditCompanyInfo
                formRef={companyInfoRef}
                initialValues={initialValues}
                isEditable={isEditable}
            />
        );
    };

    const renderFormGeneralInfo = () => {
        const clientData = getClientData();

        const initialValues = {
            ...GENERAL_INFO_INITIAL_VALUES,
            generalInfoContractNumber: clientData?.contractNumber || "",
            generalInfoCreditLimit: clientData?.creditLimit || "",
            generalInfoCreditLimitBalance: clientData?.creditLimitBalance || "",
            generalInfoDateOfContract: clientData?.dateOfContract || "",
            generalInfoDebt: clientData?.debt || "",
            generalInfoDeliveryPlan: clientData?.deliveryPlanId || "",
            generalInfoDiscountGroup: clientData?.discountGroupId || "",
            generalInfoManager: clientData?.managerId || "",
            generalInfoPaymentOfGoods: clientData?.paymentOfGoodId || "",
            generalInfoTermOfPayment: clientData?.paymentTerm || "",
            generalInfoUnpaidInvoices: clientData?.unpaidInvoices || "",
        };

        const managers = getManagers();
        const discountGroups = getDiscountGroups();
        const deliveryPlan = getDeliveryPlan();
        const paymentOfGoods = getPaymentOfGoods();

        return (
            <FormClientEditGeneralInfo
                formRef={generalInfoRef}
                managers={{
                    options: managers.options,
                    isLoading: managers.isLoading,
                }}
                discountGroups={{
                    options: discountGroups.options,
                    isLoading: discountGroups.isLoading,
                }}
                deliveryPlan={{
                    options: deliveryPlan.options,
                    isLoading: deliveryPlan.isLoading,
                }}
                paymentOfGoods={{
                    options: paymentOfGoods.options,
                    isLoading: paymentOfGoods.isLoading,
                }}
                initialValues={initialValues}
                isEditable={isEditable}
            />
        );
    };

    /* --- */

    const renderContent = () => {
        if (fetchClient.loading) {
            return (
                <div>loading...</div>
            );
        }

        return (
            <>
                {renderFormGeneralInfo()}
                <div className={styles.formsColumn}>
                    {renderFormCompanyInfo()}
                    {renderFormContactInfo()}
                </div>
                <div className={styles.formsColumn}>
                    {renderFormLegalAddress()}
                    {renderFormActualAddress()}
                </div>
                {renderFormCompanyContacts()}
                {renderFormBranchDetails()}
                {renderFormBankDetails()}
                {renderFormAdditionalInfo()}
                {renderFormCurrencySettings()}

                {renderControls()}
            </>
        );
    };

    /* --- */

    return (
        <section className="clientsEdit">
            <Breadcrumbs />
            {renderContent()}
        </section>
    );
};

ClientsEdit.defaultProps = {
    name: "",
};

export default ClientsEdit;
