import { gql } from '@apollo/client';

const 

/** @description create new post */

CREATE_MEMBER = gql`mutation CreateMember (

    $firstName: String!

    $lastName: String!

    $image: Upload

    $title: String!

    $phone: String!

    $email: String!

) {

    adminCreateContact (

        input: {

            contactsInput: {

                firstName: $firstName

                lastName: $lastName

                image: $image

                title: $title

                phone: $phone

                email: $email

            }

        }

    ) {

        contact {

            id

        }

    }

}`,

/** @description update existing post */

UPDATE_MEMBER = gql`mutation UpdateMember (

    $id: ID!

    $firstName: String!

    $lastName: String!

    $image: Upload

    $title: String!

    $phone: String!

    $email: String!

) {

    adminUpdateContact (

        input: {

            id: $id

            contactsInput: {

                firstName: $firstName

                lastName: $lastName

                image: $image

                title: $title

                phone: $phone

                email: $email

            }

        }

    ) {

        contact {

            createdAt
      
            email
            
            firstName
            
            id
            
            image
            
            lastName
            
            phone
            
            title
            
            updatedAt

        }

    }

}`,

/** @description delete existing post */

DELETE_MEMBER = gql`mutation DeleteMember (

    $id: ID!

) {

    adminDeleteContact (

        input: {

            id: $id

        }

    ) {

        contact {

            id

        }

    }

}`,

/** @name UPDATE_SHOP_CONTACTS */

UPDATE_SHOP_CONTACTS = gql`mutation UpdateShopContacts (

    $id: ID!

    $mobilePhoneNr: String!

    $email: String!

    $serviceNumber: String

    $regNr: String!

    $address: String!

    $bank: String!

    $bankAccountNumber: String!

) {

    adminUpdateShopContact (

        input: {

            id: $id

            shopContactsInput: {

                mobilePhoneNr: $mobilePhoneNr

                email: $email

                serviceNumber: $serviceNumber

                regNr: $regNr

                address: $address

                bank: $bank

                bankAccountNumber: $bankAccountNumber

            }

        }

    ) {

        shopContact {

            id

        }

    }

}`;

/** @export mutations */

export { 
    
    CREATE_MEMBER,

    UPDATE_MEMBER,

    DELETE_MEMBER,

    UPDATE_SHOP_CONTACTS

}