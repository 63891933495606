import                          "../../../../../../Styles/Modals.css";

/** @module                     React-Icons */

import { AiFillEye }            from "react-icons/ai";

/** @import @module */

import { useState, Fragment } from "react";

/** @import @components */

import Layout from "../../Modals/Contents";

/**
 * 
 * @param { id }
 *  
 * @returns modal settings component trigger
 * 
 */

const 

Preview = ( { id, type } ) => { const

    [ state, setState ] = useState ( false ),

    /**
     * 
     * @event onClick
     * 
     * @description change modal state
     * 
     */

    stateController = () => ( state ) ? setState ( false ) : setState ( true );

    /** @returns */

    return ( <Fragment>

        { state && <Layout id="product-fields" state={ setState } atts={ { productId : id, type } } /> }
    
        <button className={ `modal-settings-button product-fields view` } onClick={ stateController }>

            <AiFillEye />

        </button> 
    
    </Fragment> );

};

/** @exports Preview */
 
export default Preview;