// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__Cu0au {\n    display: grid;\n    grid-gap: 20px;\n}\n\n.styles_containerReloading__GK1Va {\n    filter: brightness(90%) blur(1px);\n}\n\n/* --- */\n\n.styles_topControls__d6yCg {\n    display: grid;\n    grid-template-columns: max-content auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n}\n\n.styles_topControlsEntries__hm0BM {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 1rem;\n\n    align-items: center;\n}\n\n/* --- */\n\n.styles_bottomControls__sVyxx {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 20px;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n/* --- */\n\n.styles_searchForm__t5O2h {\n    max-width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./src/Business/table-clients/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,iCAAiC;AACrC;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,mDAAmD;IACnD,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,cAAc;;IAEd,mBAAmB;AACvB;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA,QAAQ;;AAER;IACI,gBAAgB;AACpB","sourcesContent":[".container {\n    display: grid;\n    grid-gap: 20px;\n}\n\n.containerReloading {\n    filter: brightness(90%) blur(1px);\n}\n\n/* --- */\n\n.topControls {\n    display: grid;\n    grid-template-columns: max-content auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n}\n\n.topControlsEntries {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 1rem;\n\n    align-items: center;\n}\n\n/* --- */\n\n.bottomControls {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 20px;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n/* --- */\n\n.searchForm {\n    max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__Cu0au",
	"containerReloading": "styles_containerReloading__GK1Va",
	"topControls": "styles_topControls__d6yCg",
	"topControlsEntries": "styles_topControlsEntries__hm0BM",
	"bottomControls": "styles_bottomControls__sVyxx",
	"searchForm": "styles_searchForm__t5O2h"
};
export default ___CSS_LOADER_EXPORT___;
