import styles from "../ProductPreview.module.css";

/**
 * 
 * @exports Item component layout.
 * 
 * @param { children, title }
 * 
 * @returns product preview item component layout.
 *
 */

export default function Item ( { children, title } ) {

    /**
     * 
     * @returns product preview item component layout.
     * 
     */

    return (

        <div className="item">

            <div className={ styles.itemTitle }>

                { title }

            </div>

            <div className={ styles.itemContent }>

                { children }

            </div>

        </div>

    );

};