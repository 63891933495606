/** @module         Assets */

import noImage      from "../../../../Assets/no-image.png";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table image component
 * 
 */

Image = ( { src, alt } ) => {

    /** @returns */

    return ( <td data-title="image">

        <div className="table-image">

            <img alt={ alt } src={ src } onError={ image => image.currentTarget.src = noImage } />

        </div>

    </td> );

}

/** @exports Image */
 
export default Image;