/** @import @module */

import { useTranslation } from "react-i18next";

/** @import @icons */

import { AiOutlineCloseCircle } from "react-icons/ai";

/**
 * 
 * @param { id } 
 * 
 * @returns global aside modal layout
 * 
 */

const Layout = ( { state, element, size } ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className={ `modal-settings-layout ${ size }` }>

        <div className="filler" onClick={ () => state ( false ) }></div>

        <div className="container">

            <div className="content">

                <div className="close">

                    <button onClick={ () => state ( false ) }>

                        <AiOutlineCloseCircle />

                        <span>{ t ( "close" ) }</span>

                    </button>
                    
                </div>

                { element }

            </div>

        </div>

    </div> );

}

/** @exports Layout */
 
export default Layout;