/** @import @module */

import { useTranslation } from "react-i18next";

/** @import @icons */

import { AiOutlineCloseCircle } from "react-icons/ai";

/** @module Components */

import TireFilters from "./Tires/Filters";

import TireSettings from "./Tires/Settings";

import ProductFields from "./Shared/ProductFields";

/**
 * 
 * @param { id } 
 * 
 * @returns global aside modal layout
 * 
 */

const Layout = ( { id, state = false, atts = {} } ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="modal-settings-layout">

        <div className="filler" onClick={ () => state ( false ) }></div>

        <div className="container">

            <div className="content">

                <div className="close">

                    <button onClick={ () => state ( false ) }>

                        <AiOutlineCloseCircle />

                        <span>{ t ( "close" ) }</span>

                    </button>
                    
                </div>

                <Settings id={ id } atts={ atts } />

            </div>

        </div>

    </div> );

}

/**
 * 
 * @param { id }
 *  
 * @returns correct settings layout based on layout ID
 * 
 */

const Settings = ( { id, atts } ) => {

    switch ( id ) {

        case "tire-filters" :

            return <TireFilters />

        case "tire-settings" :

            return <TireSettings />

        case "product-fields" :

            return <ProductFields atts={ atts } />

        case "catalog-settings" :

            return <div className="modal-settings-container">Catalog Settings</div>

        default :

            return <div className="modal-settings-container">
                
                Invalid ID provided. No corresponding settings found.
            
            </div>

    }

}

/** @exports Layout */
 
export default Layout;