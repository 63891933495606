const CURRENCY = {
    eur: "eur",
};

const CURRENCY_LABELS = {
    [CURRENCY.eur]: "EUR",
};

/* --- */

const getDefaultCurrency = () => {
    return CURRENCY.eur;
};

const getCurrencyOptions = () => {
    const keys = Object.keys(CURRENCY);

    return keys.map((curr) => ({
        name: curr,
        label: CURRENCY_LABELS[curr],
    }));
};

/* --- */

export default {
    getDefaultCurrency,
    getCurrencyOptions,
};
