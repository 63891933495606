/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import { useParams }                from "react-router-dom";

/** @module                         Queries */

import { ORDERS }                   from "../../Queries/Orders";

import { ORDER_PREVIEW }            from "../../Queries/Orders";


/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import FetchTable                   from "../../Components/Dashboard/Global/Table/FetchTable";

/**
 * 
 * @param { String } name 
 * 
 * @returns initial page component
 * 
 */

const Orders = ( { name } ) => { const { t : __ } = useTranslation (),

    /** @event useParams */

    { orderFilter = false } = useParams ();

    /** @event usePagename */

    usePagename ( ( orderFilter ) ? `${ orderFilter }_orders` : name );

    /** @return Manufacturers page */

    return ( <section className="manufacturers">

        <Breadcrumbs />

        <FetchTable

            before_table={ { component: require ( "../../Components/Dashboard/Orders/BeforeContent/BeforeOrders" ).default } }
            
            query={ ORDERS }

            query_atts={ { 
                
                currentManagerOrder: ( orderFilter === "managers" ) ? true : undefined,
                
                onlyStorefrontOrders: ( orderFilter === "storefront" ) ? true : undefined,

                orderType: [ "manager_order", "user_b2c_order", "user_b2b_order" ]
            
            } }

            sort={ true }

            stringSearch={ true }

            sort_values={ [ "createdAt" ] }

            identifier="orders"

            filter={ true }

            filtersElement={ require ( "../../Components/Dashboard/Orders/Filters/Filters" ).default }

            header={ [ 
                
                "id",

                "status",

                "customer",

                "createdAt",

                "orderPayment",

                "assembly",

                "deliveryMethod",

                "orderProducts",

                "adminNotes",

                "vendors"
            
            ] }

            contents={ [

                { 
                    
                    type: "target_key_link", 
                
                    route: "/app/orders/edit/%PARAM%", 
                
                    param: "id" 
                
                },

                { 
                    
                    type: "status", 
                
                    key: "status"
                
                },

                { 
                    
                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/OrderCustomer" ).default,
                
                },

                { 
                    
                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/OrderCreatedAt" ).default,
                
                },

                { 
                    
                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/OrderPayment" ).default,
                
                },

                {

                    type: "preset",

                    preset: "assembly"

                },

                { 
                    
                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/DeliveryMethod" ).default,
                
                },

                { 
                    
                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/OrderProducts" ).default,
                
                },

                {

                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/OrderAdminNotes" ).default,

                },

                {

                    type: "component",

                    component: require ( "../../Components/Dashboard/Global/Table/Cells/OrderVendors" ).default,

                },

            ] }

            actions={ [

                {

                    type: "custom",

                    component: require ( "../../Components/Dashboard/Global/Table/Actions/PreviewProducts" ).default

                },

                { 
                    
                    type: "edit", 
                
                    permalink: "/app/orders" 
                
                },

                { 
                    
                    type: "preview", 
                
                    query: ORDER_PREVIEW 
                
                }

            ] }
        
        />

    </section> );

}

/** @exports Orders */
 
export default Orders;