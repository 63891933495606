import { useTranslation } from "react-i18next";

import { gql, useQuery, useMutation } from "@apollo/client";

import { pdf } from "@react-pdf/renderer";

import { SEND_EMAIL } from "../../../../../Mutations/Orders";

import { SHOP_CONTACTS } from "../../../../../Queries/Contacts";

import { MANAGER } from "../../../../../Queries/Managers";

import Fieldset from "../../../Global/Forms/Fieldset";

import CenterLoader from "../../../Loaders/CenterLoader";

import DocumentInvoice from "../../Documents/Invoice";

export default function PDFGeneratorWidget ( { state, order } ) { const 

    { t : __ } = useTranslation (),

    { data, loading } = useQuery ( SHOP_CONTACTS ),

    { data : contactsInfo, loading : loadingContactsInfo } = useQuery ( gql`query { contactInfo }` ),

    { data: manager, loading: loadingManager } = useQuery ( MANAGER, { variables: { id: order?.userId }, fetchPolicy: "network-only" } ),

    [ sendEmail, { loading : loadingMailer } ] = useMutation ( SEND_EMAIL ),

    /**
     * 
     * @param { Object } e
     * 
     * @param { Object } documentData
     * 
     * @description generate pdf document
     * 
     * @returns pdf document
     * 
     */

    generatePDF = async ( e, documentData ) => { e.preventDefault (); const

        blob = await pdf ( documentData ).toBlob (),

        url = window.URL.createObjectURL ( blob );

        window.open ( url, '_blank' );

    },

    sendDocument = async ( e, documentData ) => { e.preventDefault ();

        const blob = await pdf ( documentData ).toBlob ();

        sendEmail ( { variables: { orderId: parseInt ( order.id ), orderType: order.orderType, pdf: new File ( [ blob ], "invoice.pdf" ) } } );

    };

    /** @returns { JSX.Element } PDFGeneratorWidget */

    return (

        <Fieldset legend="PDF">

            {

                ( loading || loadingContactsInfo || loadingManager ) ? <CenterLoader /> :

                <div className="document_generation_actions">

                    <button className="block width_full"
                    
                        onClick={ e => generatePDF ( e, <DocumentInvoice type="invoice" state={ state } order={ order } contacts={ data } hours={ contactsInfo?.contactInfo?.working_hours || {} } manager={ manager } /> ) }>

                            { __ ( "download_invoice" ) }

                    </button>

                    <button className="block width_full"
                    
                        onClick={ e => generatePDF ( e, <DocumentInvoice type="receipt" state={ state } order={ order } contacts={ data } hours={ contactsInfo?.contactInfo?.working_hours || {} } manager={ manager } /> ) }>

                            { __ ( "download_receipt" ) }

                    </button>

                    <button className="block width_full"
                    
                        onClick={ e => generatePDF ( e, <DocumentInvoice type="offer" state={ state } order={ order } contacts={ data } hours={ contactsInfo?.contactInfo?.working_hours || {} } manager={ manager } /> ) }>

                            { __ ( "download_offer" ) }

                    </button>

                </div>

            }

        </Fieldset>

    );

};