/** @import @module */

import {    useContext, 
    
            useEffect       } from "react";

import { useTranslation     } from "react-i18next";

/** @import @context */

import { PagenameContext    } from "../../Contexts/Pagename";

/** @import @component */

import Breadcrumbs          from "../../Components/Dashboard/Breadcrumbs";

import Placeholder          from "../../Components/Dashboard/Placeholder";

import SettingsModal        from "../../Components/Dashboard/Global/Modals/Aside/Trigger";

import Tabs                 from "../../Components/Dashboard/Global/Tabs";

/**
 * 
 * @param { name } 
 * 
 * @returns product collection page component
 * 
 */

const Collection = ( { name } ) => { const 
    
    { t } = useTranslation (),

    { setPagename } = useContext ( PagenameContext );

    /** @event */

    useEffect ( () => setPagename ( name ), [] )

    /** @return */

    return ( <section className="collection">

        <Breadcrumbs data={ [

            { name: t ( "products" ), uri: "/app/products/collection" },

            { name: "Collection", uri: "/app/products/collection" }

        ] } />

        <Tabs data={ [

            { name: t ( "tires" ), uri: "/app/products/tires" },

            { name: t ( "rims" ), uri: "/app/products/rims" },

            { name: t ( "batteries" ), uri: "/app/products/batteries" }

        ] } />

        <Placeholder text="Placeholder for 'Products > Collection' end-points." />

        <SettingsModal id="products" />

    </section> );

}

/** @exports Collection */
 
export default Collection;