const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table tag component
 * 
 */

Tag = ( { text, style = "default" } ) => {

    /** @returns */

    return ( <td data-title="text" className={ `tag ${ style }` }>

        <span>
            
            { text }
            
        </span>

    </td> );

}

/** @exports Tag */
 
export default Tag;