/** @module                 React */

import { useState }         from "react";

import useAuthorization     from "./useAuthorization";

/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { AUTHENTICATION }   from "../../Mutations/Authentication";

const 

/** 
 * 
 * @returns useLogin hook for logging-in users
 * 
 */

useLogin = () => { const 

    /** @hooks */

    navigate = useNavigate (),

    [ loading,  setLoading  ] = useState ( false ),

    [ errors,   setErrors   ] = useState ( false ),

    { dispatch } = useAuthorization (),

    /** @mutations */

    [ SignIn ] = useMutation ( AUTHENTICATION, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            if ( ! data.adminSignIn.userSession[ 0 ].authenticated ) {

                setErrors ( true );

                return dispatch ( { type: "LOGOUT", state: false } );

            }

            dispatch ( { 
                
                type    : "LOGIN", 
            
                payload : data.adminSignIn.userSession[ 0 ].currentUser,

                state   : false
            
            } );

            return navigate ( "/app/dashboard" );

        },

        /** @event onError */

        onError () { setLoading ( false ); dispatch ( { type: "LOGOUT", state: false } ); }

    } ),

    /** 
     * 
     * @param { String } username
     * 
     * @param { String } password
     * 
     * @returns login action
     * 
     */

    login = ( username, password ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true );

        if ( ! username || ! password ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        /** @mutation */

        SignIn ( { variables: {

            email       : username,
    
            password    : password,
    
        } } );

    }

    /** @returns */

    return { login, loading, errors }

};

/** @exports useLogin */

export default useLogin;