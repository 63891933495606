// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_iconContainerClickable__pRmjx {\n    display: grid;\n    place-content: center;\n\n    width: 24px;\n    height: 24px;\n\n    border-radius: 50%;\n}\n\n.styles_iconContainerClickable__pRmjx:hover {\n    background-color: var(--secondary-color);\n}\n\n.styles_icon__Zy\\+uZ {\n    width: 20px;\n    height: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/Button/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;;IAErB,WAAW;IACX,YAAY;;IAEZ,kBAAkB;AACtB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".iconContainerClickable {\n    display: grid;\n    place-content: center;\n\n    width: 24px;\n    height: 24px;\n\n    border-radius: 50%;\n}\n\n.iconContainerClickable:hover {\n    background-color: var(--secondary-color);\n}\n\n.icon {\n    width: 20px;\n    height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainerClickable": "styles_iconContainerClickable__pRmjx",
	"icon": "styles_icon__Zy+uZ"
};
export default ___CSS_LOADER_EXPORT___;
