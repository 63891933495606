/**
 * 
 * @param { Number } id 
 * 
 * @returns manufacturer route permalink
 * 
 */

export const manufacturerPermalink = ( id = 0 ) => `/app/products/manufacturer/${ id }`;

/**
 * 
 * @param { Number } id 
 * 
 * @returns vendor route permalink
 * 
 */

export const vendorPermalink = ( id = 0 ) => `/app/products/vendor/${ id }`;

/**
 * 
 * @param { Number } id 
 * 
 * @param { String } type
 * 
 * @returns single product route permalink
 * 
 */

export const singleProductPermalink = ( id = 0, type = "tire" ) => `/app/products/edit/${ type }/${ id }`;

/**
 * 
 * @param { Number } id 
 * 
 * @param { String } type
 * 
 * @returns add new product route permalink
 * 
 */

export const addProductPermalink = ( id = 0, type = "tire" ) => `/app/products/add/${ type }`;