import styles from './ConfirmationModal.module.css';

import { useTranslation } from 'react-i18next';

import { useState, Fragment } from 'react';

/**
 * 
 * @exports ConfimationModal
 * 
 * @description Confirmation modal component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { JSX.Element } props.children - The children element.
 * 
 * @param { String } props.description - The description string.
 * 
 * @param { Boolean } props.loading - The loading state.
 * 
 * @param { Function } props.action - The action function.
 * 
 * @example <ConfimationModal children={ children } description={ description } loading={ loading } action={ action }> { children } </ConfimationModal>
 * 
 * @returns { JSX.Element } ConfimationModal
 * 
 */

export default function ConfimationModal ( { children, description = "Description", loading = false, action = () => {} } ) { 
    
    const 

    { t : __ } = useTranslation (),

    [ open, setOpen ] = useState ( false ),

    /**
     * 
     * @returns { void }
     * 
     * @description Toggle the modal.
     * 
     * @param { Object } e - The event object.
     * 
     */

    modal = e => {

        e.preventDefault ();

        setOpen ( ! open );

    };

    /**
     * 
     * @description
     * 
     * Render the confirmation modal component.
     * 
     * @returns { JSX.Element } ConfirmationModal
     * 
     */

    return (

        <Fragment>

            { open && 

                <div className={ styles.container }>

                    <div className={ styles.modal }>

                        <div className={ styles.description }>

                            { description }

                        </div>

                        <div className={ styles.actions }>

                            <button onClick={ modal } disabled={ loading }>

                                { __ ( "cancel" ) }

                            </button>

                            <button onClick={ action } disabled={ loading } className={ styles.confirm }>

                                { __ ( "submit" ) }

                            </button>

                        </div>

                    </div>

                </div>

            }

            <span onClick={ modal }>

                { children }

            </span>

        </Fragment>

    );

};