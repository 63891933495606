/** @import modules */

import { useState } from "react"

/**
 * 
 * @returns AP Pagename switch
 * 
 */

const Pagename = () => { const 

    /** @register theme value */

    [ pagename, setPagename ] = useState ( "Loading..." );

    /** @return hook */

    return { pagename, setPagename }

}

/** @export Theme */

export default Pagename;