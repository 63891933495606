/** @module                     Hooks */

import { usePagename }          from "../../Hooks/usePagename";

import { useTranslation }       from "react-i18next";

import { 
    
    useEffect, 
    
    useRef, 
    
    useState }                  from "react";

import useCreateAppointment     from "../../Hooks/Montage/useCreateAppointment";

/** @module                     Components */

import Breadcrumbs              from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import ComponentLoader          from "../../Components/Dashboard/Loaders/ComponentLoader";

import { 
    
    Input, 
    
    LabeledInput }              from "../../Components/Dashboard/Global/Forms/Inputs";

import Fieldset                 from "../../Components/Dashboard/Global/Forms/Fieldset";

import Sidebar                  from "../../Components/Dashboard/Montage/Add/Sidebar";

import Notice                   from "../../Components/Dashboard/Global/Notice";

/** @module                     Utilities */

import { disableAttribute }     from "../../Components/Dashboard/Utils/disableAttribute";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns add appointment page component
 * 
 */

AddAppointment = ( { name } ) => { usePagename ( name ); const 

    /** @module         Hooks */

    { t }               = useTranslation ();

    /** @returns */

    return ( <section className="mutations add appointment">

        <Breadcrumbs data={ [

            { 
                
                name    : t ( "montage" ), 
                
                uri     : "/app/montage"       
            
            },

            { 
                
                name    : t ( name ), 
                
                uri     : `/app/montage/add`    
            
            }

        ] } />

        <Component __={ t } />

    </section> );

},

/**
 * 
 * 
 * @param { Function } __
 * 
 * @returns add appointment page content component
 * 
 */

Component = ( { data, __ } ) => { const

    /** @module                 States */

    [ autoNumber,               setAutoNumber           ] = useState ( "" ),

    [ autoBrand,                setAutoBrand            ] = useState ( "" ),

    [ autoModel,                setAutoModel            ] = useState ( "" ),

    [ fullName,                 setFullName             ] = useState ( "" ),

    [ phoneNumber,              setPhoneNumber          ] = useState ( "" ),

    [ diskDiameter,             setDiskDiameter         ] = useState ( "" ),

    [ email,                    setEmail                ] = useState ( "" ),

    [ notes,                    setNotes                ] = useState ( "" ),

    [ transportType,            setTransportType        ] = useState ( "car" ),

    [ tiresStoredNotes,         setTiresStoredNotes     ] = useState ( "" ),

    [ tiresBoughtNotes,         setTiresBoughtNotes     ] = useState ( "" ),

    [ tiresStored,              setTiresStored          ] = useState ( false ),

    [ tiresBought,              setTiresBought          ] = useState ( false ),

    [ startTime,                setStartTime            ] = useState ( new Date () ),

    [ endTime,                  setEndTime              ] = useState ( new Date () ),

    [ year,                     setYear                 ] = useState ( new Date ().getFullYear () ),

    [ month,                    setMonth                ] = useState ( new Date ().getMonth () + 1 ),

    [ day,                      setDay                  ] = useState ( new Date ().getDate () ),

    [ time,                     setTime                 ] = useState ( "" ),

    /** @module                 References */

    autoNumberRef               = useRef ( null ),

    autoBrandRef                = useRef ( null ),

    autoModelRef                = useRef ( null ),

    fullNameRef                 = useRef ( null ),

    phoneNumberRef              = useRef ( null ),

    diskDiameterRef             = useRef ( null ),

    emailRef                    = useRef ( null ),

    notesRef                    = useRef ( null ),

    transportTypeRef            = useRef ( null ),

    tiresStoredNotesRef         = useRef ( null ),

    tiresBoughtNotesRef         = useRef ( null ),

    tiresStoredRef              = useRef ( null ),

    tiresBoughtRef              = useRef ( null ),

    startTimeRef                = useRef ( null ),

    endTimeRef                  = useRef ( null ),

    submitRef                   = useRef ( null ),

    yearRef                     = useRef ( null ),

    monthRef                    = useRef ( null ),

    dayRef                      = useRef ( null ),

    timeRef                     = useRef ( null ),

    /** @module                 Hooks */

    { 
        
        createAppointment, 
        
        loading, 
        
        errors 
    
    } = useCreateAppointment (),

    /** @event onSubmit */

    submitAppointment = () => createAppointment (

        autoNumber,

        autoBrand,

        autoModel,

        fullName,

        phoneNumber,

        diskDiameter,

        email,

        notes,

        transportType,

        tiresStoredNotes,

        tiresBoughtNotes,

        tiresStored,

        tiresBought,

        startTime,

        endTime

    );

    /** @event useEffect */

    useEffect ( () => {

        if ( loading ) {

            disableAttribute ( [ submitRef ], "add" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = `${ __ ( "submitting" ) }...`;

            }

        } else { 

            disableAttribute ( [ submitRef ], "remove" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = __ ( "submit" );

            }
    
        }

    }, [ loading ] );
    
    /** @returns */

    return ( 
    
        <AsideEdit aside={ 
        
            <Sidebar 
                
                details={ 
                    
                    {

                        time : { 
                            
                            view        : time, 
                            
                            set         : setTime, 
                            
                            reference   : timeRef 
                        
                        },

                        year : { 
                            
                            view        : year, 
                            
                            set         : setYear, 
                            
                            reference   : yearRef 
                        
                        },

                        month : { 
                            
                            view        : month, 
                            
                            set         : setMonth, 
                            
                            reference   : monthRef 
                        
                        },

                        day : { 
                            
                            view        : day, 
                            
                            set         : setDay, 
                            
                            reference   : dayRef 
                        
                        },

                        transportType : { 
                            
                            view        : transportType, 
                            
                            set         : setTransportType, 
                            
                            reference   : transportTypeRef 
                        
                        },

                        tiresStored : { 
                            
                            view        : tiresStored, 
                            
                            set         : setTiresStored, 
                            
                            reference   : tiresStoredRef 
                        
                        },

                        tiresBought : { 
                            
                            view        : tiresBought, 
                            
                            set         : setTiresBought, 
                            
                            reference   : tiresBoughtRef 
                        
                        },

                        startTime : { 
                            
                            view        : startTime, 
                            
                            set         : setStartTime, 
                            
                            reference   : startTimeRef 
                        
                        },

                        endTime : { 
                            
                            view        : endTime, 
                            
                            set         : setEndTime, 
                            
                            reference   : endTimeRef 
                        
                        },

                        submit : { 
                            
                            reference   : submitRef, 
                            
                            action      : submitAppointment 
                        
                        }
            
                    } 
            
                } 
            
            /> 
            
        }>

            { errors && <Notice type="danger">

                { __ ( "error_ocurred" ) }

            </Notice> }

            <Fieldset legend={ __ ( "vehicle_information" ) }>

                <LabeledInput

                    label={ __ ( "autoNumber" ) }
                    
                    value={ autoNumber }
        
                    action={ e => setAutoNumber ( e ) }
        
                    ref={ autoNumberRef }
                
                />

                <LabeledInput

                    label={ __ ( "autoBrand" ) }
                    
                    value={ autoBrand }
        
                    action={ e => setAutoBrand ( e ) }
        
                    ref={ autoBrandRef }
                
                />

                <LabeledInput

                    label={ __ ( "autoModel" ) }
                    
                    value={ autoModel }
        
                    action={ e => setAutoModel ( e ) }
        
                    ref={ autoModelRef }
                
                />

                <LabeledInput

                    ladel={ __ ( "diskDiameter" ) }
                    
                    value={ diskDiameter }
        
                    action={ e => setDiskDiameter ( e ) }
        
                    ref={ diskDiameterRef }
                
                />

            </Fieldset>

            <Fieldset legend={ __ ( "customer_information" ) }>

                <LabeledInput

                    label={ __ ( "fullName" ) }
                    
                    value={ fullName }
        
                    action={ e => setFullName ( e ) }
        
                    ref={ fullNameRef }
                
                />

                <LabeledInput

                    label={ __ ( "email" ) }
                    
                    value={ email }
        
                    action={ e => setEmail ( e ) }
        
                    ref={ emailRef }
                
                />

                <LabeledInput

                    label={ __ ( "phoneNumber" ) }

                    value={ phoneNumber }

                    action={ e => setPhoneNumber ( e ) }

                    ref={ phoneNumberRef }

                />

            </Fieldset>

            <Fieldset legend={ __ ( "notes" ) }>

                <LabeledInput

                    label={ __ ( "notes" ) }
                    
                    value={ notes }
        
                    action={ e => setNotes ( e ) }
        
                    ref={ notesRef }
                
                />

                <LabeledInput

                    label={ __ ( "tiresStoredNotes" ) }
                    
                    value={ tiresStoredNotes }
        
                    action={ e => setTiresStoredNotes ( e ) }
        
                    ref={ tiresStoredNotesRef }
                
                />

                <LabeledInput

                    label={ __ ( "tiresBoughtNotes" ) }

                    value={ tiresBoughtNotes }

                    action={ e => setTiresBoughtNotes ( e ) }

                    ref={ tiresBoughtNotesRef }

                />

            </Fieldset>
            
        </AsideEdit>  
        
    )

}
 
/** @exports AddAppointment */

export default AddAppointment;