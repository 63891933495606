const 

/**
 * 
 * @param { Object } vendor
 * 
 * @returns product table vendors component (price & quantities) 
 * 
 */

ProductVendors = ( { vendor } ) => { const 

    keys = [ 
        
        "qty",

        "qtyHour",

        "qtyDays",

        "qtyWeeks"
    
    ];

    /** @returns */

    return ( 
        
        keys.map ( ( attr, index ) => {

            return (

                <td key={ index }>

                    {

                        ( attr === "price" ) ?

                            <span className="price">

                                &euro; { parseFloat ( vendor[ 0 ] [ attr ] ).toFixed ( 2 ) }

                            </span>

                        : 

                            <span className={ `quantity ${ colorAssignment ( parseInt ( vendor[ 0 ] [ attr ] ) ) }` }>

                                { ( vendor[ 0 ] [ attr ] === 0 || vendor[ 0 ] [ attr ] === null ) ? "-" : vendor[ 0 ] [ attr ] }

                            </span>

                    }

                </td>

            );

        } ) 

    );

},

/**
 * 
 * @param { Number } qty
 * 
 * @returns color assignment
 * 
 */

colorAssignment = ( qty = 0 ) => { 

    /** @returns color warning if quantity is above 0 and below 3 */

    if ( qty <= 3 && qty > 0 ) return "warning"; 

    /** @returns color sufficent if quantity is above 3 */
        
    else if ( qty > 3 ) return "sufficent"; 

    /** @returns color default if quantity is 0 or null */
    
    else return "default";

}

/** @exports ProductVendors */
 
export default ProductVendors;