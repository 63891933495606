/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name TABLE_SORT_PARAMS */

TABLE_SORT_PARAMS = gql`query introspectTaskStateEnumType {

    __type ( name: "AdminTableColumnSortParamsEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    TABLE_SORT_PARAMS

}