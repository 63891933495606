import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Industries = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Industries page */

    return ( <section className="industries">

        Industries page

    </section> );

}

/** @exports Industries */
 
export default Industries;