import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Placeholder from "../../Components/Dashboard/Placeholder";

const Sale = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Sale page */

    return ( <section className="sale">

        <Breadcrumbs data={ [

            { name: "Products", uri: "/app/products/sale" },

            { name: "Sale", uri: "/app/products/sale" }

        ] } />

        <Placeholder text="Placeholder for 'Products > Sale' end-points." />

    </section> );

}

/** @exports Sale */
 
export default Sale;