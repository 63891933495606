import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import styles from "./styles.module.css";


const ButtonUnderline = (props) => {
    const renderIconOrCaret = () => {
        if (props.icon) {
            return (
                <props.icon
                    className={styles.icon}
                />
            );
        }

        const caretClassName = [styles.icon];

        if (props.isClicked) {
            caretClassName.push(styles.caretUp);
        }

        return (
            <MdKeyboardArrowDown
                className={caretClassName.join(" ")}
            />
        );
    };

    /* --- */

    let iconLeft = null;
    let iconRight = null;

    if (props.isClickable) {
        const icon = renderIconOrCaret();

        if (props.isRight) {
            iconRight = icon;
        } else {
            iconLeft = icon;
        }
    }

    const containerClassName = [styles.container];

    if (props.isRight) {
        containerClassName.push(styles.containerRight);
    }

    if (props.isClickable) {
        containerClassName.push(styles.containerClickable);

        if (props.isRight) {
            containerClassName.push(styles.containerClickableRight);
        }
    }

    if (props.isBlue) {
        containerClassName.push(styles.containerBlue);
    }

    return (
        <div
            className={containerClassName.join(" ")}
            onClick={(evt) => {
                if (props.isDisabled) {
                    return;
                }

                props.onClick(evt);
            }}
            tabIndex="-1"
            role="button"
        >
            {iconLeft}
            <div className={styles.label}>
                {props.label}
            </div>
            {iconRight}
        </div>
    );
};

ButtonUnderline.defaultProps = {
    label: "",
    icon: null,
    onClick: () => { },
    isDisabled: false,
    isRight: false,
    isClickable: false,
    isClicked: false,
    isBlue: false,
};

export default ButtonUnderline;
