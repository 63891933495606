/** @module                             Components */

import Fieldset                         from "../../Global/Forms/Fieldset";

import { LabeledInput }                 from "../../Global/Forms/Inputs_Spread";

const 

TireSize = ( { __, state, action, loading } ) => { const 

    /** @constant fields */

    fields = [ "width", "height", "diameter" ];

    /** @returns Size component */

    return (

        <Fieldset legend={ __ ( "tire_size" ) }>

            <div className="grid grid_template-columns-3">

            { 
            
                fields.map ( 
                    
                    ( field, index ) => 

                    <LabeledInput

                        key={ index }

                        type="number"

                        label={ __ ( field ) }
                            
                        value={ state [ field ] } 

                        action={ action }

                        name={ field }

                        disabled={ loading }
                        
                    />

                ) 
            
            }

            </div>

        </Fieldset>

    );

}

/** @exports TireSize */
 
export default TireSize;