import styles from "../ProductPreview.module.css";

/**
 * 
 * @exports ProductIcons component layout.
 * 
 * @param { product }
 * 
 * @returns product preview icons component layout.
 * 
 */

export default function ProductIcons ( { product } ) {

    /**
     * 
     * @returns product preview icons component layout.
     * 
     */

    return (

        <div className={ styles.productIcons }>

            <div className={ styles.circle }>

                { product.season.toUpperCase () }

            </div>

            { product.specifications.length > 0 &&

                <div className={ styles.circle }>

                    OE

                </div>

            }

            { product.year !== null &&

                <div className={ styles.circle }>

                    { product.year }

                </div>

            }

        </div>

    );

};