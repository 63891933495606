import React from "react";

import Button from "../../Components/Common/Button/index.js";
import Select from "../../Components/Common/Select/index.js";
import Pagination from "./../../Components/Common/Pagination/index.js";

import TableClientsTable from "./table/table.js";
import TableClientsFilters from "./filters/index.js";

import FormSearch from "./../../Forms/search/index.js";

import useTableClients from "./use-table-clients.js";

import styles from "./styles.module.css";


const TableClients = (props) => {
    const renderPagination = () => {
        return (
            <Pagination
                currentPage={props.pagination.currentPage}
                totalPages={props.pagination.totalPages}
                onSelect={props.pagination.onSelect}
            />
        );
    };

    // TODO:
    const renderTopControls = () => {
        const entriesOptions = props.entries.options.map((value) => ({
            name: value,
            label: value,
        }));

        return (
            <div className={styles.topControls}>
                <div className={styles.topControlsEntries}>
                    <div>
                        Show Entries:
                    </div>
                    <Select
                        options={entriesOptions}
                        selected={props.entries.selected}
                        onSelect={props.entries.onChange}
                    />
                </div>
                <div>
                    <div className={styles.searchForm}>
                        <FormSearch
                            onSearch={props.onSearch}
                        />
                    </div>
                </div>
                {renderPagination()}
            </div>
        );
    };

    const renderTable = () => {
        return (
            <TableClientsTable
                visibleColumns={props.filters.applied}
                clients={props.clients}
                selectedClients={props.selectedClients}
                onToggleRow={props.onToggleRow}
                onToggleAllRows={props.onToggleAllRows}
                actions={props.actions}
                onSort={props.onSort}
            />
        );
    };

    const renderBottomControls = () => {
        return (
            <div className={styles.bottomControls}>
                <Button
                    isBlue
                >
                    Export
                </Button>
                {renderPagination()}
            </div>
        );
    };

    if (props.isLoading) {
        return (
            <div>Loading...</div>
        );
    }

    const containerClassName = [styles.container];

    if (props.isReloading) {
        containerClassName.push(styles.containerReloading);
    }

    return (
        <div className={containerClassName.join(" ")}>
            <TableClientsFilters
                applied={props.filters.applied}
                onApply={props.filters.onApply}
            />
            {renderTopControls()}
            {renderTable()}
            {renderBottomControls()}
        </div>
    );
};

TableClients.defaultProps = {
    entries: {
        options: [3, 6, 9],
        selected: 6,
        onChange: () => { },
    },
    pagination: {
        currentPage: 0,
        totalPages: 0,
        onSelect: () => { },
    },
    filters: {
        applied: [],
        onApply: () => { },
    },
    actions: {
        onEdit: () => { },
        onPreview: () => { },
    },
    clients: [],
    selectedClients: [],
    onSearch: () => { },
    onToggleRow: () => { },
    onToggleAllRows: () => { },
    onSort: () => { },
    isLoading: false,
    isReloading: false,
};

export { useTableClients };
export default TableClients;
