/** @module                             Hooks */

import { useTranslation }               from "react-i18next";

/** @module                             React-Icons */

import { AiOutlinePlusCircle }          from "react-icons/ai";

/** @module                             Components */

import { Link }                         from "react-router-dom";

import ActionSearch                     from "../Global/Forms/ActionSearch";

const 

/** @returns collection page actions component */

Actions = ( { refetch = false } ) => { const { t } = useTranslation (),

    stringSearchAction = event => { event.preventDefault ();

        refetch ( {

            first           : JSON.parse ( localStorage.getItem ( "productsCollectionCount" ) ) || 12,

            last            : undefined,

            before          : undefined,

            after           : undefined,

            stringSearch    : ( event.target.stringSearch.value ) ? event.target.stringSearch.value : undefined,

        } );

    };

    return ( <div className="product-actions">

        <Link to="/app/montage/add">

            <AiOutlinePlusCircle />
            
            <span>

                { t ( "add_new_appointment" ) }

            </span>
            
        </Link>

        <ActionSearch formAction={ stringSearchAction } />

    </div> );

}

/** @exports */
 
export default Actions;