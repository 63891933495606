import styles from "./OrderPayment.module.css";

import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

import { useTranslation } from "react-i18next";

export default function OrderPayment ( { data } ) {

    const 

    { t } = useTranslation ();

    return (

        <td data-title={ t ( "orderPayment" ) }>

            <div className={ styles.container }>

                <div className={ styles.content }>

                    <div className={ styles.status }>

                        { 
                        
                            data?.paid ? 
                
                                <AiOutlineCheckCircle 
                            
                                    className="color-success" 
                                
                                    size={ 16 } /> 
                                
                            : 
                            
                                <AiOutlineCloseCircle 
                                
                                className="color-alert" 
                                
                                size={ 16 } /> 
                            
                        }

                    </div>

                    <div className={ styles.data }>

                        <div className={ styles.payment }>

                            { t ( data?.paymentMethod ) }

                        </div>

                        <div className={ styles.price }>

                            &euro; { parseFloat ( data?.price ).toFixed ( 2 ) }

                        </div>

                    </div>

                </div>

            </div>

        </td>

    );

};