/**
 * 
 * @param { Array } data 
 * 
 * @returns enum array usable for select options component
 * 
 */

export const enumOptions = data => { const placeholder = [];

    data.forEach ( element => 
        
        placeholder.push ( 
            
            { 
                
                name    : element.name.toUpperCase (), 
                
                value   : element.name 
            
            } 
            
        ) 
        
    )

    /** @returns */

    return placeholder;

}