import { useEffect } from "react";


const useGlobalClose = (callback, dependencies = []) => {
    useEffect(() => {
        const globalClose = (evt) => {
            callback(evt);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, dependencies);
};

export default useGlobalClose;
