/** @module                 Hooks */

import { 
    
    Fragment, 
    
    useContext, 
    
    useRef, 
    
    useState }              from "react";

import useAuthorization     from "../../Hooks/Authorization/useAuthorization";

import { useTranslation }   from "react-i18next";

import useLogout            from "../../Hooks/Authorization/useLogout";

/** @module                 Contexts */

import { PagenameContext }  from "../../Contexts/Pagename";

/** @module                 React-Icons */

import { 
    
    BiUserCircle, 
    
    BiLogOut }              from "react-icons/bi";

import { ImSpinner5 }       from "react-icons/im";

/** @module                 Components */

import ThemeSwitch          from "./Header/Theme";

import { ShoppingCart }     from "./Header/Cart";

import { Link }             from "react-router-dom";

const 

/**
 * 
 * @returns header component
 * 
 */

Header = () => { const

    /** @module             Contexts */

    { pagename }            = useContext ( PagenameContext ),

    /** @module             Hooks */

    { t }                   = useTranslation (),

    { 
        
        user, 
        
        pending 
    
    }                       = useAuthorization (),

    [ 
        
        logout, 
        
        loading 
    
    ]                       = useLogout (),

    /** @event              useState */

    [ 
        
        signOutBtn, 
        
        setSignOutBtn 
    
    ]                       = useState ( <BiLogOut /> ),

    /** @event              References */

    signOutButton           = useRef ( null ),

    /** 
     * 
     * @event onClick 
     * 
     * @returns sign-out action
     * 
     */

    signOut = () => {

        setSignOutBtn ( <ImSpinner5 className="spinner" /> );

        logout ();

    };

    /** @return Header page */

    return ( <header>

        <div className="location">

            <h3>{ t ( pagename ) }</h3>

        </div>

        <div className="navigation">

            <ul>

                <ShoppingCart />

                <ThemeSwitch />

                <li>

                    { pending ? `${ t ( "loading" ) }...` : <Fragment>
                    
                        <span><BiUserCircle /></span>
                        
                        <Link to={ `/app/managers/edit/${ 3 }` }>

                            { ( user === null ) ? t ( "unknown" ) : user.email }

                        </Link> 
                    
                    </Fragment> }
                    
                </li>

                <li>
                    
                    <button 
                    
                        ref={ signOutButton } 
                    
                        onClick={ signOut } 
                    
                        disabled={ loading ? true : false }>
                        
                            { signOutBtn }
                        
                    </button>
                    
                </li>

            </ul>
            
        </div>

    </header> );

}

/** @exports Header */
 
export default Header;