/** @module                         Hooks */

import { useEffect, useState }      from "react";

import { useParams }                from "react-router-dom";

import useFetch                     from "../../../Hooks/useFetch";

import { useTranslation }           from "react-i18next";

import useUpdate                    from "../../../Hooks/Mutations/useUpdate";

/** @module                         Queries */

import { GLOBAL_MARKUP }            from "../../../Queries/Markups";

/** @module                         Mutations */

import { UPDATE_GLOBAL_MARKUP }     from "../../../Mutations/Markups";

/** @module                         React-Icons */

import { MdOutlineUpdate }          from "react-icons/md";

/** @module                         Components */

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import { Input }                    from "../../../Components/Dashboard/Global/Forms/Inputs_Spread";

import ComponentLoader              from "../../../Components/Dashboard/Loaders/ComponentLoader";

const 

/**
 * 
 * @returns global markup component
 * 
 */

GlobalMarkup = () => { const

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( GLOBAL_MARKUP );

    /** @returns */

    return (

        loading ? <ComponentLoader /> : <MarkupController data={ data } loading={ loading } />

    );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Boolean } loading
 * 
 * @returns markup controller component
 * 
 */

MarkupController = ( { data, loading } ) => { const { t } = useTranslation (),
    { discountGroup } = useParams(),
    /** @event useState form fields */

    [ state, setState ] = useState ( {
        markupPercent : data.adminGlobalMarkup.markupPercent, 

        markupPercentGroupA: data.adminGlobalMarkup.markupPercentGroupA,

        markupPercentGroupB: data.adminGlobalMarkup.markupPercentGroupB,
        
        markupPercentGroupC: data.adminGlobalMarkup.markupPercentGroupC
    } ),

    [fieldName, setFieldName] = useState(''),

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading: updateLoading
    
    } = useUpdate ( UPDATE_GLOBAL_MARKUP ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value } = e.target;

        setState ( prev => ( { ...prev, [ name ]: parseFloat ( value ) } ) );

    },

    /**
     * 
     * @param { Object } e
     * 
     * @event onSubmit
     * 
     * @returns update mutation
     * 
     * @todo updateMutation
     * 
     */

    onSubmit = e => { e.preventDefault ();

        updateMutation ( state );

    };

    useEffect ( () => {
        switch ( discountGroup ) {

            case 'default': 
                setFieldName('markupPercent');
                break;

            case 'groupA':
                setFieldName('markupPercentGroupA');
                break;

            case 'groupB':
                setFieldName('markupPercentGroupB');
                break;

            case 'groupC':
                setFieldName('markupPercentGroupC');
                break;

            default: 
                setFieldName('markupPercent')
        }
     }, [ discountGroup ] );

    /** @returns */

    return (

        <form className="content__vertical-spacer" onSubmit={ onSubmit }>

            <div className="markup_content global">

                <Fieldset legend={ t ( "global_markup" ) } description={ t ( "global_markup_desc" ) }>

                    <Input 
                    
                        type="number" 
                        
                        name={fieldName}

                        disabled={ loading }
                        
                        value={ state[fieldName] } 
                        
                        action={ handleChange } />

                    <small>

                        { 
                        
                            t ( 
                            
                                "last_updated", 
                        
                                { 
                                
                                    date: new Date ( 
                                        
                                        data.adminGlobalMarkup.updatedAt 
                                        
                                    ).toLocaleString ().replace(/\//g, '.') 
                            
                                } 
                            
                            ) 
                            
                        }

                    </small>

                </Fieldset>

            </div>

            <button disabled={ updateLoading }>

                <MdOutlineUpdate />

                <span>

                    { t ( "save" ) }
                    
                </span>

            </button>

        </form>

    );

}

/** @exports GlobalMarkup */
 
export default GlobalMarkup;