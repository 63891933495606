/**
 * 
 * @param { Object } object 
 * 
 * @returns converted (INT) and sorted searchAttribute object (ASC)
 * 
 */

export const intSort = ( object, prefix = false ) => { const array = [];

    /** @description convert searchAttribute object values to integers */

    object.forEach ( element => array.push ( { 
        
        text: ( prefix ) ? `${ parseInt ( element.value ) } ${ prefix }` : parseInt ( element.value ), value: parseInt ( element.value ) 
    
    } ) );

    /** @returns */

    return [ ...array ].sort( ( a, b ) => a.value - b.value );

}

/**
 * 
 * @param { Object } object 
 * 
 * @returns converted (Float) and sorted searchAttribute object (ASC)
 * 
 */

export const floatSort = ( object, prefix = false ) => { const array = [];

    /** @description convert searchAttribute object values to floats */

    object.forEach ( element => array.push ( { 

        text: ( prefix ) ? 
        
            `${ parseFloat ( element.value ).toFixed ( 1 ) } ${ prefix }` 
            
            : 
            
            parseFloat ( element.value ).toFixed ( 1 ), value: parseFloat ( element.value ) 
    
    } ) );

    /** @returns */

    return [ ...array ].sort( ( a, b ) => a.value - b.value );

}

/**
 * 
 * @param { Object } object 
 * 
 * @returns converted searchAttribute object with uppercase text (ASC)
 * 
 */

export const textUpperCase = object => { const array = [];

    /** @description convert searchAttribute object text to upper case */

    object.forEach ( element => array.push ( { 
        
        text: element.text.toUpperCase (), value: element.value 
    
    } ) );

    /** @returns */

    return [ ...array ].sort( ( a, b ) => a.text.localeCompare ( b.text ) );

}

/**
 * 
 * @param { Object } object 
 * 
 * @returns converted searchAttribute object with each word's first letter capitalized (ASC)
 * 
 */

export const textCapital = object => { const array = [];

    /** @description convert searchAttribute object values to integers */

    object.forEach ( element => {

        const words = element.text.split ( " " );

        for ( let index = 0; index < words.length; index++ ) {

            words[ index ] = words[ index ].charAt ( 0 ).toUpperCase () + words[ index ].slice ( 1 );
        
        }
        
        array.push ( { text: words.join (" "), value: element.value  } ) 
    
    } );

    /** @returns */

    return [ ...array ].sort( ( a, b ) => a.text.localeCompare ( b.text ) );

}