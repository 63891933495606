import styles from "./Styles.module.css";

import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

import Fieldset from "../../Global/Forms/Fieldset";

import SelectSpread from "../../Global/Forms/Select_Spread";

import { countries } from "../../../../Datasets/Countries";

import { LabeledInput } from "../../Global/Forms/Inputs_Spread";

export default function DeliveryInformationSettings ( { state, update } ) { const 

    { t : __ } = useTranslation (),

    [ city, setCity ] = useState ( state.city );

    /**
     * 
     * @event useEffect
     * 
     * @description If city changes, update state.
     * 
     */

    useEffect ( () => {

        if ( city === state.city ) return;

        const timer = setTimeout( () => {

            update ( { ...state, city } );

        }, 1000 );

        return () => clearTimeout ( timer );

    }, [ city ] );

    /**
     * 
     * @event useEffect
     * 
     * @description If city changes, update state.
     * 
     */

    useEffect ( () => {

        if ( city === state.city ) return;

        setCity ( state.city );

    }, [ state.city ] );

    /** @returns { JSX.Element } DeliveryInformationSettings */

    return (

        <Fieldset legend={ "delivery_information" }>

            <div className={ styles.grid }>

                <div style={ { gridColumn: "1 / 3" } }>

                    <SelectSpread 
                        
                        id="countryCode"

                        label={ __ ( "country" ) }

                        i18n={ false }

                        def={ true }
                        
                        action={ e => update ( { ...state, countryCode: e.target.value } ) }
                        
                        options={ countries }
                        
                        value={ state.countryCode } />

                </div>

                <div style={ { gridColumn: "3 / 5" } }>

                    <LabeledInput
                                
                        label={ __ ( "city" ) }
                    
                        value={ city } 

                        action={ e => setCity ( e.target.value ) }

                        name="city"
                        
                    />

                </div>

                <div style={ { gridColumn: "5 / 9" } }>

                    <LabeledInput
                                
                        label={ __ ( "address" ) }
                    
                        value={ state.address } 

                        action={ e => update ( { ...state, address: e.target.value } ) }

                        name="address"
                        
                    />

                </div>

                <div style={ { gridColumn: "9 / 10" } }>

                    <LabeledInput
                                
                        label={ __ ( "postCode" ) }
                    
                        value={ state.postCode } 

                        action={ e => update ( { ...state, postCode: e.target.value } ) }

                        name="postCode"
                        
                    />

                </div>

                <div style={ { gridColumn: "1 / 10" } }>

                    <textarea
                                
                        name="shippingNotes"

                        id="shippingNotes"

                        onChange={ e => update ( { ...state, shippingNotes: e.target.value } ) }

                        placeholder={ `${ __ ( "shipping_notes" ) }...` }

                        value={ state.shippingNotes }
                    
                    />

                </div>

            </div>

        </Fieldset>

    );

};