import React from "react";

import styles from "./styles.module.css";


const ButtonIcon = (props) => {
    const containerClassName = [styles.container];

    if (props.isPurple) {
        containerClassName.push(styles.containerPurple);
    } else if (props.isBlue) {
        containerClassName.push(styles.containerBlue);
    } else if (props.isRed) {
        containerClassName.push(styles.containerRed);
    } else if (props.isGreenDark) {
        containerClassName.push(styles.containerGreenDark);
    } else if (props.isGreen) {
        containerClassName.push(styles.containerGreen);
    }

    return (
        <div
            className={containerClassName.join(" ")}
            onClick={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <props.icon
                className={styles.icon}
            />
        </div>
    );
};

ButtonIcon.defaultProps = {
    icon: null,
    onClick: () => { },
    isPurple: false,
    isBlue: false,
    isRed: false,
    isGreenDark: false,
    isGreen: false,
};

export default ButtonIcon;
