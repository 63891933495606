import React from "react";

import stylesButton from "./../Styles/Button/styles.module.css";
import styles from "./styles.module.css";


const Button = (props) => {
    const onClick = (evt) => {
        if (props.isDisabled) {
            return;
        }

        props.onClick(evt);
    };

    /* --- */

    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <props.icon
                className={styles.icon}
            />
        );
    };

    const renderIconAfter = () => {
        const { iconAfter } = props;

        if (!iconAfter.icon) {
            return null;
        }

        const icon = (
            <iconAfter.icon
                className={styles.icon}
            />
        );

        if (!iconAfter.isClickable) {
            return icon;
        }

        return (
            <div
                className={styles.iconContainerClickable}
                onClick={(evt) => {
                    evt.stopPropagation();
                    iconAfter.onClick(evt);
                }}
            >
                {icon}
            </div>
        );
    };

    const renderChildren = () => {
        if (!props.children) {
            return null;
        }

        return (
            <div>
                {props.children}
            </div>
        );
    };

    /* --- */

    const containerClassName = [stylesButton.button];

    if (props.isBlue) {
        containerClassName.push(stylesButton.blue);
    }

    if (props.isGrey) {
        containerClassName.push(stylesButton.grey);
    }

    if (props.isRed) {
        containerClassName.push(stylesButton.red);
    }

    return (
        <div
            className={containerClassName.join(" ")}
            onClick={onClick}
            tabIndex="-1"
            role="button"
        >
            {renderIcon()}
            {renderChildren()}
            {renderIconAfter()}
        </div>
    );
};

Button.defaultProps = {
    icon: null,
    iconAfter: {
        icon: null,
        onClick: () => { },
        isClickable: false,
    },
    children: null,
    onClick: () => { },
    isDisabled: false,
    isBlue: false,
    isGrey: false,
    isRed: false,
};

export default Button;
