import { Text, View, Image } from '@react-pdf/renderer';

import NoImage from "../../../../../Assets/missing_image.jpg";

export default function DocumentProducts ( { order, products } ) {

    return (

        <View>

            <View style={ { flexDirection: "row", flexWrap: "nowrap", borderBottom: "1px solid #999" } }>

                <Text style={ { flex: 0.15, fontWeight: 500 } }>Nr.</Text>

                {/* <Text style={ { flex: 1 } }>Prece</Text> */}

                <Text style={ { flex: 1, fontWeight: 500 } }>Apraksts</Text>

                <Text style={ { flex: 0.5, fontWeight: 500 } }>Daudzums</Text>

                <Text style={ { flex: 0.5, fontWeight: 500 } }>Atlaide</Text>

                <Text style={ { flex: 1, fontWeight: 500 } }>Cena</Text>

                <Text style={ { flex: 1, fontWeight: 500 } }>Kopējā cena</Text>

            </View>

            {

                products.map ( ( product, index ) => {

                    const productTypes = {

                        tire: `${ product.product.width } | ${ product.product.height } | R${ parseInt ( product.product.diameter ) }${ product?.product?.isCTire ? "C" : "" } | ${ product?.product?.loadIndex }${ product?.product?.speedRating?.toUpperCase () }`,
                
                        rim: `${  product.product.width || "-" }J | R${ parseInt (  product.product.diameter ) || "-" } | ${  product.product.pitchCircleDiameter || "-" } | ET${  product.product.offset || "-" } | CB${  product.product.centreBore || "-" }`,
                
                        battery: `${ product.product.voltage } V | ${ product.product.current } A | ${ product.product.capacity } Ah`,
                
                    };

                    return <View key={ product.id } style={ { flexDirection: "row", flexWrap: "nowrap", borderBottom: "1px solid #999" } }>

                        <View style={ { flex: 0.15, display: "flex", justifyContent: "center" } }>

                            <Text>{ index + 1 }</Text>

                        </View>

                        <Image style={ { flex: 0.25, display: "flex", justifyContent: "center", padding: "5" } } src={ product?.product?.currentImage || NoImage } />

                        {/* <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                            <Image src={ product.product.currentImage } style={ { width: 100 } } />

                        </View> */}

                        <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                            <View style={ { display: "block" } }>
                                
                                <Text>

                                    { [ product.product.manufacturer.name.toUpperCase (), product.product.model ].join ( " " ) }

                                </Text>
                                
                            </View>

                            <View style={ { display: "block" } }>
                                
                                <Text>{ productTypes [ product.product.productType ] }</Text>
                                
                            </View>

                            <View style={ { display: "block" } }>
                                
                                <Text style={ { color: "#bbb" } }>ID: { product.id }</Text>
                                
                            </View>

                        </View>

                        <View style={ { flex: 0.5, display: "flex", justifyContent: "center" } }>

                            <Text>{ product.count }</Text>

                        </View>

                        <View style={ { flex: 0.5, display: "flex", justifyContent: "center" } }>

                            <Text>{ product.discount + " %" }</Text>

                        </View>

                        <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                            <View style={ { display: "block" } }>
                                
                                <Text>bez PVN: { ( product.price / ( 1 + 21 / 100 ) ).toFixed ( 2 ) } EUR</Text>
                                
                            </View>

                            <View style={ { display: "block" } }>
                                
                                <Text>ar PVN: { product.price } EUR</Text>
                                
                            </View>

                        </View>

                        <View style={ { flex: 1, display: "flex", justifyContent: "center" } }>

                            <View style={ { display: "block" } }>
                                
                                <Text>bez PVN: { ( product.price / ( 1 + 21 / 100 ) ).toFixed ( 2 ) * product.count } EUR</Text>
                                
                            </View>

                            <View style={ { display: "block" } }>
                                
                                <Text>ar PVN: { product.price * product.count } EUR</Text>
                                
                            </View>

                        </View>

                    </View>

                } )

            }

            <View style={ { flexDirection: "row", alignSelf: "flex-end", fontSize: 10 } }>

                <View style={ { width: 50 + "%" } }>

                    <View style={ { flexDirection: "row" } }>

                        <Text style={{ flex: 1 }}>Atlaide</Text>

                        <Text style={{ flex: 1 }}>{ order.discountPrice.toFixed ( 2 ) } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row" } }>

                        <Text style={{ flex: 1 }}>Montāža</Text>

                        <Text style={{ flex: 1 }}>{ order.assemblyPrice.toFixed ( 2 ) } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row", borderBottom: "1px solid #999" } }>

                        <Text style={{ flex: 1 }}>Piegāde</Text>

                        <Text style={{ flex: 1 }}>{ order?.deliveryPrice?.toFixed ( 2 ) || "0.00" } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row" } }>

                        <Text style={{ flex: 1 }}>Kopsumma bez PVN</Text>

                        <Text style={{ flex: 1 }}>{ ( order.price / ( 1 + 21 / 100 ) ).toFixed ( 2 ) } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row" } }>

                        <Text style={{ flex: 1 }}>PVN</Text>

                        <Text style={{ flex: 1 }}>{ ( order.price - ( order.price / ( 1 + 21 / 100 ) ) ).toFixed ( 2 ) } EUR</Text>

                    </View>

                    <View style={ { flexDirection: "row", borderBottom: "1px solid #999", borderTop: "1px solid #999", fontWeight: 500, fontSize: 11 } }>

                        <Text style={{ flex: 1 }}>Kopsumma apmaksai</Text>

                        <Text style={{ flex: 1 }}>{ order.price.toFixed ( 2 ) } EUR</Text>

                    </View>

                </View>

            </View>

        </View>

    );

};