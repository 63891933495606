/** @module             React-Icons */

import { ImSpinner5 }   from "react-icons/im";

const 

/**
 * 
 * @param { Number } size
 * 
 * @returns component loader
 * 
 */

ComponentLoader = ( { size = 3 } ) => {

    /** @returns */

    return ( <section className="content-content-loader">

        <div className="placeholder-container">

            { 
            
                [ ...Array ( size ) ].map ( 
                
                    ( el, index ) => 
                    
                        <div 
                        
                            className="placeholder-content" 
                        
                            key={ `placeholder-left-${ index }` }>
                                
                        </div> 
                    
                ) 
                    
            }

        </div>

        <div className="progress">

            <ImSpinner5 
            
                size={ 32 } 
            
                className="spinner" />

        </div>

        <div className="placeholder-container">

            { 
                
                [ ...Array ( size ) ].map ( 
                
                    ( el, index ) => 
                    
                        <div 
                        
                            className="placeholder-content" 
                        
                            key={ `placeholder-right-${ index }` }>
                                
                        </div> 
                    
                ) 
                    
            }

        </div>

    </section> );

}

/** @exports ComponentLoader */
 
export default ComponentLoader;