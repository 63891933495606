/** @module                         Hooks */

import { 
    
    useRef, 
    
    useState, 
    
    useEffect }                     from "react";

import useFetch                     from "../../../Hooks/useFetch";

import useUpdate                    from "../../../Hooks/Mutations/useUpdate";

import { useTranslation }           from "react-i18next";

/** @module                         Queries */

import { MANUFACTURERS_MARKUP }     from "../../../Queries/Markups";

/** @module                         Mutations */

import { UPDATE_MANUFACTURER }      from "../../../Mutations/Manufacturers";

/** @module                         React-Icons */

import { MdOutlineUpdate }          from "react-icons/md";

import { ImSpinner5 }               from "react-icons/im";

/** @module                         Components */

import { Link, useParams }                     from "react-router-dom";

import ComponentLoader              from "../../../Components/Dashboard/Loaders/ComponentLoader";

import Pagination                   from "../../../Components/Dashboard/Global/Table/Pagination";



const 

ManufacturerMarkup = () => { const

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { discountGroup } = useParams(),

    [fieldName, setFieldName] = useState(''),


    { loading, data, refetch } = useFetch ( MANUFACTURERS_MARKUP, { 
        
        first: JSON.parse ( localStorage.getItem ( `manufacturersCollectionCount` ) ) || 12
    
    }, "cache-and-network" );

    useEffect ( () => {
        switch ( discountGroup ) {

            case 'default': 
                setFieldName('markup');
                break;

            case 'groupA':
                setFieldName('markupGroupA');
                break;

            case 'groupB':
                setFieldName('markupGroupB');
                break;

            case 'groupC':
                setFieldName('markupGroupC');
                break;

            default: 
                setFieldName('markup')
        }
     }, [ discountGroup ] );

    /** @returns */

    return (

        loading ? <ComponentLoader /> : <MarkupTable data={ data } refetch={ refetch } loading={ loading } fieldName={ fieldName }/>

    );

},

MarkupTable = ( { data, refetch, loading, fieldName={fieldName} } ) => { const { t } = useTranslation ();
    /** @returns */

    return (

        <section className="content__vertical-spacer">

            { ( typeof data != "undefined" ) &&
                
                <Pagination 
                   stringSearch = { true }
                    
                    pageinfo={ data.adminManufacturers.pageInfo } 
                    
                    action={ refetch } 
                    
                    cookie={ `manufacturersCollectionCount` } /> 
            
            }

            <table>

                <thead>

                    <tr>

                        <th>{ t ( "manufacturer" ) }</th>

                        <th>{ t ( "updatedAt" ) }</th>

                        <th>{ t ( "markup" ) }</th>

                        <th>{ t ( "save" ) }</th>

                    </tr>

                </thead>

                <tbody>

                    { data.adminManufacturers.edges.map 
                    
                        ( 
                            
                            ( row, index ) =>

                            <Row key={ index } row={ row } loading={ loading } fieldName={ fieldName } />

                        )

                    }

                </tbody>

            </table>

            { ( typeof data != "undefined" ) &&
                
                <Pagination 
                    
                    pageinfo={ data.adminManufacturers.pageInfo } 
                    
                    action={ refetch } 
                    
                    cookie={ `manufacturersCollectionCount` } /> 
            
            }

        </section>

    );

},

Row = ( { row, index, loading, fieldName } ) => { const { t } = useTranslation (),

    markupReference = useRef ( row.node[fieldName] ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        id : row.node.id,

        markup : row.node.markup,

        markupGroupA: row.node.markupGroupA,

        markupGroupB: row.node.markupGroupB,

        markupGroupC: row.node.markupGroupC,

        name : row.node.name

    } ),

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading: updateLoading
    
    } = useUpdate ( UPDATE_MANUFACTURER ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value } = e.target;

        setState ( prev => ( { ...prev, [ fieldName ]: parseFloat ( value ) } ) );

    },

    /**
     * 
     * @param { Object } e
     * 
     * @event onSubmit
     * 
     * @returns update mutation
     * 
     * @todo updateMutation
     * 
     */

    onSubmit = e => { e.preventDefault ();

        markupReference.current = state[fieldName];

        updateMutation ( state );

    };

    useEffect ( () => { 

        markupReference.current = row.node[fieldName];
        
        setState ( {

            id : row.node.id,

            markup : row.node.markup,

            markupGroupA: row.node.markupGroupA,

            markupGroupB: row.node.markupGroupB,

            markupGroupC: row.node.markupGroupC,

            name : row.node.name

        } ) 

    }, [ row, fieldName ] );

    /** @returns */

    return (

        <tr key={ index }>

            <td>

                <Link to={ `/app/shop/manufacturers/edit/${ row.node.id }` }>

                    { capitalize ( row.node.name ) }
                
                </Link>
                
            </td>

            <td>{ 
            
                new Date ( 
                        
                    row.node.updatedAt 
                    
                ).toLocaleString ().replace(/\//g, '-')
                
            }</td>

            <td>

                <input 
                
                    type="number"

                    name={ fieldName }


                    min={ 0 }

                    disabled={ loading }

                    value={ state[fieldName] }

                    onChange={ handleChange }
                
                />
                
            </td>

            <td>

                <button

                    onClick={ onSubmit }
                
                    disabled={ ( markupReference.current == state[fieldName] || updateLoading ) }

                
                >

                    { updateLoading ? <ImSpinner5 className="spinner" /> : <MdOutlineUpdate />   }

                    <span>

                        { t ( "save" ) }

                    </span>

                </button>

            </td>

        </tr>

    );

},

/**
 * 
 * @param { String } str
 * 
 * @returns capitalized string
 * 
 */

capitalize = str => str.replace ( /\w\S*/g, txt => txt.charAt ( 0 ).toUpperCase () + txt.substr ( 1 ).toLowerCase () );

/** @exports ManufacturerMarkup */
 
export default ManufacturerMarkup;