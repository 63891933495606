import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import { FaRegTrashAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdUndo } from "react-icons/io";


import Input from "./../../Components/Common/Forms/input/index.js";
import Select from "./../../Components/Common/Select/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";
import ButtonIconV2 from "../../Components/Common/ButtonIconV2/index.js";

import styles from "./styles.module.css";


const PREFIX = "companyContacts";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    firstName: getName("FirstName"),
    lastName: getName("LastName"),
    branchOffice: getName("BranchOffice"),
    mobilePhone: getName("MobilePhone"),
    email: getName("Email"),
};

const ROW = {
    [FIELDS.firstName]: "",
    [FIELDS.lastName]: "",
    [FIELDS.branchOffice]: "",
    [FIELDS.mobilePhone]: "",
    [FIELDS.email]: "",
};

/* --- */

const FormClientEditCompanyContacts = (props) => {
    const [isFormVisible, setIsFormVisible] = useState(false);

    const [rows, setRows] = useState(() => props.initialRows);

    const { t } = useTranslation();

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const onToggleForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const onAddRow = () => {
        setRows((prev) => {
            return [...prev].concat(ROW);
        });
    };

    const onRemoveRow = (index) => {
        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            isDeleted: true,
        };

        setRows(newRows);
    };

    const onUnlockRow = (index) => {
        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            isDeleted: false,
        };

        setRows(newRows);
    };

    const onChangeField = (index, field, evt) => {
        const value = evt.target.value || "";

        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            [field]: value,
        };

        setRows(newRows);
    };

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const renderRowRemoveLine = (index) => {
        let removeOrUndo = null;

        if (rows[index].isDeleted) {
            removeOrUndo = (
                <ButtonIconV2
                    icon={IoMdUndo}
                    onClick={() => {
                        onUnlockRow(index);
                    }}
                    isDisabled={isDisabled()}
                    isBlue
                />
            );
        } else {
            removeOrUndo = (
                <ButtonIconV2
                    icon={FaRegTrashAlt}
                    onClick={() => {
                        onRemoveRow(index);
                    }}
                    isDisabled={isDisabled()}
                    isRed
                />
            );
        }

        return (
            <div className={styles.rowRemoveLine}>
                <ButtonUnderline
                    label={`${t("contact")} ${index + 1}`}
                />
                {removeOrUndo}
            </div>
        );
    };

    const renderRow = (index, data, formMethods) => {
        const { handleBlur } = formMethods;

        const {
            firstName,
            lastName,
            branchOffice,
            mobilePhone,
            email,
        } = FIELDS;


        let branchOfficeField = null;

        if (index > 0) {
            // TODO:
            branchOfficeField = (
                <Select
                    options={[]}
                    selected={null}
                    onSelect={(value) => {
                        const evt = { target: { value } };
                        onChangeField(index, branchOffice, evt);
                    }}
                    isDisabled={isDisabled()}
                />
            );
        }

        const columnClassName = [styles.column];

        if (data.isDeleted) {
            columnClassName.push(styles.columnDeleted);
        }

        return (
            <div className={columnClassName.join(" ")}>
                <Input
                    name={`${index}-${firstName}`}
                    label={t("firstName")}
                    placeholder="......"
                    value={data[firstName]}
                    onChange={(evt) => {
                        onChangeField(index, firstName, evt);
                    }}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
                <div>
                    <Input
                        name={`${index}-${lastName}`}
                        label={t("lastName")}
                        placeholder="......"
                        value={data[lastName]}
                        onChange={(evt) => {
                            onChangeField(index, lastName, evt);
                        }}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    {branchOfficeField}
                </div>
                <Input
                    name={`${index}-${mobilePhone}`}
                    label={t("mobile_phone")}
                    placeholder="......"
                    value={data[mobilePhone]}
                    onChange={(evt) => {
                        onChangeField(index, mobilePhone, evt);
                    }}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
                <Input
                    name={`${index}-${email}`}
                    label={t("email")}
                    placeholder="......"
                    value={data[email]}
                    onChange={(evt) => {
                        onChangeField(index, email, evt);
                    }}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
            </div>
        );
    };

    const renderRows = (formMethods) => {
        const forms = [];

        for (let i = 0; i < rows.length; i += 1) {
            const data = rows[i];

            let header = null;

            if (i === 0) {
                header = (
                    <ButtonUnderline
                        label={t("main_contact_person")}
                    />
                );
            } else {
                header = renderRowRemoveLine(i);
            }

            forms.push(
                <div
                    className={styles.row}
                    key={`row-${i}`}
                >
                    {header}
                    {renderRow(i, data, formMethods)}
                </div>
            );
        }

        return forms;
    };

    const renderFormContent = (formMethods) => {
        if (!isFormVisible) {
            return null;
        }

        return (
            <>
                {renderRows(formMethods)}
                <ButtonUnderline
                    label={t("add_contact")}
                    icon={FiPlusCircle}
                    onClick={onAddRow}
                    isDisabled={isDisabled()}
                    isClickable
                    isBlue
                />
            </>
        );
    };

    const renderForm = (formMethods) => {
        const { handleSubmit } = formMethods;

        if (props.formRef) {
            props.formRef.current = formMethods;
            props.formRef.current.values = {
                ...formMethods.values,
                rows,
            };
        }

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <ButtonUnderline
                    label={t("company_contacts__additional_staff_with_access_to_B2B")}
                    onClick={onToggleForm}
                    isClicked={isFormVisible}
                    isClickable
                />
                {renderFormContent(formMethods)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={{}}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditCompanyContacts.defaultProps = {
    formRef: null,
    initialRows: [],
    onSubmit: () => { },
    isEditable: false,
};

export {
    FIELDS,
    ROW,
};

export default FormClientEditCompanyContacts;
