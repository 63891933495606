/** @module                     Hooks */

import { useTranslation }       from "react-i18next";

/** @module                     Utilities */

import { enumOptions }          from "../../Utils/enumOptions";

/** @module                     Components */

import Fieldset                 from "../../Global/Forms/Fieldset";

import Select                   from "../../Global/Forms/Select";

import Checkbox                 from "../../Global/Forms/Checkbox";

import MutationActions          from "../../Global/Layouts/Mutations/MutationActions";

const

/**
 * 
 * @param { Object } data 
 * 
 * @param { Object } details
 * 
 * @returns add new manager sidebar component
 * 
 */ 

Sidebar = ( { data, details } ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <aside>

        <Fieldset legend={ t ( "actions" ) }>

            <MutationActions 
                
                submitAction={ details.submit.action }
                
                references={ [ details.submit.reference, "" ] } />

        </Fieldset>

        <Fieldset legend={ t ( "options" ) }>

            <Checkbox

                id="blocked"

                text={ t ( "blocked" ) }

                state={ details.blocked.view }

                action={ e => details.blocked.set ( ! details.blocked.view ) }

                ref={ details.blocked.reference } />

            <Select 
                    
                id="role"
                
                action={ e => details.role.set ( e ) }
                
                options={ enumOptions ( data ) }
                
                value={ details.role.view }
                
                ref={ details.role.reference } />

        </Fieldset>

        { details.blocked.view &&

            <Fieldset legend={ t ( "blocked_reason" ) }>

                <textarea

                    id="blockedReason"

                    name="blockedReason"

                    value={ details.blockedReason.view }

                    onChange={ e => details.blockedReason.set ( e.target.value ) }

                    ref={ details.blockedReason.reference } />

            </Fieldset>

        }

    </aside> );

}

/** @exports Sidebar */
 
export default Sidebar;