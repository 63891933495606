/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name MANUFACTURER_PRODUCTS */

MANUFACTURER_PRODUCTS = gql`query

    fetchManufacturerProducts (

        $id: [ID!]!

        $after: String

        $before: String

        $first: Int

        $last: Int

    ) {

        product (

            id: $id

            after: $after

            before: $before

            first: $first

            last: $last

        ) {

            edges {

                node {

                    id

                    model

                    currentImage

                }

            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        }

    }

`,

/** @name MANUFACTURERS */

MANUFACTURERS = gql`query

    fetchManufacturers (

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminManufacturers (

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

        ) {

            edges {

                node {

                    aliases {

                        id

                    }

                    createdAt

                    deleted

                    hashId

                    id

                    models

                    name

                    markup

                    products {

                        id

                    }

                    sizes

                    updatedAt

                    visible

                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name MANUFACTURER_PREVIEW */

MANUFACTURER_PREVIEW = gql`query fetchManufacturerPreview ( $id: ID! ) {

    adminManufacturer ( id: $id ) {

        name

        updatedAt

        createdAt

        markup

        visible

    }

}`,

/** @name  */

MANUFACTURER = gql`query fetchManufacturer ( $id: ID! ) {

    adminManufacturer ( id: $id ) {

        aliases {

            id

        }

        createdAt

        deleted

        hashId

        id

        models

        name

        markup

        products {

            id

        }

        sizes

        updatedAt

        visible

    }

}`,

/** @name MANUFACTURER_PRODUCT_ENUM */

MANUFACTURER_PRODUCT_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "ManufacturerProductTypesEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    MANUFACTURERS,

    MANUFACTURER,

    MANUFACTURER_PREVIEW,

    MANUFACTURER_PRODUCTS,

    MANUFACTURER_PRODUCT_ENUM

}