import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Applications = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Applications page */

    return ( <section className="applications">

        Applications page

    </section> );

}

/** @exports Applications */
 
export default Applications;