// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_formsColumn__ZCnJC {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.styles_controls__PA8MT {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n}\n\n.styles_errorList__HFKiR {\n    display: grid;\n    justify-content: end;\n    text-align: end;\n}\n\n.styles_error__14fYM {\n    color: red;\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/Clients/Add/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".formsColumn {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.controls {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n}\n\n.errorList {\n    display: grid;\n    justify-content: end;\n    text-align: end;\n}\n\n.error {\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formsColumn": "styles_formsColumn__ZCnJC",
	"controls": "styles_controls__PA8MT",
	"errorList": "styles_errorList__HFKiR",
	"error": "styles_error__14fYM"
};
export default ___CSS_LOADER_EXPORT___;
