/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { useState }                 from "react";

/** @module                         React-Icons */

import { AiOutlinePlusCircle }      from "react-icons/ai";

import { MdFilterList }             from "react-icons/md";

/** @module                         Components */

import { Link }                     from "react-router-dom";

import Modals                       from "../Global/Modals/Aside/Button";

import UserFilters                  from "./Modals/Filters";

// import ActionSearch                 from "../Global/Forms/ActionSearch";

const 

/** @returns collection page actions component */

Actions = ( { refetch, variables } ) => { const { t } = useTranslation (),

    [ state, setState ] = useState ( variables.role );

    /** @returns */

    return ( <div className="product-actions">

        <Link to="/app/managers/add">

            <AiOutlinePlusCircle />
            
            <span>

                { t ( "add_managers" ) }

            </span>
            
        </Link>

        <Modals 
            
                text="filters" 
            
                icon={ <MdFilterList /> } 
            
                element={ 
                
                    <UserFilters 

                        state={ state }

                        setState={ setState }
                    
                        refetch={ refetch }

                        variables={ variables }

                    />
                    
                } />

        {/* <ActionSearch formAction={ stringSearchAction } /> */}

    </div> );

}

/** @exports */
 
export default Actions;