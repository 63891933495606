/** @module                             Hooks */

import { usePagename }                  from "../../Hooks/usePagename";

import useFetch                         from "../../Hooks/useFetch";

import { useTranslation }               from "react-i18next";

/** @module                             Queries */

import { PAGES_TYPES_ENUM }             from "../../Queries/Pages";

/** @module                             Components */

import { Link }                         from "react-router-dom";

import Breadcrumbs                      from "../../Components/Dashboard/Breadcrumbs";

import ComponentLoader                  from "../../Components/Dashboard/Loaders/ComponentLoader";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns pagelist for pages component
 * 
 */

DynamicPages = ( { name } ) => { usePagename ( name ); const { t } = useTranslation (),

    /** @event useFetch */

    { loading, data } = useFetch ( PAGES_TYPES_ENUM );

    /** @returns */

    return (

        <section className="pagelist">

            <Breadcrumbs />

            {

                loading ? <ComponentLoader /> : 

                data.__type.enumValues.map ( ( { name }, index ) => 
                
                    <Link to={ `/app/pages/${ name }` } key={ index }>{ t ( name ) }</Link>
                
                )

            }

        </section>

    );

}

/** @exports DynamicPages */
 
export default DynamicPages;