/** @module                     Hooks */

import { usePagename }          from "../../Hooks/usePagename";

import { useTranslation }       from "react-i18next";

import { 
    
    useEffect, 
    
    useRef, 
    
    useState }                  from "react";

import { useParams }            from "react-router-dom";

import useFetch                 from "../../Hooks/useFetch";

import useUpdateManager         from "../../Hooks/Managers/useUpdateManager";

import useDeleteManager         from "../../Hooks/Managers/useDeleteManager";

/** @module                     Queries */

import { MANAGER }              from "../../Queries/Managers";

/** @module                     Components */

import Breadcrumbs              from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import ComponentLoader          from "../../Components/Dashboard/Loaders/ComponentLoader";

import { 
    
    Input, 
    
    LabeledInput }              from "../../Components/Dashboard/Global/Forms/Inputs";

import Fieldset                 from "../../Components/Dashboard/Global/Forms/Fieldset";

import Sidebar                  from "../../Components/Dashboard/Managers/Edit/Sidebar";

import Notice                   from "../../Components/Dashboard/Global/Notice";

/** @module                     Utilities */

import { disableAttribute }     from "../../Components/Dashboard/Utils/disableAttribute";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns edit manager page component
 * 
 */

EditManager = ( { name } ) => { usePagename ( name ); const 

    /** @module         Hooks */

    { managerId }       = useParams (),

    { t }               = useTranslation (),

    { data, loading }   = useFetch ( MANAGER, { id: managerId } );

    /** @returns */

    return ( <section className="mutations edit manager">

        <Breadcrumbs data={ [

            { name: t ( "managers"  ), uri: "/app/managers/active"       },

            { name: t ( name        ), uri: `/app/managers/edit/${ managerId }`    }

        ] } />

        { loading ? <ComponentLoader /> : <Component data={ data } __={ t } /> }

    </section> );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Function } __
 * 
 * @returns edit manager page content component
 * 
 */

Component = ( { data, __ } ) => { const

    /** @module                 States */

    [ email,                    setEmail                    ] = useState ( data.adminUser.email || "" ),

    [ password,                 setPassword                 ] = useState ( "" ),

    [ passwordConfirmation,     setPasswordConfirmation     ] = useState ( "" ),

    [ role,                     setRole                     ] = useState ( data.adminUser.role ),

    [ firstName,                setFirstName                ] = useState ( data.adminUser.firstName || "" ),

    [ lastName,                 setLastName                 ] = useState ( data.adminUser.lastName || "" ),

    [ blocked,                  setBlocked                  ] = useState ( data.adminUser.blocked ),

    [ blockedReason,            setBlockedReason            ] = useState ( data.adminUser.blockedReason || "" ),

    /** @module                 References */

    emailRef                    = useRef ( null ),

    passwordRef                 = useRef ( null ),

    passwordConfirmationRef     = useRef ( null ),

    roleRef                     = useRef ( null ),
    
    submitRef                   = useRef ( null ),
    
    removeRef                   = useRef ( null ),

    firstNameRef                = useRef ( null ),

    lastNameRef                 = useRef ( null ),

    blockedRef                  = useRef ( null ),

    blockedReasonRef            = useRef ( null ),

    /** @module                 Hooks */

    { updateManager, loading, errors, success } = useUpdateManager (),

    { deleteManager, loading : deleteLoading, errors : deleteErrors } = useDeleteManager (),

    /** @event onSubmit */

    submitManager = () => {

        updateManager (

            data.adminUser.id,

            email,

            firstName,

            lastName,

            role,

            password,

            passwordConfirmation,

            blocked,

            blockedReason

        );

    },

    /** @event onDelete */

    submitRemoval = () => {

        deleteManager ( data.adminUser.id );

    };

    /** @event useEffect */

    useEffect ( () => {

        if ( loading ) {

            disableAttribute ( [ emailRef, roleRef, passwordRef, passwordConfirmationRef, submitRef ], "add" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = `${ __ ( "submitting" ) }...`;

            }

        } else { 

            disableAttribute ( [ emailRef, roleRef, passwordRef, passwordConfirmationRef, submitRef ], "remove" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = __ ( "submit" );

            }
    
        }

    }, [ loading ] );
    
    /** @returns */

    return ( <AsideEdit aside={ <Sidebar data={ data.adminUser } details={ {

        role            : { view: role, set: setRole, reference: roleRef },

        blocked         : { view: blocked, set: setBlocked, reference: blockedRef },

        blockedReason   : { view: blockedReason, set: setBlockedReason, reference: blockedReasonRef },

        submit          : { reference: submitRef, action: submitManager },

        remove          : { reference: removeRef, action: submitRemoval }
        
        } } /> }>

            { errors && <Notice type="danger">

                { __ ( "failed_save" ) }

            </Notice> }

            { deleteErrors && <Notice type="danger">

                { __ ( "failed_delete" ) }

            </Notice> }

            { success && <Notice type="success">

                { __ ( "save_success" ) }

            </Notice> }

            <Fieldset legend={ __ ( "user_information" ) }>

                <div className="grid .grid_template-columns-3">

                    <LabeledInput
                        
                        value={ email }

                        label={ __ ( "email" ) }
            
                        action={ e => setEmail ( e ) }
            
                        ref={ emailRef }
                    
                    />

                    <LabeledInput
                        
                        value={ firstName }

                        label={ __ ( "firstName" ) }
            
                        action={ e => setFirstName ( e ) }
            
                        ref={ firstNameRef }
                    
                    />

                    <LabeledInput
                        
                        value={ lastName }

                        label={ __ ( "lastName" ) }
            
                        action={ e => setLastName ( e ) }
            
                        ref={ lastNameRef }
                    
                    />

                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "security" ) }>

                <LabeledInput

                    label={ __ ( "new_password" ) }
                    
                    value={ password }
        
                    action={ e => setPassword ( e ) }
        
                    ref={ passwordRef }
                
                />

                <LabeledInput

                    label={ __ ( "confirm_new_password" ) }
                    
                    value={ passwordConfirmation }
        
                    action={ e => setPasswordConfirmation ( e ) }
        
                    ref={ passwordConfirmationRef }
                
                />

            </Fieldset>
        
        </AsideEdit>  )

}
 
/** @exports EditManager */

export default EditManager;