/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name SUPPLIERS */

SUPPLIERS = gql`query

    fetchSuppliers (

        $name: String,

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminVendors (

            name: $name

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

        ) {

            edges {

                node {

                    addPvn

                    name

                    createdAt

                    deleted

                    hashId

                    id

                    updatedAt

                    visible

                    markup

                    markupGroupA

                    markupGroupB

                    markupGroupC
                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name SUPPLIER_PREVIEW */

SUPPLIER_PREVIEW = gql`query fetchSupplierPreview ( $id: ID! ) {

    adminVendor ( id: $id ) {

        id

        name

        visible

        addPvn

        createdAt

        updatedAt

        markup

    }

}`,

/** @name SUPPLIER */

SUPPLIER = gql`query fetchSupplier ( $id: ID! ) {

    adminVendor ( id: $id ) {

        addPvn

        createdAt

        deleted

        hashId

        id

        updatedAt

        visible

        name

        markup

    }

}`

/** @exports Queries */

export {
    SUPPLIERS,
    SUPPLIER,
    SUPPLIER_PREVIEW
};

export default {
    SUPPLIERS,
};
