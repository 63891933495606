import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Tabs from "../../Components/Dashboard/Global/Tabs";

import Placeholder from "../../Components/Dashboard/Placeholder";

const StorefrontOrders = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Orders page */

    return ( <section className="orders">

        <Breadcrumbs data={ [

            { name: "Orders", uri: "/app/orders/orders" }

        ] } />

        <Tabs data={ [

            { name: "Orders", uri: "/app/orders/orders" },

            { name: "Offers", uri: "/app/orders/offers" }

        ] } />

        <Placeholder text="Placeholder for 'Orders' end-points." />

        <div>Orders Storefront</div>

    </section> );

}

/** @exports StorefrontOrders */
 
export default StorefrontOrders;