import { useRef } from "react";

const Tooltip = ( { target, content } ) => { const 

    element = useRef ( null );

    if ( target.current ) {

        // document.addEventListener('mousemove', (e) => {

        //     element.current.style.left = e.pageX - ( element.current.offsetWidth / 2 ) + 'px';

        //     element.current.style.top = e.pageY - element.current.offsetHeight - 16 + 'px';

        // }, false);

        target.current.addEventListener ( "mouseenter", () => {

            document.addEventListener('mousemove', watch, false);

            element.current.style.display = "inline-block";

        } );

        function watch (e) {

            element.current.style.left = e.pageX - ( element.current.offsetWidth / 2 ) + 'px';

            element.current.style.top = e.pageY - element.current.offsetHeight - 16 + 'px';
    
        }

        target.current.addEventListener ( "mouseleave", () => {

            element.current.style.display = "none";

            document.removeEventListener ( "mousemove", watch );

        } );

    }

    /** @return component */

    return ( <div className="tooltip" ref={ element }>

        { content }

    </div> );

}
 
/** @export component */

export default Tooltip;