// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VehicleType_container__rd6\\+a {\n\n    display: flex;\n    \n    flex-direction: column;\n    \n    flex-wrap: wrap;\n    \n    max-height: 140px;\n    \n    gap: 0.5rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Filters/Elements/VehicleType.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,sBAAsB;;IAEtB,eAAe;;IAEf,iBAAiB;;IAEjB,WAAW;;AAEf","sourcesContent":[".container {\n\n    display: flex;\n    \n    flex-direction: column;\n    \n    flex-wrap: wrap;\n    \n    max-height: 140px;\n    \n    gap: 0.5rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VehicleType_container__rd6+a"
};
export default ___CSS_LOADER_EXPORT___;
