import React, { useState } from "react";

import { IoClose } from "react-icons/io5";
import { IoFilterSharp } from "react-icons/io5";

import { useTranslation } from "react-i18next";

import ButtonDropdown from "./../../../Components/Common/ButtonDropdown/index.js";
import Checkbox from "./../../../Components/Common/Forms/checkbox/index.js";
import Button from "./../../../Components/Common/Button/index.js";

import Columns from "./../columns.jsx";

import styles from "./styles.module.css";


const COLUMNS = Columns.getColumns();

const TableClientsFilters = (props) => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const [selected, setSelected] = useState(() => props.applied);

    const { t } = useTranslation();

    /* --- */

    const toggleOption = (name, apply = false) => {
        const options = selected.indexOf(name) === -1
            ? [...selected].concat(name)
            : [...selected].filter((n) => n !== name);

        setSelected(options);

        if (apply) {
            props.onApply(options);
        }
    };

    const onCancel = () => {
        setSelected(props.applied);
        setIsVisibleMenu(false);
    };

    const onToggleMenu = (isVisible) => {
        if (!isVisible) {
            onCancel();
            return;
        }

        setIsVisibleMenu(true);
    };

    const onApply = () => {
        props.onApply(selected);
        setIsVisibleMenu(false);
    };

    /* --- */

    const renderAppliedOptions = () => {
        const { applied } = props;

        const options = [];

        if (applied.length) {
            options.push(
                <Button
                    key="filters-reset-all"
                    onClick={() => {
                        setSelected([]);
                        props.onApply([]);
                    }}
                >
                    Reset All
                </Button>,
            );
        }

        for (let i = 0; i < applied.length; i += 1) {
            const name = applied[i];
            const label = Columns.getColumnLabelByName(name);

            options.push(
                <Button
                    key={`filters-applied-${name}`}
                    iconAfter={{
                        icon: IoClose,
                        onClick: () => {
                            toggleOption(name, true);
                        },
                        isClickable: true,
                    }}
                >
                    {label}
                </Button>,
            );
        }

        return options;
    };

    const renderFilterOptions = () => {
        const options = COLUMNS.map((opt) => {
            const name = `filter-option-${opt.field}`;

            return (
                <Checkbox
                    key={name}
                    name={name}
                    label={opt.label}
                    onChange={() => {
                        toggleOption(opt.field, false);
                    }}
                    isSelected={selected.indexOf(opt.field) !== -1}
                />
            );
        });

        return (
            <div className={styles.filtersOptions}>
                {options}
                <div className={styles.filtersControls}>
                    <Button
                        onClick={onApply}
                        isBlue
                    >
                        {t("apply")}
                    </Button>
                    <Button
                        onClick={onCancel}
                        isGrey
                    >
                        {t("cancel")}
                    </Button>
                </div>
            </div>
        );
    };

    /* --- */

    return (
        <div className={styles.filters}>
            <ButtonDropdown
                icon={IoFilterSharp}
                label={t("columns")}
                onToggleMenu={onToggleMenu}
                isVisibleMenu={isVisibleMenu}
                isBlue
            >
                {renderFilterOptions()}
            </ButtonDropdown>
            {renderAppliedOptions()}
        </div>
    );
};

TableClientsFilters.defaultProps = {
    applied: [],
    onApply: () => { },
};

export default TableClientsFilters;
