// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__ztv51 {}\n\n.styles_input__BOYxm {\n    display: none;\n}\n\n.styles_button__Kp5Lg {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 10px;\n\n    align-items: center;\n\n    height: 50px;\n    padding: 0 30px;\n\n    border-radius: 10px;\n    border: 1px solid white;\n\n    -webkit-user-select: none;\n\n            user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/FileUploader/styles.module.css"],"names":[],"mappings":"AAAA,0BAAY;;AAEZ;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,cAAc;;IAEd,mBAAmB;;IAEnB,YAAY;IACZ,eAAe;;IAEf,mBAAmB;IACnB,uBAAuB;;IAEvB,yBAAiB;;YAAjB,iBAAiB;;IAEjB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".container {}\n\n.input {\n    display: none;\n}\n\n.button {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 10px;\n\n    align-items: center;\n\n    height: 50px;\n    padding: 0 30px;\n\n    border-radius: 10px;\n    border: 1px solid white;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__ztv51",
	"input": "styles_input__BOYxm",
	"button": "styles_button__Kp5Lg"
};
export default ___CSS_LOADER_EXPORT___;
