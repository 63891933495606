/** @module                     React-Icons */

import { BsTrash }              from "react-icons/bs";

const 

/**
 * 
 * @param { Number } id
 * 
 * @returns product table delete product component
 * 
 */

Delete = ( { id } ) => { const

    /** @returns delete product */

    deleteProduct = () => { return; };

    /** @returns */

    return ( <button className="delete" onClick={ deleteProduct }>
        
        <BsTrash />
        
    </button> );

};

/** @exports Delete */
 
export default Delete;