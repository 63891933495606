const 

/**
 * 
 * @param {Object} data
 * 
 * @returns {OrderAdminNotes}
 * 
 * @example <OrderAdminNotes data={ data } />
 * 
 */

OrderAdminNotes = ( { data } ) => { const 

    /** @event previewNote */

    previewNote = () => {

        return;

    }

    /** @returns OrderAdminNotes */

    return (

        <td data-title="adminNotes" className="order_admin_notes">

            { data.adminNotes == null ? "-" : 

                data.adminNotes.length > 32 ? 

                    <span onClick={ previewNote }>

                        <div className="extension">

                            { data.adminNotes }

                        </div>

                        { data.adminNotes.substring ( 0, 32 ) + "..." }

                    </span>

                :

                    data.adminNotes

            }

        </td>
    );

}

/** @exports OrderAdminNotes */
 
export default OrderAdminNotes;