import { gql } from "@apollo/client";

/**
 * 
 * @returns product preview query
 * 
 * @param { id }
 * 
 * @query productPreview 
 * 
 */

const PRODUCT_PREVIEW = gql`

    query productPreview ( $id: ID!  ) {

        adminProduct ( id: $id ) {

            productType

            id

            currentImage

            ean

            model

            manufacturer {

                id

                name

            }

            width

            height

            diameter

            vehicleType

            speedRating

            season

            loadIndex

            noiseIndex

            fuelEfficiency

            wetBreakingEfficiency

            runFlat

            studs

            year

            current

            voltage

            capacity

            productVendors {

                id
        
                price
        
                productId
        
                qty
        
                qtyDays
        
                qtyHour
        
                qtyWeeks
        
                vendorId
        
            }

            specifications

            description

            pitchCircleDiameter

            offset

            createdAt

            updatedAt

        }

    }

`;

const PRODUCT_PREVIEW_VENDOR = gql`

    query productPreviewVendor ( $id: ID! ) {

        adminVendor ( id: $id ) {

            name

        }

    }

`;

export { PRODUCT_PREVIEW, PRODUCT_PREVIEW_VENDOR }