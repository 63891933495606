import { gql } from '@apollo/client';

const 

/** @description run import */

CREATE_IMPORT = gql`mutation createImport (

    $runnerId: Int!
) {

    adminCreateImport (

        input: {

            importInput: {

                runnerId: $runnerId,

            }

        }

    ) {

        result

    }

}`,

/** @description update import */

UPDATE_IMPORT = gql`mutation updateImport (

    $id: ID!

    $currentStatus: String

    $interval: Int

) {

    adminUpdateImport (

        input: {

            id: $id

            importInput: {

                currentStatus: $currentStatus

                interval: $interval

            }

        }

    ) {

        import

    }

}`



/** @export mutations */

export { 
    CREATE_IMPORT,
    UPDATE_IMPORT
}