import { useState, useEffect } from "react";

/**
 * 
 * @exports FilterText
 * 
 * @description This component renders a text input to filter the product collection
 * 
 * @param { Object } props
 * 
 * @param { Function } props.refetch The refetch function to refetch the product collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the product collection
 * 
 * @param { string } props.name The name of the variable to be filtered
 * 
 * @param { string } props.parse The type of the variable to be filtered
 * 
 * @returns { JSX.Element } FilterText
 * 
 */

export default function FilterText ( { refetch, variables, name, parse, placeholder = "", loader } ) { const 

    [ value, setValue ] = useState ( variables [ name ] || "" );

    /**
     * 
     * @event useEffect
     * 
     * @returns refetch the product collection after 1 second of inactivity
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && value === "" ) return;

        if ( variables [ name ] == value ) return;

        const timer = setTimeout( () => {

            refetch ( 

                { 
                
                    [ name ] : value ? parse === "number" ? parseInt ( value ) : parse === "float" ? parseFloat ( value ) : value : undefined,
    
                    first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,
    
                    last: undefined,
    
                    before: undefined,
    
                    after: undefined
                
                }
                
            );
        
        }, 1000 );
      
        return () => clearTimeout ( timer );

    }, [ value ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set the value of the input to the value of the variable in the variables object or an empty string if it is not found
     * 
     */

    useEffect ( () => {

        setValue ( variables [ name ] || "" );

    }, [ variables ] );

    /** @returns { JSX.Elements } FilterText */

    return (

        <div className="input-group default">

            <input 
            
                type={ parse === "number" ? "number" : parse === "float" ? "number" : parse === "date" ? "date" : "text" } 

                style={ { height: "38px" } }
            
                value={ value } 
            
                name={ name } 

                placeholder={ placeholder }

                disabled={ loader }
            
                onChange={ e => setValue ( e.target.value ) } 
                
            />

        </div>

    );

};