// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__jaRB2 {\n    position: relative;\n}\n\n/* --- */\n\n.styles_menu__QFXo- {\n    position: absolute;\n    top: calc(100% + 10px);\n    left: 0;\n\n    background-color: var(--secondary-color);\n    padding: 20px 30px;\n\n    border-radius: 10px;\n\n    z-index: var(--z-index-button-dropdown-menu);\n}\n\n/* --- */\n\n.styles_icon__Awess {\n    width: 20px;\n    height: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/ButtonDropdown/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA,QAAQ;;AAER;IACI,kBAAkB;IAClB,sBAAsB;IACtB,OAAO;;IAEP,wCAAwC;IACxC,kBAAkB;;IAElB,mBAAmB;;IAEnB,4CAA4C;AAChD;;AAEA,QAAQ;;AAER;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    position: relative;\n}\n\n/* --- */\n\n.menu {\n    position: absolute;\n    top: calc(100% + 10px);\n    left: 0;\n\n    background-color: var(--secondary-color);\n    padding: 20px 30px;\n\n    border-radius: 10px;\n\n    z-index: var(--z-index-button-dropdown-menu);\n}\n\n/* --- */\n\n.icon {\n    width: 20px;\n    height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__jaRB2",
	"menu": "styles_menu__QFXo-",
	"icon": "styles_icon__Awess"
};
export default ___CSS_LOADER_EXPORT___;
