import                      "../Styles/Authorization.css";

/** @module                 React */

import { 
    
    forwardRef, 
    
    useId, 
    
    useState, 
    
    useRef }                from "react";

import useLogin             from "../Hooks/Authorization/useLogin";

/** @module                 Router */

import { Navigate }         from "react-router-dom";

/** @module                 Contexts */

import useAuthorization     from "../Hooks/Authorization/useAuthorization";

/** @module                 i18n */

import { useTranslation }   from "react-i18next";

/** @module                 Components */

import LoadPage             from "../Components/Misc/LoadPage";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns authorization page component
 * 
 */

Authorization = ( { name } ) => { const

    { t } = useTranslation (),

    /** contexts */

    { user, pending } = useAuthorization (),

    /** element references */

    usernameRef = useRef ( null ),

    passwordRef = useRef ( null ),

    buttonRef   = useRef ( null ),

    /** register states */

    [ username, setUsername ] = useState ( "" ),

    [ password, setPassword ] = useState ( "" ),

    { login, loading, errors } = useLogin (),

    /**
     * 
     * @param { Event } event
     * 
     * @returns authorization form onSubmit event controller
     * 
     */

    loginController = event => { event.preventDefault ();

        /** @validation */

        login ( username, password );

    };

    /** @returns */

    return ( ( pending ) ? <LoadPage /> : ( user ) ? <Navigate to="/app" /> : 
    
    <div className={ name }>

        <form onSubmit={ e => loginController ( e ) }>

            { errors && <div className="error">

                { t ( "invalid_auth" ) }

            </div> }

            <AuthInput 
            
                text={ t ( "username" ) } 
            
                type="text" 
            
                value={ username } 
            
                action={ setUsername } 
            
                ref={ usernameRef }
                
                disabled={ loading } />

            <AuthInput 
            
                text={ t ( "password" ) } 
            
                type="password" 
            
                value={ password } 
            
                action={ setPassword } 
            
                ref={ passwordRef }
                
                disabled={ loading } />

            <button ref={ buttonRef } disabled={ loading }>
                
                { loading ? t ( "loading" ) : t ( "authorize" ) }
                
            </button>

        </form>

    </div> );

},

/**
 * 
 * @param { String } text
 * 
 * @param { String } type
 * 
 * @param { State } value
 * 
 * @param { SetStateAction } action
 * 
 * @param { RefObject } ref
 * 
 * @returns authorization input component
 * 
 */

AuthInput = forwardRef ( ( { 
    
        text = "Unknown", type = "text", value = "", action = () => {} 

    }, ref ) => { const ID = useId ();

    /** @returns */

    return ( <div className="input-group">

        <label htmlFor={ ID }>
            
            { text }
            
        </label>

        <input 
        
            type={ type } 
        
            defaultValue={ value } 
        
            onChange={ e => action ( e.target.value ) } 
        
            id={ ID } ref={ ref } />

    </div> ) 

} );

/** @exports Authorization */
 
export default Authorization;