import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const DirectSuppliers = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Direct_Suppliers page */

    return ( <section className="direct-suppliers">

        DirectSuppliers page

    </section> );

}

/** @exports DirectSuppliers */
 
export default DirectSuppliers;