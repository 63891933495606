import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { useRef } from "react";

import { sortData } from "../../../Products/Collection/Filters/Utilities";

/**
 * 
 * @exports FilterSelect
 * 
 * @description This component renders a select input for filtering the tire collection 
 * 
 * @param { Object } props
 * 
 * @param { Array } props.data The data to be used to render the select options 
 * 
 * @param { String } props.parse The type of data to parse the value to before setting it to the state 
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection 
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection 
 * 
 * @param { String } props.name The name of the variable to be used to refetch the tire collection
 * 
 * @param { Boolean } props.textValue The value to be used as the text value for the select options 
 * 
 * @param { String } props.label The label to be used for the select input 
 * 
 * @returns { JSX.Element } FilterSelect 
 * 
 */

export function FilterSelect ( { data, parse = false, refetch, variables, name, textValue = false, label = false, loader } ) { const 

    { t } = useTranslation (),

    [ value, setValue ] = useState ( variables[ name ] ),

    /**
     * 
     * @function handleChange
     * 
     * @param { Object } e The event object
     * 
     * @returns set the value to the state
     * 
     */

    handleChange = e => {

        e.target.value === "reset" ? setValue ( "" ) : 
        
        setValue ( parse === "number" ? parseInt ( e.target.value ) : parse === "float" ? parseFloat ( e.target.value ) : e.target.value );

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined 
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && value === "reset" ) return;

        if ( variables [ name ] == value ) return;

        refetch ( 

            { 
            
                [ name ] : value ? value : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ value ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setValue ( "" );
        
        }

    }, [ variables [ name ] ] );

    /** @returns { JSX.Element } FilterSelected */

    return (

        <div className={ `input-group default` }>

            <select onChange={ handleChange } value={ value } disabled={ loader }>

                <option value="reset">
                    
                    { label ? t ( label ) : t ( name ) }
                    
                </option>

                { /** Map the data to render the select options */
                
                    sortData ( data, textValue ).map ( ( item, index ) => 
                    
                        <option key={ index } value={ textValue ? item.text : item.value }>
                            
                            { item.text }
                            
                        </option> 
                        
                    ) 
                    
                }

            </select>

        </div>

    );

};

/**
 * 
 * @exports FilterSelectEnum
 * 
 * @description This component renders a select input for filtering the tire collection 
 * 
 * @param { Object } props
 * 
 * @param { Array } props.data The data to be used to render the select options 
 * 
 * @param { String } props.parse The type of data to parse the value to before setting it to the state 
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection 
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection 
 * 
 * @param { String } props.name The name of the variable to be used to refetch the tire collection
 * 
 * @param { Boolean } props.textValue The value to be used as the text value for the select options 
 * 
 * @param { String } props.label The label to be used for the select input 
 * 
 * @returns { JSX.Element } FilterSelectEnum 
 * 
 */

export function FilterSelectEnum ( { parse = false, refetch, variables, name, query, label, loader } ) { const 

    { t } = useTranslation (),

    { data, loading } = useQuery ( query ),

    select = useRef ( null ),

    [ disabledList, setDisabledList ] = useState ( variables [ name ] || "" ),

    /**
     * 
     * @function handleChange
     * 
     * @param { Object } e The event object
     * 
     * @returns set the value to the state
     * 
     */

    handleChange = e => {

        e.target.value === "reset" ? setDisabledList ( "" ) : 
        
            setDisabledList ( parse === "number" ? parseInt ( e.target.value ) : parse === "float" ? parseFloat ( e.target.value ) : e.target.value );

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns refetch the tire collection if the disabled list changes 
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && select.current.value === "reset" ) return;

        if ( variables [ name ] == disabledList ) return;

        refetch ( 

            { 
            
                [ name ] : disabledList.length ? disabledList : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ disabledList ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setDisabledList ( "" ); 
            
            select.current.value = "reset"; 
        
        }

    }, [ variables [ name ] ] );

    /** @returns { JSX.Element } FilterMultipleEnum */

    return (

        <div className={ `input-group default` }>

            <select onChange={ handleChange } ref={ select } disabled={ loader }>

                <option value="reset">

                    { label ? t ( label ) : t ( name ) }

                </option>

                { ! loading  &&
                
                    data.__type.enumValues.map ( ( item, index ) => 
                
                        <option key={ index } value={ item.name } disabled={ loader }>
                            
                            { t ( item.name ) }
                            
                        </option> 
                
                    ) 
                
                }

            </select>

        </div>

    );

};