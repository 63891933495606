/** @module                 React-Icons */

import { 
    
    BiChevronRight, 
    
    BiChevronLeft,

    BiChevronsLeft }        from "react-icons/bi";

const 

/**
 * 
 * @param { Object } pageinfo
 * 
 * @param { Function } action
 * 
 * @returns product table pagination
 * 
 */

Pagination = ( { pageinfo, action } ) => { const 

    /** @returns refetch for the first product catalog page */

    first = () => action ( 
        
        { 
        
            first   : JSON.parse ( localStorage.getItem ( "productsCollectionCount" ) ) || 12, 
            
            last    : undefined, 
            
            before  : undefined, 
            
            after   : undefined 
    
        } 
        
    ),  

    /** @returns refetch for the next product catalog page */

    next = () => action ( 
        
        { 
        
            first   : JSON.parse ( localStorage.getItem ( "productsCollectionCount" ) ) || 12, 
            
            last    : undefined, 
            
            before  : undefined, 
            
            after   : pageinfo.endCursor 
    
        } 
        
    ),

    /** @returns refetch for the previous product catalog page */

    previous = () => action ( 
        
        { 
        
            first   : undefined, 
            
            last    : JSON.parse ( localStorage.getItem ( "productsCollectionCount" ) ) || 12, 
            
            before  : pageinfo.startCursor, 
            
            after   : undefined 
    
        } 
        
    );

    /** @returns */

    return ( <div className="pagination">

        { pageinfo.hasPreviousPage &&
        
            <button 
            
                onClick={ first }>

                    <BiChevronsLeft />

            </button> 
            
        }

        <button 
        
            onClick={ previous } 
        
            disabled={ ! pageinfo.hasPreviousPage }>

                <BiChevronLeft />

        </button>

        <button 
        
            onClick={ next } 
        
            disabled={ ! pageinfo.hasNextPage }>

                <BiChevronRight />

        </button>

    </div> );

}

/** @exports Pagination */
 
export default Pagination;