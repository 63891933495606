/** @module                         Hooks */

import { 
    
    Fragment, 
    
    forwardRef, 
    
    useId }                         from "react";

import { useTranslation }           from "react-i18next";

const 

Button = forwardRef ( ( { 

    autofocus           = false,

    disabled            = false,

    form                = "",

    formaction          = "",

    formenctype         = "application/x-www-form-urlencoded",

    formmethod          = "",

    formnovalidate      = "",

    formtarget          = "_self",

    icon                = false,

    name                = "",

    type                = "button",

    value               = "",

    text                = "Button",

    action              = () => { return }

}, ref ) => { const generateId = useId (), { t } = useTranslation ();

    /** @returns */

    return (

        <button 

            autoFocus={ autofocus }

            form={ form }

            formAction={ formaction }

            formEncType={ formenctype }

            formMethod={ formmethod }

            formNoValidate={ formnovalidate }

            formTarget={ formtarget }

            name={ ( ! name ) ? generateId : name }

            type={ type }

            value={ value }

            onClick={ action }

            disabled={ disabled }
        
            ref={ ref }
            
        >

            { ( icon ) ? 
            
                    <Fragment>

                        { icon }

                        <span>

                            { t ( text ) }
                            
                        </span>

                    </Fragment> 
                
                : 
                
                    t ( text ) 
            
            }

        </button>

    );

} );
 
/** @exports Button */

export default Button;