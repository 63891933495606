import React from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import Input from "./../../Components/Common/Forms/input/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";

import styles from "./styles.module.css";

const PREFIX = "companyInfo";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    companyName: getName("CompanyName"),
    registrationName: getName("RegistrationName"),
    registrationNumber: getName("RegistrationNumber"),
    VATRegistrationNumber: getName("VATRegistrationNumber"),
    homepage: getName("Homepage"),
};

const INITIAL_VALUES = {
    [FIELDS.companyName]: "",
    [FIELDS.registrationNumber]: "",
    [FIELDS.registrationName]: "",
    [FIELDS.VATRegistrationNumber]: "",
    [FIELDS.homepage]: "",
};

/* --- */

const FormClientEditCompanyInfo = (props) => {
    const { t } = useTranslation();

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const renderForm = (formMethods) => {
        const {
            handleSubmit,
            values,
            handleChange,
            handleBlur,
        } = formMethods;

        const {
            companyName,
            registrationName,
            registrationNumber,
            VATRegistrationNumber,
            homepage,
        } = FIELDS;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <div className={styles.header}>
                    <ButtonUnderline
                        label="Company Information"
                    />
                </div>
                <Input
                    name={companyName}
                    label={t("company_name")}
                    placeholder="......"
                    value={values[companyName]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />

                <Input
                    name={registrationNumber}
                    label={t("registration_number")}
                    placeholder="......"
                    value={values[registrationNumber]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
                
                <Input
                    name={VATRegistrationNumber}
                    label={t("VAT_registration_number")}
                    placeholder="......"
                    value={values[VATRegistrationNumber]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
                <Input
                    name={homepage}
                    label={t("homepage")}
                    placeholder="......"
                    value={values[homepage]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            innerRef={props.formRef}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditCompanyInfo.defaultProps = {
    formRef: null,
    initialValues: INITIAL_VALUES,
    onSubmit: () => { },
    isEditable: false,
};

export {
    PREFIX,
    INITIAL_VALUES,
    FIELDS,
};

export default FormClientEditCompanyInfo;
