/** @module                 React */

import { useState }         from "react";

/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { CREATE_USER }      from "../../Mutations/Managers";

const 

/** 
 * 
 * @returns useCreateManager hook for creating a new manager
 * 
 */

useCreateManager = () => { const navigate = useNavigate (),

    /** @hooks */

    [ loading,  setLoading  ] = useState ( false ),

    [ errors,   setErrors   ] = useState ( false ),

    /** @mutations */

    [ CreateUser ] = useMutation ( CREATE_USER, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            return navigate ( `/app/managers/edit/${ data.adminCreateUser.user.id }` );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { String } title
     * 
     * @param { String } content
     * 
     * @param { Boolean } isActive
     * 
     * @param { Boolean } tiremarketOnly
     * 
     * @param { String } language
     * 
     * @returns create service action
     * 
     */

    createManager = ( email, firstName, lastName, password, passwordConfirmation, role, blocked, blockedReason ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true );

        if ( ! email || ! role || ! password || ! passwordConfirmation ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        if ( blocked && ! blockedReason ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        if ( password !== passwordConfirmation ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        }

        /** @mutation */

        CreateUser ( { variables: {

            email,

            password,

            passwordConfirmation,

            firstName,

            lastName,

            role,

            blocked,

            blockedReason
    
        } } );

    }

    /** @returns */

    return { createManager, loading, errors }

};

/** @exports useCreateManager */

export default useCreateManager;