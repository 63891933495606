import { NavLink, Link }          from "react-router-dom";

import Navigation from "../../Datasets/Aside";

import Submenu from "./Submenu";

const Aside = () => { const 

    Item = ( { data } ) => {

        switch ( data.type ) {

            case 1: return <Submenu nav={ data } />;

            default: return  <NavLink to={ data.URI } className={({ isActive }) => (isActive ? 'active' : 'inactive')} end>

                <span>{ data.icon }</span>

                { data.name }

            </NavLink>;

        }

    },

    Nav = ( { navigation } ) => {

        return navigation.map ( ( nav, index ) => <div className="item" key={ index }>

            <Item data={ nav } />

        </div> )

    };

    /** @return Aside page */

    return ( <aside>

        <div className="logotype">

            <Link to="/app/dashboard">
            
                <h3>AP Tireshop</h3>
            
            </Link>

        </div>

        <div className="navigation">

            <Nav navigation={ Navigation () } />

        </div>

    </aside> );

}

/** @exports Aside */
 
export default Aside;