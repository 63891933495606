import { useEffect, useRef, useState } from "react";

import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import { sortData } from "../Utilities";

/**
 * 
 * @exports FilterMultiple
 * 
 * @description This component renders a multiple select input for filtering the tire collection
 * 
 * @param { Object } props 
 * 
 * @param { Array } props.data The data to be used to render the select options
 * 
 * @param { String } props.parse The type of data to parse the value to before setting it to the state
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection
 * 
 * @param { String } props.name The name of the variable to be used to refetch the tire collection
 * 
 * @param { Boolean } props.textValue The value to be used as the text value for the select options
 * 
 * @returns { JSX.Element } FilterMultiple
 * 
 */

export function FilterMultiple ( { data, parse = false, refetch, variables, name, textValue = false, loader } ) { const 

    { t } = useTranslation (),

    select = useRef ( null ),

    [ disabledList, setDisabledList ] = useState ( variables [ name ] || [] ),

    /**
     * 
     * @function handleChange
     * 
     * @param { Object } e The event object
     * 
     * @returns set the value to the state
     * 
     */

    handleChange = e => {

        e.target.value === "reset" ? setDisabledList ( [] ) : 
        
        setDisabledList ( [...disabledList, 
            
            parse === "number" ? parseInt ( e.target.value ) : parse === "float" ? parseFloat ( e.target.value ) : e.target.value 
        
        ] );

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns refetch the tire collection if the disabled list changes
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && select.current.value === "reset" ) return;

        if ( variables [ name ] == disabledList ) return;

        refetch ( 

            { 
            
                [ name ] : disabledList.length ? disabledList : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ disabledList ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setDisabledList ( [] ); 
            
            select.current.value = "reset"; 
        
        }

    }, [ variables [ name ] ] );

    /** @returns { JSX.Element } FilterMultiple */

    return (

        <div className={ `input-group default` }>

            <select onChange={ handleChange } ref={ select } disabled={ loader }>

                <option value="reset">{ t ( name ) }</option>

                { 
                
                    sortData ( data, textValue ).map ( 
                        
                        ( item, index ) => 
                    
                            <option 
                            
                                key={ index } 
                                
                                value={ textValue ? item?.text : item?.value } 
                                
                                disabled={ disabledList.find ( ( disabledItem ) => disabledItem == ( textValue ? item?.text : item?.value ) ) }>
                                
                                    { item?.text?.toString ().charAt ( 0 ).toUpperCase () + item?.text?.toString ().slice ( 1 ) }
                                
                            </option> 
                        
                    ) 
                    
                }

            </select>

        </div>

    );

};

/**
 * 
 * @exports FilterMultipleEnum
 * 
 * @description This component renders a multiple select input for filtering the tire collection
 * 
 * @param { Object } props
 * 
 * @param { String } props.parse The type of data to parse the value to before setting it to the state
 * 
 * @param { Function } props.refetch The refetch function to refetch the tire collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the tire collection
 * 
 * @param { String } props.name The name of the variable to be used to refetch the tire collection
 * 
 * @param { Boolean } props.textValue The value to be used as the text value for the select options
 * 
 * @param { String } props.query The query to be used to fetch the enum values
 * 
 * @returns { JSX.Element } FilterMultipleEnum
 * 
 */

export function FilterMultipleEnum ( { parse = false, refetch, variables, name, query, loader } ) { const 

    { t } = useTranslation (),

    { data, loading } = useQuery ( query ),

    select = useRef ( null ),

    [ disabledList, setDisabledList ] = useState ( variables [ name ] || [] ),

    /**
     * 
     * @function handleChange
     * 
     * @param { Object } e The event object
     * 
     * @returns set the value to the state
     * 
     */

    handleChange = e => {

        e.target.value === "reset" ? setDisabledList ( [] ) : 
        
            setDisabledList ( [...disabledList, 
                
                parse === "number" ? parseInt ( e.target.value ) : parse === "float" ? parseFloat ( e.target.value ) : e.target.value 
            
            ] );

    };

    /**
     * 
     * @event useEffect
     * 
     * @returns refetch the tire collection if the disabled list changes 
     * 
     */

    useEffect ( () => {

        if ( ! variables [ name ] && select.current.value === "reset" ) return;

        if ( variables [ name ] == disabledList ) return;

        refetch ( 

            { 
            
                [ name ] : disabledList.length ? disabledList : undefined,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            }
            
        );

    }, [ disabledList ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set value to empty string if variable is undefined
     * 
     */

    useEffect ( () => {
       
        if ( ! variables[ name ] ) { 
            
            setDisabledList ( [] ); 
            
            select.current.value = "reset"; 
        
        }

    }, [ variables [ name ] ] );

    /** @returns { JSX.Element } FilterMultipleEnum */

    return (

        <div className={ `input-group default` }>

            <select onChange={ handleChange } ref={ select } disabled={ loader }>

                <option value="reset">{ t ( name ) }</option>

                { ! loading  &&
                
                    data.__type.enumValues.map ( ( item, index ) => 
                
                        <option key={ index } value={ item.name } disabled={ disabledList.find ( ( disabledItem ) => disabledItem == item.name ) }>
                            
                            { t ( item.name ) }
                            
                        </option> 
                
                    ) 
                
                }

            </select>

        </div>

    );

};