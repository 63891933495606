import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PagenameContext } from "../../Contexts/Pagename";

import date from "../../Lib/date.js";
import useFetch from "./../../Hooks/useFetch.js";

import QueriesAdminManagers from "./../../Queries/AdminManagers.js";
import QueriesAdminClients from "./../../Queries/AdminClients.js";

import { IoIosAddCircleOutline } from "react-icons/io";

import storage from "./../../Storage/index.js";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";
import Button from "../../Components/Common/Button/index.js";
import Select from "../../Components/Common/Select/index.js";

import ClientsTypeFilter from "./../../Business/client-type-filter/index.js";
import TableClients, {
    useTableClients,
} from "./../../Business/table-clients/index.js";

import styles from "./styles.module.css";

const Clients = (props) => {
    const DEFAULT_FILTERS = storage.local.getClientsTableFilters();

    /* --- */

    const [isClientsReloading, setClientsIsReloading] = useState(false);

    const [selectedClientsType, setSelectedClientsType] = useState([]);

    /* --- */

    const pageContext = useContext(PagenameContext);

    const tableClients = useTableClients(DEFAULT_FILTERS);

    const navigate = useNavigate();

    const fetchManagers = useFetch(QueriesAdminManagers.FETCH_ADMIN_MANAGERS, {});
    const fetchClients = useFetch(QueriesAdminClients.FETCH_ADMIN_CLIENTS, {
        items: tableClients.DEFAULT.ENTRIES,
        page: tableClients.DEFAULT.PAGE,
    });

    const [selectedManagerId, setSelectedManagerId] = useState(null);

    /* --- */

    const getClients = () => {
        const items = fetchClients?.data?.adminClients?.items || [];

        return items.map((client) => {
            let createdAt = client.createdAt || "";

            if (createdAt) {
                createdAt = date.tryFormatDate(createdAt, date.formatDayMonthYearHoursMinutes);
            }

            return {
                id: client.id || null,
                companyName: client.companyName || "",
                regNr: client.regNr || "",
                name: client.fullName || "",
                type: client.clientType || "",
                businessSphere: client.businessSphere || "",
                discountGroup: client.discountGroup || "",
                contactName: client.primaryContact || "",
                phone: client.mobilePhoneNr || "",
                email: client.email || "",
                manager: client.manager || "",
                newsletter: client.theNewsCommunication.toString() || "",
                isEnabled: client.isEnabled?.toString() || false.toString(),
                registrationDate: createdAt,
                homepage: client.homepage || "",
                B2BPortal: client.B2BPortal || "",
            };
        });
    };

    const getDefaultFetchClientVariables = () => {
        return {
            items: tableClients.state.entries.selected,
            page: tableClients.state.page,
            managerId: selectedManagerId || undefined,
            search: tableClients.state.search || undefined,
            sort: tableClients.state.sort || undefined,
            clientType: selectedClientsType.join(",") || undefined,
        };
    };

    /* --- */

    const refetchClients = async (params = {}) => {
        const defaultVariables = getDefaultFetchClientVariables();

        setClientsIsReloading(true);

        try {
            await fetchClients.refetch({
                ...defaultVariables,
                ...params,
            });
        } catch (e) {
            console.error("failed to refetch clients", e);
        }

        setClientsIsReloading(false);
    };

    /* --- */

    const onSelectPage = (page) => {
        tableClients.setPage(page);

        refetchClients({
            page,
        });
    };

    const onSearchClients = (search) => {
        tableClients.setSearch(search);
        tableClients.setPage(1);

        refetchClients({
            search,
            page: 1,
        });
    };

    const onSelectEntries = (items) => {
        tableClients.setSelectedEntries(items);

        const page = 1;
        tableClients.setPage(page);

        refetchClients({
            items,
            page,
        });
    };

    const onSelectManager = (managerId) => {
        setSelectedManagerId(managerId);

        const page = 1;
        tableClients.setPage(page);

        refetchClients({
            managerId,
            page,
        });
    };

    const onSort = (sort) => {
        tableClients.setSelectedSort(sort);

        const page = 1;
        tableClients.setPage(page);

        refetchClients({
            sort,
            page,
        });
    };

    /* --- */

    const toggleClientType = (type) => {
        const types = selectedClientsType.indexOf(type) === -1
            ? [...selectedClientsType].concat(type)
            : [...selectedClientsType].filter((n) => n !== type);

        setSelectedClientsType(types);

        refetchClients({
            clientType: types.join(",") || undefined,
        });
    };

    const onApplyFilters = (filters) => {
        storage.local.saveClientsTableFilters(filters);
        tableClients.applyFilters(filters);
    };

    const onEditClient = (id) => {
        if (!id) {
            return;
        }

        navigate(`/app/clients/edit/${id}`);
    };

    const onPreviewClient = (id) => {
        if (!id) {
            return;
        }

        navigate(`/app/clients/preview/${id}`);
    };

    const onAddNewClient = () => {
        navigate("/app/clients/add");
    };

    /* --- */

    useEffect(() => {
        pageContext.setPagename(props.name);
    }, []);

    /* --- */

    const renderControls = () => {
        const managerOptions = (fetchManagers?.data?.adminManagers || []).map((manager) => ({
            name: manager.id,
            label: manager.fullName,
        }));

        return (
            <div className={styles.controls}>
                <ClientsTypeFilter
                    selected={selectedClientsType}
                    onToggle={toggleClientType}
                />
                <div className={styles.controlsButtons}>
                    <Button
                        icon={IoIosAddCircleOutline}
                        onClick={onAddNewClient}
                        isBlue
                    >
                        Add new client
                    </Button>
                    <Select
                        options={managerOptions}
                        selected={selectedManagerId}
                        onSelect={onSelectManager}
                    />
                </div>
            </div>
        );
    };

    const renderTable = () => {
        const clients = getClients();

        return (
            <TableClients
                entries={{
                    options: tableClients.state.entries.options,
                    selected: tableClients.state.entries.selected,
                    onChange: onSelectEntries,
                }}
                pagination={{
                    currentPage: tableClients.state.page,
                    totalPages: fetchClients?.data?.adminClients?.pagy?.totalPage || 0,
                    onSelect: onSelectPage,
                }}
                filters={{
                    applied: tableClients.state.filters,
                    onApply: onApplyFilters,
                }}
                actions={{
                    onEdit: onEditClient,
                    onPreview: onPreviewClient,
                }}
                clients={clients}
                selectedClients={tableClients.state.selectedClients}
                onSearch={onSearchClients}
                onToggleRow={tableClients.toggleSelectedClients}
                onToggleAllRows={tableClients.toggleSelectedClientsAll}
                onSort={onSort}
                isLoading={fetchClients.loading || false}
                isReloading={isClientsReloading}
            />
        );
    };

    /* --- */

    return (
        <section className="clients">
            <Breadcrumbs />
            {renderControls()}
            {renderTable()}
        </section>
    );
};

Clients.defaultProps = {
    name: "",
};

export default Clients;
