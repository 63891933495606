/** @module                 Icons */

import { MdOutlineImage }   from "react-icons/md";

/** @module                 Components */

import Image                from "../Shared/Image";

import Manufacturer         from "../Shared/Manufacturer";

import Model                from "../Shared/Model";

import Size                 from "./Size";

import SpeedIndex           from "./SpeedIndex";

import SalePrice            from "../Shared/SalePrice";

import Quantity             from "../Shared/Quantity";

import Edit                 from "../Shared/Edit";

import Delete               from "../Shared/Delete";

import Preview              from "../Shared/Preview";

const 

/**
 * 
 * @returns product warehouse table component
 * 
 */

Table = ( { products } ) => {

    /** @returns */

    return ( <table>

        <thead>

            <tr>

                <th><MdOutlineImage /></th>

                <th>Manufacturer</th>

                <th>Model</th>

                <th>Size</th>

                <th>SI</th>

                <th>Sale &euro;</th>

                <th>Local</th>

                <th>24H</th>

                <th>1 - 3 D</th>

                <th>5 - 7 D</th>

                <th>Actions</th>

            </tr>

        </thead>

        { ( typeof products != "undefined" ) && <tbody>

            { products.adminMultiFieldSearch.edges.map ( ( product, index ) => <tr key={ index }>

                <Image          product={ product.node } />

                <Manufacturer   product={ product.node } />

                <Model          product={ product.node } />

                <Size           product={ product.node } />

                <SpeedIndex     product={ product.node } />

                <SalePrice      product={ product.node } />

                <Quantity       product={ product.node } 
                
                                title="local_quantity" />

                <Quantity       product={ product.node } 
                
                                title="24H" />

                <Quantity       product={ product.node } 
                
                                title="1_3_days" />

                <Quantity       product={ product.node } 
                
                                title="5_7_days" />

                <td data-title="actions" className="actions">

                    <Preview    id={ product.node.id } 
                        
                                type={ product.node.productType } />

                    <Edit       id={ product.node.id } 
                    
                                type={ product.node.productType } />

                    <Delete     id={ product.node.id } />

                </td>

            </tr> ) }

        </tbody> }

    </table> );

}

/** @exports Table */
 
export default Table;