/** @module                     Hooks */

import { forwardRef, useId }    from "react";

import { useTranslation }       from "react-i18next";

const 

/**
 * 
 * @param { String } id 
 * 
 * @param { String } label
 * 
 * @param { Function } action
 * 
 * @param { Array } options
 * 
 * @param { String } value
 * 
 * @returns select input component
 * 
 */

SelectSpread = forwardRef ( ( {

    id,

    label       = false,

    action      = false,

    options     = [],

    def         = false,

    defValue    = false,

    i18n        = false,

    value       = 0,

    style       = "default"

}, ref ) => { const generateId = useId (),

    { t } = useTranslation ();

    /** @returns */
        
    return ( <div className={ `input-group ${ style }` }>

        { ! label === false && <label htmlFor={ ( ! id ) ? generateId : id }>

            { label }

        </label> }

        <select 
        
            id={ ( ! id ) ? generateId : id }

            name={ ( ! id ) ? generateId : id }

            onChange={ ( action === false ) ? e => e.target.value : e => action ( e ) }

            value={ value }

            ref={ ref }
            
        >

            { def && <option value={ "default" }>

                { ( ! defValue ) ? t ( "select_option" ) : defValue }

            </option> }

            { options.map ( ( option, index ) => <option key={ index } value={ option.value }>

                { ( i18n === false ) ? option.name : t ( option.name.toLowerCase () ) }

            </option> ) }

        </select>

    </div> );

} );

/** @exports SelectSpread */
 
export default SelectSpread;