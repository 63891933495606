/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { Outlet }                   from "react-router-dom";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import Tabs                         from "../../../Components/Dashboard/Global/Tabs";

/**
 * 
 * @param { String } name 
 * 
 * @returns initial page component
 * 
 */

const ProductsLayout = ( { name } ) => { const { t } = useTranslation ();

    /** @event usePagename */

    usePagename ( name );

    /** @return ProductsLayout page */

    return ( <section className="collection">

        <Breadcrumbs />

        <Tabs data={ [

            { name: t ( "tires"     ), uri: "/app/products/tire"        },

            { name: t ( "rims"      ), uri: "/app/products/rim"         },

            { name: t ( "batteries" ), uri: "/app/products/battery"     }

        ] } />

        <Outlet />

    </section> );

}

/** @exports ProductsLayout */
 
export default ProductsLayout;