/** @module                 Hooks */

import { useTranslation }   from "react-i18next";

/** @module                 Components */

import Text                 from "../Global/Table/Text";

import Routing              from "../Global/Table/Link";

import Bool                 from "../Global/Table/Bool";

import Edit                 from "../Global/Table/Actions/Edit";

import Preview              from "../Global/Table/Actions/Preview";

import Tag                  from "../Global/Table/Tag";

const 

/**
 * 
 * @param { Object } products
 * 
 * @returns posts table component
 * 
 */

Table = () => { const 

    { t } = useTranslation ();

    /** @returns */

    return ( <table>

        <thead>

            <tr>

                <th>{ t ( "client" ) }</th>

                <th>{ t ( "type" ) }</th>

                <th>{ t ( "phone" ) }</th>

                <th>{ t ( "email" ) }</th>

                <th>{ t ( "manager" ) }</th>

            </tr>

        </thead>

        <tbody>

            <tr>

                <Text text="Autogars" />

                <Tag text="Auto serviss" />

                <Text text="26125045" />

                <Text text="marcis@autogars.lv" />

                <Text text="support@tireshop.lv" />

            </tr>

            <tr>

                <Text text="AUTO VHV" />

                <Tag text="Auto serviss" />

                <Text text="26567573" />

                <Text text="autovhv@inbox.lv" />

                <Text text="support@tireshop.lv" />

            </tr>

            <tr>

                <Text text="Apse" />

                <Tag text="Auto serviss" />

                <Text text="26519111" />

                <Text text="apse.veikals.cesis@gmail.com" />

                <Text text="support@tireshop.lv" />

            </tr>

            <tr>

                <Text text="MEGA REM" />

                <Tag text="Auto serviss" />

                <Text text="29463430" />

                <Text text="megarem@inbox.lv" />

                <Text text="support@tireshop.lv" />

            </tr>

            <tr>

                <Text text="VIESULIS PLUS" />

                <Tag text="Riepu tirgotājs" />

                <Text text="29409733" />

                <Text text="viesulisplus@inbox.lv" />

                <Text text="support@tireshop.lv" />

            </tr>

        </tbody>

    </table> );

}

/** @exports Table */
 
export default Table;