/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

/** @module                         Components */

import Tabs                         from "../../Dashboard/Global/Tabs";

const 

/**
 * 
 * @returns MontageTabs
 * 
 * @description MontageTabs component
 * 
 * @example <MontageTabs />
 * 
 */

MontageTabs = () => { const 

    { t } = useTranslation ( "montage" );

    /** @returns MontageTabs */

    return (

        <Tabs
            
            data={ 
                
                [

                    { name: t ( "appointments" ), uri: "/app/montage" },

                    { name: t ( "workhours" ), uri: "/app/montage/workhours" }

                ] 
            
            }
        
        />

    );

}

/** @exports MontageTabs */
 
export default MontageTabs;