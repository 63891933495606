import { NavLink } from "react-router-dom";

const Tabs = ( { data } ) => {

    /** @return component */

    return ( <div className="tabs">

        { data.map ( ( tab, index ) => <NavLink end
        
            to={ tab.uri } 
        
            key={ index } 
        
            className={ ( { isActive } ) => ( isActive ? 'active' : 'inactive' ) }>

            { tab.name }

        </NavLink> ) }

    </div> );

}

/** @export Tabs */
 
export default Tabs;