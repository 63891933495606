import { useState, useEffect }  from "react";

/** @module                     Utilities */

import { convertDatetime }      from "../../../Utils/Datetime";

/** @module                     Components */

import { Fragment }             from "react";

import Pagination               from "../../../Global/Table/Pagination";

import CurrentImage             from "./Attributes/currentImage";

import ProductBoolean           from "./Boolean";

import Manufacturer             from "./Attributes/manufacturer";

import PriceList                from "./Attributes/PriceList";

import Prices                   from "./Attributes/prices";

import Vendors                  from "./Attributes/vendors";

import ProductVendors           from "./Attributes/productVendors";

import Edit                     from "../../../Global/Table/Actions/Edit";

import ExternalUrl              from "../../../Global/Table/Actions/ExternalUrl";

import Preview                  from "../../../Global/Table/Actions/Preview";

import Information              from "../../../Global/Table/Actions/Entry";

import { AddToCartAction }      from "../../../Global/Table/Actions/Cart";

import Checkbox                 from "../../../Global/Forms/Checkbox";

import RunFlat                  from "./Attributes/runFlat";

import Th                       from "./Table/Th";

import ProductPreview           from "./ProductPreview/ProductPreview";

import ProductName              from "./Attributes/productName";

import TireSize                 from "./Attributes/TireSize";

import Studs from "./Attributes/Studs";

const 

ProductTable = ( { products, refetch, variables, productPreview, table_fields, __ } ) => { const 

    [ clientView, setClientView ] = useState ( localStorage.getItem ( "clientView" ) === "true" ? true : false );

    useEffect ( () => {

        localStorage.setItem ( "clientView", clientView );

    }, [ clientView ] );

    /** @returns */

    return (

        <Fragment>

            <Checkbox

                id={ "clientView" }

                text={ __ ( "client_view" ) }

                state={ clientView }

                action={ e => setClientView ( e.target.checked ) }

                name={ "clientView" }

                styling={ "onBody inlineFlex contained" }

            />

            { ( typeof products != "undefined" ) &&
                
                <Pagination 
                    
                    pageinfo={ products[ Object.keys ( products )[ 0 ] ].pageInfo } 
                    
                    action={ refetch } 
                    
                    cookie={ "productsCollectionCount" }
                    
                    stringSearch={ true } /> 

            }

            <table>

                <thead>

                    <tr>

                        { table_fields.map ( ( field, index ) => {

                            if ( field === "priceList" ) {

                                return ( 
                            
                                    <Fragment key={ index }>

                                        <Th name={ "original_price" } __={ __ } refetch={ refetch } variables={ variables } />

                                        <Th name={ "retail_price" } __={ __ } refetch={ refetch } variables={ variables } />
    
                                    </Fragment> 
                                
                                )

                            } else if ( field === "vendors" ) {

                                return (

                                    ( ! clientView ) &&

                                    <Th key={ index } name={ "vendors" } __={ __ } refetch={ refetch } variables={ variables } />

                                )

                            } else if ( field === "prices" ) {

                                return (

                                    <Fragment key={ index }>

                                        <Th name={ "price" } __={ __ } refetch={ refetch } variables={ variables } />

                                        { ( ! clientView ) &&

                                            <Fragment>

                                                <Th name={ "priceGroupA" } __={ __ } refetch={ refetch } variables={ variables } />

                                                <Th name={ "priceGroupB" } __={ __ } refetch={ refetch } variables={ variables } />

                                                <Th name={ "priceGroupC" } __={ __ } refetch={ refetch } variables={ variables } />

                                                <Th name={ "originalPrice" } __={ __ } refetch={ refetch } variables={ variables } />

                                                <Th name={ "originalPriceVAT" } __={ __ } refetch={ refetch } variables={ variables } />

                                            </Fragment>

                                        }

                                    </Fragment>

                                ) 

                            } else if ( field === "productVendors" ) {

                                return (

                                    <Fragment key={ index }>

                                        <Th name={ "qty" } __={ __ } refetch={ refetch } variables={ variables } />

                                        <Th name={ "qtyHour" } __={ __ } refetch={ refetch } variables={ variables } />

                                        <Th name={ "qtyDays" } __={ __ } refetch={ refetch } variables={ variables } />

                                        <Th name={ "qtyWeeks" } __={ __ } refetch={ refetch } variables={ variables } />

                                    </Fragment>

                                ) 

                            } else { return <Th key={ index } name={ field } __={ __ } refetch={ refetch } variables={ variables } /> }

                        } ) }

                        <th>

                            { __ ( "actions" ) }

                        </th>

                    </tr>

                </thead>

                <tbody>

                    { ( typeof products != "undefined" ) && 
                    
                        products[ Object.keys ( products )[ 0 ] ].edges.map ( ( product, index ) =>

                            <tr key={ index }>

                                { table_fields.map ( ( field ) => {

                                    return <Fragment key={ field }>
                                        
                                        { ( field == "currentImage" ) && <CurrentImage id={ product.node.id } src={ product.node.currentImage } alt={ product.node.model } /> }

                                        { /** @descirption un-used fields */
                                        
                                            ( 
                                                
                                                field == "awsImageUrl" ||

                                                field == "image" ||

                                                field == "imageCrop" ||

                                                field == "originalImageUrl"
                                                
                                            ) && <td>-</td> }

                                        { /** @description boolean fields */
                                        
                                            ( 
                                                
                                                field == "boltsIncluded" || 
                                                
                                                field == "deleted" ||

                                                field == "used" ||

                                                field == "visible" ||

                                                field == "isCTire"
                                                
                                            ) && <ProductBoolean state={ product.node[ field ] } /> }

                                        { /** @description simple string values */

                                            (

                                                field === "width" ||

                                                field === "height" ||

                                                field === "diameter" ||

                                                field === "hashId" ||

                                                field === "ean" ||

                                                field === "id" ||

                                                field === "loadIndex" ||

                                                field === "manufacturerId" ||

                                                field === "model" ||

                                                field === "noiseIndex" ||

                                                field === "noiseLevel" ||

                                                field === "offset" ||

                                                field === "pitchCircleDiameter" ||

                                                field === "productType" ||

                                                field === "radius" ||

                                                field === "roadSurface" ||

                                                field === "scraper" ||

                                                field === "uid" ||

                                                field === "url" ||

                                                field === "usage" ||

                                                field === "vehicleType" ||

                                                field === "userDiscount" ||

                                                field === "viewCount" ||

                                                field === "viewUniqCount" ||

                                                field === "year" ||

                                                field === "length" ||

                                                field === "capacity" ||

                                                field === "current" ||

                                                field === "voltage" ||

                                                field === "centreBore" ||

                                                field === "dedicatedAxle"

                                            ) && ( product.node[ field ] === null || product.node[ field ] === undefined ? <td>-</td> : 
                                            
                                                <td>{ __( product.node[ field ] ) }</td> )

                                        }

                                        { /** @description uppercase string values */

                                            (

                                                field === "wetBreakingEfficiency" ||

                                                field === "speedRating" ||

                                                field === "season" ||

                                                field === "fuelEfficiency" ||

                                                field === "freightClass"

                                            ) && ( product.node[ field ] === null || product.node[ field ] === undefined ? <td>-</td> :
                                            
                                                <td>{ __( product.node[ field ] ).toUpperCase () }</td> )

                                        }

                                        { /** @description date values */

                                            (

                                                field === "createdAt" ||

                                                field === "updatedAt"

                                            ) && ( product.node[ field ] === null || product.node[ field ] === undefined ? <td>-</td> :
                                            
                                                <td>{ convertDatetime ( product.node[ field ] ) }</td> )

                                        }

                                        { /** @description product description */

                                            (

                                                field === "description"

                                            ) && ( product.node[ field ] === null || product.node[ field ] === undefined ? <ProductBoolean state={ false } /> :
                                            
                                                <ProductBoolean state={ true } /> )

                                        }

                                        { /** @description product manufacturer */

                                            (

                                                field === "manufacturer"

                                            ) && <Manufacturer id={ product.node.id } manufacturer={ product.node[ field ] } />

                                        }

                                        { /** @description product specifications */

                                            (

                                                field === "specifications"

                                            ) && ( product.node[ field ].length > 0 ? <ProductBoolean state={ true } /> : <ProductBoolean state={ false } /> )

                                        }

                                        { /** @description product price list */

                                            (

                                                field === "priceList"

                                            ) && <PriceList price_list={ product.node[ field ] } />

                                        }

                                        { /** @description product prices (markups and discounts) */

                                            (

                                                field === "prices"

                                            ) && <Prices prices={ product.node[ field ] } clientView={ clientView } />

                                        }

                                        { /** @description product vendors */

                                            ( ! clientView ) &&

                                            (

                                                field === "vendors"

                                            ) && <Vendors vendor={ product.node[ field ] [ 0 ] } />

                                        }

                                        { /** @description runFlat */

                                            (

                                                field === "runFlat"

                                            ) && <RunFlat runFlat={ product.node[ field ] } />

                                        }

                                        { /** @description product productVendors */

                                            (

                                                field === "productVendors"

                                            ) && <ProductVendors vendor={ product.node[ field ] } />

                                        }

                                        { /** @description product productVendors */

                                            (

                                                field === "productName"

                                            ) && <ProductName product={ product.node } />

                                        }

                                        { /** @description product productVendors */

                                            (

                                                field === "productSize"

                                            ) && <TireSize product={ product.node } />

                                        }

                                        { /** @description product studs */

                                            (

                                                field === "studs"

                                            ) && <Studs product={ product.node } />

                                        }
                                        
                                    </Fragment>;

                                } ) }

                                <td data-title="actions" className="actions">


                                    {

                                        ( product.node.url !== null ) && <ExternalUrl route={ product.node.url } />

                                    }

                                    <AddToCartAction
                                    
                                        product={ product.node }
                                    
                                    />

                                    <Edit 
                                    
                                        route={ `/app/products/edit/${ product.node.id }` } 

                                        blank={ true }
                                        
                                    />

                                    <Preview 

                                        size="static_large"
                                    
                                        element={ 

                                            <ProductPreview productId={ product.node.id } />
                                            
                                        } 
                                        
                                    />

                                </td>

                            </tr>

                        )
                    
                    }
                    
                </tbody>

            </table>

            { ( typeof products != "undefined" ) &&
                
                <Pagination 
                    
                    pageinfo={ products[ Object.keys ( products )[ 0 ] ].pageInfo } 
                    
                    action={ refetch } 
                    
                    cookie={ "productsCollectionCount" } /> 

            }

        </Fragment>

    );

}
 
/** @exports ProductTable */

export default ProductTable;