/** @module                     React-Icons */

import { 
    
    AiOutlineCheckCircle, 
    
    AiOutlineCloseCircle }      from "react-icons/ai";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns table boolean component
 * 
 */

Bool = ( { state } ) => {

    /** @returns */

    return ( <div data-title="bool">

        { state ? 
        
            <AiOutlineCheckCircle 
        
                className="color-success" 
            
                size={ 16 } /> 
            
        : 
        
            <AiOutlineCloseCircle 
            
            className="color-alert" 
            
            size={ 16 } /> }

    </div> );

}

/** @exports Bool */
 
export default Bool;