import                          "../../../../../Styles/Modals.css";

/** @module                     React-Icons */

import { AiFillEye }            from "react-icons/ai";

/** @module                     Hooks */

import { useState, Fragment }   from "react";

/** @module                     Components */

import Layout                   from "../../Modals/Aside/Templates/Layout"; 

/**
 * 
 * @param { id }
 *  
 * @returns modal settings component trigger
 * 
 */

const 

Preview = ( { element, size = "default" } ) => { const

    [ state, setState ] = useState ( false ),

    /**
     * 
     * @event onClick
     * 
     * @description change modal state
     * 
     */

    stateController = () => ( state ) ? setState ( false ) : setState ( true );

    /** @returns */

    return ( <Fragment>

        { state && <Layout id="product-fields" state={ setState } element={ element } size={ size } /> }
    
        <button className={ `modal-settings-button product-fields view` } onClick={ stateController }>

            <AiFillEye />

        </button> 
    
    </Fragment> );

};

/** @exports Preview */
 
export default Preview;