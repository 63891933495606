
const KEYS = {
    clientsTableFilters: "clientsTableFilters",
};

/* --- */

const setItem = (key, value) => {
    if (!window.localStorage.setItem) {
        return;
    }

    window.localStorage.setItem(key, value);
};

const getItem = (key) => {
    if (!window.localStorage.getItem) {
        return "";
    }

    return window.localStorage.getItem(key);
};

/* --- */

const saveClientsTableFilters = (filters) => {
    const value = filters.join(",");
    setItem(KEYS.clientsTableFilters, value);
};

const getClientsTableFilters = () => {
    const value = getItem(KEYS.clientsTableFilters);
    return (value || "").split(",").filter((val) => val);
};

/* --- */

export default {
    saveClientsTableFilters,
    getClientsTableFilters,
};
