import { gql } from '@apollo/client';

const 

/** @description create new vendor */

CREATE_VENDOR = gql`mutation CreateVendor (

    $addPvn     : Boolean!

    $visible    : Boolean!

    $name       : String!

    $markup     : Int

) {

    adminCreateVendor (

        input: {

            vendorInput: {

                addPvn      : $addPvn

                visible     : $visible

                name        : $name

                markup      : $markup

            }

        }

    ) {

        vendor {

            id

        }

    }

}`,

/** @description update existing vendor */

UPDATE_VENDOR = gql`mutation UpdateVendor (
 
    $id          : ID!

    $addPvn      : Boolean!

    $visible     : Boolean!

    $name        : String!

    $markup      : Int

    $markupGroupA: Int

    $markupGroupB: Int

    $markupGroupC: Int
) {

    adminUpdateVendor (

        input: {

            id: $id,

            vendorInput: {

                addPvn      : $addPvn

                visible     : $visible

                name        : $name

                markup      : $markup

                markupGroupA: $markupGroupA

                markupGroupB: $markupGroupB

                markupGroupC: $markupGroupC
            }

        }

    ) {

        vendor {

            id

            addPvn

            visible

            name

            markup

            markupGroupA

            markupGroupB

            markupGroupC

            updatedAt

        }

    }

}`,

/** @description delete existing vendor */

DELETE_VENDOR = gql`mutation DeleteVendor (

    $id: ID!

) {

    adminDeleteVendor (

        input: {

            id: $id

        }

    ) {

        vendor {

            id

        }

    }

}`;

/** @export mutations */

export { CREATE_VENDOR, UPDATE_VENDOR, DELETE_VENDOR }