import "../../../../../Styles/Modals.css";

/** @import @module */

import { useState, Fragment } from "react";

/** @import @components */

import Layout from "./Templates/Layout";

/**
 * 
 * @param { id }
 *  
 * @returns modal settings component trigger
 * 
 */

const SettingsModal = ( { id } ) => { const 

    [ state, setState ] = useState ( false ),

    /**
     * 
     * @event onClick
     * 
     * @description change modal state
     * 
     */

    stateController = () => ( state ) ? setState ( false ) : setState ( true );

    /** @returns */

    return ( <Fragment>

        { state && <Layout id={ id } state={ setState } /> }
    
        <button className={ `modal-settings-button ${ id }` } onClick={ stateController }>

            Settings

        </button> 
    
    </Fragment> );

}

/** @exports SettingsModal */
 
export default SettingsModal;