// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Studs_yes__2ILDC {\n\n    color: var( --color-success );\n\n}\n\n.Studs_no__3d\\+Vp {\n\n    color: var( --color-error );\n\n}\n\n.Studs_possible__15E8d {\n\n    color: var( --color-warning );\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Tables/Attributes/Studs.module.css"],"names":[],"mappings":"AAAA;;IAEI,6BAA6B;;AAEjC;;AAEA;;IAEI,2BAA2B;;AAE/B;;AAEA;;IAEI,6BAA6B;;AAEjC","sourcesContent":[".yes {\n\n    color: var( --color-success );\n\n}\n\n.no {\n\n    color: var( --color-error );\n\n}\n\n.possible {\n\n    color: var( --color-warning );\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"yes": "Studs_yes__2ILDC",
	"no": "Studs_no__3d+Vp",
	"possible": "Studs_possible__15E8d"
};
export default ___CSS_LOADER_EXPORT___;
