/** @module Hooks */

import { usePagename }      from "../../Hooks/usePagename";

import { useTranslation }   from "react-i18next";

/** @module                 Apollo */

import useFetch             from "../../Hooks/useFetch";

import { APPOINTMENTS }     from "../../Queries/Montage";

/** @module                 Components */

import Breadcrumbs          from "../../Components/Dashboard/Breadcrumbs";

import Table                from "../../Components/Dashboard/Montage/Table/Table";

import ComponentLoader      from "../../Components/Dashboard/Loaders/ComponentLoader";

import Pagination           from "../../Components/Dashboard/Global/Table/Pagination";

import Actions              from "../../Components/Dashboard/Montage/Actions";

import Schedule             from "../../Components/Dashboard/Montage/Schedule";

import MontageTabs          from "../../Components/Dashboard/Montage/Tabs";

const

/**
 * 
 * @param { String } name 
 * 
 * @returns monatage page component
 * 
 */

Montage = ( { name } ) => { const { t } = useTranslation (),

    { loading, data, refetch } = useFetch ( APPOINTMENTS, { 
        
        first: JSON.parse ( localStorage.getItem ( "appointmentsCollectionCount" ) ) || 12
    
    }, "cache-and-network" );

    /** @event usePagename */

    usePagename ( name );

    /** @return Services page */

    return ( <section className="services">

        <Breadcrumbs />

        <MontageTabs />

        { ( loading ) ? <ComponentLoader size={ 1 } /> : <Schedule appointments={ data.adminAppointments } /> }

        { ( loading ) ? <ComponentLoader size={ 1 } /> : <Actions refetch={ refetch } /> }

        { ! loading && <Pagination pageinfo={ data.adminAppointments.pageInfo } action={ refetch } cookie="appointmentsCollectionCount" /> }

        { ( loading ) ? <ComponentLoader /> : <Table products={ data } /> }

        { ! loading && <Pagination pageinfo={ data.adminAppointments.pageInfo } action={ refetch } cookie="appointmentsCollectionCount" /> }

    </section> );

}

/** @exports Montage */
 
export default Montage;