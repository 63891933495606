import { gql } from '@apollo/client';

const 

/** @name ORDER_TYPE_ENUM */

ORDER_TYPE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "OrderTypeEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name ORDER_STATUS_ENUM */

ORDER_STATUS_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "OrderStatusEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name ORDER_DELIVERY_METHOD_ENUM */

ORDER_DELIVERY_METHOD_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "DeliveryMethodEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name ORDER_PAYMENT_METHOD_ENUM */

ORDER_PAYMENT_METHOD_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "OrderPaymentMethodEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @name ORDER_STATUS_ENUM */

export { 
    
    ORDER_TYPE_ENUM,

    ORDER_STATUS_ENUM,

    ORDER_DELIVERY_METHOD_ENUM,

    ORDER_PAYMENT_METHOD_ENUM

};