import React from "react";
import { TiArrowUnsorted } from "react-icons/ti";

import styles from "./styles.module.css";


const TableClientsColumnName = (props) => {
    const renderSortIcon = () => {
        if (!props.isSortable) {
            return null;
        }

        return (
            <TiArrowUnsorted
                className={styles.sort}
            />
        );
    };

    /* --- */

    const containerClassName = [styles.container];

    if (props.isSortable) {
        containerClassName.push(styles.containerSortable);
    }

    return (
        <div
            className={containerClassName.join(" ")}
            onClick={(evt) => {
                if (!props.isSortable) {
                    return;
                }

                props.onClick(evt);
            }}
            style={{
                minWidth: `${props.width}px`,
            }}
            role="button"
            tabIndex="-1"
        >
            {props.name}
            {renderSortIcon()}
        </div>
    );
};

TableClientsColumnName.defaultProps = {
    name: "",
    width: 0,
    onClick: () => { },
    isSortable: false,
};

export default TableClientsColumnName;
