import { usePagename } from "../../Hooks/usePagename";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { ORDER } from "../../Queries/Orders";

import { UPDATE_ORDER } from "../../Mutations/Orders";

import useUpdate from "../../Hooks/Mutations/useUpdate";

import { useState } from "react";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import ComponentLoader from "../../Components/Dashboard/Loaders/ComponentLoader";

import Customer from "../../Components/Dashboard/Orders/Single/Customer";

import DeliveryInformationSettings from "../../Components/Dashboard/Orders/Single/DeliveryInfoSettings";

import NotesSettings from "../../Components/Dashboard/Orders/Single/NotesSettings";

import OrderSettings from "../../Components/Dashboard/Orders/Single/Aside/OrderSettings";

import DeliverySettings from "../../Components/Dashboard/Orders/Single/Aside/DeliverySettings";

import PaymentSettings from "../../Components/Dashboard/Orders/Single/Aside/PaymentSettings";

import MontageSettings from "../../Components/Dashboard/Orders/Single/Aside/MontageSettings";

import OrderProductModal from "../../Components/Dashboard/Products/Collection/Modals/OrderProduct/Modal";

import OrderProducts from "../../Components/Dashboard/Orders/Single/Products";

import EditAction from "../../Components/Dashboard/Orders/Single/Aside/EditAction";

import ConfirmationAction from "../../Components/Dashboard/Orders/Single/ConfirmationAction";

import CustomerSearch from "../../Components/Dashboard/Orders/Single/Search";

import PDFGeneratorWidget from "../../Components/Dashboard/Orders/Single/Aside/PDFGenerator";

import SendPDF from "../../Components/Dashboard/Orders/Single/Aside/SendPDF";

import OrderHistory from "../../Components/Dashboard/Orders/Single/OrderHistory";

/**
 * 
 * @exports Content
 * 
 * @description Content component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.data - The data object.
 * 
 * @example <Content data={ data } />
 * 
 * @returns { JSX.Element } Content
 * 
 */

export function Content ( { data, refetch } ) { const 

    { t : __ } = useTranslation (),

    /**
     * 
     * @description Utility hooks.
     * 
     * @type { Object }
     *
     * @property { Function } updateMutation - The update mutation function.
     * 
     * @property { Boolean } loading - The loading state.
     * 
     * @property { Boolean } complete - The complete state.
     * 
     * @property { Object } errors - The errors object.
     * 
     * @property { Function } setErrors - The setErrors function.
     * 
     * @example const { updateMutation, loading, complete, errors, setErrors } = useUpdate ( UPDATE_ORDER );
     * 
     * @returns { Object } updateMutation, loading, complete, errors, setErrors
     * 
     */

    { updateMutation, loading, complete, errors, setErrors } = useUpdate ( UPDATE_ORDER ),

    /** @type { Object } state - The state object. */

    [ state, setState ] = useState ( 
        
        {

            id: data.id || "",

            orderType: data.orderType || "",

            status: data.status || "",

            paymentMethod: data.paymentMethod || "",

            orderPaymentNotes: data.orderPaymentNotes || "",

            vatIncluded: (data.vatIncluded != null) ? data.vatIncluded : true,

            assembly: data.assembly || false,

            assemblyPrice: data.assemblyPrice || 0, 

            orderDeliveryNotes: data.orderDeliveryNotes || "",

            deliveryPrice: data.deliveryPrice || 0,

            deliveryPlanId: data.deliveryPlanId || "",

            deliveryMethod: data.deliveryMethod || "",

            firstName: data.firstName || "",

            lastName: data.lastName || "",

            mobilePhoneNr: data.mobilePhoneNr || "",

            email: data.email || "",

            customerIdCode: data.customerIdCode || "",

            companyName: data.companyName || "",

            regNr: data.regNr || "",

            pvnRegNr: data.pvnRegNr || "",

            bank: data.bank || "",

            bankSwift: data.bankSwift || "",

            bankIban: data.bankIban || "",

            adminNotes: data.adminNotes || "",

            userNotes: data.userNotes || "",

            countryCode: data.countryCode || "LV",

            city: data.city || "",

            address: data.address || "",

            postCode: data.postCode || "",

            shippingNotes: data.shippingNotes || "",

            orderProductVendors: data.orderProductVendors || [],

            customDeliveryPrice: data.deliveryPrice || 0,

            userId: data.userId || ""

        } 
    
    ),

    /**
     * 
     * @event submit
     * 
     * @description Submit form.
     * 
     * @param { Event } e
     * 
     * @returns { void }
     * 
     * @example <form onSubmit={ submit } />
     * 
     */

    submit = e => { e.preventDefault (); 

        /** If required fields are not filled, set errors to true. */

        if ( ! state.firstName ) { return setErrors ( { message: "Missing first name" } ); }

        if ( ! state.lastName ) { return setErrors ( { message: "Missing last name" } ); }

        if ( ! state.email ) { return setErrors ( { message: "Missing email" } ); }

        if ( ! state.mobilePhoneNr ) { return setErrors ( { message: "Missing mobile phone number" } ); }

        if ( ! state.orderType ) { return setErrors ( { message: "Missing order type" } ); }

        if ( ! state.status ) { return setErrors ( { message: "Missing status" } ); }

        if ( ! state.userId ) { return setErrors ( { message: "Missing user ID" } ); }

        if ( ! state.deliveryMethod ) { return setErrors ( { message: "Missing delivery method" } ); }

        /** If delivery method is tireshop delivery, check if all required fields are filled. */

        if ( state.deliveryMethod === "tireshop_delivery" && ! state.city ) { 
            
            return setErrors ( { message: "Please enter city" } ); 
        
        }

        if ( state.deliveryMethod === "tireshop_delivery" && ! state.address ) { 
            
            return setErrors ( { message: "Missing delivery address" } ); 
        
        }

        if ( state.deliveryMethod === "tireshop_delivery" && ! state.postCode ) { 
            
            return setErrors ( { message: "Missing postal code" } ); 
        
        }
        
        updateMutation(productInput(state)).then(() => {

            refetch();
            
        });
    
    };

    /** @returns { JSX.Element } Content */

    return (

        <form onSubmit={ submit }>

            <div className="aside-edit">

                <div className="content">

                    <CustomerSearch state={ state } update={ setState } />

                    <Customer state={ state } update={ setState } />

                    <DeliveryInformationSettings state={ state } update={ setState } />

                    <OrderProductModal orderData={ { view: state, set: setState } } />

                    <OrderProducts order={ state } setState={ setState } isEditing />

                    <NotesSettings state={ state } update={ setState } />

                    <ConfirmationAction errors={ errors } loading={ loading } complete={ complete } />

                    <OrderHistory order={ data } />

                </div>

                <aside>

                    <EditAction errors={ errors } loading={ loading } complete={ complete } order={ data } />

                    <OrderSettings state={ state } update={ setState } />

                    <PDFGeneratorWidget state={ state } order={ data } />

                    <SendPDF state={ state } order={ data } />

                    <DeliverySettings state={ state } update={ setState } />

                    <PaymentSettings state={ state } update={ setState } />

                    <MontageSettings state={ state } update={ setState } />

                    <ConfirmationAction errors={ errors } loading={ loading } complete={ complete } />

                </aside>

            </div>

        </form>

    );

};

/**
 * 
 * @exports EditOrder
 * 
 * @description EditOrder component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { String } props.name - The name of the page.
 * 
 * @example <EditOrder name="Edit Order" />
 * 
 * @returns { JSX.Element } EditOrder
 * 
 */

export default function EditOrder ( { name } ) { usePagename ( name ); const 

    { orderId } = useParams (),

    { data, loading, refetch } = useQuery ( ORDER, { variables: { id : orderId } } );

    /** @returns { JSX.Element } EditOrder */

    return (

        <section className="mutations create_order">

            <Breadcrumbs />

            { loading ? <ComponentLoader /> : <Content data={ data.adminOrder } refetch={ refetch } /> }

        </section>

    );

};

/**
 * 
 * @function productInput
 * 
 * @description Product input.
 * 
 * @param { Object } state - The state object.
 * 
 * @returns { Object } mutationObject
 * 
 * @example const mutationObject = productInput ( state );
 * 
 */

export function productInput ( state ) { const

    /** @todo add productInput */

    mutationObject = { ...state, [ "productInput" ]: state.orderProductVendors.map (

        item => ( {

            productId: parseInt ( item.productId ),

            discount: parseFloat ( item.discount ),

            price: parseFloat ( item.price ),

            count: parseInt ( item.count )

        } )

    ) };

    /** @todo remove orderProductVendors */

    delete mutationObject.orderProductVendors;

    /** @returns mutation object */

    return mutationObject;

};