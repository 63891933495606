import React from "react";

import styles from "./styles.module.css";


const Switch = (props) => {
    const renderLabel = () => {
        if (!props.label) {
            return null;
        }

        return (
            <div className={styles.label}>
                {props.label}
            </div>
        );
    };

    const renderSwitch = () => {
        const switchClassName = [styles.switch];
        const dotContainerClassName = [styles.switchDotContainer];
        const dotClassName = [styles.switchDot];

        if (props.isSelected) {
            dotContainerClassName.push(styles.switchDotContainerSelected);
            dotClassName.push(styles.switchDotSelected);
        }

        if (props.isDisabled) {
            switchClassName.push(styles.switchDisabled);
        }

        return (
            <div
                className={switchClassName.join(" ")}
                onClick={() => {
                    if (props.isDisabled) {
                        return;
                    }

                    props.onChange(!props.isSelected);
                }}
                role="button"
                tabIndex="-1"
            >
                <div className={dotContainerClassName.join(" ")}>
                    <div className={dotClassName.join(" ")} />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {renderLabel()}
            {renderSwitch()}
        </div>
    );
};

Switch.defaultProps = {
    label: "",
    onChange: () => { },
    isSelected: false,
    isDisabled: false,
};

export default Switch;
