/** @module             React-Icons */

import { ImSpinner5 }   from "react-icons/im";

const 

/** 
 * 
 * @returns centered loader component
 * 
 */

CenterLoader = ( { size = 24 } ) => {

    /** @returns */

    return ( <div className="component-loader center">

        <ImSpinner5 className="spinner" size={ size } />

    </div> );

}

/** @exports CenterLoader */
 
export default CenterLoader;