// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderHistory_container__IxhLk > *:not( :last-child ) {\n\n    margin-bottom: 1rem;\n\n}\n\n.OrderHistory_content__a6EFZ > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.OrderHistory_authorIcon__ZbMG2 {\n\n    line-height: 0;\n\n    color: #4CAF50;\n\n}\n\n.OrderHistory_date__FO5ZP {\n\n    color: var(--invert-solid);\n\n}\n\n.OrderHistory_author__bGzxq {\n\n    display: flex;\n\n    align-items: center;\n\n    gap: 0.25rem;\n\n    font-size: 0.8rem;\n\n}\n\n.OrderHistory_title__OkOgT {\n\n    margin: 0;\n\n    padding: 0;\n\n    color: var( --invert-solid );\n\n    text-transform: uppercase;\n\n    font-weight: 500;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Orders/Single/OrderHistory.module.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;;AAEvB;;AAEA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,cAAc;;IAEd,cAAc;;AAElB;;AAEA;;IAEI,0BAA0B;;AAE9B;;AAEA;;IAEI,aAAa;;IAEb,mBAAmB;;IAEnB,YAAY;;IAEZ,iBAAiB;;AAErB;;AAEA;;IAEI,SAAS;;IAET,UAAU;;IAEV,4BAA4B;;IAE5B,yBAAyB;;IAEzB,gBAAgB;;AAEpB","sourcesContent":[".container > *:not( :last-child ) {\n\n    margin-bottom: 1rem;\n\n}\n\n.content > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.authorIcon {\n\n    line-height: 0;\n\n    color: #4CAF50;\n\n}\n\n.date {\n\n    color: var(--invert-solid);\n\n}\n\n.author {\n\n    display: flex;\n\n    align-items: center;\n\n    gap: 0.25rem;\n\n    font-size: 0.8rem;\n\n}\n\n.title {\n\n    margin: 0;\n\n    padding: 0;\n\n    color: var( --invert-solid );\n\n    text-transform: uppercase;\n\n    font-weight: 500;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrderHistory_container__IxhLk",
	"content": "OrderHistory_content__a6EFZ",
	"authorIcon": "OrderHistory_authorIcon__ZbMG2",
	"date": "OrderHistory_date__FO5ZP",
	"author": "OrderHistory_author__bGzxq",
	"title": "OrderHistory_title__OkOgT"
};
export default ___CSS_LOADER_EXPORT___;
