/** @module                 Hooks */

import { useTranslation }   from "react-i18next";

import { convertDatetime }  from "../../Utils/Datetime";

/** @module                 Components */

import Text                 from "../../Global/Table/Text";

import Bool                 from "../../Global/Table/Bool";

import Routing              from "../../Global/Table/Link";

import Edit                 from "../../Global/Table/Actions/Edit";

import Preview              from "../../Global/Table/Actions/Preview";

import Information          from "../Modals/Information";

const

/**
 * 
 * @param { Object } users 
 * 
 * @returns managers table component
 * 
 */

Table = ( { users } ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <table>

        <thead>

            <tr>

                <th>ID</th>

                <th>{ t ( "email" ) }</th>

                <th>{ t ( "name" ) }</th>

                <th>{ t ( "lastname" ) }</th>

                <th>{ t ( "blocked" ) }</th>

                <th>{ t ( "createdAt" ) }</th>

                <th>{ t ( "actions" ) }</th>

            </tr>

        </thead>

        { ( typeof users != "undefined" ) && <tbody>

            { users.adminUsers.edges.map ( ( user, index ) => <tr key={ index }>

                <Text text={ user.node.id } />

                <Routing text={ user.node.email } route={ `/app/managers/edit/${ user.node.id }` } />

                <Text text={ user.node.firstName } />

                <Text text={ user.node.lastName } />

                <Bool state={ user.node.blocked } />

                <Text text={ convertDatetime ( user.node.createdAt ) } />

                <td data-title="actions" className="actions">

                    <Edit route={ `/app/managers/edit/${ user.node.id }` } />

                    <Preview element={ <Information atts={ { id : user.node.id } } /> } />

                </td>

            </tr> ) }

        </tbody> }

    </table> );

}

/** @exports Table */
 
export default Table;