// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductsVendor_container__pAMtM > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.ProductsVendor_vendorName__sLuRM {\n\n    text-transform: capitalize;\n\n}\n\n.ProductsVendor_priceVAT__ltZOI {\n\n    color: var( --invert-solid );\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Orders/Single/Products/ProductsVendor.module.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,0BAA0B;;AAE9B;;AAEA;;IAEI,4BAA4B;;AAEhC","sourcesContent":[".container > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.vendorName {\n\n    text-transform: capitalize;\n\n}\n\n.priceVAT {\n\n    color: var( --invert-solid );\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProductsVendor_container__pAMtM",
	"vendorName": "ProductsVendor_vendorName__sLuRM",
	"priceVAT": "ProductsVendor_priceVAT__ltZOI"
};
export default ___CSS_LOADER_EXPORT___;
