/** @module                         Hooks */

import { useParams }                from "react-router-dom";

import { useQuery }                 from "@apollo/client";

import { useTranslation }           from "react-i18next";

import { useState, useEffect }      from "react";

/** @module                         Configurations */

import { DEFAULT_FIELDS }           from "../../../Configs/CONSTANTS/Products";

/** @module                         Queries */

import { PRODUCTS }                 from "../../../Queries/Products";

import { PRODUCT_PREVIEW }          from "../../../Queries/Products";

/** @module                         Components */

import ProductTable                 from "../../../Components/Dashboard/Products/Collection/Tables/Table";

import ComponentLoader              from "../../../Components/Dashboard/Loaders/ComponentLoader";

import Actions                      from "../../../Components/Dashboard/Products/Collection/Actions";

import { Fragment }                 from "react";

import ProductFilters               from "../../../Components/Dashboard/Products/Collection/Filters/Container";

const 

/**
 * 
 * @returns products table
 * 
 */

Catalog = () => { const 

    filters = JSON.parse ( localStorage.getItem ( "productFiltersType" ) ) || "static",

    { productType } = useParams (),

    { t } = useTranslation (),

    [ fields, setFields ] = useState ( JSON.parse ( localStorage.getItem ( `ptc_${ productType }` ) ) || DEFAULT_FIELDS ( productType ) ),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading, data, variables, refetch } = useQuery ( PRODUCTS, { 

        variables: {

        productType,
            
        first: JSON.parse ( localStorage.getItem ( `productsCollectionCount` ) ) || 12

        }, notifyOnNetworkStatusChange: true

    } );

    /**
     * 
     * @event useEffect
     * 
     * @returns set table fields to local storage
     * 
     */

    useEffect ( () => {

        localStorage.setItem ( `ptc_${ productType }`, JSON.stringify ( fields ) );

    }, [ fields ] );

    /**
     * 
     * @event useEffect
     * 
     * @returns set & updates table fields state hook
     * 
     */

    useEffect ( () => {

        setFields ( JSON.parse ( localStorage.getItem ( `ptc_${ productType }` ) ) || DEFAULT_FIELDS );

    }, [ productType ] );

    // `ptc_${ productType }`

    /** @returns */

    return (  

        <Fragment>

            <Actions 
            
                type={ productType } 
                
                dynamic_data={ { 
                
                    default_fields: fields,

                    setFields: setFields
                
                } }
                
                refetch={ refetch }

                variables={ variables }

                filters={ filters }
                
            />

            { filters === "static" && 

                <ProductFilters 
                
                    category={ productType }
                    
                    refetch={ refetch }
                    
                    variables={ variables }

                    loading={ false }
                    
                />

            }

            { loading ? <ComponentLoader size={ 3 } /> :
            
                <ProductTable 
                
                    products={ data } 

                    refetch={ refetch }

                    variables={ variables }

                    productPreview={ PRODUCT_PREVIEW }

                    table_fields={ fields }

                    __={ t }
                    
                />

            }

        </Fragment>
        
    );

}

/** @exports Catalog */
 
export default Catalog;