/** @module                     React-Icons */

import { 
    
    AiOutlineCheckCircle, 
    
    AiOutlineCloseCircle }      from "react-icons/ai";

/** @module                     Components */

import { Fragment }             from "react";

const 

/**
 * 
 * @param {object} data
 * 
 * @returns custom table cell data preset for orders assembly
 * 
 */

OrderAssembly = ( { data } ) => {

    /** @returns */

    return ( <td data-title="assembly" className="assembly">

        { 
        
            data.assembly ? 

                <div className="assembly_found">
            
                    <AiOutlineCheckCircle 
            
                        className="color-success" 
                    
                        size={ 16 } />

                    <span className="assembly_price">
                        
                        &euro; { parseFloat ( data.assemblyPrice ).toFixed ( 2 ) }
                        
                    </span>

                </div>
                
            : 
            
                <AiOutlineCloseCircle 
            
                    className="color-alert" 
                    
                    size={ 16 } />
            
        }

    </td> );

}

/** @exports OrderAssembly */
 
export default OrderAssembly;