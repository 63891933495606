import "../../../../../Styles/Modals.css";

/** @module                         Hooks */

import { useState }                 from "react";

/** @module                         Components */

import { Fragment }                 from "react";

import Layout                       from "./Templates/Layout";

import Button                       from "../../Forms/Button";

const

/**
 * 
 * @param { ReactComponentElement } element
 *  
 * @returns modal trigger
 * 
 */

Modals = ( { element, text = "Modal", icon = false } ) => { const 

    [ state, setState ] = useState ( false ),

    /**
     * 
     * @event onClick
     * 
     * @description change modal state
     * 
     */

    stateController = () => ( state ) ? setState ( false ) : setState ( true );

    /** @returns */

    return ( 
    
        <Fragment>

            { state && <Layout 
            
                state={ setState } 
            
                element={ element } /> }

            <Button 
            
                text={ text } 
            
                action={ stateController }
                
                icon={ icon } />
    
        </Fragment> 
    
    );

}

/** @exports Modals */
 
export default Modals;