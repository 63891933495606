import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Placeholder from "../../Components/Dashboard/Placeholder";

const Pricing = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Contacts page */

    return ( <section className="pricing">

        <Breadcrumbs data={ [

            { name: "Pricing", uri: "/app/prices" }

        ] } />

        <Placeholder text="Placeholder for 'Pricing' end-points." />

    </section> );

}

/** @exports Pricing */
 
export default Pricing;