/** @module                             Hooks */

import { usePagename }                  from "../../Hooks/usePagename";

import { useTranslation }               from "react-i18next";

/** @module                             Components */

import { Link }                         from "react-router-dom";

import Breadcrumbs                      from "../../Components/Dashboard/Breadcrumbs";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns pagelist for shop component
 * 
 */

ShopPages = ( { name } ) => { usePagename ( name ); const { t } = useTranslation (),

    /** shop page list */

    pages = [

        { name: t ( "manufacturers" ), route: "/app/shop/manufacturers" },

        { name: t ( "vendors" ), route: "/app/shop/suppliers" },

        { name: t ( "markups" ), route: "/app/shop/markups/global/default" },

    ];

    /** @returns */

    return (

        <section className="pagelist">

            <Breadcrumbs />

            {

                pages.map ( ( { name, route }, index ) => 
                
                    <Link to={ route } key={ index }>{ t ( name ) }</Link>
                
                )

            }

        </section>

    );

}

/** @exports ShopPages */
 
export default ShopPages;