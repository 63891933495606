import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Fieldset from "../../../Global/Forms/Fieldset";

import Checkbox from "../../../Global/Forms/Checkbox";

import { Input } from "../../../Global/Forms/Inputs_Spread";

/**
 * 
 * @exports MontageSettings
 * 
 * @description MontageSettings component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.state - The state object.
 * 
 * @param { Function } props.update - The update function.
 * 
 * @example <MontageSettings state={ state } update={ update } />
 * 
 * @returns { JSX.Element } MontageSettings
 * 
 */

export default function MontageSettings ( { state, update } ) { const 

    { t : __ } = useTranslation (),

    [ assemblyPrice, setAssemblyPrice ] = useState ( state.assemblyPrice );

    /**
     * 
     * @event useEffect
     * 
     * @description If assemblyPrice changes, update state.
     * 
     */

    useEffect ( () => {

        if ( assemblyPrice === state.assemblyPrice ) return;

        const timer = setTimeout( () => {

            update ( { ...state, assemblyPrice: parseFloat ( assemblyPrice ) } );
        
        }, 1000 );
      
        return () => clearTimeout ( timer );

    }, [ assemblyPrice ] );

    /** @returns { JSX.Element } MontageSettings */

    return (

        <Fieldset legend="Montage Settings">

            <Checkbox

                id={ "assembly" }

                text={ __ ( "assembly" ) }

                state={ state.assembly }

                action={ e => update ( { ...state, assembly: e.target.checked } ) }

                name={ "assembly" }

            />

            { /* If assembly is true, show assembly price input */

                state.assembly &&

                <div className="input-group default">

                    <input

                        type="number"

                        id="assemblyPrice"

                        value={ assemblyPrice }

                        onChange={ e => setAssemblyPrice ( e.target.value ) }

                    />

                </div>

            }

        </Fieldset>

    );

};