const LANGUAGES = {
    latvian: "latvian",
};

const LANGUAGE_LABELS = {
    [LANGUAGES.latvian]: "Latvian",
};

/* --- */

const getLanguageOptions = () => {
    const keys = Object.keys(LANGUAGES);

    return keys.map((name) => ({
        label: LANGUAGE_LABELS[name],
        name,
    }));
};

const getDefaultLanguage = () => {
    return LANGUAGES.latvian;
};

/* --- */

export default {
    getLanguageOptions,
    getDefaultLanguage,
};
