import { useState, useEffect, useRef } from "react";

import { BsBasket } from "react-icons/bs";

import useCart from "../../../Hooks/Cart/useCart";

import { CartTable } from "./CartTable";

/**
 * 
 * @exports ShoppingCart
 * 
 * @description ShoppingCart component for the header.
 * 
 * @example <ShoppingCart />
 * 
 */

export function ShoppingCart () { const 

    { cart, removeFromCart, updateQuantity, clearCart } = useCart (),

    [ expanded, setExpanded ] = useState ( false ),

    detector = useRef ( null );

    /**
     * 
     * @event useEffect
     * 
     * @description Close the cart container, if clicked outside.
     * 
     */

    useEffect ( () => { const 
        
        handleOutsideClick = ( event ) => {

            if ( detector.current && ! detector.current.contains ( event.target ) ) {

                setExpanded ( false );

            }

        };

        document.addEventListener ( "mousedown", handleOutsideClick );

        /** @returns { void } */

        return () => document.removeEventListener ( "mousedown", handleOutsideClick );

    }, [] );

    /** @returns { JSX.Element } ShoppingCart */

    return (

        <li className="cartListElement" ref={ detector }>

            { /* Add cart length badge, if cart has at least one item */
            
                cart.length > 0 &&

                <div className="badge">

                    { cart.length }

                </div>

            }

            <button className="cart" aria-expanded={ expanded } onClick={ () => setExpanded ( ! expanded ) }>

                <BsBasket />

            </button>

            { /* Show cart container, if expanded is true */

                expanded &&

                <CartTable 

                    close={ () => setExpanded ( false ) }
                
                    cart={ cart } 
                
                    remove={ removeFromCart } 
                
                    update={ updateQuantity } 
                
                    reset={ clearCart } />

            }

        </li>

    );

};