const 

/**
 * 
 * @param { String } date
 * 
 * @returns formatted date (DD-MM-YYY)
 * 
 */

convertDate = date => { const 
    
    datetime    = new Date ( date ),

    year        = datetime.getFullYear  (),

    month       = datetime.getMonth     () + 1,

    day         = datetime.getDate      ();

    /** @returns date string (DD-MM-YYY) */

    return [ 
        
        day.toString ().padStart ( 2, '0' ), 
    
        month.toString ().padStart ( 2, '0' ), 
    
        year 
    
    ].join ( '.' );

},

/**
 * 
 * @param { String } date
 * 
 * @returns formatted time (HH:MM)
 * 
 */

convertTime = date => { const 
    
    datetime    = new Date ( date ),

    hours       = datetime.getHours     (),

    minutes     = datetime.getMinutes   ();

    /** @returns time string (HH:MM) */

    return [

        hours.toString ().padStart ( 2, '0' ),

        minutes.toString ().padStart ( 2, '0' )

    ].join ( ":" );

},

/**
 * 
 * @param { String } date
 * 
 * @returns formatted time (DD-MM-YYY HH:MM)
 * 
 */

convertDatetime = date => { const 
    
    datetime    = new Date ( date ),

    year        = datetime.getFullYear  (),

    month       = datetime.getMonth     () + 1,

    day         = datetime.getDate      (),

    hours       = datetime.getHours     (),

    minutes     = datetime.getMinutes   (),

    /** @description generate a date string (DD-MM-YYYY) */

    datestring = [ 
        
        day.toString        ().padStart ( 2, '0' ), 
    
        month.toString      ().padStart ( 2, '0' ), 
    
        year 
    
    ].join ( '.' ),

    /** @description generate a time string (HH:MM) */

    timestring = [ 
        
        hours.toString      ().padStart ( 2, '0' ), 
    
        minutes.toString    ().padStart ( 2, '0' ), 
    
    ].join ( ':' );

    /** @returns datetime string (DD-MM-YYY HH:MM) */

    return [ datestring, timestring, ].join ( ' - ' );

},

/**
 * 
 * @returns list of next 5 years starting from current
 * 
 */

yearList = () => { const 

    current     = new Date ().getFullYear (),

    max         = current + 5,

    list        = [];

    for ( let index = current; index <= max; index ++ ) {

        list.push ( { name: index.toString () } )

    }

    /** @returns */

    return list;

},

/**
 * 
 * @returns list of days of current year and month for select options
 * 
 */

dayListOptions = ( year = 1999, month = 1, direct = true ) => { const 
    
    days    = new Date ( year, month, 0 ).getDate (),

    options = ( direct ) ? [] : [ { name: "--", value: "" } ];

    for ( let index = 1; index <= days; index ++ ) {

        options.push ( { name: index, value: index } );

    }

    /** @returns */

    return options;

},

/**
 * 
 * @returns list of months for select options
 * 
 */

monthOptions = __ => {

    return [

        { name: __ ( "january" ),      value: "1" },

        { name: __ ( "february" ),     value: "2" },

        { name: __ ( "march" ),        value: "3" },

        { name: __ ( "april" ),        value: "4" },

        { name: __ ( "may" ),          value: "5" },

        { name: __ ( "june" ),         value: "6" },

        { name: __ ( "july" ),         value: "7" },

        { name: __ ( "august" ),       value: "8" },

        { name: __ ( "september" ),    value: "9" },

        { name: __ ( "oktober" ),      value: "10" },

        { name: __ ( "november" ),     value: "11" },

        { name: __ ( "december" ),     value: "12" },

    ];

},

/**
 * 
 * @returns list of appointment times for select options
 * 
 */

dayAppointments = data => { 

    /** validate data */
    
    if ( data === undefined ) return [ 
        
        { name: "--", value: "" } 
    
    ];

    /** options list placeholder */

    const options = [ { name: "--", value: "" } ];

    data.appointmentsForDay.forEach ( element => {

        /** validate appointment time status */

        if ( element.status === "active" ) { const

            startH  = element.startHour.toString ().padStart ( 2, '0' ),

            startM  = element.startMinute.toString ().padStart ( 2, '0' ),

            endH    = element.endHour.toString ().padStart ( 2, '0' ),

            endM    = element.endMinute.toString ().padStart ( 2, '0' );

            options.push ( { 
                
                name: `${ startH }:${ startM } - ${ endH }:${ endM }`, 
                
                value: `${ element.startHour }:${ element.startMinute }:${ element.endHour }:${ element.endMinute }`
            
            } );

        }

    } )

    /** @returns */

    return options;

},

/**
 * 
 * @returns ISO8601DateTime appontment start time and end time
 * 
 */

isoConvertner = ( optionString, year, month, day ) => { const 

    date = new Date ();

    var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
    
    const 

    optionArray = optionString.split ( ":" ),

    starts = new Date (

        year +'-' + pad(month) +'-' + pad(day) +'T' + pad(optionArray[ 0 ]) +':' + pad(optionArray[ 1 ]) +':' + "00" +dif + pad(Math.floor(Math.abs(tzo) / 60)) +':' + pad(Math.abs(tzo) % 60)

    ),

    ends = new Date (

        year +'-' + pad(month) +'-' + pad(day) +'T' + pad(optionArray[ 2 ]) +':' + pad(optionArray[ 3 ]) +':' + "00" +dif + pad(Math.floor(Math.abs(tzo) / 60)) +':' + pad(Math.abs(tzo) % 60)

    );

    return [ { appointmentStarts: starts, appointmentEnds: ends } ];

},

/**
 * 
 * @returns converts ISO8601DateTime into a date string
 * 
 */

extractDate = date => { const 
    
    iso     = new Date ( date ),

    year    = iso.getFullYear (),

    month   = iso.getMonth () + 1,

    day     = iso.getDate ();

    /** @returns date string (DD-MM-YYY) */

    return [

        day.toString ().padStart ( 2, '0' ),

        month.toString ().padStart ( 2, '0' ),

        year

    ].join ( "-" );

},

/**
 * 
 * @returns converts ISO8601DateTime into a time string
 * 
 */

extractTime = date => { const 
    
    iso         = new Date ( date ),

    hours       = iso.getHours ().toString ().padStart ( 2, "0" ),

    minutes     = iso.getMinutes ().toString ().padStart ( 2, "0" );

    /** @returns time string (HH:MM) */

    return [ hours, minutes ].join ( ":" );

}

/** @exports */

export {

    convertDate,

    convertTime,

    yearList, 

    monthOptions,

    dayListOptions,

    dayAppointments,

    isoConvertner,

    extractDate,

    extractTime,

    convertDatetime

}