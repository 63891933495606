// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__VMth9 {\n    display: grid;\n    grid-row-gap: 5px;\n\n    align-content: start;\n}\n\n.styles_containerFixedHeight__2as3l {\n    min-height: 90px;\n}\n\n/* --- */\n\n.styles_label__PI1TS {\n    font-weight: 600;\n    color: var(--text-color);\n}\n\n.styles_input__uSJWA {\n    width: 100%;\n    height: 45px;\n\n    color: var(--text-color);\n    background-color: var(--input-bg-color);\n\n    padding: 0 20px;\n\n    border-radius: 10px;\n    border: 1px solid var(--input-border-color);\n}\n\n.styles_inputWithError__FrpUq {\n    border-color: #FF0000;\n}\n\n.styles_inputDisabled__X2maM {\n    cursor: not-allowed;\n}\n\n.styles_inputError__FldL2 {\n    color: red;\n    font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/Forms/Common/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;;IAEjB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,QAAQ;;AAER;IACI,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,wBAAwB;IACxB,uCAAuC;;IAEvC,eAAe;;IAEf,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".container {\n    display: grid;\n    grid-row-gap: 5px;\n\n    align-content: start;\n}\n\n.containerFixedHeight {\n    min-height: 90px;\n}\n\n/* --- */\n\n.label {\n    font-weight: 600;\n    color: var(--text-color);\n}\n\n.input {\n    width: 100%;\n    height: 45px;\n\n    color: var(--text-color);\n    background-color: var(--input-bg-color);\n\n    padding: 0 20px;\n\n    border-radius: 10px;\n    border: 1px solid var(--input-border-color);\n}\n\n.inputWithError {\n    border-color: #FF0000;\n}\n\n.inputDisabled {\n    cursor: not-allowed;\n}\n\n.inputError {\n    color: red;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__VMth9",
	"containerFixedHeight": "styles_containerFixedHeight__2as3l",
	"label": "styles_label__PI1TS",
	"input": "styles_input__uSJWA",
	"inputWithError": "styles_inputWithError__FrpUq",
	"inputDisabled": "styles_inputDisabled__X2maM",
	"inputError": "styles_inputError__FldL2"
};
export default ___CSS_LOADER_EXPORT___;
