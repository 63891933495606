/** @import modules */

import { useContext }       from "react";

/** @import contexts */

import { ThemeContext }     from "../../../Contexts/Theme";

/** @import icons */

import { 
    
    MdOutlineModeNight, 
    
    MdOutlineLightMode }    from "react-icons/md";

/** 
 * 
 * @returns Header theme switch component 
 * 
 */

const ThemeSwitch = () => { const

    { theme, setTheme } = useContext ( ThemeContext );

    /** @return component */

    return ( <li>
        
        <button onClick={ () => ( theme === "light" ) ? setTheme ( "default" ) : setTheme ( "light" ) }>
            
            { ( theme === "light" ) ? <MdOutlineModeNight /> : <MdOutlineLightMode /> }
            
        </button>
        
    </li> );

}

/** @export ThemeSwitch */
 
export default ThemeSwitch;