/** @module                             Components */

import Fieldset                         from "../../Global/Forms/Fieldset";

import SelectSpread                     from "../../Global/Forms/Select_Spread";

import { LabeledInput }                 from "../../Global/Forms/Inputs_Spread";

const 

TireOptions = ( { __, state, action, loading } ) => { 

    /** @returns Size component */

    return (

        <Fieldset legend={ __ ( "tire_options" ) }>

            <div className="grid grid_template-columns-3">

                <SelectSpread
                    
                    id="season"

                    action={ action }

                    options={ [

                        { value: "s", name: "S" },

                        { value: "w", name: "W" },

                        { value: "ms", name: "MS" }

                    ] }

                    value={ state.season }

                    def={ true }

                    defValue={ state.season }

                    loading={ loading }
                
                />

                <SelectSpread
                    
                    id="speedRating"

                    action={ action }

                    options={ [

                        { value: "f", name: "F" },

                        { value: "g", name: "G" },

                        { value: "j", name: "J" },

                        { value: "k", name: "K" },

                        { value: "l", name: "L" },

                        { value: "m", name: "M" },

                        { value: "n", name: "N" },

                        { value: "p", name: "P" },

                        { value: "q", name: "Q" },

                        { value: "r", name: "R" },

                        { value: "s", name: "S" },

                        { value: "t", name: "T" },

                        { value: "u", name: "U" },

                        { value: "h", name: "H" },

                        { value: "v", name: "V" },

                        { value: "w", name: "W" },

                        { value: "y", name: "Y" }

                    ] }

                    value={ state.speedRating }

                    def={ true }

                    defValue={ state.speedRating }

                    loading={ loading }
                
                />

                <SelectSpread
                    
                    id="loadIndex"

                    action={ action }

                    options={ generateLoadIndexes () }

                    value={ state.loadIndex }

                    def={ true }

                    defValue={ state.loadIndex }

                    loading={ loading }
                
                />

            </div>

        </Fieldset>

    );

},

/** Generate load indexes from 62 to 126 */

generateLoadIndexes = () => {

    const loadIndexes = [];

    for ( let i = 62; i <= 126; i++ ) {

        loadIndexes.push ( { value: i, name: i } );

    }

    return loadIndexes;

}

/** @exports TireOptions */
 
export default TireOptions;