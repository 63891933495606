// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Boolean_boolean__f7tg1 {\n\n    display: flex;\n\n    width: 100%;\n\n    height: 38px;\n\n    align-items: center;\n\n    background-color: var( --main-color );\n\n    border-radius: 0.3rem;\n\n}\n\n.Boolean_boolean__f7tg1:hover {\n\n    cursor: pointer;\n\n}\n\n.Boolean_boolean__f7tg1[ aria-checked=\"true\" ] {\n\n    background-color: var( --accent-color );\n\n}\n\n.Boolean_boolean__f7tg1[ aria-checked=\"true\" ] > .Boolean_text__wuYj\\+ {\n\n    color: white;\n\n}\n\n.Boolean_text__wuYj\\+ {\n\n    font-size: 0.9rem;\n\n    color: var( --typography-color );\n\n    margin: 0.2rem 0.7rem;\n\n    pointer-events: none;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Filters/Elements/Boolean.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;IAEb,WAAW;;IAEX,YAAY;;IAEZ,mBAAmB;;IAEnB,qCAAqC;;IAErC,qBAAqB;;AAEzB;;AAEA;;IAEI,eAAe;;AAEnB;;AAEA;;IAEI,uCAAuC;;AAE3C;;AAEA;;IAEI,YAAY;;AAEhB;;AAEA;;IAEI,iBAAiB;;IAEjB,gCAAgC;;IAEhC,qBAAqB;;IAErB,oBAAoB;;AAExB","sourcesContent":[".boolean {\n\n    display: flex;\n\n    width: 100%;\n\n    height: 38px;\n\n    align-items: center;\n\n    background-color: var( --main-color );\n\n    border-radius: 0.3rem;\n\n}\n\n.boolean:hover {\n\n    cursor: pointer;\n\n}\n\n.boolean[ aria-checked=\"true\" ] {\n\n    background-color: var( --accent-color );\n\n}\n\n.boolean[ aria-checked=\"true\" ] > .text {\n\n    color: white;\n\n}\n\n.text {\n\n    font-size: 0.9rem;\n\n    color: var( --typography-color );\n\n    margin: 0.2rem 0.7rem;\n\n    pointer-events: none;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boolean": "Boolean_boolean__f7tg1",
	"text": "Boolean_text__wuYj+"
};
export default ___CSS_LOADER_EXPORT___;
