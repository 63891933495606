/** @module                             Components */

import Fieldset                         from "../../Global/Forms/Fieldset";

import { Input }                        from "../../Global/Forms/Inputs_Spread";

const 

Model = ( { __, state, action, loading } ) => {

    /** @returns Model component */

    return (

        <Fieldset legend={ __ ( "model" ) }>

            <Input

                type="text"

                label={ __ ( "model" ) }
                    
                value={ state.model } 

                action={ action }

                name={ "model" }

                disabled={ loading }
                
            />

        </Fieldset>

    );

}

/** @exports Model */
 
export default Model;