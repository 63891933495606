import { Text, View } from '@react-pdf/renderer';

export default function DocumentManager ( { manager } ) {

    return (

        <View>

            <Text style={ { fontWeight: 500 } }>

                { [ manager?.adminUser?.firstName, manager?.adminUser?.lastName ].join ( " " ) }

            </Text>

            <Text>Tālrunis: { manager?.adminUser?.phoneNr || "-" }</Text>

            <Text>E-Pasts: { manager?.adminUser?.email || "-" }</Text>

        </View>

    );

};