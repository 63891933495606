import { Text, View, Image } from '@react-pdf/renderer';

import Logotype from "../../../../../Assets/tireshop-logo.png";

/**
 * 
 * @exports DocumentHeader
 * 
 * @description document header component
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentHeader () {

    /**
     * 
     * @description
     * 
     * Render the document header component.
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View style={ { flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", gap: 5, alignItems: "center" } }>

            <Text>

                Pasūtijums

            </Text>

            <Image src={ Logotype } style={ { width: 150 } } />
            
        </View>

    );

};