/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

/** @module                         Queries */

import { SUPPLIERS }                from "../../../Queries/Suppliers";

import { SUPPLIER_PREVIEW }         from "../../../Queries/Suppliers";

/** @module                         React-Icons */

import { AiOutlinePlusCircle }      from "react-icons/ai";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import FetchTable                   from "../../../Components/Dashboard/Global/Table/FetchTable";

import PageActions                  from "../../../Components/Dashboard/Global/PageActions";

/**
 * 
 * @param { String } name 
 * 
 * @returns initial page component
 * 
 */

const Suppliers = ( { name } ) => { const { t } = useTranslation ();

    /** @event usePagename */

    usePagename ( name );

    /** @return Suppliers page */

    return ( <section className="suppliers">

        <Breadcrumbs />

        <PageActions actions={ [

            { name: t ( "create_new" ), uri: "/app/shop/suppliers/create", icon: <AiOutlinePlusCircle /> }

        ] } />

        <FetchTable
        
            query={ SUPPLIERS }

            identifier="suppliers"

            stringSearch={ true }

            header={ [ 
                
                "name", 
                
                "vat", 

                "markup",
                
                "visible", 
                
                "published"
            
            ] }

            contents={ [

                { 
                    
                    type: "target_key_link_name", 
                
                    route: "/app/shop/suppliers/edit/%PARAM%", 
                
                    link_param: "id",
                    
                    text_param: "name"
                
                },

                { 
                    
                    type: "boolean", 
                
                    key: "addPvn" 
                
                },

                { 
                    
                    type: "keytag", 
                
                    key: "markup"
                
                },

                { 
                    
                    type: "boolean", 
                
                    key: "visible" 
                
                },

                { 
                    
                    type: "date", 
                
                    key: "createdAt" 
                
                }

            ] }

            actions={ [

                { 
                    
                    type: "edit", 
                
                    permalink: "/app/shop/suppliers" 
                
                },

                { 
                    
                    type: "preview", 
                
                    query: SUPPLIER_PREVIEW 
                
                }

            ] }
        
        />

    </section> );

}

/** @exports Suppliers */
 
export default Suppliers;