import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Banks = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Banks page */

    return ( <section className="banks">

        Banks page

    </section> );

}

/** @exports Banks */
 
export default Banks;