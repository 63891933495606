import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import { TABLE_SORT_PARAMS } from "../../../../../../Queries/Enums";

import CenterLoader from "../../../../Loaders/CenterLoader";

import { camelToSnake } from "../../../../Utils/caseConverter";

import { GoTriangleDown, GoTriangleUp, GoTriangleLeft } from "react-icons/go";

export default function ThSortable ( { name, __, refetch, variables } ) { 
    
    const

    { loading, data } = useQuery ( TABLE_SORT_PARAMS ),

    onSort = e => { 

        let direction, sortParamIndex;

        const 

        column = camelToSnake ( e.target.dataset.column );

        if ( variables?.sortParams ) {

            if ( variables.sortParams.column === column ) {

                sortParamIndex = data.__type.enumValues.findIndex ( sort_value => sort_value.name.toLowerCase () === variables.sortParams.direction );

                direction = sortParamIndex === data.__type.enumValues.length - 1 ? data.__type.enumValues[ 0 ].name.toLowerCase () : data.__type.enumValues[ sortParamIndex + 1 ].name.toLowerCase ();

            } else {

                direction = data.__type.enumValues[ 0 ].name.toLowerCase ();

            };

        } else {

            direction = data.__type.enumValues[ 0 ].name.toLowerCase ();

        };

        refetch ( { sortParams: { column, direction } } );

    };

    return (

        loading ? <th> <CenterLoader size={ 16 } /> </th> :

        <th className="sortable" onClick={ onSort } data-column={ name }>

            <div className="sortableCell" data-column={ name }>

                { __ ( `product_table_${ name }` ) }

                {

                    variables?.sortParams?.column === camelToSnake ( name ) ?

                        ( 
                            
                            variables?.sortParams?.direction === "asc" ? 
                        
                            <span className="icon" data-column={ name }> <GoTriangleUp /> </span>
                            
                        : 
                        
                            <span className="icon" data-column={ name }> <GoTriangleDown /> </span>
                        
                        )

                    : <span className="icon" data-column={ name }> <GoTriangleLeft /> </span>

                }

            </div>

        </th>

    );

};