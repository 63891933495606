const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table size component
 * 
 */

Size = ( { product } ) => {

    /** @returns */

    return ( <td data-title="size">

        { `${ product.width } | ${ product.height } | R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" }` }

    </td> );

}

/** @exports Size */
 
export default Size;