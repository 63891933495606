const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table sale price component
 * 
 */

Quantity = ( { product, title = "local_quantity" } ) => {

    /** @returns */

    return ( <td data-title={ title }>

        { title === "local_quantity" && quantityUtility ( parseInt ( product.productVendors[0].qty ) ) }

        { title === "24H" && quantityUtility ( parseInt ( product.productVendors[0].qtyHour ) ) }

        { title === "1_3_days" && quantityUtility ( parseInt ( product.productVendors[0].qtyDays ) ) }

        { title === "5_7_days" && quantityUtility ( parseInt ( product.productVendors[0].qtyWeeks ) ) }

    </td> );

},

/**
 * 
 * @param { Number } quantity
 * 
 * @returns product quantity
 * 
 */

quantityUtility = ( quantity = 0 ) => {

    if ( ! quantity ) return <span className="color-alert">--</span>

    if ( quantity <= 4 && quantity > 0 ) return <span className="color-warning">{ quantity }</span>

    if ( quantity > 4 ) return <span className="color-success">{ quantity }</span>

} 

/** @exports Quantity */
 
export default Quantity;