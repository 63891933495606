import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { FaRegTrashAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdUndo } from "react-icons/io";

import Input from "./../../Components/Common/Forms/input/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";
import ButtonIconV2 from "../../Components/Common/ButtonIconV2/index.js";

import styles from "./styles.module.css";


const PREFIX = "bankDetails";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    bankName: getName("BankName"),
    swift: getName("Swift"),
    bankAddress: getName("BankAddress"),
    accountNumber: getName("AccountNumber"),
};

const ROW = {
    [FIELDS.bankName]: "",
    [FIELDS.swift]: "",
    [FIELDS.bankAddress]: "",
    [FIELDS.accountNumber]: "",
};

/* --- */

const FormClientEditBankDetails = (props) => {
    const [isFormVisible, setIsFormVisible] = useState(false);

    const [rows, setRows] = useState(() => props.initialRows);

    const { t } = useTranslation();

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const onToggleForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const onAddRow = () => {
        setRows((prev) => {
            return [...prev].concat(ROW);
        });
    };

    const onRemoveRow = (index) => {
        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            isDeleted: true,
        };

        setRows(newRows);
    };

    const onUnlockRow = (index) => {
        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            isDeleted: false,
        };

        setRows(newRows);
    };

    const onChangeField = (index, field, evt) => {
        const value = evt.target.value || "";

        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            [field]: value,
        };

        setRows(newRows);
    };

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const renderRowRemoveLine = (index) => {
        let removeOrUndo = null;

        if (rows[index].isDeleted) {
            removeOrUndo = (
                <ButtonIconV2
                    icon={IoMdUndo}
                    onClick={() => {
                        onUnlockRow(index);
                    }}
                    isDisabled={isDisabled()}
                    isBlue
                />
            );
        } else {
            removeOrUndo = (
                <ButtonIconV2
                    icon={FaRegTrashAlt}
                    onClick={() => {
                        onRemoveRow(index);
                    }}
                    isDisabled={isDisabled()}
                    isRed
                />
            );
        }

        return (
            <div className={styles.rowRemoveLine}>
                <ButtonUnderline
                    label={`${t("bank")} ${index + 1}`}
                />
                {removeOrUndo}
            </div>
        );
    };

    const renderRowForm = (index, data, formMethods) => {
        const { handleBlur } = formMethods;

        const {
            bankName,
            swift,
            bankAddress,
            accountNumber,
        } = FIELDS;

        const rowClassName = [styles.row];

        if (data.isDeleted) {
            rowClassName.push(styles.rowDeleted);
        }

        return (
            <div className={rowClassName.join(" ")}>

                <div className={styles.column}>
                    <Input
                        name={`${index}-${bankName}`}
                        label={t("bank_name")}
                        placeholder="......"
                        value={data[bankName]}
                        onChange={(evt) => {
                            onChangeField(index, bankName, evt);
                        }}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={`${index}-${swift}`}
                        label="SWIFT"
                        placeholder="......"
                        value={data[swift]}
                        onChange={(evt) => {
                            onChangeField(index, swift, evt);
                        }}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
                <div className={styles.column}>
                    <Input
                        name={`${index}-${bankAddress}`}
                        label={t("bank_address")}
                        placeholder="......"
                        value={data[bankAddress]}
                        onChange={(evt) => {
                            onChangeField(index, bankAddress, evt);
                        }}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={`${index}-${accountNumber}`}
                        label={t("account_number")}
                        placeholder="......"
                        value={data[accountNumber]}
                        onChange={(evt) => {
                            onChangeField(index, accountNumber, evt);
                        }}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
            </div>
        );
    };

    const renderForms = (formMethods) => {
        const forms = [];

        for (let i = 0; i < rows.length; i += 1) {
            const data = rows[i];

            let header = null;

            if (i > 0) {
                header = renderRowRemoveLine(i);
            }

            forms.push(
                <div
                    key={`form-row-${i}`}
                >
                    {header}
                    {renderRowForm(i, data, formMethods)}
                </div>
            );
        }

        return forms;
    };

    const renderFormContent = (formMethods) => {
        if (!isFormVisible) {
            return null;
        }

        return (
            <>
                {renderForms(formMethods)}
                <ButtonUnderline
                    label={t("add_bank")}
                    icon={FiPlusCircle}
                    onClick={onAddRow}
                    isDisabled={isDisabled()}
                    isClickable
                    isBlue
                />
            </>
        );
    };

    const renderForm = (formMethods) => {
        const { handleSubmit } = formMethods;

        if (props.formRef) {
            props.formRef.current = formMethods;
            formMethods.values = {
                ...formMethods.values,
                rows,
            };
        }

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <ButtonUnderline
                    label={t("bank")}
                    onClick={onToggleForm}
                    isClicked={isFormVisible}
                    isClickable
                />
                {renderFormContent(formMethods)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={{}}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditBankDetails.defaultProps = {
    formRef: null,
    initialRows: [],
    onSubmit: () => { },
    isEditable: false,
};

export {
    FIELDS,
    ROW,
};

export default FormClientEditBankDetails;
