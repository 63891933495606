const COLUMNS = [
    {
        field: "companyName",
        label: "Company Name",
        sortBy: "company_name",
        width: 150,
    },
    {
        field: "name",
        label: "Name",
        sortBy: "first_name",
        width: 150,
    },
    {
        field: "regNr",
        label: "Registration Number",
        sortBy: "reg_nr",
        width: 200,
    },
    {
        field: "type",
        label: "Type",
        sortBy: "client_type",
        width: 120,
    },
    {
        field: "businessSphere",
        label: "Business sphere",
        sortBy: "business_sphere",
        width: 155,
    },
    {
        field: "discountGroup",
        label: "Discount group",
        sortBy: "discount_group",
        width: 160,
    },
    {
        field: "contactName",
        label: "Contact name",
        width: 140,
    },
    {
        field: "phone",
        label: "Phone",
        sortBy: "phone_nr",
        width: 140,
    },
    {
        field: "email",
        label: "Email",
        sortBy: "email",
        width: 200,
    },
    {
        field: "manager",
        label: "Manager",
        sortBy: "managers",
        width: 200,
    },
    {
        field: "newsletter",
        label: "Newsletter",
        width: 170,
    },
    {
        field: "isEnabled",
        label: "ENABLED/DISABLED",
        width: 190,
    },
    {
        field: "registrationDate",
        label: "Registration date",
        sortBy: "created_at",
        width: 190,
    },
    {
        field: "homepage",
        label: "WWW",
        sortBy: "homepage",
        width: 200,
    },
    {
        field: "B2BPortal",
        label: "B2B Portal",
        width: 140,
    },
];

/* --- */

export const getColumns = () => {
    return [...COLUMNS];
};

export const getColumnLabelByName = (name) => {
    for (let i = 0; i < COLUMNS.length; i += 1) {
        const column = COLUMNS[i];

        if (column.field === name) {
            return column.label;
        }
    }

    return "";
};

/* --- */

export default {
    getColumns,
    getColumnLabelByName,
};
