import { useTranslation } from "react-i18next";

export default function RunFlat ( { runFlat } ) { const 

    { t : __ } = useTranslation ();

    return (

        <td>

            { runFlat?.length ? __ ( runFlat ) : "-" }

        </td>

    );

};