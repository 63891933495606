/** @module                     React-Router */

import { useNavigate }          from "react-router-dom";

/** @module                     React-Icons */

import { AiOutlineEdit }        from "react-icons/ai";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table sale price component
 * 
 */

Edit = ( { id, type } ) => { const navigate = useNavigate (),

    /** @returns navigate to correct edit product page */

    editProduct = () => navigate ( `/app/products/edit/${ type }/${ id }` );

    /** @returns */

    return ( <button className="edit" onClick={ editProduct }>
        
        <AiOutlineEdit />
        
    </button> );

};

/** @exports Edit */
 
export default Edit;