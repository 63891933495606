import styles from "./Container.module.css";

import { useTranslation } from "react-i18next";

import Fieldset from "../../../Global/Forms/Fieldset";

import BatteriesFilters from "./Batteries/Filters";

import TiresFilters from "./Tires/Filters";

import RimsFilters from "./Rims/Filters";

/**
 * 
 * @exports ProductFilters
 * 
 * @description This component renders the filters for the product collection
 * 
 * @param { Object } props
 * 
 * @param { string } props.category The category of the product
 * 
 * @param { Function } props.refetch The refetch function to refetch the product collection
 * 
 * @param { Object } props.variables The variables to be used to refetch the product collection
 * 
 * @returns { JSX.Element } ProductFilters
 * 
 */

export default function ProductFilters ( { category, refetch, variables, loading } ) { const 

    { t } = useTranslation ();

    /** @returns { JSX.Elements } ProductFilters */

    return (

        <Fieldset legend={ `${ t ( "filters" ) } - ${ t ( category ) }` }>

            {

                category === "tire" ? 

                    /** If the category is "tires", render the TiresFilters component */
                
                    <TiresFilters
                    
                        refetch={ refetch }

                        variables={ variables }

                        product_loader={ loading }
                    
                    /> 
                    
                : 
                    
                category === "rim" ? 

                    /** If the category is "rims", render the RimsFilters component */
                
                    <RimsFilters 
                    
                        refetch={ refetch }

                        variables={ variables }

                        product_loader={ loading }

                    /> 
                
                : 
                
                category === "battery" ? 

                    /** If the category is "batteries", render the BatteriesFilters component */
                
                    <BatteriesFilters 
                    
                        refetch={ refetch }

                        variables={ variables }

                        product_loader={ loading }

                    /> 
                
                : 

                /** If the category is unknown, render a message */
                
                <p className={ styles.message }>
                    
                    - Unknown product category -
                    
                </p>

            }

        </Fieldset>

    );

};