import styles from "./Studs.module.css";

import { FaRegCircleXmark } from "react-icons/fa6";

import { FaRegCheckCircle } from "react-icons/fa";

import { useTranslation } from "react-i18next";

import { Fragment } from "react";

/**
 * 
 * @exports Studs
 * 
 * @description renders the studs attribute of a product in the product table component of the products collection dashboard page.
 * 
 * @param { Object } product
 * 
 * @returns { JSX.Element }
 * 
 */

export default function Studs ( { product } ) {

    const 
    
    { t : __ } = useTranslation (),

    studs = product?.studs || "";

    let icon;

    /**
     * 
     * @description
     * 
     * Switch statement to determine the icon to render based on the studs attribute of a product.
     * 
     * @returns { JSX.Element }
     * 
     */

    switch ( studs ) {

        case "yes":

            icon = <FaRegCheckCircle size={ 15 } />;

            break;

        case "no":

            icon = <FaRegCircleXmark size={ 15 } />;

            break;

        case "possible":

            icon = <Fragment> <FaRegCircleXmark size={ 15 } /> <FaRegCheckCircle size={ 15 } /> </Fragment>;

            break;

        default:

            icon = "-";

            break;

    }

    /**
     * 
     * @description
     * 
     * Renders the studs attribute of a product in the product table component of the products collection dashboard page.
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <td className={ styles[ studs || "default" ] }>

            { icon }

        </td>

    );

};