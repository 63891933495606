// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__ctPsa {\n    display: grid;\n    grid-gap: 20px;\n    justify-content: end;\n\n    padding: 20px 0;\n\n    border: 1px solid white;\n    border-left: none;\n    border-right: none;\n}\n\n.styles_setting__m-xRT {\n    display: grid;\n    grid-template-columns: 200px auto;\n    align-items: center;\n}\n\n.styles_settingLabel__nwJ-u {\n    font-weight: 600;\n}\n\n.styles_settingController__vTniP {\n    display: grid;\n    justify-content: end;\n}\n", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-currency-settings/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,oBAAoB;;IAEpB,eAAe;;IAEf,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 20px;\n    justify-content: end;\n\n    padding: 20px 0;\n\n    border: 1px solid white;\n    border-left: none;\n    border-right: none;\n}\n\n.setting {\n    display: grid;\n    grid-template-columns: 200px auto;\n    align-items: center;\n}\n\n.settingLabel {\n    font-weight: 600;\n}\n\n.settingController {\n    display: grid;\n    justify-content: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__ctPsa",
	"setting": "styles_setting__m-xRT",
	"settingLabel": "styles_settingLabel__nwJ-u",
	"settingController": "styles_settingController__vTniP"
};
export default ___CSS_LOADER_EXPORT___;
