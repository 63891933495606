/** @module                     Hooks */

import { usePagename }          from "../../Hooks/usePagename";

import { useTranslation }       from "react-i18next";

import { useState }             from "react";

import cookies                  from "js-cookie";

import i18next                  from "i18next";

/** @module                     Components */

import Fieldset                 from "../../Components/Dashboard/Global/Forms/Fieldset";

import Select                   from "../../Components/Dashboard/Global/Forms/Select";

import Tabs                     from "../../Components/Dashboard/Global/Tabs";

import Breadcrumbs              from "../../Components/Dashboard/Breadcrumbs";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns table settings page component
 * 
 */

GeneralSettings = ( { name } ) => { const 

    { t } = useTranslation (),

    [ language, setLanguage ] = useState ( cookies.get ( 'i18next' ) || "lv" ),

    [ productFilters, setProductFilters ] = useState ( JSON.parse ( localStorage.getItem ( "productFiltersType" ) ) || "static" );

    /** @event usePagename */

    usePagename ( name );

    /** @return Settings page */

    return ( <section className="settings general-settings">

        <Breadcrumbs />

        <Tabs data={ [

            { name: t ( "general"       ), uri: "/app/settings" },

            { name: t ( "tables"        ), uri: "/app/settings/tables" }

        ] } />

        <Fieldset legend={ t ( "language" ) }>

            <Select 
                        
                id="language"
                
                action={ e => {

                    i18next.changeLanguage ( e ); 
                                    
                    setLanguage ( e );

                } }
                
                options={ [

                    { name: t ( "lv" ), value: "lv" },

                    { name: t ( "en" ), value: "en" }

                ] }
                
                value={ language } />

        </Fieldset>

        <Fieldset legend={ t ( "product_filters_settings" ) }>

            <Select 
                        
                id="product_filters"
                
                action={ e => {

                    setProductFilters ( e );

                    localStorage.setItem ( "productFiltersType", JSON.stringify ( e ) );

                } }
                
                options={ [

                    { name: t ( "filter_modal" ), value: "modal" },

                    { name: t ( "filter_static" ), value: "static" }

                ] }
                
                value={ productFilters } />

        </Fieldset>

    </section> );

}

/** @exports GeneralSettings */
 
export default GeneralSettings;