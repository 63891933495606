import styles from "./Reset.module.css";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { IoMdClose } from "react-icons/io";

/**
 * 
 * @exports FiltersReset
 * 
 * @description This component renders a reset button and the current filters applied to the product collection.
 * 
 * @param { Object } props
 * 
 * @param { Object } props.variables The variables to be used to refetch the product collection
 * 
 * @param { Function } props.refetch The refetch function to refetch the product collection
 * 
 * @returns { JSX.Element } FiltersReset
 * 
 */

export default function FiltersReset ( { variables, refetch } ) { const

    { t } = useTranslation (),

    [ values, setValues ] = useState ( filterVariables ( variables ) );

    /**
     * 
     * @event useEffect
     * 
     * @description This effect sets the values to the filtered variables when the variables change.
     * 
     * @listens variables
     * 
     * @returns { void }
     *
     */

    useEffect ( () => { setValues ( filterVariables ( variables ) ); }, [ variables ] );

    /** @returns { JSX.Element } FiltersReset */

    return ( Object.keys ( values ).length > 0 &&

        <div className={ styles.container }>

            <span className={ styles.resetAll } onClick={ () => refetch ( resetVariables ( variables ) ) }>

                { t ( "reset_all" ) }

            </span>

            { /** Map the values to a span element with a close button and the value of the variable as text. */

                values.map ( ( [ key, value ] ) =>

                    <span className={ styles.tag } key={ key }>

                        <span className={ styles.close } onClick={ () => refetch ( { [ key ]: undefined } ) }>

                            <IoMdClose />

                        </span>

                        <span className={ styles.text }>

                            { t ( key ) } : { value instanceof Array ? value.join ( ", " ) : typeof value === "boolean" ? t ( "yes" ) : value }

                        </span>

                    </span>
                
                )

            }

        </div>

    );

};

/**
 * 
 * @function filterVariables
 * 
 * @param { Object } variables
 * 
 * @returns { Array }
 * 
 * @description filters variables to remove undefined, false, first, after, last, before and productType keys and values.
 * 
 */

function filterVariables ( variables ) {

    /** @returns { Array } */

    return Object.entries ( variables ).filter ( 
        
        ( 
            
            [ key, value ] ) => 
        
                value !== undefined 
                
                && 
                
                value !== false 
                
                && 
                
                key !== "first" 
                
                && 
                
                key !== "after" 
                
                && 
                
                key !== "last" 
                
                && 
                
                key !== "before" 
                
                && 
                
                key !== "productType"

                &&

                key !== "sortParams"
        
    );

};

/**
 * 
 * @function resetVariables
 * 
 * @param { Object } variables
 * 
 * @returns { Object }
 * 
 * @description resets all variables to undefined except for the productType and first variables.
 * 
 */

export function resetVariables ( variables ) { const 
    
    newVariables = { ...variables };

    for ( const key in newVariables ) {

        if ( key !== "productType" && key !== "first" ) newVariables [ key ] = undefined;

    }

    if ( ! newVariables.first && newVariables.last ) {

        newVariables.first = newVariables.last;

        newVariables.last = undefined;

    }

    return newVariables;

}