import { gql } from "@apollo/client";


const FETCH_ADMIN_MANAGERS = gql`
query fetchAdminManagers {
    adminManagers {
        id,
        fullName,
    }
}
`;

export default {
    FETCH_ADMIN_MANAGERS,
};