/** @import @module */

import { gql } from "@apollo/client";

const 

/** @name Products */

CURRENT_USER = gql`query {

    currentUser {

      id

      role

      email

    }

  }`;


/** @exports Queries */

export {

    CURRENT_USER

}