/** @module                 Hooks */

import { useTranslation }   from "react-i18next";

/** @module                 Utilities */

import { 
    
    convertDate, 
    
    convertTime }           from "../../Utils/Datetime";

/** @module                 Components */

import Text                 from "../../Global/Table/Text";

import Bool                 from "../../Global/Table/Bool";

import Edit                 from "../../Global/Table/Actions/Edit";

import Information          from "../Modals/Information";

import Preview              from "../../Global/Table/Actions/Preview";

import Tag                  from "../../Global/Table/Tag";

const 

/**
 * 
 * @param { Object } products
 *  
 * @returns managers table component
 * 
 */

Table = ( { products } ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <table>

        <thead>

            <tr>

                <th>{ t ( "date" ) }</th>

                <th>{ t ( "start" ) }</th>

                <th>{ t ( "end" ) }</th>

                <th>{ t ( "serviceBox" ) }</th>

                <th>{ t ( "tiresStored" ) }</th>

                <th>{ t ( "autoNumber" ) }</th>

                <th>{ t ( "autoBrand" ) }</th>

                <th>{ t ( "autoModel" ) }</th>

                <th>{ t ( "actions" ) }</th>

            </tr>

        </thead>

        { ( typeof products != "undefined" ) && <tbody>

            { products.adminAppointments.edges.map ( ( product, index ) => <tr key={ index }>

                <Text text={ convertDate ( product.node.startTime ) } />

                <Text text={ convertTime ( product.node.startTime ) } />

                <Text text={ convertTime ( product.node.endTime ) } />

                <Tag text={ product.node.serviceBox } />

                <Bool text={ product.node.tiresStored } />

                <Text text={ product.node.autoNumber } />

                <Text text={ product.node.autoBrand } />

                <Text text={ product.node.autoModel } />

                <td data-title="actions" className="actions">

                    <Edit route={ `/app/montage/edit/${ product.node.id }` } />

                    <Preview element={ <Information atts={ { id : product.node.id } } /> } />

                </td>

            </tr> ) }

        </tbody> }

    </table> );

}

/** @exports Table */
 
export default Table;