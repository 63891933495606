import { gql } from "@apollo/client";


const FETCH_ADMIN_CLIENTS = gql`
query fetchAdminClients (
    $page: Int
    $items: Int
    $search: String
    $clientType: String
    $managerId: Int
    $clientId: Int
    $sort: String
) {
    adminClients (
        page: $page
        items: $items
        stringSearch: $search
        clientType: $clientType
        managerId: $managerId
        clientId: $clientId
        sortBy: $sort
    ) {
        items {
            id
            companyName
            regNr
            clientType
            discountGroup {
                value
            }
            fullName
            email
            mobilePhoneNr
            businessSphere {
                value
            }
            manager
            homepage
            theNewsCommunication
            createdAt
        }
        pagy {
            page
            totalPage
            count
        }
    }
}
`;

const FETCH_ADMIN_CLIENT = gql`
query fetchAdminClient ($id: ID!) {
    adminClient (id: $id) {
        id
        contractNumber
        dateOfContract
        managerId
        discountGroupId

        creditLimit
        paymentTerm
        creditLimitBalance
        unpaidInvoices
        debt
        paymentOfGoodId
        deliveryPlanId

        companyInformation
        companyName
        regNr
        pvnRegNr
        homepage

        firstName
        lastName
        mobilePhoneNr
        email
        faxNr
        landlinePhone
        accountingPhone
        accountingEmail

        logo
        apiAccess
        apiAccessComment
        directSupplierIds
        onSaleCommunication
        discountCommunication
        newsCommunication
        theNewsCommunication
        language
        comment
        currency
        businessSphereId

        addresses {
            id
            country
            city
            address
            postalCode
            comment
            isLegal
        }
        companyContacts {
            id
            firstName
            lastName
            phone
            email
            isMain
        }
        affiliates {
            id
            country
            name
            deliveryPlanId
            address
            comment
        }
        banks {
            id
            name
            address
            swift
            number
        }
    }
}
`;

const EDIT_CLIENT = gql`
mutation adminClientEdit(
    $id: ID!
    $generalInfoCreditLimit: Float
    $generalInfoTermOfPayment: String
    $generalInfoCreditLimitBalance: Float
    $generalInfoUnpaidInvoices: Int
    $generalInfoDebt: Float
    $generalInfoContractNumber: String
    $generalInfoDateOfContract: String
    $generalInfoManager: ID!
    $generalInfoDiscountGroup: ID!
    $generalInfoPaymentOfGoods: ID!
    $generalInfoDeliveryPlan: ID!
    $companyInfoCompanyName: String
    $companyInfoRegistrationNumber: String
    $companyInfoVATRegistrationNumber: String
    $companyInfoHomepage: String
    $contactInfoFirstName: String
    $contactInfoLastName: String
    $contactInfoMobilePhone: String
    $contactInfoEmail: String
    $contactInfoLandlinePhone: String
    $contactInfoFax: String
    $contactInfoAccountingPhone: String
    $contactInfoAccountingEmail: String
    $legalAddressCountry: String!
    $legalAddressCity: String!
    $legalAddressAddress: String!
    $legalAddressPostCode: String!
    $actualAddressCountry: String!
    $actualAddressCity: String!
    $actualAddressAddress: String!
    $actualAddressPostCode: String!
    $actualAddressComment: String
    $additionalInfoLanguage: String
    $additionalInfoBusinessSphere: ID
    $additionalInfoComment: String
    $additionalInfoDirectSuppliers: [Int!]
    $additionalInfoLogoFile: Upload
    $additionalInfoIsAPIAccess: Boolean
    $additionalInfoAPIAccess: String
    $additionalInfoIsDiscounts: Boolean
    $additionalInfoIsNews: Boolean
    $additionalInfoIsOnSale: Boolean
    $additionalInfoIsTheNews: Boolean
    $companyContacts: [AdminsCompanyContactFormInput!]
    $branchDetails: [ClientsAffiliateFormInput!]
    $bankDetails: [ClientsBankFormInput!]
    $currency: String
    $showVAT: Boolean
) {
    adminUpdateClient (
        input: {
            id: $id,
            clientInput: {
                contractNumber: $generalInfoContractNumber
                dateOfContract: $generalInfoDateOfContract
                managerId: $generalInfoManager
                discountGroupId: $generalInfoDiscountGroup
                creditLimit: $generalInfoCreditLimit
                paymentTerm: $generalInfoTermOfPayment
                creditLimitBalance: $generalInfoCreditLimitBalance
                unpaidInvoices: $generalInfoUnpaidInvoices
                debt: $generalInfoDebt
                paymentOfGoodId: $generalInfoPaymentOfGoods
                deliveryPlanId: $generalInfoDeliveryPlan
                companyInformation: $companyInfoCompanyName
                companyName: $companyInfoCompanyName
                regNr: $companyInfoRegistrationNumber
                pvnRegNr: $companyInfoVATRegistrationNumber
                homepage: $companyInfoHomepage
                firstName: $contactInfoFirstName
                lastName:  $contactInfoLastName
                mobilePhoneNr: $contactInfoMobilePhone
                email: $contactInfoEmail
                landlinePhone: $contactInfoLandlinePhone
                faxNr: $contactInfoFax
                accountingPhone: $contactInfoAccountingPhone
                accountingEmail: $contactInfoAccountingEmail
                companyContactsAttributes: $companyContacts
                affiliatesAttributes: $branchDetails
                banksAttributes: $bankDetails
                addressesAttributes: [
                    { country: $legalAddressCountry, city: $legalAddressCity, address: $legalAddressAddress, postalCode: $legalAddressPostCode, isLegal: true },
                    { country: $actualAddressCountry, city: $actualAddressCity, address: $actualAddressAddress, postalCode: $actualAddressPostCode, comment: $actualAddressComment, isLegal: false }
                ]
                logo: $additionalInfoLogoFile
                apiAccess: $additionalInfoIsAPIAccess
                apiAccessComment: $additionalInfoAPIAccess
                directSupplierIds: $additionalInfoDirectSuppliers
                onSaleCommunication: $additionalInfoIsOnSale
                discountCommunication: $additionalInfoIsDiscounts
                newsCommunication: $additionalInfoIsNews
                theNewsCommunication: $additionalInfoIsTheNews
                language: $additionalInfoLanguage
                comment: $additionalInfoComment
                businessSphereId: $additionalInfoBusinessSphere
                currency: $currency
                showVat: $showVAT
            }
        }
    ) {
        client {
            id
            contractNumber
            dateOfContract
            managerId
            discountGroupId
            creditLimit
            paymentTerm
            creditLimitBalance
            unpaidInvoices
            debt
            paymentOfGoodId
            deliveryPlanId
            companyInformation
            companyName
            regNr
            homepage
            firstName
            lastName
            mobilePhoneNr
            email
            landlinePhone
            faxNr
            accountingPhone
            accountingEmail
            companyContacts {
                id
                firstName
                lastName
                phone
                email
                isMain
            }
            affiliates {
                id
                country
                name
                deliveryPlanId
                address
                comment
            }
            banks {
                id
                name
                address
                swift
                number
            }
            logo
            apiAccess
            apiAccessComment
            directSupplierIds
            onSaleCommunication
            discountCommunication
            newsCommunication
            theNewsCommunication
            language
            comment
            businessSphereId
        }
    }
}
`;

const ADD_CLIENT = gql`
mutation adminCreateClient(
    $generalInfoCreditLimit: Float
    $generalInfoTermOfPayment: String
    $generalInfoCreditLimitBalance: Float
    $generalInfoUnpaidInvoices: Int
    $generalInfoDebt: Float
    $generalInfoContractNumber: String
    $generalInfoDateOfContract: String
    $generalInfoManager: ID!
    $generalInfoDiscountGroup: ID!
    $generalInfoPaymentOfGoods: ID!
    $generalInfoDeliveryPlan: ID!
    $companyInfoCompanyName: String
    $companyInfoRegistrationNumber: String
    $companyInfoVATRegistrationNumber: String
    $companyInfoHomepage: String
    $contactInfoFirstName: String
    $contactInfoLastName: String
    $contactInfoMobilePhone: String
    $contactInfoEmail: String
    $contactInfoLandlinePhone: String
    $contactInfoFax: String
    $contactInfoAccountingPhone: String
    $contactInfoAccountingEmail: String
    $legalAddressCountry: String!
    $legalAddressCity: String!
    $legalAddressAddress: String!
    $legalAddressPostCode: String!
    $actualAddressCountry: String!
    $actualAddressCity: String!
    $actualAddressAddress: String!
    $actualAddressPostCode: String!
    $actualAddressComment: String
    $additionalInfoLanguage: String
    $additionalInfoBusinessSphere: ID
    $additionalInfoComment: String
    $additionalInfoDirectSuppliers: [Int!]
    $additionalInfoLogoFile: Upload
    $additionalInfoIsAPIAccess: Boolean
    $additionalInfoAPIAccess: String
    $additionalInfoIsDiscounts: Boolean
    $additionalInfoIsNews: Boolean
    $additionalInfoIsOnSale: Boolean
    $additionalInfoIsTheNews: Boolean
    $companyContacts: [AdminsCompanyContactFormInput!]
    $branchDetails: [ClientsAffiliateFormInput!]
    $bankDetails: [ClientsBankFormInput!]
    $currency: String
    $showVAT: Boolean
) {
    adminCreateClient (
        input: {
            clientInput: {
                contractNumber: $generalInfoContractNumber
                dateOfContract: $generalInfoDateOfContract
                managerId: $generalInfoManager
                discountGroupId: $generalInfoDiscountGroup
                creditLimit: $generalInfoCreditLimit
                paymentTerm: $generalInfoTermOfPayment
                creditLimitBalance: $generalInfoCreditLimitBalance
                unpaidInvoices: $generalInfoUnpaidInvoices
                debt: $generalInfoDebt
                paymentOfGoodId: $generalInfoPaymentOfGoods
                deliveryPlanId: $generalInfoDeliveryPlan
                companyInformation: $companyInfoCompanyName
                companyName: $companyInfoCompanyName
                regNr: $companyInfoRegistrationNumber
                pvnRegNr: $companyInfoVATRegistrationNumber
                homepage: $companyInfoHomepage
                firstName: $contactInfoFirstName
                lastName:  $contactInfoLastName
                mobilePhoneNr: $contactInfoMobilePhone
                email: $contactInfoEmail
                landlinePhone: $contactInfoLandlinePhone
                faxNr: $contactInfoFax
                accountingPhone: $contactInfoAccountingPhone
                accountingEmail: $contactInfoAccountingEmail
                companyContactsAttributes: $companyContacts
                affiliatesAttributes: $branchDetails
                banksAttributes: $bankDetails
                addressesAttributes: [
                    { country: $legalAddressCountry, city: $legalAddressCity, address: $legalAddressAddress, postalCode: $legalAddressPostCode, isLegal: true },
                    { country: $actualAddressCountry, city: $actualAddressCity, address: $actualAddressAddress, postalCode: $actualAddressPostCode, comment: $actualAddressComment, isLegal: false }
                ]
                logo: $additionalInfoLogoFile
                apiAccess: $additionalInfoIsAPIAccess
                apiAccessComment: $additionalInfoAPIAccess
                directSupplierIds: $additionalInfoDirectSuppliers
                onSaleCommunication: $additionalInfoIsOnSale
                discountCommunication: $additionalInfoIsDiscounts
                newsCommunication: $additionalInfoIsNews
                theNewsCommunication: $additionalInfoIsTheNews
                language: $additionalInfoLanguage
                comment: $additionalInfoComment
                businessSphereId: $additionalInfoBusinessSphere
                currency: $currency
                showVat: $showVAT
            }
        }
    ) {
        client {
            id
            contractNumber
            dateOfContract
            managerId
            discountGroupId
            creditLimit
            paymentTerm
            creditLimitBalance
            unpaidInvoices
            debt
            paymentOfGoodId
            deliveryPlanId
            companyInformation
            companyName
            regNr
            homepage
            firstName
            lastName
            mobilePhoneNr
            email
            landlinePhone
            faxNr
            accountingPhone
            accountingEmail
            companyContacts {
                id
                firstName
                lastName
                phone
                email
                isMain
            }
            affiliates {
                id
                country
                name
                deliveryPlanId
                address
                comment
            }
            banks {
                id
                name
                address
                swift
                number
            }
            logo
            apiAccess
            apiAccessComment
            directSupplierIds
            onSaleCommunication
            discountCommunication
            newsCommunication
            theNewsCommunication
            language
            comment
            businessSphereId
        }
    }
}`;

export default {
    FETCH_ADMIN_CLIENTS,
    FETCH_ADMIN_CLIENT,
    EDIT_CLIENT,
    ADD_CLIENT,
};