/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

/** @module                         Components */

import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";

import Breadcrumbs                                 from "../../../Components/Dashboard/Breadcrumbs";

import Tabs                                        from "../../../Components/Dashboard/Global/Tabs";

const 

/**
 * 
 * @param {string} name
 * 
 * @returns {JSX.Element}
 * 
 * @exports Markups
 * 
 */

Markups = ( { name } ) => { const { t } = useTranslation (),
   location = useLocation(),

   link = location.pathname,

   mainLink = link.substring(0, link.lastIndexOf('/')),

   { discountGroup } = useParams(),

    nav_items = [
        { name: t ( "global_markup" ), route: `/app/shop/markups/global/${discountGroup}` },

        { name: t ( "vendors" ), route: `/app/shop/markups/vendors/${discountGroup}` },

        { name: t ( "manufacturers" ), route: `/app/shop/markups/manufacturers/${discountGroup}` },

        { name: t ( "season" ), route: `/app/shop/markups/seasons/${discountGroup}` },

    ];

    /** @event usePagename */

    usePagename ( name );

    /** @return Markups page */


    return (

        <section className="markups">

            <Breadcrumbs />

            <Tabs data={ [

                { name: t ( "Default" ), uri: `${mainLink}/default`},

                { name: t ( "Group A" ), uri: `${mainLink}/groupA` },

                { name: t ( "Group B" ), uri: `${mainLink}/groupB` },

                { name: t ( "Group C" ), uri: `${mainLink}/groupC` },

            ] } />

            <div className="markups_container">

                <nav>

                    <ul>

                        { nav_items.map ( ( item, key ) => 
                        
                            {

                                return (

                                    <li key={ key }>

                                        <NavLink end 
                                        
                                            to={ item.route }
                                            
                                            className={ ( { isActive } ) => ( isActive ? 'active' : 'inactive' ) }>
                                                
                                                { item.name }
                                                
                                        </NavLink>

                                    </li>

                                );

                            }

                        ) }

                    </ul>

                </nav>

                <div className="content">
                    
                    <Outlet />
                    
                </div>

            </div>

        </section>

    );

}

/** @exports Markups */
 
export default Markups;