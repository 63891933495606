/** @module                 React */

import { useState }         from "react";

/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { DELETE_USER }      from "../../Mutations/Managers";

const 

/** 
 * 
 * @returns useDeleteManager hook for deleting existing manager
 * 
 */

useDeleteManager = () => { const 

    /** @hooks */

    navigate                    = useNavigate (),

    [ loading,  setLoading  ]   = useState ( false ),

    [ errors,   setErrors   ]   = useState ( false ),

    /** @mutations */

    [ DeleteUser ] = useMutation ( DELETE_USER, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            return navigate ( `/app/managers/active` );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { Number } id
     * 
     * @returns delete user action
     * 
     */

    deleteManager = ( id ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true );

        if ( ! id ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        /** @mutation */

        DeleteUser ( { variables: { id } } );

    }

    /** @returns */

    return { deleteManager, loading, errors }

}

/** @exports useDeleteManager */

export default useDeleteManager;