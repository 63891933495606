/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import { useState }                 from "react";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import MontageTabs                  from "../../Components/Dashboard/Montage/Tabs";

import Fieldset                     from "../../Components/Dashboard/Global/Forms/Fieldset";

import { Input }                    from "../../Components/Dashboard/Global/Forms/Inputs_Spread";

import Button                       from "../../Components/Dashboard/Global/Forms/Button";

const 

AppointmentWorkhours = ( { name } ) => {

    /** @event usePagename */

    usePagename ( name );

    const 
    
    { t } = useTranslation (),

    [ state, setState ] = useState ( {

        workhours: {
            
            monday: {

                from: "",

                to: ""

            },

            tuesday: {

                from: "",

                to: ""

            },

            wednesday: {

                from: "",

                to: ""

            },

            thursday: {

                from: "",

                to: ""

            },

            friday: {

                from: "",

                to: ""

            },

            saturday: {

                from: "",

                to: ""

            },

            sunday: {

                from: "",

                to: ""

            }

        }

    } ),

    handleChange = ( event ) => {

        const { name, value } = event.target;

        setState ( {

            ...state,

            workhours: {

                ...state.workhours,

                [ event.target.id ]: {

                    ...state.workhours [ event.target.id ],

                    [ event.target.name ]: value

                }

            }

        } );

    },

    handleSubmit = ( event ) => {

        event.preventDefault ();

    };

    /** @returns AppointmentWorkhours */

    return (

        <section className="manufacturers">

            <Breadcrumbs />
            
            <MontageTabs />

            {

                Object.keys ( state.workhours ).map ( ( day, index ) => {

                    return (

                        <Fieldset legend={ t ( day ) } key={ index }>

                            <div className="grid grid_template-columns-2">

                                <Input

                                    type="text"

                                    name={ "from" }

                                    id={ day }

                                    value={ state.workhours [ day ].from }

                                    onChange={ handleChange }
                                
                                />

                                <Input

                                    type="text"

                                    name={ "to" }

                                    id={ day }

                                    value={ state.workhours [ day ].to }

                                    onChange={ handleChange }

                                />

                            </div>

                        </Fieldset>

                    );

            } ) }

            <Button

                text={ "save" }

                action={ handleSubmit }

            />

        </section>

    );

}

/** @exports AppointmentWorkhours */
 
export default AppointmentWorkhours;