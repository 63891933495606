/** @module                         Hooks */

import useFetch                     from "../../../../Hooks/useFetch";

import { useEffect, useState }                 from "react";

import { useTranslation }           from "react-i18next";

/** @module                         Queries */

import { MANAGERS_ROLE_ENUM }       from "../../../../Queries/Managers";

/** @module                         Utilities */

import { enumOptions }              from "../../Utils/enumOptions";

/** @module                         Components */

import CenterLoader                 from "../../../../Components/Dashboard/Loaders/CenterLoader";

import SelectSpread                 from "../../Global/Forms/Select_Spread";

const 

UserFilters = ( { variables, refetch, state, setState } ) => { const

    /** @event useFetch */

    { loading, data } = useFetch ( MANAGERS_ROLE_ENUM );

    /** @returns UserFilters */

    return (

        loading ?

            <div className="modal-settings-container">

                <CenterLoader />

            </div>

        :

        <Filters data={ data } variables={ variables } refetch={ refetch } state={ state } setState={ setState } />

    );

},

Filters = ( { variables, refetch, data, state, setState } ) => { const

    /** @event useTranslation */

    { t } = useTranslation (),

    /** @event useState */

    // [ state, setState ] = useState ( variables.role ),

    /** @event handleChange */

    handleChange = e => {

        /** @event setState */

        setState ( e.target.value );

        /** @event refetch */

        refetch ( 
                    
            { 
                
                role : e.target.value === "default" ? undefined : e.target.value,

                first: ( typeof variables.first == "undefined" ) ? variables.last : variables.first,

                last: undefined,

                before: undefined,

                after: undefined
            
            } 
            
        );

    };

    /** @returns UserFilters */

    return (

        <div className="modal-settings-container">

            <SelectSpread 

                id={ "role" }

                label={ t ( "role" ) }

                options={ enumOptions ( data.__type.enumValues ) }

                value={ state }

                def={ true }
                
                action={ handleChange } />

        </div>

    );

}

/** @exports UserFilters */
 
export default UserFilters;