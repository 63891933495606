import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const AdditionalInfo = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Additional_Information page */

    return ( <section className="additional_information">

        AdditionalInfo page

    </section> );

}

/** @exports AdditionalInfo */
 
export default AdditionalInfo;