import styles from './Columns.module.css';

import { useTranslation } from 'react-i18next';

import { useState, useEffect, useRef } from 'react';

import { productColumns } from './productColumns';

import Checkbox from '../../../../Global/Forms/Checkbox';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { MdDragIndicator } from "react-icons/md";

/**
 * 
 * @exports ProductColumns
 * 
 * @param { Object } props
 * 
 * @param { String } props.productType
 * 
 * @param { Array } props.columns
 * 
 * @param { Function } props.setColumns
 * 
 * @description A component that allows the user to select the columns to be displayed in the product table
 * 
 * @returns { JSX.Element }
 * 
 * @example <ProductColumns productType="tire" columns={ columns } setColumns={ setColumns } />
 * 
 */

export default function ProductColumns ( { productType, columns, setColumns } ) {

    const

    { t } = useTranslation (),

    [ checkboxList, setCheckboxList ] = useState ( productColumns[ productType ] ),
    
    elements = useRef ( null ),

    /**
     * 
     * @event onCheckbox
     * 
     * @param { Object } e
     * 
     * @description Updates the columns list based on the checkbox state when the checkbox is clicked 
     * 
     * @returns { void }
     * 
     */

    onCheckbox = e => {

        e.target.checked ? setColumns ( [ ...columns, e.target.name ] ) : setColumns ( columns.filter ( field => field !== e.target.name ) );
        
    },

    /**
     * 
     * @event searchColumns
     * 
     * @param { Object } e
     * 
     * @description Filters the columns list based on the search input value when the search input value changes 
     * 
     * @returns { void }
     * 
     */

    searchColumns = e => {

        const 
        
        search = e.target.value;

        elements.current.childNodes.forEach ( element => 
            
            {

                if ( ! element.textContent.toLowerCase ().includes ( search.toLowerCase () ) ) {

                    element.style.display = "none";

                } else {

                    element.style.display = "block";

                }

            } 
    
        );

    },

    /**
     * 
     * @event onDragEnd
     * 
     * @param { Object } result
     * 
     * @description Updates the checkboxList based on the dragged item when the item is dropped 
     * 
     * @returns { void }
     * 
     */

    onDragEnd = result => {

        if ( ! result.destination ) return;

        const updatedList = Array.from ( checkboxList );

        const [ movedItem ] = updatedList.splice ( result.source.index, 1 );

        updatedList.splice ( result.destination.index, 0, movedItem );

        setCheckboxList ( updatedList );

        const updatedColumns = updatedList.filter ( column => columns.includes ( column ) );

        setColumns ( updatedColumns );

    };

    /**
     * 
     * @event useEffect
     * 
     * @description Updates the checkboxList based on the productType and columns list when the productType or columns change
     * 
     * @returns { void }
     * 
     */

    useEffect ( () => {

        setCheckboxList ( sortList ( columns, productColumns[ productType ] ) );   

    }, [ columns, productType ] );

    /**
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <div className={ styles.container }>

            <div className="input-group default">

                <input type="text" onChange={ searchColumns } placeholder={ `${ t ( "search" ) }...` } />

            </div>

            <DragDropContext onDragEnd={onDragEnd}>

                <Droppable droppableId="checkboxList">

                    { provided => (

                        <div 
                            
                            className={ styles.collection } 
                            
                            ref={ node => { elements.current = node; provided.innerRef ( node ); } } 
                            
                            { ...provided.droppableProps }
                            
                        >

                            { /* Map through the checkboxList and render the draggable checkbox elements */
                            
                                checkboxList.map ( ( column, index ) => (

                                    columns.includes ( column ) ? (

                                        <Draggable key={ column } draggableId={ column } index={ index }>

                                            { provided =>

                                                <div ref={ provided.innerRef } { ...provided.draggableProps } { ...provided.dragHandleProps }>

                                                    <div className={ styles.draggable }>

                                                        <MdDragIndicator />

                                                        <Checkbox

                                                            id={ column }

                                                            text={ t ( column ) }

                                                            value={ column }

                                                            state={ columns.includes ( column ) }

                                                            action={ onCheckbox }

                                                        />

                                                    </div>

                                                </div>

                                            }

                                        </Draggable>

                                    ) : (

                                        <div key={ column }>

                                            <Checkbox

                                                id={ column }

                                                text={ t ( column ) }

                                                value={ column }

                                                state={ columns.includes ( column ) }

                                                action={ onCheckbox }

                                            />

                                        </div>

                                    )

                                ) )
                            
                            } { provided.placeholder }

                        </div>

                    ) }

                </Droppable>

            </DragDropContext>

        </div>

    );

}

/**
 * 
 * @name sortList
 * 
 * @param { Array } columns
 * 
 * @param { Array } productColumns
 * 
 * @description Sorts the columns list based on the productColumns list order and returns the sorted list
 * 
 * @returns { Array } [ ...checked, ...unchecked ]
 * 
 */

const sortList = ( columns, productColumns ) => {

    const

    checked = columns,

    unchecked = productColumns.filter ( column => ! columns.includes ( column ) );

    return [ ...checked, ...unchecked ];

};
