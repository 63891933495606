/** @module                     React-Icons */

import { 
    
    AiOutlineCheckCircle, 
    
    AiOutlineCloseCircle }      from "react-icons/ai";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns table boolean component
 * 
 */

ProductBoolean = ( { state } ) => {

    /** @returns */

    return ( 

        <td>

            { 
            
                state ? 
                
                    <AiOutlineCheckCircle 
                
                        className="color-success" 
                    
                        size={ 16 } /> 
                    
                : 
                
                    <AiOutlineCloseCircle 
                    
                    className="color-alert" 
                    
                    size={ 16 } /> 
                
            }

        </td>

    );

}

/** @exports ProductBoolean */
 
export default ProductBoolean;