/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { useParams }                from "react-router-dom";

import useFetch                     from "../../../Hooks/useFetch";

/** @module                         Queries */

import { MANUFACTURER }             from "../../../Queries/Manufacturers";

import { MANUFACTURER_PRODUCTS }    from "../../../Queries/Manufacturers";

import { PRODUCT_MAX }              from "../../../Queries/Products";

import { PRODUCT_PREVIEW }          from "../../../Queries/Products";

/** @module                         Components */

import ComponentLoader              from "../../../Components/Dashboard/Loaders/ComponentLoader";

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import FetchTable                   from "../../../Components/Dashboard/Global/Table/FetchTable";

/**
 * 
 * @param { String } name 
 * 
 * @returns initial page component
 * 
 */

const ManufacturerProducts = ( { name } ) => { const { t } = useTranslation (),

    { manufacturerId } = useParams (),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading, data, refetch } = useFetch ( MANUFACTURER, { 

        id: manufacturerId

    }, "cache-and-network" );

    /** @event usePagename */

    usePagename ( name );

    /** @return Manufacturer products page */

    return ( <section className="manufacturers">

        <Breadcrumbs />

        { loading ? <ComponentLoader size={ 3 } /> : <Products ids={ data.adminManufacturer.products.map ( object => object.id ) } /> }

    </section> );

}

/**
 * 
 * @param { Array } ids 
 * 
 * @returns manufacturer products table
 * 
 */

export const Products = ( { ids } ) => {  

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    // { loading, data, refetch } = useFetch ( MANUFACTURER_PRODUCTS, { 

    //     id: ids,
            
    //     first: JSON.parse ( localStorage.getItem ( `manufacturerProductsCollectionCount` ) ) || 12

    // }, "cache-and-network" );

    return ( 
    
        <FetchTable
        
            query={ MANUFACTURER_PRODUCTS }

            query_atts={ { id: ids } }

            identifier="manufacturerProducts"

            header={ [ 

                "image",
                
                "id", 

                "model"
            
            ] }

            contents={ [

                { 
                    
                    type: "image", 
                
                    src: "currentImage",

                    alt: "model"
                
                },

                { 
                    
                    type: "key_text", 
                
                    key: "id" 
                
                },

                { 
                    
                    type: "key_text", 
                
                    key: "model" 
                
                }

            ] }

            actions={ [

                { 
                    
                    type: "edit", 
                
                    permalink: "/app/shop/manufacturers" 
                
                },

                { 
                    
                    type: "preview", 
                
                    query: PRODUCT_PREVIEW 
                
                }

            ] }
        
        />
        
    );

}

/** @exports ManufacturerProducts */
 
export default ManufacturerProducts;