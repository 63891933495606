const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table speed index component
 * 
 */

SpeedIndex = ( { product } ) => {

    /** @returns */

    return ( <td data-title="speedIndex">

        { ( product.speedRating === null || product.speedRating === "undefined" ) ? "--" : product.speedRating.toUpperCase () }

    </td> );

}

/** @exports SpeedIndex */
 
export default SpeedIndex;