import styles from './ProductPreview.module.css';

import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { PRODUCT_PREVIEW } from './Query';

import CenterLoader from '../../../../Loaders/CenterLoader';

import Title from './components/Title';

import { convertDatetime } from '../../../../Utils/Datetime';

import TextArea from './components/TextArea';

import ProductImage from './components/ProductImage';

import ProductOptions from './components/Options';

import Quantities from './components/Quantity';

import ProductIcons from './components/ProductIcons';

/**
 * 
 * @exports ProductPreview component layout.
 * 
 * @param { productId }
 * 
 * @description product preview component layout.
 * 
 * @returns product preview component layout.
 * 
 */

export default function ProductPreview ( { productId } ) { const 

    { t } = useTranslation (),

    { loading, data } = useQuery ( PRODUCT_PREVIEW, { variables: { id: productId } } );

    /**
     * 
     * @returns product preview component layout.
     * 
     */

    return (

        <div className={ styles.container }>

            { loading ? <CenterLoader /> : 
            
                <div className={ styles.content }>

                    <div className={ styles.flexEnd }>

                        <span>ID: { data.adminProduct.id }</span>

                        <span>EAN: { ( data.adminProduct.ean === null ) ? "-" : data.adminProduct?.ean }</span>

                    </div>

                    <Title product={ data.adminProduct } />
                    
                    <div className={ styles.columns }>

                        <div className={ styles.column } style={ { flex: 0.5 } }>

                            <ProductImage product={ data.adminProduct } />

                        </div>

                        <div className={ styles.column } style={ { flex: 1 } }>

                            { data.adminProduct.productType === "tire" &&
                            
                                <ProductIcons product={ data.adminProduct } />
                            
                            }

                            <ProductOptions product={ data.adminProduct } />

                            <Quantities product={ data.adminProduct } />

                        </div>

                    </div>

                    { /** Display OEM for tire products only */
                    
                        data.adminProduct.productType === "tire" &&

                        <TextArea title="OEM">

                            { ( ! data.adminProduct.specifications.length ) ? "-" : data.adminProduct.specifications.join ( ". " ) }

                        </TextArea>

                    }

                    <TextArea title={ t ( "description" ) }>

                        { ( data.adminProduct.description === null ) ? "-" : data.adminProduct.description }

                    </TextArea>

                    <div className={ styles.flexEnd }>

                        <span>{ t ( "createdAt" ) }: { convertDatetime ( data.adminProduct.createdAt ) }</span>

                        <span>{ t ( "updatedAt" ) }: { convertDatetime ( data.adminProduct.updatedAt ) }</span>

                    </div>

                </div>
            
            }

        </div>

    );

};