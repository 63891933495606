// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__MQ9-T {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 10px;\n\n    align-items: center;\n    justify-content: start;\n\n    color: var(--typography-color);\n}\n\n.styles_containerSortable__AHMlp {\n    -webkit-user-select: none;\n            user-select: none;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.styles_sort__xW2hZ {\n    width: 15px;\n    height: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/Business/table-clients/column-name/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,sBAAsB;;IAEtB,8BAA8B;AAClC;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;;IAEjB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 10px;\n\n    align-items: center;\n    justify-content: start;\n\n    color: var(--typography-color);\n}\n\n.containerSortable {\n    user-select: none;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.sort {\n    width: 15px;\n    height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__MQ9-T",
	"containerSortable": "styles_containerSortable__AHMlp",
	"sort": "styles_sort__xW2hZ"
};
export default ___CSS_LOADER_EXPORT___;
