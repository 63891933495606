/**
 * 
 * @param { string } str
 * 
 * @returns { string }
 * 
 * @description Converts a string from camelCase to snake_case
 * 
 * @example camelToSnake ( "camelCase" ) // "camel_case"
 *
 */

export const camelToSnake = str => {

    return str.replace ( /([A-Z])/g, $1 => {

        return "_" + $1.toLowerCase ();

    } );

}