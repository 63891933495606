import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Currencies = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Currencies page */

    return ( <section className="currencies">

        Currencies page

    </section> );

}

/** @exports Currencies */
 
export default Currencies;