import { useParams } from "react-router-dom";

/**
 * 
 * @constant { Object } FILTERS
 * 
 * @exports FILTERS
 * 
 * @property { Array } tire
 * 
 * @property { Array } rim
 * 
 * @property { Array } battery
 * 
 * @description Products filter constants
 * 
 */

export const FILTERS = {

    tire: [

        "sortOrder",

        "width",

        "height",

        "diameter",

        "speedRating",

        "noiseIndex",

        "model",

        "manufacturer",

        "season",

        "loadIndex",

        "fuelEfficiency",

        "wetBreakingEfficiency",

        "vehicleType",

        "specifications",

        "vendorId",

        "studs",

        "year",

        "scraper",

        "runFlat"

    ],

    rim: [

        "sortOrder",

        "model",

        "radius",

        "offset",

        "pitchCircleDiameter",

        "manufacturer",

        "vendorId"

    ],

    battery: [

        "sortOrder",

        "model",

        "manufacturer",

        "voltage",

        "current",

        "capacity",

        "vendorId"

    ]

};

/**
 * 
 * @constant { Array } DEFAULT_FIELDS
 * 
 * @exports DEFAULT_FIELDS
 * 
 * @description Default product table fields
 * 
 */

export const DEFAULT_FIELDS = () => { const { productType } = useParams ();

    switch ( productType ) {

        case "tire":

            return [

                "id",

                "currentImage",

                "productName",

                "speedRating",

                "loadIndex",

                "fuelEfficiency",

                "studs",

                "year",

                "runFlat",

                "productVendors",

                "prices",

                "vendors"

            ];

        case "rim":

            return [

                "id",

                "currentImage",

                "productName",

                "boltsIncluded",

                "pitchCircleDiameter",

                "diameter",

                "offset",

                "productVendors",

                "prices",

                "vendors"

            ];

        case "battery":

            return [

                "id",

                "currentImage",

                "productName",

                "productVendors",

                "prices",

                "vendors"

            ];

        default:

            return [

                "currentImage",

                "manufacturer",

                "model",

                "productVendors",

            ];

    };
};