import styles from "./OrderProducts.module.css";

import { useTranslation } from "react-i18next";

export default function OrderProducts ( { data } ) {

    const { t } = useTranslation ();

    return (

        <td data-title="order_products" className={ styles.container }>

            {

                data?.orderProductVendors?.map ( ( product, index ) => {

                    const

                    productTypes = {

                        tire: `${ product.product.width } x ${ product.product.height } x R${ parseInt ( product.product.diameter ) }${ product?.product?.isCTire ? "C" : "" }`,

                        rim: `${ product.product.width }' | R${ parseInt ( product.product.diameter ) }`,

                        battery: `${ product.product.voltage } V | ${ product.product.current } A | ${ product.product.capacity } Ah`,

                    };

                    return ( <div className={ styles.content } key={ index }>
                        
                        <div className={ styles.manufacturer }>

                            { product?.product?.manufacturer?.name }
                            
                        </div>

                        <div className={ styles.model }>

                            { product.product.model }
                            
                        </div>

                        <div className={ styles.properties }>

                            { `${ productTypes[ product.product.productType] } - ${ product.count } ${ t ( "pcs" ) }` }
                            
                        </div>
                        
                    </div> )

                } )

            }

        </td>

    );

};