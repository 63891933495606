/** @module         Router */

import { Link }     from "react-router-dom";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table manufacturer component
 * 
 */

Manufacturer = ( { product } ) => {

    /** @returns */

    return ( <td data-title="manufacturer">

        <Link to={ `/app/products/manufacturer/${ product.manufacturerId }` }>
            
            { product.manufacturer.name.toUpperCase () }
            
        </Link>

    </td> );

}

/** @exports Manufacturer */
 
export default Manufacturer;