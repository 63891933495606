import                          "../Styles/Dashboard.css";

import                          "../Styles/Products.css";

/** @module                     Router */

import { Navigate, Outlet }     from 'react-router-dom';

/** @module                     Apollo */

import { CURRENT_USER }         from '../Queries/Authentification';

import { useQuery }             from '@apollo/client';

/** @module                     Contexts */

import { ThemeContext }         from '../Contexts/Theme';

import { PagenameContext }      from '../Contexts/Pagename';

import { CartContextProvider }  from "../Contexts/CartContext";

/** @module                     Hooks */

import Theme                    from '../Hooks/Theme';

import Pagename                 from '../Hooks/Pagename';

/** @module                     Components */

import Layout                   from "../Components/Dashboard/Layout";

import LoadPage                 from '../Components/Misc/LoadPage';


/**
 * 
 * @returns Dashboard page
 * 
 */

const Dashboard = () => { const 

    { loading, error } = useQuery ( CURRENT_USER );

    /** @return Dashboard page */

    return ( 
        <ThemeContext.Provider value={ Theme () }>
            <PagenameContext.Provider value={ Pagename () }>
                <CartContextProvider>
                    {
                        ( loading ) ? <LoadPage /> : ( ! loading && ! error ) ?
                            <Layout>
                                <Outlet />
                            </Layout>
                        : <Navigate to="/" />
                    }
                </CartContextProvider>
            </PagenameContext.Provider>
        </ThemeContext.Provider>
    );

}

/** @exports Dashboard */

export default Dashboard;