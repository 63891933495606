/** @import modules */

import { useEffect, useState } from "react"

/**
 * 
 * @returns AP Theme switch
 * 
 */

const Theme = () => { const 

    /** @register theme value */

    [ theme, setTheme ] = useState ( localStorage.getItem ( "ap_theme" ) || "default" );

    /** @switch theme value */

    useEffect ( () => {

        ( localStorage.getItem ( "ap_theme" ) !== theme ) &&

            localStorage.setItem ( "ap_theme", theme )

    }, [ theme ] )

    /** @return hook */

    return { theme, setTheme }

}

/** @export Theme */

export default Theme;