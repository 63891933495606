import React from "react";

import styles from "./styles.module.css";


const Checkbox = (props) => {
    const onChange = (evt) => {
        if (props.isDisabled) {
            return;
        }

        props.onChange(!props.isSelected, evt);
    };

    /* --- */

    const renderCheck = () => {
        const className = [styles.check];

        if (props.isDisabled) {
            className.push(styles.checkDisabled);
        }

        return (
            <span className={className.join(" ")} />
        );
    };

    const renderLabel = () => {
        const className = [styles.label];

        if (props.isDisabled) {
            className.push(styles.labelDisabled);
        }

        return (
            <label
                className={className.join(" ")}
                htmlFor={props.name}
            >
                {props.label}
            </label>
        );
    };

    /* --- */

    const inputClassName = [styles.input];

    if (props.error) {
        inputClassName.push(styles.inputWithError);
    }

    if (props.isDisabled) {
        inputClassName.push(styles.inputDisabled);
    }

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <input
                    className={inputClassName.join(" ")}
                    id={props.name}
                    name={props.name}
                    type="checkbox"
                    onChange={onChange}
                    checked={props.isSelected}
                />
                {renderCheck()}
            </div>
            {renderLabel()}
        </div>
    );
};

Checkbox.defaultProps = {
    name: "",
    label: "",
    error: "",
    onChange: () => { },
    isSelected: false,
    isDisabled: false,
};

export default Checkbox;
