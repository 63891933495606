/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name POSTS */

POSTS = gql`query

    fetchPosts (

        $language: BlogItemsLanguagesEnum,

        $tiremarketOnly: Boolean,

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminBlogItems (

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

            tiremarketOnly: $tiremarketOnly

            language: $language

        ) {

            edges {

                node {

                    content

                    createdAt

                    description

                    id

                    image

                    isPublished

                    language

                    tiremarketOnly

                    title

                    updatedAt

                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name POSTS_PREVIEW */

POSTS_PREVIEW = gql`query fetchPostsPreview ( $id: ID! ) {

    adminBlogItem ( id: $id ) {

        title

        isPublished

        tiremarketOnly

        language

        createdAt

        updatedAt

    }

}`,

/** @name POST */

POST = gql`query fetchPost (

    $id: ID!

) { adminBlogItem ( id: $id ) {

    content

    createdAt

    description

    id

    image

    isPublished

    language

    tiremarketOnly

    title

    updatedAt

} }`,

/** @name POSTS_LANGUAGE_ENUM */

POSTS_LANGUAGE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "BlogItemsLanguagesEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    POSTS,

    POST,

    POSTS_PREVIEW,

    POSTS_LANGUAGE_ENUM

}