/**
 * 
 * @param {Array} data
 * 
 * @param {boolean} textValue
 * 
 * @returns {Array} data
 * 
 * @description Sorts the data alphabetically or numerically
 * 
 * @example sortData ( data, true )
 * 
 */

export function sortData ( data, textValue ) {

    return [ ...data ].sort ( ( a, b ) => {

        if (typeof a.value === 'string' && typeof b.value === 'string') {

            if ( textValue ) return a.text.localeCompare(b.text);

            return a.value.localeCompare(b.value);

        } else {

            if ( textValue ) return a.text.localeCompare(b.text);

            return a.value - b.value;

        }

    });

}