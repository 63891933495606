/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name TEAM */

TEAM = gql`query

    fetchTeam (

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminContacts (

            first: $first

            last: $last

            before: $before

            after: $after

        ) {

            edges {

                node {

                    createdAt
      
                    email
                    
                    firstName
                    
                    id
                    
                    image
                    
                    lastName
                    
                    phone
                    
                    title
                    
                    updatedAt

                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name POSTS_PREVIEW */

TEAM_PREVIEW = gql`query fetchTeamPreview ( $id: ID! ) {

    adminContact ( id: $id ) {

        createdAt
      
        email
        
        firstName
        
        lastName
        
        phone
        
        title
        
        updatedAt

    }

}`,

/** @name POST */

MEMBER = gql`query fetchMember (

    $id: ID!

) { adminContact ( id: $id ) {

    id

    createdAt
      
    email
    
    firstName
    
    lastName
    
    phone
    
    title

    image
    
    updatedAt

} }`,

/** @name SHOP_CONTACTS */

SHOP_CONTACTS = gql`query {
  
    adminShopContacts {

      id
      
      address
      
      bank
      
      bankAccountNumber
      
      email
      
      mobilePhoneNr
      
      regNr
      
      serviceNumber

    }
    
  }`;

/** @exports Queries */

export {

    TEAM,

    TEAM_PREVIEW,

    MEMBER,

    SHOP_CONTACTS

}