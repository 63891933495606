/**
 * 
 * @param { String } legend 
 * 
 * @returns fieldset layout component
 * 
 */

const 

Fieldset = ( { children, legend = "Fieldset", description = false } ) => {

    /** @returns */

    return ( <fieldset>
        
        <legend>

            { legend }

        </legend>

        { description && <div className="description">

            { description }

        </div> }
    
        <div className="content">
    
            { children }
    
        </div>
    
    </fieldset> );

}

/** @exports Fieldset */
 
export default Fieldset;