/** @import modules */

import { Link } from "react-router-dom";

/** @import components */

import QuickSearch from "./QuickSearch";

/**
 * 
 * @param { actions } 
 * 
 * @returns page actions - buttons
 * 
 */

const PageActions = ( { actions, search = false } ) => { const 

    Actions = ( { buttons } ) => {

        return (
        
            <div className="product-actions">

                { buttons.map ( ( button, index ) => <Link to={ button.uri } key={ index }>

                    { button.icon }

                    <span>

                        { button.name }

                    </span>

                </Link> ) }

                { search && 

                    <div>

                        <QuickSearch />

                    </div>

                }

            </div>

        );

    }

    /** @return component */

    return ( <div className="page-actions">

        <Actions buttons={ actions } />

    </div> );

}

/** @export component */
 
export default PageActions;