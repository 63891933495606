import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { useEffect } from "react";

import useAuthorization from "../../../../../Hooks/Authorization/useAuthorization";

import { ORDER_STATUS_ENUM, ORDER_TYPE_ENUM } from "../../../../../Queries/Orders";

import { MANAGERS } from "../../../../../Queries/Managers";

import Fieldset from "../../../Global/Forms/Fieldset";

import SelectSpread from "../../../Global/Forms/Select_Spread";

import CenterLoader from "../../../Loaders/CenterLoader";

/**
 * 
 * @exports OrderSettings
 * 
 * @description OrderSettings component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.state - The state object.
 * 
 * @param { Function } props.update - The update function.
 * 
 * @example <OrderSettings state={ state } update={ update } />
 * 
 * @returns { JSX.Element } OrderSettings
 * 
 */

export default function OrderSettings ( { state, update, orderType } ) { 
    
    const
    
    { t : __ } = useTranslation (),

    { user } = useAuthorization (),

    { data: statusData, loading: statusLoading } = useQuery ( ORDER_STATUS_ENUM, { fetchPolicy: "network-only" } ),

    { data: typeData, loading: typeLoading } = useQuery ( ORDER_TYPE_ENUM, { fetchPolicy: "network-only" } ),

    { data: managersData, loading: managersLoading } = useQuery ( MANAGERS, { fetchPolicy: "network-only" } );

    /**
     * 
     * @event useEffect 
     * 
     * @description If user id is not set, update user id to user id.
     * 
     */

    useEffect ( () => {

        if ( state.userId === "" && user?.id ) {

            if ( orderType === "offer" ) {

                return update ( { ...state, userId: parseInt ( user.id ), orderType: "offer" } );

            }

            update ( { ...state, userId: parseInt ( user.id ) } );

        }

    }, [ managersData ] );

    /**
     * 
     * @event useEffect 
     * 
     * @description If not loading, update status to first enum value.
     * 
     */

    useEffect ( () => {

        if ( ! statusLoading && state.status === "" ) {

            update ( { ...state, status: statusData.__type.enumValues[ 0 ].name } );

        }

    }, [ statusData ] );

    /**
     * 
     * @event useEffect 
     * 
     * @description If not loading, update type to first enum value.
     * 
     */

    useEffect ( () => {

        if ( ! typeLoading && state.orderType === "" ) {

            update ( { ...state, orderType: typeData.__type.enumValues[ 0 ].name } );

        }

    }, [ typeData ] );

    /** @returns { JSX.Element } OrderSettings */

    return (

        <Fieldset legend={ __ ( "order" ) }>

            {

                managersLoading ? <CenterLoader /> : 
                
                <SelectSpread 
                    
                    id="userId"
                    
                    action={ e => update ( { ...state, userId: parseInt ( e.target.value ) } ) }
                    
                    options={ managersData.adminUsers.edges.map ( ( data ) => ( { value: data.node.id, name: `${ data.node.firstName } ${ data.node.lastName }` } ) ) }
                    
                    value={ state.userId } 
                        
                />

            }

            { /** If loading, show loader, else show select spread component with order type options. */

                typeLoading ? <CenterLoader /> : 
                
                <SelectSpread 
                    
                    id="orderType"

                    i18n={ true }
                    
                    action={ e => update ( { ...state, orderType: e.target.value } ) }
                    
                    options={ typeData.__type.enumValues.map ( ( { name } ) => ( { value: name, name } ) ) }
                    
                    value={ state.orderType } 
                        
                />

            }

            { /** If loading, show loader, else show select spread component with order status options. */

                statusLoading ? <CenterLoader /> : 
                
                <SelectSpread 
                
                    id="status"

                    i18n={ true }
                    
                    action={ e => update ( { ...state, status: e.target.value } ) }
                    
                    options={ statusData.__type.enumValues.map ( ( { name } ) => ( { value: name, name } ) ) }
                    
                    value={ state.status } 
                        
                />

            }

        </Fieldset>

    );

};