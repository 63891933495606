import { gql } from "@apollo/client";


const FETCH_DISCOUNT_GROUPS = gql`
query {
    discountGroups {
        id,
        value
    }
}
`;

export default {
    FETCH_DISCOUNT_GROUPS,
};
