/** @module                 React */

import { 
    
    useId, 
    
    forwardRef }            from "react";

/** @module                 i18n */

import { useTranslation }   from "react-i18next";

const 

/**
 * 
 * @param { String } type
 * 
 * @param { String } name
 * 
 * @param { String } id
 * 
 * @param { String } value
 * 
 * @param { Function } action
 * 
 * @param { Object } ref
 * 
 * @returns input component
 * 
 */

Input = forwardRef ( ( { type = "text", name, id, value = "", action = false }, ref ) => { const 
    
    generateId = useId ();

    /** @returns */

    return ( <div className="input-group">

        <input 
            
            type={ type } 
            
            id={ ( ! id ) ? generateId : id } 
            
            name={ ( ! name ) ? generateId : name }
            
            value={ value }
            
            onChange={ ( action === false ) ? e => e.target.value : e => action ( e.target.value ) }
            
            ref={ ref } />

    </div> );

} ),

/**
 * 
 * @param { String } type
 * 
 * @param { String } name
 * 
 * @param { String } id
 * 
 * @param { String } value
 * 
 * @param { Function } action
 * 
 * @param { String } label
 * 
 * @param { String } desc
 * 
 * @param { Object } ref
 * 
 * @returns labeled input component
 * 
 */

LabeledInput = forwardRef ( ( { type = "text", name, id, value = "", action = false, label = "Unknown", desc = false }, ref ) => { const 

    { t } = useTranslation (),
    
    generateId = useId ();

    /** @returns */

    return ( <div className="input-group">

        <label htmlFor={ ( ! id ) ? generateId : id } className="label">

            <div className="name">
                
                { t ( label ) }
                
            </div>

            <Input 

                type={ type } 

                id={ ( ! id ) ? generateId : id } name={ name } 

                value={ value } action={ action }
                
                ref={ ref } />

            { desc !== false && <small>

                { t ( desc ) }

            </small> }

        </label>

    </div> );

} );

/** @exports Components */
 
export { 

    LabeledInput,
    
    Input 

}