
const TYPES = {
    onlineStoreB2C: "b2c",
    B2B: "b2b",
};

/* --- */

const getTypes = () => {
    return { ...TYPES };
};

const isOnlineStoreB2C = (type) => {
    return type === TYPES.onlineStoreB2C;
};

const isB2B = (type) => {
    return type === TYPES.B2B;
};

/* --- */

export default {
    getTypes,
    isOnlineStoreB2C,
    isB2B,
};

