/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

/** @module                         Components */

import { NavLink }                  from "react-router-dom";

import ComponentLoader              from "../Loaders/ComponentLoader";


const 

/**
 * 
 * @param { Boolean } loading
 * 
 * @param { Object } data
 * 
 * @param { String } id
 * 
 * @returns pages lanugage tabs component
 * 
 */

LanguageTabs = ( { loading, data, route } ) => { const { t } = useTranslation ();

    /** @return */

    return ( 
        
        loading ? <ComponentLoader size={ 1 } /> : 
        
        <div className="tabs">

            <NavLink 
                
                to={ route } end
            
                className={ ( { isActive } ) => ( isActive ? 'active' : 'inactive' ) }>

                { t ( "all" ) }

            </NavLink>

            { data.__type.enumValues.map 
            
                ( ( tab, index ) => 
            
                    <NavLink 
                        
                        to={ `${ route }/${ tab.name }` }
                    
                        key={ index } 
                    
                        className={ ( { isActive } ) => ( isActive ? 'active' : 'inactive' ) }>

                        { tab.name.toUpperCase () }

                    </NavLink> 
            
                ) 
            
            }
    
        </div> 
    
    );

}

/** @export LanguageTabs */
 
export default LanguageTabs;