// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RetailPrice_container__j2UFY > *:not( :last-child ) {\n\n  margin-bottom: 0.25rem;\n\n}\n\n.RetailPrice_price__gYMmM {\n\n    color: var( --invert-solid );\n\n}\n\n.RetailPrice_description__cc1EO {\n\n    font-size: 0.75rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Orders/Single/Products/RetailPrice.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;;AAExB;;AAEA;;IAEI,4BAA4B;;AAEhC;;AAEA;;IAEI,kBAAkB;;AAEtB","sourcesContent":[".container > *:not( :last-child ) {\n\n  margin-bottom: 0.25rem;\n\n}\n\n.price {\n\n    color: var( --invert-solid );\n\n}\n\n.description {\n\n    font-size: 0.75rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RetailPrice_container__j2UFY",
	"price": "RetailPrice_price__gYMmM",
	"description": "RetailPrice_description__cc1EO"
};
export default ___CSS_LOADER_EXPORT___;
