/** @module                 Hooks */

import { usePagename }      from "../../../Hooks/usePagename";

import { useTranslation }   from "react-i18next";

import { 
    
    useRef, 
    
    useState, 
    
    useEffect }             from "react";

import { useParams }        from "react-router-dom";

import useFetch             from "../../../Hooks/useFetch";

import { disableAttribute } from "../../../Components/Dashboard/Utils/disableAttribute";

import useDeleteService     from "../../../Hooks/Services/useDeleteService";

import useEditService       from "../../../Hooks/Services/useUpdateService";

/** @module                 Queries */

import { SERVICE }          from "../../../Queries/Services";

/** @module                 Components */

import { Editor }           from '@tinymce/tinymce-react';

import Breadcrumbs          from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit            from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Sidebar              from "../../../Components/Dashboard/Content/Services/Edit/Sidebar";

import ComponentLoader      from "../../../Components/Dashboard/Loaders/ComponentLoader";

import { Input }            from "../../../Components/Dashboard/Global/Forms/Inputs";

import Fieldset             from "../../../Components/Dashboard/Global/Forms/Fieldset";

import Notice               from "../../../Components/Dashboard/Global/Notice";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns edit service page component
 * 
 */

EditService = ( { name } ) => { usePagename ( name ); const 

    /** @module         Hooks */

    { serviceId }       = useParams (),

    { t }               = useTranslation (),

    { data, loading }   = useFetch ( SERVICE, { id: serviceId } );

    /** @returns */

    return ( <section className="mutations edit service">

        <Breadcrumbs data={ [

            { name: t ( "services"  ), uri: "/app/content/services"       },

            { name: t ( name        ), uri: `/app/content/services/edit/${ serviceId }`    }

        ] } />

        { loading ? <ComponentLoader /> : <Component data={ data } __={ t } /> }

    </section> );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Function } __
 * 
 * @returns edit service page content component
 * 
 */

Component = ( { data, __ } ) => { const

    /** @module         States */

    [ title,            setTitle            ] = useState ( data.adminService.title          || ""       ),

    [ content,          setContent          ] = useState ( data.adminService.content        || ""       ),

    [ image,            setImage            ] = useState ( data.adminService.image          || ""       ),

    [ isActive,         setIsActive         ] = useState ( data.adminService.isActive       || false    ),

    [ language,         setLanguage         ] = useState ( data.adminService.language       || ""       ),

    [ tiremarketOnly,   setTiremarketOnly   ] = useState ( data.adminService.tiremarketOnly || false    ),

    /** @module         References */

    titleRef            = useRef ( null ),

    contentRef          = useRef ( null ),

    imageRef            = useRef ( null ),

    isActiveRef         = useRef ( null ),

    languageRef         = useRef ( null ),

    tiremarketOnlyRef   = useRef ( null ),

    submitRef           = useRef ( null ),

    removeRef           = useRef ( null ),

    /** @module                 Hooks */

    { 
        
        updateService, 
        
        loading, 
        
        errors, 
        
        success } = useEditService (),

    { 
        
        deleteService, 
        
        loading : deleteLoading, 
        
        errors : deleteErrors } = useDeleteService (),

    /** @event onSubmit */

    submitService = () => {

        updateService (

            data.adminService.id,

            title,

            content,

            image,

            isActive,

            tiremarketOnly,

            language

        );

    },

    /** @event onDelete */

    submitRemoval = () => {

        deleteService ( data.adminService.id );

    };

    /** @event useEffect */

    useEffect ( () => {

        if ( loading ) {

            disableAttribute ( [ titleRef, isActiveRef, tiremarketOnlyRef, languageRef ], "add" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = `${ __ ( "submitting" ) }...`;

            }

        } else { 

            disableAttribute ( [ titleRef, isActiveRef, tiremarketOnlyRef, languageRef ], "remove" );

            if ( submitRef.current ) {

                submitRef.current.innerHTML = __ ( "submit" );

            }
    
        }

    }, [ loading ] );
    
    /** @returns */

    return ( 
    
        <AsideEdit aside={ 
        
            <Sidebar 
            
                data={ data.adminService } 
                
                details={ 
                    
                    {

                        isActive        : { 
                            
                            view        : isActive, 
                            
                            set         : setIsActive, 
                            
                            reference   : isActiveRef 
                        
                        },
                        
                        tiremarketOnly  : { 
                            
                            view        : tiremarketOnly, 
                            
                            set         : setTiremarketOnly, 
                            
                            reference   : tiremarketOnlyRef 
                        
                        },

                        image  : { 
                            
                            view        : image, 
                            
                            set         : setImage, 
                            
                            reference   : imageRef 
                        
                        },

                        language        : { 
                            
                            view        : language, 
                            
                            set         : setLanguage, 
                            
                            reference   : languageRef 
                        
                        },

                        submit          : { 
                            
                            reference   : submitRef, 
                            
                            action      : submitService 
                        
                        },

                        remove          : { 
                            
                            reference   : removeRef, 
                            
                            action      : submitRemoval 
                        
                        }
        
                    } 
            
                } 
        
        /> } >

            { errors && <Notice type="danger">

                { __ ( "failed_save" ) }

            </Notice> }

            { deleteErrors && <Notice type="danger">

                { __ ( "failed_delete" ) }

            </Notice> }

            { success && <Notice type="success">

                { __ ( "save_success" ) }

            </Notice> }

            <Fieldset legend={ __ ( "title" ) }>

                <Input
                    
                    value={ title }
        
                    action={ e => setTitle ( e ) }
        
                    ref={ titleRef }
                
                />

            </Fieldset>

            <Fieldset legend={ __ ( "content" ) }>

                <Editor 

                    apiKey="2nr6f475ij0nazn91dwmt3x6fpevnv36no701uj3ap6wgzfn"
                
                    onEditorChange={ ( newValue ) => setContent ( newValue ) } 

                    value={ content }

                    plugins={ [

                        "charmap",

                        "emoticons",

                        "image",

                        "insertdatetime",

                        "link",

                        "lists",

                        "media",

                        "preview",

                        "searchreplace",

                        "table",

                        "visualblocks",

                        "visualchars",

                        "wordcount"

                    ] }

                    init={ {

                        selector:'textarea',

                        skin: "oxide-dark",

                        content_css: "dark",

                        image_title: true,

                        automatic_uploads: true,

                        file_picker_types: 'image',

                        file_picker_callback: function (cb, value, meta) {

                            var input = document.createElement('input');

                            input.setAttribute('type', 'file');

                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {

                            var file = this.files[0];

                            var reader = new FileReader();

                            reader.onload = function () {

                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                cb(blobInfo.blobUri(), { title: file.name });
                            };

                            reader.readAsDataURL(file);
                            
                            };

                            input.click();
                        },

                    } }
                    
                />

            </Fieldset>
        
        </AsideEdit>  )

}
 
/** @exports EditService */

export default EditService;