import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Placeholder from "../../Components/Dashboard/Placeholder";

import { usePagename } from "../../Hooks/usePagename";

import { moment } from "moment";

import LIAA from "../../Assets/liaa.png";

import { Pie } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
    },
  };

  const labels = ['April', 'May', 'June'];

  export const databrowser = {
    labels: ['Chrome', 'Firefox', 'Safari', 'Edge', 'Opera' ],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 8, 5, 2],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  export const datacountries = {
    labels: ['Latvia', 'Lithuania', 'Estonia', 'Sweden' ],
    datasets: [
      {
        label: '# of Votes',
        data: [85, 5, 6, 3, 1],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  export const dataappointmentratio = {
    labels: ['Online', 'Direct' ],
    datasets: [
      {
        label: '# of Votes',
        data: [68, 32],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  export const dataplatform = {
    labels: ['Desktop', 'Mobile', "Tabled" ],
    datasets: [
      {
        label: '# of Votes',
        data: [71, 25, 4],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  function getLast10Days() {
    const dates = [];
    const today = new Date(); // Get today's date
  
    // Loop from 9 days ago until today
    for (let i = 9; i >= 0; i--) {
      const currentDate = new Date(today); // Create a new Date object
      currentDate.setDate(today.getDate() - i); // Subtract the number of days from the current date
      dates.push(`${ currentDate.getDate() }.${ currentDate.getMonth() - 1 }.${ currentDate.getFullYear() }`); // Add the date to the array
    }
  
    return dates;
  }


  export const data = {
    labels: getLast10Days(),
    datasets: [
      {
        label: 'Page views',
        data: [ 28, 56, 42, 58, 78, 98, 125, 85, 72, 32 ],
        borderColor: 'rgb(34, 100, 244)',
        backgroundColor: 'rgba(24, 100, 244, 0.5)',
      }
    ],
  };

const Dash = ( { name } ) => { 

    /** @event usePagename */

    usePagename ( name );

    /** @return Dash page */

    return ( <section className="dash">

        <Breadcrumbs data={ [

            { name: "Statistics", uri: "/app/dashboard" }

        ] } />

        <div className="chart-grid">

            <div>

            <Pie data={databrowser} />

            </div>

            <div>

            <Pie data={datacountries} />

            </div>

            <div>

            <Pie data={dataappointmentratio} />

            </div>

            <div>

            <Pie data={dataplatform} />

            </div>

        </div>

        <div className="line-chart">

          <Line options={options} data={data} />

        </div>

        <div className="liaa">

          <img src={ LIAA } alt="liaa" />

          <div className="liaa-text">

          SIA Tire Market ir 2022.gada 22.augustā noslēdzis līgumu Nr. SKVL-2022/370 ar Latvijas Investīciju un attīstības aģentūru par atbalsta saņemšanu pasākuma “Starptautiskās konkurētspējas veicināšana” ietvaros, ko līdzfinansē Eiropas Reģionālās attīstības fonds.

          </div>

        </div>

    </section> );

}

/** @exports Dash */
 
export default Dash;
