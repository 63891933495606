/** @import modules */

import { useContext } from "react";

/** @import contexts */

import { ThemeContext } from "../../Contexts/Theme";

/** @import components */

import Header from "./Header";

import Footer from "./Footer";

import Aside from "./Aside";

/**
 * 
 * @param { children } param0 
 * 
 * @returns Dashboard layout component
 * 
 */

const Layout = ( { children } ) => { const

    { theme } = useContext ( ThemeContext ); 

    /** @returns Dashboard layout component */

    return ( <section className="dashboard" data-theme={ theme }>

        <Aside />

        <section className="container">

            <Header />

                { children }

            <Footer />

        </section>

    </section> );

}

/** @exports Layout */
 
export default Layout;