/** @module                         Hooks */

import { useNavigate }              from "react-router-dom";

/** @module                         React-Icons */

import { AiOutlinePlusCircle }      from "react-icons/ai";

import { BiTable }                  from "react-icons/bi";

/** @module                         Components */

import Button                       from "../../Global/Forms/Button";

import Modals                       from "../../Global/Modals/Aside/Button";

import ProductColumns               from "./Modals/Tables/Columns";

const 

/** @returns product collection actions component */

Actions = ( { type, dynamic_data } ) => { const 

    navigate = useNavigate (),

    /**
     * 
     * @event createProduct
     * 
     * @returns create product callback
     * 
     */

    createProduct = () => navigate ( `/app/products/${ type }/create` );

    return ( <div className="product-actions">

        <Button 
        
            text="create_new" 
        
            action={ createProduct } 
        
            icon={ <AiOutlinePlusCircle /> } />

        <Modals 
        
            text="table" 
        
            icon={ <BiTable /> } 
        
            element={ 

                <ProductColumns 
                
                    productType={ type }

                    columns={ dynamic_data.default_fields } 
                
                    setColumns={ dynamic_data.setFields } 
                    
                />
                
            } />

    </div> );

}

/** @exports */
 
export default Actions;