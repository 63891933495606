// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BeforeOrders_container__rwsx7 > *:not( :last-child )  {\n\n    margin-bottom: 1rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Orders/BeforeContent/BeforeOrders.module.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;;AAEvB","sourcesContent":[".container > *:not( :last-child )  {\n\n    margin-bottom: 1rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BeforeOrders_container__rwsx7"
};
export default ___CSS_LOADER_EXPORT___;
