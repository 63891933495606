/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import { Fragment, useEffect, useState }      from "react";

import { useNavigate, useParams }   from "react-router-dom";

import useCreate                    from "../../Hooks/Mutations/useCreate";

/** @module                         GQL */

import { CREATE_PRODUCT }           from "../../Mutations/Products";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../Components/Dashboard/Global/Forms/Fieldset";

import Quantity                     from "../../Components/Dashboard/Products/Single/Quantity";

import Options                      from "../../Components/Dashboard/Products/Single/Options";

import ProductType                  from "../../Components/Dashboard/Products/Single/ProductType";

import BatterySettings              from "../../Components/Dashboard/Products/Single/BatterySettings";

import TireSize                     from "../../Components/Dashboard/Products/Single/TireSize";

import RimSize                      from "../../Components/Dashboard/Products/Single/RimSize";

import Price                        from "../../Components/Dashboard/Products/Single/Price";

import Model                        from "../../Components/Dashboard/Products/Single/Model";

import RimOptions                   from "../../Components/Dashboard/Products/Single/RimOptions";

import TireOptions                  from "../../Components/Dashboard/Products/Single/TireOptions";

import Manufacturer                 from "../../Components/Dashboard/Products/Single/Manufacturer";

import Vendor                       from "../../Components/Dashboard/Products/Single/Vendor";

const 

/**
 * 
 * @returns create mutation
 * 
 */

CreateProduct = ( { name } ) => { 

    /** @event usePagename */

    usePagename ( name );
    
    const 

    { productType } = useParams (),
    
    { t }           = useTranslation (),

    navigate        = useNavigate (),

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors, 
        
        setErrors 
    
    } = useCreate ( CREATE_PRODUCT ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        productType: productType,

        manufacturerId: "",

        vendorId: "",

        image: "",

        price: "",

        qty: 0,

        qtyHours: 0,

        qtyDays: 0,

        qtyWeeks: 0,

        model: "",

        width: "",

        height: "",

        diameter: "",

        loadIndex: "",

        speedRating: "",

        season: "",

        runFlat: false,

        studs: false,

        radius: "",

        offset: "",

        boltsIncluded: false,

        boltPattern: "",

        centreBore: "",

        pitchCircleDiameter: "",

        voltage: "",

        capacity: "",

        current: ""

    } ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( ! state.productType ) return setErrors ( true );

        if ( ! state.manufacturerId ) return setErrors ( true );

        if ( ! state.vendorId ) return setErrors ( true );

        //if ( ! state.image ) return setErrors ( true );
        
        createMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( 
            
            name === "qty" || 
            
            name === "qtyHours" || 
            
            name === "qtyDays" || 
            
            name === "qtyWeeks" || 
            
            name === "manufacturerId" || 
            
            name === "vendorId" ||

            name === "width" ||

            name === "height" ||

            name === "diameter" ||

            name === "loadIndex" ||

            name === "centreBore"
            
        ) {

            return setState ( prev => ( { ...prev, [ name ]: parseInt ( value ) } ) );

        }

        if ( 
            
            name === "radius" || 
            
            name === "offset" || 
            
            name === "pitchCircleDiameter" || 
            
            name === "voltage" || 
            
            name === "capacity" || 
            
            name === "current"
            
        ) {

            return setState ( prev => ( { ...prev, [ name ]: parseFloat ( value ) } ) );

        }

        if ( type === "file" ) return setState ( prev => ( { ...prev, [ name ]: e.target.files[0] } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( `/app/products/${ state.productType }/edit/${ returnData.adminCreateProduct.product.id }` );

    }, [ complete ] );

    /** @returns */

    return (

        <section className="mutations create_supplier">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }
                        
                    /> 
                    
                }>

                    <Model
            
                        __={ t }

                        state={ state }

                        action={ handleChange }

                        loading={ loading }
                    
                    />

                    { state.productType === "tire" &&

                        <Fragment>

                            <TireSize
                    
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                            <TireOptions
                
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                        </Fragment>

                    }

                    { state.productType === "rim" &&

                        <Fragment>

                            <RimSize
                    
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                            <RimOptions
                    
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                        </Fragment>

                    }

                    { state.productType === "battery" &&

                        <BatterySettings
                
                            __={ t }

                            state={ state }

                            action={ handleChange }

                            loading={ loading }
                        
                        />

                    }

                    <Quantity
            
                        __={ t }

                        state={ state }

                        action={ handleChange }

                        loading={ loading }
                    
                    />

                </AsideEdit>

            </form>

        </section>

    );

},

Sidebar = ( { 
    
    __, 
    
    state = "", 
    
    changeAction = () => { return },

    errors = false,

    pending = false

} ) => {

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending }>

                        { __ ( "submit" ) }

                    </button>

                </div>

            </Fieldset>

            <ProductType
            
                __={ __ }

                state={ state }

                action={ changeAction }
            
            />

            <Manufacturer 
            
                __={ __ } 
            
                state={ state } 
            
                changeAction={ changeAction }
                
            />

            <Vendor 
            
                __={ __ } 
            
                state={ state } 
            
                changeAction={ changeAction }
                
            />

            <Price 
            
                __={ __ } 
            
                state={ state } 
            
                action={ changeAction } 

                loading={ pending }
                
            />

            <Fieldset legend={ __ ( "image" ) }>

                <input 
                
                    type="file" 
                
                    defaultValue={ state.image } 
                    
                    onChange={ changeAction }

                />

            </Fieldset>

            { ( state.productType === "tire" || state.productType === "rim" ) &&

                <Options 
                
                    __={ __ } 
                
                    state={ state } 
                
                    action={ changeAction } 
                    
                />

            }

        </aside>

    );

};

/** @exports CreateProduct */
 
export default CreateProduct;