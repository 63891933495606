import styles from "../../../../Pages/Orders/Orders.module.css";

import { useTranslation } from "react-i18next";

import Fieldset from "../../Global/Forms/Fieldset";

/**
 * 
 * @exports NotesSettings
 * 
 * @description NotesSettings component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.state - The state object.
 * 
 * @param { Function } props.update - The update function.
 * 
 * @example <NotesSettings state={ state } update={ update } />
 * 
 * @returns { JSX.Element } NotesSettings
 * 
 */

export default function NotesSettings ( { state, update } ) { const 

    { t : __ } = useTranslation ();

    /** @returns { JSX.Element } NotesSettings */

    return (

        <Fieldset legend={ "notes" }>

            <div className={ styles.notes }>

                <textarea
                
                    name="adminNotes"

                    id="adminNotes"

                    onChange={ e => update ( { ...state, adminNotes: e.target.value } ) }

                    placeholder={ `${ __ ( "admin_notes" ) }...` }

                    value={ state.adminNotes }
                
                />

                <textarea
                
                    name="userNotes"

                    id="userNotes"

                    onChange={ e => update ( { ...state, userNotes: e.target.value } ) }

                    placeholder={ `${ __ ( "user_notes" ) }...` }

                    value={ state.userNotes }
                
                />

            </div>

        </Fieldset>

    );

};