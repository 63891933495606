import { gql } from '@apollo/client';

const 

/** @description user login mutation */

AUTHENTICATION = gql`mutation SignIn ( $email: String!, $password: String! ) {

    adminSignIn ( input: { signInInfo: { email: $email, password: $password } } ) {

        userSession {

            authenticated

            token

            currentUser {

                id

                role

                email

            }

        },

        errors

    }

}`,

/** @description user logout mutation */

SIGNOUT = gql`mutation { adminSignOut ( input: {} ) { success } }`;

/** @export mutations */

export { AUTHENTICATION, SIGNOUT }