/** @module                         Components */

import { Fragment }                 from "react";

const 

/**
 * 
 * @param { Object } prices
 * 
 * @returns product table prices component (markups and discounts)
 * 
 */

Prices = ( { prices, clientView } ) => { 
    
    const keys = ( clientView ) ? [ "price" ] : [ 

        "price",

        "priceGroupA",

        "priceGroupB",

        "priceGroupC",

        "originalPrice",

        "originalPriceVAT"
    
    ];

    /** @returns */

    return ( 
        
        keys.map ( ( price, index ) => {

            return (

                <td key={ index }>

                    {

                        prices?.length ? 

                            price === "originalPriceVAT" ?

                                <Fragment> &euro; { ( parseFloat ( prices[ 0 ].originalPrice ) * ( 1 + 0.21 ) ).toFixed ( 2 ) } </Fragment>
                            
                            :

                            <Fragment> &euro; { prices[ 0 ] [ price ] } </Fragment>

                        :  
                        
                            <Fragment> &euro; - </Fragment>

                    }

                </td> 

            );

        } ) 

    );

}

/** @exports Prices */
 
export default Prices;