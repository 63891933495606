import styles from "../ProductPreview.module.css";

export default function Title ( { product } ) { const 

    sizes = {

        tire : [ product.width, product.height, "R" + parseInt ( product.diameter ) + product.isCTire ? "C" : "" ].join ( " | " ),

        rim : [ product.width, "R" + parseInt ( product.diameter ) ].join ( " | " ),

        battery : [ product.voltage + " V", product.current + " A", product.capacity + " Ah" ].join ( " | " )

    },

    title = [ product.manufacturer.name.toUpperCase (), product.model, sizes[ product.productType ] ].join ( " - " );

    return (

        <div className={ styles.title }>

            { title }

        </div>

    );

};