import styles from "../ProductPreview.module.css";

import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";

import { PRODUCT_PREVIEW_VENDOR } from "../Query";

import ComponentLoader from "../../../../../Loaders/ComponentLoader";

import ContentSection from "./ContentSection";

import Item from "./Item";

/**
 * 
 * @exports Quantities component layout.
 * 
 * @param { product }
 * 
 * @returns product preview quantities component layout.
 * 
 */

export default function Quantities ( { product } ) { const 

    { t } = useTranslation (),

    { loading, data } = useQuery ( PRODUCT_PREVIEW_VENDOR, { variables: { id: parseInt ( product.id ) } } );

    /**
     * 
     * @returns product preview quantities component layout.
     * 
     */

    return (

        loading ? <ComponentLoader /> :

        product?.productVendors.map ( ( vendor, index ) =>

            <ContentSection title={ `${ t ( "quantity" ) }` } key={ index }>

                <div className={ styles.optionsGrid }>

                    <Item title={ t ( "qty" ) }>{ ( ! vendor.qty ) ? "-" : vendor.qty }</Item>

                    <Item title={ t ( "qtyDays" ) }>{ ( ! vendor.qtyDays ) ? "-" : vendor.qtyDays }</Item>

                    <Item title={ t ( "qtyHour" ) }>{ ( ! vendor.qtyHour ) ? "-" : vendor.qtyHour }</Item>

                    <Item title={ t ( "qtyWeeks" ) }>{ ( ! vendor.qtyWeeks ) ? "-" : vendor.qtyWeeks }</Item>

                </div>

            </ContentSection>

        )

    );

};