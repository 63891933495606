/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import { useState, useEffect, Fragment }      from "react";

import useFetch                     from "../../Hooks/useFetch";

import { useParams, useNavigate }   from "react-router-dom";

import useUpdate                    from "../../Hooks/Mutations/useUpdate";

import useRemove                    from "../../Hooks/Mutations/useRemove";

/** @module                         GQL */

import { PRODUCT }                  from "../../Queries/Products";

import { 
    
    UPDATE_PRODUCT, 
    
    DELETE_PRODUCT }                from "../../Mutations/Products";

import { PRODUCT_TYPES_ENUM }       from "../../Queries/Products";

/** @module                         Utilities */

import { enumOptions }              from "../../Components/Dashboard/Utils/enumOptions";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import ComponentLoader              from "../../Components/Dashboard/Loaders/ComponentLoader";

import Attributes                   from "../../Components/Dashboard/Global/Forms/Fieldset/Attributes";

import AsideEdit                    from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../Components/Dashboard/Global/Forms/Fieldset";

import Quantity                     from "../../Components/Dashboard/Products/Single/Quantity";

import Options                      from "../../Components/Dashboard/Products/Single/Options";

import ProductType                  from "../../Components/Dashboard/Products/Single/ProductType";

import BatterySettings              from "../../Components/Dashboard/Products/Single/BatterySettings";

import TireSize                     from "../../Components/Dashboard/Products/Single/TireSize";

import RimSize                      from "../../Components/Dashboard/Products/Single/RimSize";

import Price                        from "../../Components/Dashboard/Products/Single/Price";

import Model                        from "../../Components/Dashboard/Products/Single/Model";

import RimOptions                   from "../../Components/Dashboard/Products/Single/RimOptions";

import TireOptions                  from "../../Components/Dashboard/Products/Single/TireOptions";

import Manufacturer                 from "../../Components/Dashboard/Products/Single/Manufacturer";

import Vendor                       from "../../Components/Dashboard/Products/Single/Vendor";

const 

/** @constant DEFINITIONS */

DEFINITIONS = {

    query                           : PRODUCT,

    update_query                    : UPDATE_PRODUCT,

    delete_query                    : DELETE_PRODUCT,

    paramId                         : "productId",

    entryName                       : "adminProduct",

    collection_url                  : "/app/products/tire",

    state_collection                : [ 

        "id",

        "productType",

        "manufacturerId",

        "vendorId",

        // "image",

        "price",

        "qty",

        "qtyHours",

        "qtyDays",

        "qtyWeeks",

        "model",

        "width",

        "height",

        "diameter",

        "loadIndex",

        "speedRating",

        "season",

        "runFlat",

        "studs",

        "radius",

        "offset",

        "boltsIncluded",

        "boltPattern",

        "centreBore",

        "pitchCircleDiameter",

        "voltage",

        "capacity",

        "current",

        "image_preview"
    
    ]

},

/**
 * 
 * @param { String } name
 * 
 * @returns update mutation
 * 
 */

UpdateProduct = ( { name } ) => { usePagename ( name ); const 

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( DEFINITIONS.query, { 

        id: useParams () [ DEFINITIONS.paramId ]

    } );

    /** @returns */

    return (

        <Fragment>

            {

                loading ? 
                
                <section className="mutations update_entry">
                
                    <ComponentLoader /> 

                </section>
                
                :

                <UpdateEntry entry={ data [ DEFINITIONS.entryName ] } />

            }

        </Fragment>

    );

},

/**
 * 
 * 
 * @param { Object } entry
 * 
 * @returns update mutation
 * 
 */

UpdateEntry = ( { entry } ) => { const 
    
    { t } = useTranslation (),

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading, 
        
        complete, 
        
        errors, 
        
        setErrors 
    
    } = useUpdate ( DEFINITIONS.update_query ),

    /** @event useState form fields */

    [ state, setState ] = useState ( generateEntryState ( entry, DEFINITIONS.state_collection ) ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        //if ( ! state.name ) { return setErrors ( true ); }
        
        updateMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

       if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        if ( 
            
            name === "qty" || 
            
            name === "qtyHours" || 
            
            name === "qtyDays" || 
            
            name === "qtyWeeks" || 
            
            name === "manufacturerId" || 
            
            name === "vendorId" ||

            name === "width" ||

            name === "height" ||

            name === "diameter" ||

            name === "loadIndex" ||

            name === "centreBore"
            
        ) {

            return setState ( prev => ( { ...prev, [ name ]: parseInt ( value ) } ) );

        }

        if ( 
            
            name === "radius" || 
            
            name === "offset" || 
            
            name === "pitchCircleDiameter" || 
            
            name === "voltage" || 
            
            name === "capacity" || 
            
            name === "current"
            
        ) {

            return setState ( prev => ( { ...prev, [ name ]: parseFloat ( value ) } ) );

        }

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @returns */

    return (

        <section className="mutations update_entry">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 

                        entry={ entry }
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }

                        success={ complete }
                        
                    /> 
                    
                }>

                    <Model
            
                        __={ t }

                        state={ state }

                        action={ handleChange }

                        loading={ loading }
                    
                    />

                    { state.productType === "tire" &&

                        <Fragment>

                            <TireSize
                    
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                            <TireOptions
                
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                        </Fragment>

                    }

                    { state.productType === "rim" &&

                        <Fragment>

                            <RimSize
                    
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                            <RimOptions
                    
                                __={ t }

                                state={ state }

                                action={ handleChange }

                                loading={ loading }
                            
                            />

                        </Fragment>

                    }

                    { state.productType === "battery" &&

                        <BatterySettings
                
                            __={ t }

                            state={ state }

                            action={ handleChange }

                            loading={ loading }
                        
                        />

                    }

                    <Quantity
            
                        __={ t }

                        state={ state }

                        action={ handleChange }

                        loading={ loading }
                    
                    />

                </AsideEdit>

            </form>

        </section>

    );

},

/**
 * 
 * @param { Object } props
 * 
 * @returns sidebar component
 * 
 */

Sidebar = ( { 
    
    __, 
    
    state           = "", 

    entry           = {},
    
    changeAction    = () => { return },

    errors          = false,

    pending         = false,

    success         = false

} ) => { const 
    
    navigate = useNavigate (),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading: enumLoading, data: enumData } = useFetch ( PRODUCT_TYPES_ENUM ),

    /** @callback useUpdate update mutation hook */

    { 
        
        removeMutation, 
        
        loading, 
        
        complete
    
    } = useRemove ( DEFINITIONS.delete_query ),

    /**
     * 
     * @param { Object } e
     * 
     * @description remove entry action
     * 
     * @returns redirect to previous page
     * 
     */

    removeAction = e => { e.preventDefault ();

        if ( ! state.id ) { return; }
        
        removeMutation ( { id: state.id } );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( DEFINITIONS.collection_url );

    }, [ complete ] );

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { success && ! errors &&
                
                    <div className="success">

                        <div className="success_message">

                            { __ ( "successfully_updated" ) }

                        </div>

                    </div> 
                
                }

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <Attributes details={ [

                    { name: __ ( "createdAt" ), value: new Date ( entry.createdAt ).toLocaleString () },

                    { name: __ ( "updatedAt" ), value: new Date ( entry.updatedAt ).toLocaleString () },

                ] } />

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending || loading }>

                        { __ ( "submit" ) }

                    </button>

                    <button 
        
                        className="button remove" 

                        type="button"
                    
                        disabled={ pending || loading } 
                    
                        onClick={ removeAction } >

                            { __ ( "delete" ) }

                    </button> 

                </div>

            </Fieldset>

            <ProductType
            
                __={ __ }

                state={ state }

                action={ changeAction }
            
            />

            <Manufacturer 
            
                __={ __ } 
            
                state={ state } 
            
                changeAction={ changeAction }
                
            />

            <Vendor 
            
                __={ __ } 
            
                state={ state } 
            
                changeAction={ changeAction }
                
            />

            <Price 
            
                __={ __ } 
            
                state={ state } 
            
                action={ changeAction } 

                loading={ pending }
                
            />

            {/* <Fieldset legend={ __ ( "image" ) }>

                <div className="entry-image-square">

                    <img src={ state.image_preview } className="contain-image" />

                </div>

                <input 
                
                    type="file" 
                
                    defaultValue={ state.image } 
                    
                    onChange={ changeAction }

                />

            </Fieldset> */}

            { ( state.productType === "tire" || state.productType === "rim" ) &&

                <Options 
                
                    __={ __ } 
                
                    state={ state } 
                
                    action={ changeAction } 
                    
                />

            }

        </aside>

    );

},

/**
 * 
 * @param { Object } entry
 * 
 * @param { Array } collection
 * 
 * @returns state object
 * 
 */

generateEntryState = ( entry, collection ) => { let 

    state = {};

    collection.forEach ( item => {

        if ( item === "qty" || item === "qtyDays" || item === "qtyWeeks" || item === "price" ) {

            state [ item ] = entry.productVendors [ 0 ] [ item ];

        } else if ( item === "qtyHours" ) {
                
            state [ item ] = entry.productVendors [ 0 ] [ "qtyHour" ];

        } else if ( item === "vendorId" ) {

            state [ item ] = parseInt ( entry.vendors [ 0 ] [ "id" ] );

        } else if ( item === "image_preview" ) {

            state [ item ] = entry [ "currentImage" ];

        } else if ( item === "speedIndex" ) {
                
            state [ item ] = entry [ "speedRating" ];

        } else if ( item === "loadIndex" ) {
                
            state [ item ] = parseInt ( entry [ "loadIndex" ] );

        } else if ( item === "price" ) {

            state[ item ] = entry[ "price" ].toString ();

        } else { state [ item ] = entry [ item ]; }

    } );

    return state;

};

/** @exports UpdateProduct */
 
export default UpdateProduct;