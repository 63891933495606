import { Text, View } from '@react-pdf/renderer';

import { convertDatetime, convertDate } from '../../../Utils/Datetime';

/**
 * 
 * @exports DocumentParameters
 * 
 * @description document parameters
 * 
 * @param { Object } props
 * 
 * @param { Object } props.order
 * 
 * @returns JSX.Element
 * 
 * @example <DocumentParameters order={ order } />
 * 
 */

export default function DocumentParameters ( { type, order } ) {

    /**
     * 
     * @description
     * 
     * Render document parameters
     * 
     * @returns JSX.Element
     * 
     */

    return (

        <View style={ { flexDirection: "row", gap: 5, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap" } }>

            <Text>No.: #{ order.id }</Text>

            <Text>Izveidots: { convertDatetime ( order.updatedAt ) }</Text>

            {

                type === "receipt" && <Text>Apmaksāt līdz: { convertDate ( new Date ( order.updatedAt ).getTime () + 172800000 ) }</Text>

            }

            {

                type === "offer" && <Text>Derīgs līdz: { convertDatetime ( new Date ( order.updatedAt ).getTime () + 86400000 ) }</Text>

            }

            <Text render={ ( { pageNumber, totalPages } ) => `${ pageNumber } / ${ totalPages }` } />

        </View>

    );

};