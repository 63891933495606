import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import Placeholder from "../../Components/Dashboard/Placeholder";

const Export = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Export page */

    return ( <section className="export">

        <Breadcrumbs data={ [

            { name: "Products", uri: "/app/products/export" },

            { name: "Export", uri: "/app/products/export" }

        ] } />

        <Placeholder text="Placeholder for 'Products > Export' end-points." />

    </section> );

}

/** @exports Export */
 
export default Export;