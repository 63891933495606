/** @module                 Hooks */

import { useTranslation }   from "react-i18next";

/** @module                 Components */

import Checkbox             from "../../../Global/Forms/Checkbox";

import MutationActions      from "../../../Global/Layouts/Mutations/MutationActions";

import Fieldset             from "../../../Global/Forms/Fieldset";

import Select               from "../../../Global/Forms/Select";

/** @module                 Utilities */

import { enumOptions }      from "../../../Utils/enumOptions";

const 

/**
 * 
 * @param { Object } data
 * 
 * @param { Object } details
 * 
 * @param { Object } errors
 * 
 * @returns add service sidebar component
 * 
 */

Sidebar = ( { data, details, errors } ) => { const 

    { t } = useTranslation (),

    /** @event onClick */

    updateIsActive          = e => details.isActive.set         ( e.target.checked ),

    updateTiremarketOnly    = e => details.tiremarketOnly.set   ( e.target.checked );

    /** @returns */

    return ( <aside>

        <Fieldset legend={ t ( "actions" ) }>

            { errors && <div className="errors">

                <div className="error">

                    { t ( "error_ocurred" ) }

                </div>

            </div> }

            <MutationActions 
                
                submitAction={ details.submit.action }
                
                references={ [ details.submit.reference, null ] } />

        </Fieldset>

        <Fieldset legend={ t ( "image" ) }>

            <input 
            
                type="file" 
            
                defaultValue={ details.image.view } 
            
                onChange={ e => details.image.set ( e.target.files[ 0 ] ) } />

        </Fieldset>

        <Fieldset legend={ t ( "options" ) }>

            <Select 
            
                id="language"
                
                action={ e => details.language.set ( e ) }
                
                options={ enumOptions ( data ) }
                
                value={ details.language.view }
                
                ref={ details.language.reference } />

            <Checkbox 
                
                id="isActive" 
            
                text={ t ( "isActive" ) } 
            
                value={ details.isActive.view } 
            
                state={ details.isActive.view } 
            
                action={ updateIsActive }
                
                ref={ details.isActive.reference } />

            <Checkbox 
            
                id="tiremarketOnly" 
            
                text={ t ( "tiremarketOnly" ) } 
            
                value={ details.tiremarketOnly.view } 
            
                state={ details.tiremarketOnly.view } 
            
                action={ updateTiremarketOnly }
                
                ref={ details.tiremarketOnly.reference } />

        </Fieldset>

    </aside> );

}

/** @exports Sidebar */
 
export default Sidebar;