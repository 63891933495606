/** @module                 React */

import { useState }         from "react";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { UPDATE_APPOINTMENT } from "../../Mutations/Montage";

const 

/** 
 * 
 * @returns useUpdateAppointment hook for updating existing appointment
 * 
 */

useUpdateAppointment = () => { const 

    /** @hooks */

    [ loading,  setLoading  ] = useState ( false ),

    [ errors,   setErrors   ] = useState ( false ),

    [ success,  setSuccess  ] = useState ( false ),

    /** @mutations */

    [ UpdateAppointment ] = useMutation ( UPDATE_APPOINTMENT, {

        /** @event onComplete */

        onCompleted ( data ) {

            setSuccess ( true );

            setLoading ( false );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { String } autoNumber
     * 
     * @param { String } autoBrand
     * 
     * @param { String } autoModel
     * 
     * @param { String } fullName
     * 
     * @param { String } phoneNumber
     * 
     * @param { String } diskDiameter
     * 
     * @param { String } email
     * 
     * @param { String } notes
     * 
     * @param { String } transportType
     * 
     * @param { String } tiresStoredNotes
     * 
     * @param { String } tiresBoughtNotes
     * 
     * @param { Boolean } tiresStored
     * 
     * @param { Boolean } tiresBought
     * 
     * @param { Date } startTime
     * 
     * @param { Date } endTime
     * 
     * @returns update appointment action
     * 
     */

    updateAppointment = ( 

        id,

        placeholderStart,

        placeholderEnd,
        
        autoNumber,

        autoBrand,

        autoModel,

        fullName,

        phoneNumber,

        diskDiameter,

        email,

        notes,

        transportType,

        tiresStoredNotes,

        tiresBoughtNotes,

        tiresStored,

        tiresBought,

        startTime,

        endTime ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true ); setSuccess ( false );

        if ( ! autoNumber || ! autoBrand || ! autoModel || ! fullName || ! phoneNumber || ! email || ! transportType ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        /** @mutation */

        UpdateAppointment ( { variables: {

            id,

            autoNumber,

            autoBrand,

            autoModel,

            fullName,

            phoneNumber,

            diskDiameter,

            email,

            notes,

            transportType,

            tiresStoredNotes,

            tiresBoughtNotes,

            tiresStored,

            tiresBought,

            startTime: ( ! startTime ) ? placeholderStart : startTime,

            endTime: ( ! endTime ) ? placeholderEnd : endTime
    
        } } );

    }

    /** @returns */

    return { updateAppointment, loading, errors, success }

};

/** @exports useUpdateAppointment */

export default useUpdateAppointment;