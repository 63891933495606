import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function DocumentFAQ
 * 
 * @description Export the FAQ section of the document as a React Component
 * 
 * @param { Object } contacts - contact information of the company
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentFAQ ( { contacts } ) {

    /**
     * 
     * @description
     * 
     * Render the FAQ section of the document as a React Component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View style={ { flexDirection: "row", gap: 10 } }>

            <Text style={ { fontWeight: 500 } }>Jautājumi par pasūtījumiem un to piegādēm:</Text>

            <View style={ { flex: 1 } }>

                <Text style={ { textDecoration: "underline" } }>{ contacts?.adminShopContacts?.mobilePhoneNr || "-" }</Text>

                <Text>{ contacts?.adminShopContacts?.email || "-" }</Text>

            </View>
            
        </View>

    );

};