/** @module                         Hooks */

import { useEffect, useRef, useState, useCallback }                from 'react';

import { createConsumer }           from '@rails/actioncable';

import pluralize                    from 'pluralize';

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import useFetch                     from "../../Hooks/useFetch";

import { useParams }                from "react-router-dom";

import { Link }                     from "react-router-dom";

import useCreate                    from "../../Hooks/Mutations/useCreate";

import useRemove                    from "../../Hooks/Mutations/useRemove";

import useUpdate                    from '../../Hooks/Mutations/useUpdate';

import { convertDatetime }         from '../../Components/Dashboard/Utils/Datetime';

/** @module                         Queries */

import { PRODUCT_IMPORTS }          from "../../Queries/Products";

/** @module                         Mutations */

import { CREATE_IMPORT, UPDATE_IMPORT } from "../../Mutations/Imports"; 

/** @module                         Mutations */

import { DELETE_PRODUCTS_COLLECTION } from "../../Mutations/Products";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import Tabs                         from "../../Components/Dashboard/Global/Tabs";

import ComponentLoader              from "../../Components/Dashboard/Loaders/ComponentLoader";

const cable = createConsumer(process.env.REACT_APP_CABLE_URL)

/**
 * 
 * @param { String } name 
 * 
 * @returns initial page component
 * 
 */

const Import = ( { name } ) => { 
    const [imports, setImports] = useState({})

    const subscriptionRef = useRef(null);

    const importCallback = useCallback((object) => {

        setImports(prevImports => {

            const updatedImports = {};

            Object.keys(prevImports).forEach(key => {

                updatedImports[key] = prevImports[key].map(data => (data.id === object.id ? object : data));

            });

            return { ...updatedImports };

        });
        
    }, []);

    useEffect(() => {
        if(Object.keys(imports).length !== 0 && !subscriptionRef.current) {

            subscriptionRef.current = cable.subscriptions.create(
                
                { channel: 'RunnersChannel' },

                {

                  connected: () => console.log('connected'),

                  received: data => {
                    importCallback(data)
                  }

                }

              );

            }

    }, [imports, importCallback])

    useEffect(() => {

        return () => {

            if (subscriptionRef.current) {

              subscriptionRef.current.unsubscribe();

            }

        }

    }, [])


    /** @event usePagename */

    usePagename ( name );
    
    const 
    
    { t } = useTranslation (),

    { productType } = useParams (),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state and refetch callback
     * 
     */

    { loading, data } = useFetch ( PRODUCT_IMPORTS, { 
            
        first: JSON.parse ( localStorage.getItem ( `adminImportsCollectionCount` ) ) || 12

    } );

    useEffect(() => {
        if (data === undefined || Object.keys(data).length === 0) return

        setImports(data.adminImports)

    }, [data])



    /** @callback useCreate create mutation hook */

    const { 
        
        createMutation
    
    } = useCreate ( CREATE_IMPORT )

    const { 
        
        updateMutation
    
    } = useUpdate ( UPDATE_IMPORT )

    const { 
        
        removeMutation
    
    } = useRemove ( DELETE_PRODUCTS_COLLECTION )

    /**
     * 
     * @param { Object } e
     * 
     * @event onStartImport
     * 
     * @returns create mutation
     * 
     * @todo createMutation
     * 
     */


    const onStartImport = (e, id) =>{ e.preventDefault ();

        createMutation({

            runnerId: id
        })

    };

    /**
     * 
     * @param { Object } e
     * 
     * @event onDeleteProducts
     * 
     * @returns remove mutation
     * 
     * @todo removeMutation
     * 
     */


    const onDeleteProducts = (e, runnerId, productType) =>{ e.preventDefault ();

        removeMutation({

            runnerId: runnerId,

            productType: pluralize.singular(productType)

        })

    };

    /**
     * 
     * @param { Object } e
     * 
     * @event onDeleteProducts
     * 
     * @returns remove mutation
     * 
     * @todo removeMutation
     * 
     */


    const onCancelImport = (e, id) =>{ e.preventDefault ();

        updateMutation({

            id: id,

            currentStatus: 'canceled'

        })

    };


    /**
     * 
     * @param { Object } e
     * 
     * @event onDeleteProducts
     * 
     * @returns remove mutation
     * 
     * @todo removeMutation
     * 
     */


    const onSave = (e, id, currentStatus, interval) =>{ e.preventDefault ();

        updateMutation({

            id: id,

            currentStatus: currentStatus,

            interval: interval

        })

    },


    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = (e, id) => { const { value } = e.target;

        setImports(prevImports => {

            const updatedImports = {};

            Object.keys(prevImports).forEach(key => {

                updatedImports[key] = prevImports[key].map(data => (data.id === id ? {...data, interval: parseInt(value)} : data));

            });

            return { ...updatedImports };

        });

    };


    /** @return imports page */

    const tableData =  productType === undefined ? combineImports ( imports ) : imports[ productType ]

    return ( <section className="imports">

        <Breadcrumbs />

        { loading ? <ComponentLoader size={ 1 } /> :

            <Tabs data={ tabs ( data.adminImports, t ) } />

        }

        { loading ? <ComponentLoader size={ 3 } /> :

            <table>

                <thead>

                    <tr>

                        <th>{ t ( "provider_name" ) }</th>

                        <th>{ t ( "type" ) }</th>

                        <th>{ t ( "count" ) }</th>

                        <th>{ t ( "status" ) }</th>

                        <th>{ t ( "synced at" ) }</th>

                        <th>{ t ( "delete status" ) }</th>

                        <th>{ t ( "period (min)" ) }</th>

                        <th>{ t ( "actions" ) }</th>

                    </tr>

                </thead>

                <tbody>

                    { 
                    
                    Object.keys(imports).length !== 0 && tableData.map ( 
                        
                        ( { id, runnable_id, products_count, provider_name, type, current_status, delete_status, interval, synced_at }, index ) => 

                            <Row 
                                id={id} 

                                runnable_id={runnable_id} 

                                products_count={products_count} 

                                provider_name={provider_name} 

                                type={type === undefined ? productType : type} 

                                current_status={current_status} 

                                delete_status={delete_status} 

                                interval={interval} 

                                index={index} 

                                synced_at={synced_at}

                                onCancelImport={onCancelImport}

                                onSave={onSave}

                                onDeleteProducts={onDeleteProducts}

                                onStartImport={onStartImport}

                                handleChange={handleChange} 

                                productType={productType} 
                                
                                key={`${ (type === undefined ? productType : type) + id}`} />
                        ) 
                    
                    }

                </tbody>

            </table>
        }

    </section> );

},

/**
 * 
 * @param { Object } query
 * 
 * @param { Function } __
 * 
 * @returns tab list
 * 
 */

tabs = ( query, __ ) => { const 

    keys        = Object.keys ( query ),

    tabList     = [ { name: __ ( "all" ), uri: "/app/products/import" } ];

    /** @event push tabs */

    keys.map ( key => tabList.push ( { name: __ ( key ), uri: `/app/products/import/${ key }` } ) );

    /** @return tab list */

    return tabList;

},

/**
 * 
 * @param { Object } query
 * 
 * @returns combined imports
 * 
 */

combineImports = ( query ) => { const

    keys = Object.keys ( query ),

    combinedImports = [];

    keys.map ( 
        
        key => query[ key ].map ( importItem => 
            
            combinedImports.push ( { ...importItem, type: key } ) 
            
        ) 
        
    );

    /** @return combined imports */

    return combinedImports;

}

const Row = ({ id, runnable_id, provider_name, type, products_count, current_status, delete_status, interval, synced_at, onStartImport, onCancelImport, onDeleteProducts, onSave, handleChange }) => {

    const { t } = useTranslation ()

    const intervalReference = useRef ( interval )

    const handleSave = e => {

        intervalReference.current = interval

        onSave(e, id, current_status, interval)
    }

    return (

        <tr key={`${ (type) + id}`}>

            <td>

                {

                    runnable_id === null ?

                        provider_name

                    :

                        <Link to={ `/app/shop/suppliers/edit/${ runnable_id }` }>

                            { provider_name }
                            
                        </Link>

                }

            </td>

            <td>{ t ( type ) }</td>

            <td>{ products_count }</td>

            <td>{ current_status }</td>

            <td>{ synced_at && convertDatetime(synced_at) }</td>

            <td>{ delete_status }</td>

            <td>

                <input 

                    style={{ width: 100 }}

                    type="number"

                    name='interval'

                    min={ 0 }

                    value={ interval }

                    disabled={ runnable_id === null }

                    onChange={  e => handleChange(e, id) }

                />

            </td>

            <td>

                <button onClick={ e => onStartImport(e, id) } disabled= { 

                    runnable_id === null || 

                    !['completed', 'not started', 'canceled', 'failed'].includes(current_status) ||

                    !['completed', 'not started'].includes(delete_status)}>

                    <span>

                        { t ( "Run" ) }

                    </span>

                </button>

                <button

                    style={{ marginLeft: 10 }} 

                    onClick={ e => onDeleteProducts(e, id, type) }

                    disabled= {
                                                                
                        runnable_id === null || 

                        !['completed', 'not started', 'canceled'].includes(current_status) ||

                        !['completed', 'not started'].includes(delete_status)}>

                    <span>

                        { t ( "Delete" ) }

                    </span>

                </button>

                <button

                    style={{ marginLeft: 10 }} 

                    onClick={ e => onCancelImport(e, id) } 

                    disabled= {
                                                                
                        runnable_id === null || 

                        ['canceled', 'completed', 'not started'].includes(current_status)}>

                    <span>

                        { t ( "Cancel" ) }

                    </span>

                </button>

                <button
                
                    style={{ marginLeft: 10 }} 

                    onClick={ e => handleSave(e) } 

                    disabled= {intervalReference.current === interval}>

                    <span>

                        { t ( "Save" ) }

                    </span>

                </button>

            </td>

        </tr> 
    )
};

/** @exports Import */
 
export default Import;