import { gql } from "@apollo/client";


const FETCH_BUSINESS_SPHERES = gql`
query {
    businessSpheres {
        id
        value
    }
}
`;

export default {
    FETCH_BUSINESS_SPHERES,
};
