/** @module                 React */

import { TiresContext }     from "../../Contexts/Products/TiresContext";

import { useContext }       from "react";

const 

/**
 * 
 * @returns authorization context
 * 
 */

useTiresContext = () => { const 

    context = useContext ( TiresContext );

    /** @throws */

    if ( ! context ) {

        throw Error ( "useTiresContext must be used inside an TiresContext." );

    }

    /** @returns */

    return context;

};

/** @exports useTiresContext */

export default useTiresContext;