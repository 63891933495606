/** @module                 React */

import { useState }         from "react";

/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { CREATE_APPOINTMENT } from "../../Mutations/Montage";

const 

/** 
 * 
 * @returns useCreateAppointment hook for creating new appointment
 * 
 */

useCreateAppointment = () => { const 

    /** @hooks */

    navigate = useNavigate (),

    [ loading,  setLoading  ] = useState ( false ),

    [ errors,   setErrors   ] = useState ( false ),

    /** @mutations */

    [ CreateAppointment ] = useMutation ( CREATE_APPOINTMENT, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            return navigate ( `/app/montage/edit/${ data.adminCreateAppointment.appointment.id }` );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { String } autoNumber
     * 
     * @param { String } autoBrand
     * 
     * @param { String } autoModel
     * 
     * @param { String } fullName
     * 
     * @param { String } phoneNumber
     * 
     * @param { String } diskDiameter
     * 
     * @param { String } email
     * 
     * @param { String } notes
     * 
     * @param { String } transportType
     * 
     * @param { String } tiresStoredNotes
     * 
     * @param { String } tiresBoughtNotes
     * 
     * @param { Boolean } tiresStored
     * 
     * @param { Boolean } tiresBought
     * 
     * @param { Date } startTime
     * 
     * @param { Date } endTime
     * 
     * @returns create appointment action
     * 
     */

    createAppointment = ( 
        
        autoNumber,

        autoBrand,

        autoModel,

        fullName,

        phoneNumber,

        diskDiameter,

        email,

        notes,

        transportType,

        tiresStoredNotes,

        tiresBoughtNotes,

        tiresStored,

        tiresBought,

        startTime,

        endTime ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true );

        if ( ! autoNumber || ! autoBrand || ! autoModel || ! fullName || ! phoneNumber || ! email || ! transportType || ! startTime || ! endTime ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        /** @mutation */

        CreateAppointment ( { variables: {

            autoNumber,

            autoBrand,

            autoModel,

            fullName,

            phoneNumber,

            diskDiameter,

            email,

            notes,

            transportType,

            tiresStoredNotes,

            tiresBoughtNotes,

            tiresStored,

            tiresBought,

            startTime,

            endTime
    
        } } );

    }

    /** @returns */

    return { createAppointment, loading, errors }

};

/** @exports useCreateAppointment */

export default useCreateAppointment;