import './Styles/Application.css';

/** @import @module */

import React                    from 'react';

import { ApolloProvider }       from '@apollo/client';

import Apollo                   from './Configs/Apollo';

import Locales                  from './Configs/useLanguages';

import ReactDOM                 from 'react-dom/client';

import Application              from './Pages/Application';

/** @render */

Locales ();

ReactDOM.createRoot ( document.getElementById ( 'root' ) ).render ( 

    <ApolloProvider client={ Apollo }>

        <Application />

    </ApolloProvider>
    
);
