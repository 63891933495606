/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name GLOBAL_MARKUP */

GLOBAL_MARKUP = gql`query

    fetchGlobalMarkup {

        adminGlobalMarkup {

            createdAt

            deleted

            id

            markupPercent

            markupPercentGroupA

            markupPercentGroupB

            markupPercentGroupC

            updatedAt

        }

    }

`,

/** @name MANUFACTURERS_MARKUP */

MANUFACTURERS_MARKUP = gql`query

    fetchManufacturers (

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminManufacturers (

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

        ) {

            edges {

                node {

                    id

                    name

                    markup

                    attributedMarkup

                    markupGroupA

                    attributedMarkupGroupA

                    markupGroupB

                    attributedMarkupGroupB

                    markupGroupC

                    attributedMarkupGroupC

                    summerMarkup

                    winterMarkup

                    updatedAt

                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }
    
`;

/** @exports Queries */

export {

    GLOBAL_MARKUP,

    MANUFACTURERS_MARKUP

}