/** @module                     Hooks */

import { usePagename }          from "../../../Hooks/usePagename";

import { useParams }            from "react-router-dom";

import { useTranslation }       from "react-i18next";

import useFetch                 from "../../../Hooks/useFetch";

/** @module                     Apollo */

import { 
    
    POSTS, 
    
    POSTS_PREVIEW, 
    
    POSTS_LANGUAGE_ENUM }       from "../../../Queries/Posts";

/** @module                     React-Icons */

import { AiOutlinePlusCircle }  from "react-icons/ai";

/** @module                     Components */

import Breadcrumbs              from "../../../Components/Dashboard/Breadcrumbs";

import FetchTable               from "../../../Components/Dashboard/Global/Table/FetchTable";

import PageActions              from "../../../Components/Dashboard/Global/PageActions";

import LanguageTabs             from "../../../Components/Dashboard/Global/LanguageTabs";

const 

/**
 * 
 * @param { String } name
 * 
 * @returns posts page component
 * 
 */

Posts = ( { name } ) => { usePagename ( name ); const { t } = useTranslation (),

    { language }      = useParams (),

    { loading, data } = useFetch ( POSTS_LANGUAGE_ENUM );

    /** @returns */

    return ( 
    
        <section className="services">
        
            <Breadcrumbs />

            <LanguageTabs 
            
                loading={ loading } 
                
                data={ data }

                route="/app/content/posts"
                
            />

            <PageActions actions={ [

                { name: t ( "create_new" ), uri: "/app/content/posts/add", icon: <AiOutlinePlusCircle /> }

            ] } />

            <FetchTable
        
                query={ POSTS }

                query_atts={ { language: ( language ) ? language : undefined } }

                identifier="adminBlogItems"

                stringSearch={ true }

                filter={ true }

                filters={

                    [

                        { filter: "tiremarketOnly", component: "TiremarketOnly" }
                
                    ]

                }

                header={ [ 
                    
                    "title", 

                    "tiremarketOnly",

                    "isPublished",
                    
                    "language", 
                    
                    "createdAt"
                
                ] }

                contents={ [

                    { 
                        
                        type: "target_key_link_name", 
                    
                        route: "/app/content/posts/edit/%PARAM%", 
                    
                        link_param: "id",
                        
                        text_param: "title" 
                    
                    },

                    { 
                        
                        type: "boolean", 
                    
                        key: "tiremarketOnly" 
                    
                    },

                    { 
                        
                        type: "boolean", 
                    
                        key: "isPublished" 
                    
                    },

                    { 
                        
                        type: "keytag", 
                    
                        key: "language"
                    
                    },

                    { 
                        
                        type: "date", 
                    
                        key: "createdAt" 
                    
                    }

                ] }

                actions={ [

                    { 
                        
                        type: "edit", 
                    
                        permalink: "/app/content/posts" 
                    
                    },

                    { 
                        
                        type: "preview", 
                    
                        query: POSTS_PREVIEW 
                    
                    }

                ] }
            
            />

        </section> 
    
    );

}

/** @exports Posts */
 
export default Posts;