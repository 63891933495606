import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports DocumentSeller
 * 
 * @description Document seller component
 * 
 * @param { Object } props
 * 
 * @param { Object } props.contacts
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentSeller ( { contacts } ) {

    /**
     * 
     * @description
     * 
     * Render seller component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View style={ { gap: 5 } }>

            <View>

                <Text style={ { fontWeight: 500 } }>Pārdevējs:</Text>

            </View>

            <View>

                <Text>Tireshop SIA</Text>

                <Text>Reģ. nr.: { contacts?.regNr || "-" }</Text>

                <Text>PVN reģ. nr.: LV{ contacts?.regNr || "-" }</Text>

                <Text>{ contacts?.address || "-" }</Text>
                
            </View>

            <View>

                <Text>Banka: { contacts?.bank || "-" }</Text>

                <Text>Konts: { contacts?.bankAccountNumber || "-" }</Text>

            </View>

            <View>

                <Text>Banka: { "AS Citadele banka" || "-" }</Text>

                <Text>Konts: { "LV02PARX0021177960002" || "-" }</Text>

            </View>
            
        </View>

    );

};