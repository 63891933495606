import { useTranslation } from "react-i18next";

export default function DeliveryMethod ( { data } ) { 

    const 

    { t } = useTranslation ();

    return (

        <td data-title={ t ( "deliveryMethod" ) }>

            { t ( data?.deliveryMethod ) }

        </td>

    );

};