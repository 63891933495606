/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name SERVICES */

SERVICES = gql`query

    fetchServices (

        $language: ServicesLanguagesEnum,

        $tiremarketOnly: Boolean,

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int

    ) { 
        
        adminServices (

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

            tiremarketOnly: $tiremarketOnly

            language: $language

        ) {

            edges {

                node {

                    id 

                    title 

                    image

                    tiremarketOnly

                    isActive

                    language

                    createdAt

                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name SERVICE_PREVIEW */

SERVICE_PREVIEW = gql`query fetchServicePreview ( $id: ID! ) {

    adminService ( id: $id ) {

        title

        isActive

        tiremarketOnly

        language

        createdAt

        updatedAt

    }

}`,

/** @name SERVICE */

SERVICE = gql`query fetchService ( $id: ID! ) {

    adminService ( id: $id ) {

        id

        title

        image

        content

        isActive

        tiremarketOnly

        language

        createdAt

        updatedAt

    }

}`,

/** @name SERVICES_LANGUAGE_ENUM */

SERVICES_LANGUAGE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "ServicesLanguagesEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    SERVICES,

    SERVICE,

    SERVICE_PREVIEW,

    SERVICES_LANGUAGE_ENUM

}