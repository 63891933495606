import styles from "./OrderCreatedAt.module.css";

import { MANAGER } from "../../../../../Queries/Managers";

import { useQuery } from "@apollo/client";

import CenterLoader from "../../../Loaders/CenterLoader";

import { convertDatetime } from "../../../Utils/Datetime";

import { FaUserEdit, FaUser } from "react-icons/fa";

import { MdEdit } from "react-icons/md";

import { IoMdAddCircle } from "react-icons/io";

export default function OrderCreatedAt ( { data } ) {

    const 

    { data : user, loading } = useQuery ( MANAGER, { variables: { id: data.userId } } );

    return (

        <td data-title="createdAt" className={ styles.container }>

            {

                data?.invoiceNumber &&

                <div className={ styles.invoiceNumber }>

                    { data?.invoiceNumber }

                </div>

            }

            <div className={ styles.date }>

                { convertDatetime ( data?.createdAt ) }

            </div>

            <div className={ styles.manager }>

                <span className={ styles.created }>

                    <IoMdAddCircle />

                </span>

                { loading ? <CenterLoader /> : `${ user.adminUser.firstName } ${ user.adminUser.lastName }` }

            </div>

            {

                data?.lastEditedBy &&

                <div className={ styles.manager }>

                    <span className={ styles.edited }>

                        <MdEdit />

                    </span>

                    { loading ? <CenterLoader /> : `${ data?.lastEditedBy }` }

                </div>

            }

        </td>

    );

};