const 

/**
 * 
 * @param { Object } product
 * 
 * @returns product table sale price component
 * 
 */

SalePrice = ( { product } ) => {

    /** @returns */

    return ( <td data-title="sale_price">
        
        ??
        
    </td> );

}

/** @exports SalePrice */
 
export default SalePrice;