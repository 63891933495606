/** @import modules */

import { useState, useEffect, useRef } from "react";

/** @import components */

import Tooltip from "./Tooltip";

/** @import icons */

import { RiDeleteBack2Line } from "react-icons/ri";

import { AiOutlineSearch } from "react-icons/ai";

const QuickSearch = () => { const 

    clear = useRef ( null ),

    [ query, setQuery ] = useState ( "" );

    useEffect ( () => {

        if ( query.length ) {

            if ( clear.current.classList.contains ( "display-hidden" ) ) {

                clear.current.classList.toggle ( "display-hidden" );

            }

        } else {

            if ( ! clear.current.classList.contains ( "display-hidden" ) ) {

                clear.current.classList.toggle ( "display-hidden" );

            }

        }

    }, [ query ] );

    /** @return component */
    
    return ( <div className="quick-search">

        <span>

            <AiOutlineSearch />

        </span>

        <form onSubmit={ e => { e.preventDefault (); } }>

            <input 
                
                type="text" 
            
                value={ query } 
            
                placeholder="Search..." 
            
                onChange={ e => setQuery ( e.target.value ) } />

        </form>

        <button 
        
            className="display-hidden" 
        
            ref={ clear } 
        
            onClick={ e => { e.preventDefault (); return setQuery ( "" ); } }>

            <RiDeleteBack2Line />

        </button>

        <Tooltip target={ clear } content="Clear" />

    </div> );

}

/** @export component */

export default QuickSearch;