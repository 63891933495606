import { useTranslation } from "react-i18next";

import { DELIVERY_PLANS } from "../../../../../Queries/Orders";

import { useQuery } from "@apollo/client";

import { useState, useEffect } from "react";

import Fieldset from "../../../Global/Forms/Fieldset";

import CenterLoader from "../../../Loaders/CenterLoader";

import SelectSpread from "../../../Global/Forms/Select_Spread";

export default function DeliverySettings ( { state, update, orderType } ) { const

    { t: __ } = useTranslation (),

    { data : delivery_plans, loading : loading_delivery_plans } = useQuery ( DELIVERY_PLANS ),

    [ deliveryPrice, setDeliveryPrice ] = useState ( state.deliveryPrice ),

    [ deliveryId, setDeliveryId ] = useState ( state.deliveryPlanId );

    /**
     * 
     * @event useEffect
     * 
     * @description If deliveryId changes, update state.
     * 
     */

    useEffect ( () => {

        if ( deliveryId === state.deliveryPlanId ) return;

        setDeliveryId ( state.deliveryPlanId );

    }, [ state.deliveryPlanId ] );

    /**
     * 
     * @event useEffect
     * 
     * @description If assemblyPrice changes, update state.
     * 
     */

    useEffect ( () => {

        if ( deliveryPrice === state.customDeliveryPrice ) return;

        const timer = setTimeout( () => {

            update ( { ...state, customDeliveryPrice: parseFloat ( deliveryPrice ) } );
        
        }, 1000 );
      
        return () => clearTimeout ( timer );

    }, [ deliveryPrice ] );

    /**
     * 
     * @event useEffect
     * 
     * @description If deliveryId changes, update state. 
     * 
     */

    useEffect ( () => {

        if ( ! loading_delivery_plans ) {

            const deliveryValue = delivery_plans.deliveryPlans.find ( elem => parseInt ( elem.id ) === parseInt ( deliveryId ) )

            update ( { ...state, 
                
                deliveryPlanId: parseInt ( deliveryId ), 
                
                deliveryMethod: deliveryValue ? deliveryValue.value.toLowerCase ().split ( ' ' ).join ( '_' ) : ""
            
            } );

        }

    }, [ deliveryId ] );

    /**
     * 
     * @event useEffect
     * 
     * @description If delivery plans are loaded, set deliveryId.
     * 
     * @returns { void }
     * 
     */

    useEffect ( () => {

        if ( ! loading_delivery_plans && delivery_plans && orderType === "offer" ) {

            setDeliveryId ( delivery_plans.deliveryPlans.find ( el => el.value === "Pickup" ).id );

        }

    }, [ delivery_plans ] );

    /** @returns { JSX.Element } DeliverySettings */

    return (

        <Fieldset legend={ __ ( "delivery" ) }>

            {

                loading_delivery_plans ? <CenterLoader /> :

                <SelectSpread 
            
                    id="deliveryId"

                    i18n={ true }

                    def={ true }
                    
                    action={ e => setDeliveryId ( e.target.value ) }
                    
                    options={ delivery_plans.deliveryPlans.map ( el => ( { value: el.id , name: el.value } ) ) }
                    
                    value={ deliveryId } />

            }

            {

                state.deliveryMethod === "courier" &&

                <div className="input-group default">

                    <input

                        type="number"

                        id="deliveryPrice"

                        value={ deliveryPrice }

                        onChange={ e => setDeliveryPrice ( e.target.value ) }

                    />

                </div>

            }

            <textarea
            
                name="orderDeliveryNotes"

                id="orderDeliveryNotes"

                onChange={ e => update ( { ...state, orderDeliveryNotes: e.target.value } ) }

                placeholder={ `${ __ ( "delivery_notes" ) }...` }

                value={ state.orderDeliveryNotes }
            
            />

        </Fieldset>

    );

};