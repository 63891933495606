import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports DocumentCustomer
 * 
 * @description Document customer component
 * 
 * @param { Object } props
 * 
 * @param { Object } props.state
 * 
 * @param { Object } props.contacts
 * 
 * @returns { JSX.Element }
 * 
 */

export default function DocumentCustomer ( { state, contacts } ) {

    /**
     * 
     * @description 
     * 
     * Render customer component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View style={ { flex: 1 } }>

            <View style={ { gap: 5 } }>

                <View>

                    <Text style={ { fontWeight: 500 } }>Pasūtītājs:</Text>

                </View>

                <View style={ { flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", gap: 5 } }>

                    <Text style={ { flex: 1 } }>Kontaktpersona:</Text>

                    <View style={ { flex: 1 } }>

                        <Text style={ { fontWeight: 500 } }>{ [ state?.firstName || "-", state?.lastName || "-" ].join ( " " ) }</Text>

                        <Text>{ state?.email || "-" }</Text>

                        <Text>{ state?.mobilePhoneNr || "-" }</Text>

                    </View>

                    <View style={ { flex: 1, gap: 5 } }>

                        <View>

                            { state?.companyName && 
                            
                                <Text style={ { fontWeight: 500 } }>{ state?.companyName || "-" }</Text>
                            
                            }

                            { state?.regNr && 
                            
                                <Text>Reģ. nr.: { state?.regNr || "-" }</Text>
                            
                            }

                        </View>

                        <View>

                            { state?.bank && 
                            
                                <Text>Banka: { state?.bank || "-" }</Text>
                            
                            }

                            { state?.bankIban && 
                            
                                <Text>Konts: { state?.bankIban || "-" }</Text>
                            
                            }

                            { state?.bankSwift && 
                            
                                <Text>Swift: { state?.bankSwift || "-" }</Text>
                            
                            }

                        </View>

                    </View>
                    
                </View>

                <View>

                    <Text style={ { fontWeight: 500 } }>Maksājuma metode: 

                        <Text style={ { fontWeight: 400 } }> { paymentMethod ( state?.paymentMethod || "-" ) }</Text>

                    </Text>

                </View>

                <View>

                    {

                        state.deliveryMethod === "pickup" &&

                        <Text style={ { fontWeight: 500 } }>Piegāde: Pasūtījuma saņemšana veikalā,

                            <Text style={ { fontWeight: 400 } }> { contacts?.address || "-" }</Text>

                        </Text>

                    }

                    {

                        state.deliveryMethod !== "pickup" &&

                        <View style={ { flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", gap: 5 } }>

                            <Text style={ { fontWeight: 500, flex: 1 } }>Piegāde:</Text>

                            <Text style={ { fontWeight: 500, flex: 1 } }>Piegāde ar kurjeri</Text>

                            <View style={ { flex: 1 } }>

                                <Text style={ { fontWeight: 500 } }>Piegādes adrese:</Text>

                                <Text>{ state?.address || "-" }</Text>

                                <Text>{ state?.city || "-" }</Text>

                                <Text>{ state?.postCode || "-" }</Text>

                                <Text>{ state?.countryCode || "-" }</Text>

                            </View>

                        </View>

                    }

                </View>
                
            </View>
            
        </View>

    );

};

/**
 * 
 * @param { string } method
 * 
 * @returns { string }
 * 
 * @description Get payment method
 * 
 */

function paymentMethod ( method ) {

    switch ( method ) {

        case "bank": return "Pārskaitījums";

        case "cash": return "Skaidra nauda";

        case "terminal": return "Karte";

        default: return "-";

    };

};