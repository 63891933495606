/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../Hooks/usePagename";

import { useEffect, useState }      from "react";

import { useNavigate, useParams }   from "react-router-dom";

import useCreate                    from "../../Hooks/Mutations/useCreate";

/** @module                         GQL */

import { CREATE_PAGE }              from "../../Mutations/Pages";

/** @module                         Utilities */

import { enumOptions }              from "../../Components/Dashboard/Utils/enumOptions";

/** @module                         Components */

import Breadcrumbs                  from "../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../Components/Dashboard/Global/Forms/Fieldset";

import SelectSpread                 from "../../Components/Dashboard/Global/Forms/Select_Spread";

import { Editor }                   from "@tinymce/tinymce-react";

const 

/**
 * 
 * @returns create mutation
 * 
 */

AddPage = ( { name } ) => { usePagename ( name ); const 
    
    { t } = useTranslation (),

    { type, language } = useParams (),

    navigate = useNavigate (),

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors, 
        
        setErrors 
    
    } = useCreate ( CREATE_PAGE ),

    /** @event useState form fields */

    [ state, setState ] = useState ( {

        language: language,

        pageType: type,

        content: ""

    } ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        if ( 

            ! state.language ||

            ! state.pageType ||

            ! state.content
            
        ) { return setErrors ( true ); }
        
        createMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( `/app/pages/${ type }/${ language }/edit/${ returnData.adminCreateFrontendPage.page.id }` );

    }, [ complete ] );

    /** @returns */

    return (

        <section className="mutations create_page_content">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "content" ) }>

                        <Editor 

                            apiKey="2nr6f475ij0nazn91dwmt3x6fpevnv36no701uj3ap6wgzfn"
                        
                            onEditorChange={ ( newValue ) => setState ( prev => ( { ...prev, content: newValue } ) ) } 

                            value={ state.content }

                            plugins={ [

                                "charmap",

                                "emoticons",

                                "image",

                                "insertdatetime",

                                "link",

                                "lists",

                                "media",

                                "preview",

                                "searchreplace",

                                "table",

                                "visualblocks",

                                "visualchars",

                                "wordcount"

                            ] }

                            init={ {

                                selector:'textarea',

                                skin: "oxide-dark",

                                content_css: "dark",

                                image_title: true,

                                automatic_uploads: true,

                                file_picker_types: 'image',

                                file_picker_callback: function (cb, value, meta) {

                                    var input = document.createElement('input');

                                    input.setAttribute('type', 'file');

                                    input.setAttribute('accept', 'image/*');

                                    input.onchange = function () {

                                    var file = this.files[0];

                                    var reader = new FileReader();

                                    reader.onload = function () {

                                        var id = 'blobid' + (new Date()).getTime();
                                        var blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
                                        var base64 = reader.result.split(',')[1];
                                        var blobInfo = blobCache.create(id, file, base64);
                                        blobCache.add(blobInfo);

                                        cb(blobInfo.blobUri(), { title: file.name });
                                    };

                                    reader.readAsDataURL(file);
                                    
                                    };

                                    input.click();
                                },

                            } }
                            
                        />

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

Sidebar = ( { 
    
    __, 
    
    state = "", 
    
    changeAction = () => { return },

    errors = false,

    pending = false

} ) => {

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending }>

                        { __ ( "submit" ) }

                    </button>

                </div>

            </Fieldset>

        </aside>

    );

}

/** @exports AddPage */
 
export default AddPage;