import { usePagename } from "../../Hooks/usePagename";

import { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";

import { CREATE_ORDER } from "../../Mutations/Orders";

import useCreate from "../../Hooks/Mutations/useCreate";

import useCart from "../../Hooks/Cart/useCart";

import Breadcrumbs from "../../Components/Dashboard/Breadcrumbs";

import OrderSettings from "../../Components/Dashboard/Orders/Single/Aside/OrderSettings";

import PaymentSettings from "../../Components/Dashboard/Orders/Single/Aside/PaymentSettings";

import MontageSettings from "../../Components/Dashboard/Orders/Single/Aside/MontageSettings";

import DeliverySettings from "../../Components/Dashboard/Orders/Single/Aside/DeliverySettings";

import Customer from "../../Components/Dashboard/Orders/Single/Customer";

import DeliveryInformationSettings from "../../Components/Dashboard/Orders/Single/DeliveryInfoSettings";

import NotesSettings from "../../Components/Dashboard/Orders/Single/NotesSettings";

import OrderProducts from "../../Components/Dashboard/Orders/Single/Products";

import OrderProductModal from "../../Components/Dashboard/Products/Collection/Modals/OrderProduct/Modal";

import CreateAction from "../../Components/Dashboard/Orders/Single/Aside/CreateAction";

import CustomerSearch from "../../Components/Dashboard/Orders/Single/Search";

import ConfirmationAction from "../../Components/Dashboard/Orders/Single/ConfirmationAction";

/**
 * 
 * @exports CreateOrder
 * 
 * @description CreateOrder component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { String } props.name - The name of the page.
 * 
 * @example <CreateOrder name="Create Order" />
 * 
 * @returns { JSX.Element } CreateOrder
 * 
 */

export default function CreateOrder ( { name } ) { usePagename ( name ); const 

    /** 
     * 
     * @description Utility hooks. 
     * 
     */

    { checkout } = useParams (),

    { clearCart } = useCart (),

    navigate = useNavigate (),

    [ searchParams ] = useSearchParams (),
  
    getOrderType = searchParams.get ( "order" ),

    /** 
     * 
     * @type { Object } state 
     * 
     */

    [ state, setState ] = useState ( 
        
        {

            orderType: "",

            status: "",

            paymentMethod: "",

            orderPaymentNotes: "",

            vatIncluded: true,

            assembly: false,

            assemblyPrice: 0, 

            orderDeliveryNotes: "",

            deliveryPrice: 0,

            deliveryPlanId: 0,

            deliveryMethod: "",

            firstName: "",

            lastName: "",

            mobilePhoneNr: "",

            email: "",

            customerIdCode: "",

            companyName: "",

            regNr: "",

            pvnRegNr: "",

            bank: "",

            bankSwift: "",

            bankIban: "",

            adminNotes: "",

            userNotes: "",

            countryCode: "",

            city: "",

            address: "",

            postCode: "",

            shippingNotes: "",

            orderProductVendors: checkout === "checkout" ? JSON.parse ( localStorage.getItem ( "cart" ) ) : [],

            customDeliveryPrice: 0,

            userId: ""

        } 
    
    ),

    /**
     * 
     * @event createMutation
     * 
     * @description Create order mutation.
     * 
     */

    { createMutation, loading, complete, returnData, errors, setErrors } = useCreate ( CREATE_ORDER ),

    /**
     * 
     * @event submit
     * 
     * @description Submit form.
     * 
     * @param { Event } e
     * 
     * @returns { void }
     * 
     * @example <form onSubmit={ submit } />
     * 
     */

    submit = ( e ) => { e.preventDefault ();

        /** If required fields are not filled, set errors to true. */

        if ( ! state.firstName ) { return setErrors ( { message: "Missing first name" } ); }

        if ( ! state.lastName ) { return setErrors ( { message: "Missing last name" } ); }

        if ( ! state.email ) { return setErrors ( { message: "Missing email" } ); }

        if ( ! state.mobilePhoneNr ) { return setErrors ( { message: "Missing mobile phone number" } ); }

        if ( ! state.orderType ) { return setErrors ( { message: "Missing order type" } ); }

        if ( ! state.status ) { return setErrors ( { message: "Missing status" } ); }

        if ( ! state.userId ) { return setErrors ( { message: "Missing user ID" } ); }

        if ( ! state.deliveryMethod ) { return setErrors ( { message: "Missing delivery method" } ); }

        /** If delivery method is tireshop delivery, check if all required fields are filled. */

        if ( state.deliveryMethod === "tireshop_delivery" && ! state.city ) { 
            
            return setErrors ( { message: "Please enter city" } ); 
        
        }

        if ( state.deliveryMethod === "tireshop_delivery" && ! state.address ) { 
            
            return setErrors ( { message: "Missing delivery address" } ); 
        
        }

        if ( state.deliveryMethod === "tireshop_delivery" && ! state.postCode ) { 
            
            return setErrors ( { message: "Missing postal code" } ); 
        
        }

        const submitState = optimizeState ( state );

        createMutation ( submitState );

    };

    /**
     * 
     * @event useEffect
     * 
     * @description If complete, navigate to order edit page and clear cart.
     * 
     * @returns { void }
     * 
     */

    useEffect ( () => {

        if ( complete === true ) {
        
            navigate ( `/app/orders/edit/${ returnData.adminCreateOrder.order.id }` );

            clearCart ();

        }
            
    }, [ complete ] );

    /** @returns { JSX.Element } CreateOrder */

    return (

        <section className="mutations create_order">

            <Breadcrumbs />

            <form onSubmit={ submit }>

                <div className="aside-edit">

                    <div className="content">

                        <CustomerSearch state={ state } update={ setState } />

                        <Customer state={ state } update={ setState } />

                        {

                            ( state.deliveryMethod === "tireshop_delivery" || state.deliveryMethod === "courier" ) &&

                            <DeliveryInformationSettings state={ state } update={ setState } />

                        }

                        <OrderProductModal orderData={ { view: state, set: setState } } />

                        <OrderProducts order={ state } setState={ setState } />

                        <NotesSettings state={ state } update={ setState } />

                        <ConfirmationAction errors={ errors } loading={ loading } />

                    </div>

                    <aside>

                        <CreateAction errors={ errors } loading={ loading } />

                        <OrderSettings state={ state } update={ setState } orderType={ getOrderType } />

                        <DeliverySettings state={ state } update={ setState } orderType={ getOrderType } />

                        <PaymentSettings state={ state } update={ setState } />

                        <MontageSettings state={ state } update={ setState } />

                        <ConfirmationAction errors={ errors } loading={ loading } />

                    </aside>

                </div>

            </form>

        </section>

    );

};

/**
 * 
 * @function optimizeState
 * 
 * @description Optimize state.
 * 
 * @param { Object } obj - The object to optimize.
 * 
 * @returns { Object } state
 * 
 * @example const state = optimizeState ( obj );
 * 
 */

export function optimizeState ( obj ) {

    /** @event optimizeState */

    const productInput = obj.orderProductVendors.map ( el => ( { productId: parseInt ( el.productId ), discount: parseFloat ( el.discount || "0" ), price: parseFloat ( el.price ), count: parseInt ( el.count )} ) );

    /** @event return state */

    return { ...obj, productInput };

}