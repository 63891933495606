/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name PAGES */

PAGES = gql`query fetchPages ( $pageType: FrontendPagesTypesEnum, $language: FrontendPagesLanguagesEnum ) {

    adminFrontendPages ( pageType: $pageType, language: $language ) {

        edges {

            node {

                content

                id

                language

                pageType

            }

        }

    }

}`,

/** @name PAGE */

PAGE = gql`query fetchPage ( $id: ID! ) {

    adminFrontendPage ( id: $id ) {

        content
        
        id
        
        language
        
        pageType

    }

}`,

/** @name PAGES_LANGUAGE_ENUM */

PAGES_LANGUAGE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "FrontendPagesLanguagesEnum" ) {

        enumValues {

            name

        }

    }

}`,

PAGES_TYPES_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "FrontendPagesTypesEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    PAGES_LANGUAGE_ENUM,

    PAGES_TYPES_ENUM,

    PAGES,

    PAGE

}