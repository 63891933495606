import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import languages from "../../Lib/languages.js";

import Select from "./../../Components/Common/Select/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";
import Checkbox from "../../Components/Common/Forms/checkbox/index.js";
import Textarea from "../../Components/Common/Forms/Textarea/index.js";
import FileUploader from "../../Components/Common/FileUploader/index.js";

import styles from "./styles.module.css";


const PREFIX = "additionalInfo";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    logoFile: getName("LogoFile"),
    isAPIAccess: getName("IsAPIAccess"),
    APIAccess: getName("APIAccess"),
    isOnSale: getName("IsOnSale"),
    isDiscounts: getName("IsDiscounts"),
    isNews: getName("IsNews"),
    isTheNews: getName("IsTheNews"),
    comment: getName("Comment"),
    language: getName("Language"),
    businessSphere: getName("BusinessSphere"),
    directSuppliers: getName("DirectSuppliers"),
};

const INITIAL_VALUES = {
    [FIELDS.logoFile]: null,
    [FIELDS.isAPIAccess]: false,
    [FIELDS.APIAccess]: "",
    [FIELDS.isOnSale]: false,
    [FIELDS.isDiscounts]: false,
    [FIELDS.isNews]: false,
    [FIELDS.isTheNews]: false,
    [FIELDS.comment]: "",
    [FIELDS.language]: "",
    [FIELDS.businessSphere]: "",
    [FIELDS.directSuppliers]: "",
};

/* --- */

const FormClientEditAdditionalInfo = (props) => {
    const [isFormVisible, setIsFormVisible] = useState(false);

    const { t } = useTranslation();

    /* --- */

    const onToggleForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const renderLogoUploader = (formMethods) => {
        let logoImg = null;

        if (formMethods.values[FIELDS.logoFile]) {
            const src = URL.createObjectURL(formMethods.values[FIELDS.logoFile]);

            if (src) {
                logoImg = (
                    <img
                        className={styles.logoImg}
                        alt="Logo"
                        src={src}
                    />
                );
            }
        }

        return (
            <div className={styles.logoUploader}>
                <div>
                    {t("client_logo")}
                </div>
                <FileUploader
                    id="client-edit-company-image"
                    onUpload={(file) => {
                        formMethods.setFieldValue(FIELDS.logoFile, file);
                    }}
                    isDisabled={isDisabled()}
                />
                {logoImg}
            </div>
        );
    };

    const renderFormContent = (formMethods) => {
        if (!isFormVisible) {
            return null;
        }

        const {
            values,
            setFieldValue,
            handleBlur,
            handleChange,
        } = formMethods;

        const {
            isAPIAccess,
            APIAccess,
            isOnSale,
            isDiscounts,
            isNews,
            isTheNews,
            comment,
        } = FIELDS;

        return (
            <div className={styles.formContent}>
                <div className={styles.columns}>
                    <div className={styles.row}>
                        {renderLogoUploader(formMethods)}
                        <div className={styles.apiAccessContent}>
                            <Checkbox
                                name={isAPIAccess}
                                label={t("API_access")}
                                onChange={(isSelected) => {
                                    setFieldValue(isAPIAccess, isSelected);
                                }}
                                isDisabled={isDisabled()}
                                isSelected={values[isAPIAccess]}
                            />
                            <Textarea
                                name={APIAccess}
                                label={t("API_access")}
                                placeholder="..."
                                value={values[APIAccess]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isDisabled={isDisabled()}
                            />
                        </div>
                        <Select
                            label={t("direct_suppliers")}
                            options={props.directSuppliers.options}
                            selected={values[FIELDS.directSuppliers]}
                            onSelect={(value) => {
                                setFieldValue(FIELDS.directSuppliers, value);
                            }}
                            isDisabled={isDisabled(props.directSuppliers.isLoading)}
                            isFixedHeight
                        />
                    </div>
                    <div className={styles.row}>
                        <div>
                            {t("communication_settings")}
                        </div>
                        <div className={styles.communicationSettings}>
                            <Checkbox
                                name={isOnSale}
                                label={t("on_sale")}
                                onChange={(isSelected) => {
                                    setFieldValue(isOnSale, isSelected);
                                }}
                                isDisabled={isDisabled()}
                                isSelected={values[isOnSale]}
                            />
                            <Checkbox
                                name={isDiscounts}
                                label={t("discounts")}
                                onChange={(isSelected) => {
                                    setFieldValue(isDiscounts, isSelected);
                                }}
                                isDisabled={isDisabled()}
                                isSelected={values[isDiscounts]}
                            />
                            <Checkbox
                                name={isNews}
                                label={t("news")}
                                onChange={(isSelected) => {
                                    setFieldValue(isNews, isSelected);
                                }}
                                isDisabled={isDisabled()}
                                isSelected={values[isNews]}
                            />
                            <Checkbox
                                name={isTheNews}
                                label={t("the_news")}
                                onChange={(isSelected) => {
                                    setFieldValue(isTheNews, isSelected);
                                }}
                                isDisabled={isDisabled()}
                                isSelected={values[isTheNews]}
                            />
                        </div>
                        <Select
                            label={t("language")}
                            options={languages.getLanguageOptions()}
                            selected={values[FIELDS.language]}
                            onSelect={(value) => {
                                setFieldValue(FIELDS.language, value);
                            }}
                            isDisabled={isDisabled()}
                            isFixedHeight
                        />
                        <Select
                            label={t("business_sphere")}
                            options={props.businessSpheres.options}
                            selected={values[FIELDS.businessSphere]}
                            onSelect={(value) => {
                                setFieldValue(FIELDS.businessSphere, value);
                            }}
                            isDisabled={isDisabled(props.businessSpheres.isLoading)}
                            isFixedHeight
                        />
                    </div>
                </div>
                <Textarea
                    name={comment}
                    label={t("comment")}
                    value={values[comment]}
                    placeholder="..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
            </div>
        );
    };

    const renderForm = (formMethods) => {
        const { handleSubmit } = formMethods;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <ButtonUnderline
                    label={t("additional_info")}
                    onClick={onToggleForm}
                    isClicked={isFormVisible}
                    isClickable
                />
                {renderFormContent(formMethods)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            innerRef={props.formRef}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditAdditionalInfo.defaultProps = {
    formRef: null,
    businessSpheres: {
        options: [],
        isLoading: false,
    },
    directSuppliers: {
        options: [],
        isLoading: false,
    },
    initialValues: INITIAL_VALUES,
    onSubmit: () => { },
    isEditable: false,
};

export {
    PREFIX,
    INITIAL_VALUES,
    FIELDS,
};

export default FormClientEditAdditionalInfo;
