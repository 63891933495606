import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import Input from "./../../Components/Common/Forms/input/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";

import styles from "./styles.module.css";

const PREFIX = "contactInfo";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    firstName: getName("FirstName"),
    lastName: getName("LastName"),
    mobilePhone: getName("MobilePhone"),
    email: getName("Email"),
    landlinePhone: getName("LandlinePhone"),
    fax: getName("Fax"),
    accountingPhone: getName("AccountingPhone"),
    accountingEmail: getName("AccountingEmail"),
};

const INITIAL_VALUES = {
    [FIELDS.firstName]: "",
    [FIELDS.lastName]: "",
    [FIELDS.mobilePhone]: "",
    [FIELDS.email]: "",
    [FIELDS.landlinePhone]: "",
    [FIELDS.fax]: "",
    [FIELDS.accountingPhone]: "",
    [FIELDS.accountingEmail]: "",
};

/* --- */

const FormClientEditContactInfo = (props) => {
    const { t } = useTranslation();

    /* --- */

    // TODO: later
    const validateForm = () => {
        const errors = {};
        return errors;
    };

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const renderForm = (formMethods) => {
        const {
            handleSubmit,
            values,
            handleChange,
            handleBlur,
        } = formMethods;

        const {
            firstName,
            lastName,
            mobilePhone,
            email,
            landlinePhone,
            fax,
            accountingPhone,
            accountingEmail,
        } = FIELDS;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <div className={styles.header}>
                    <ButtonUnderline
                        label={t("contact_information")}
                    />
                </div>
                <div className={styles.column}>
                    <Input
                        name={firstName}
                        label={t("firstName")}
                        placeholder="......"
                        value={values[firstName]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={lastName}
                        label={t("lastName")}
                        placeholder="......"
                        value={values[lastName]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
                <div className={styles.column}>
                    <Input
                        name={mobilePhone}
                        label={t("mobile_phone")}
                        placeholder="......"
                        value={values[mobilePhone]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={email}
                        label={t("email")}
                        placeholder="......"
                        value={values[email]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
                <div className={styles.column}>
                    <Input
                        name={landlinePhone}
                        label={t("landline_phone")}
                        placeholder="......"
                        value={values[landlinePhone]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={fax}
                        label={t("fax")}
                        placeholder="......"
                        value={values[fax]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
                <div className={styles.column}>
                    <Input
                        name={accountingPhone}
                        label={t("accounting_phone")}
                        placeholder="......"
                        value={values[accountingPhone]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={accountingEmail}
                        label={t("accounting_email")}
                        placeholder="......"
                        value={values[accountingEmail]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            innerRef={props.formRef}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditContactInfo.defaultProps = {
    formRef: null,
    initialValues: INITIAL_VALUES,
    onSubmit: () => { },
    isEditable: false,
};

export {
    PREFIX,
    INITIAL_VALUES,
    FIELDS,
};

export default FormClientEditContactInfo;
