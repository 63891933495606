export const productColumns = {

    tire: [

        "id",

        "currentImage",

        "productName",

        "manufacturer",

        "model",

        "productSize",

        "width",

        "height",

        "diameter",

        "speedRating",

        "loadIndex",

        "fuelEfficiency",

        "studs",

        "year",

        "runFlat",

        "productVendors",

        "prices",

        "productType",

        "vendors",

        "createdAt",

        "updatedAt",

        "deleted",

        "description",

        "ean",

        "freightClass",

        "hashId",

        "isCTire",

        "manufacturerId",

        "roadSurface",

        "season",

        "specifications",

        "uid",

        "usage",

        "used",

        "visible",

        "wetBreakingEfficiency"

    ],

    rim: [

        "id",

        "currentImage",

        "productName",

        "manufacturer",

        "model",

        "boltsIncluded",

        "pitchCircleDiameter",

        "diameter",

        "offset",

        "productVendors",

        "prices",

        "productType",

        "vendors",

        "productType",

        "createdAt",

        "updatedAt",

        "deleted",

        "description",

        "ean",

        "hashId",

        "manufacturerId",

        "uid",

        "usage",

        "used",

        "visible"

    ],

    battery: [

        "id",

        "currentImage",

        "productName",

        "manufacturer",

        "model",

        "voltage",

        "current",

        "capacity",

        "productVendors",

        "prices",

        "productType",

        "vendors",

        "createdAt",

        "updatedAt",

        "deleted",

        "description",

        "ean",

        "hashId",

        "manufacturerId",

        "uid",

        "usage",

        "used",

        "visible"

    ]

};