// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__ou2-0 {\n    display: grid;\n    grid-gap: 20px;\n}\n\n/* --- */\n\n.styles_column__y8bnK {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.styles_columnDeleted__H09N2 {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n.styles_row__Wr72K {\n    display: grid;\n    grid-gap: 20px;\n}\n\n.styles_rowRemoveLine__Ff3O7 {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-company-contacts/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,sCAAsC;IACtC,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,cAAc;;IAEd,mBAAmB;AACvB","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 20px;\n}\n\n/* --- */\n\n.column {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.columnDeleted {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n.row {\n    display: grid;\n    grid-gap: 20px;\n}\n\n.rowRemoveLine {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__ou2-0",
	"column": "styles_column__y8bnK",
	"columnDeleted": "styles_columnDeleted__H09N2",
	"row": "styles_row__Wr72K",
	"rowRemoveLine": "styles_rowRemoveLine__Ff3O7"
};
export default ___CSS_LOADER_EXPORT___;
