import "../../../Styles/Notice.css";

import { 
    
    BsFillQuestionCircleFill,

    BsCheckCircleFill,

    BsInfoCircleFill,

    BsFillExclamationTriangleFill,

    BsFillExclamationOctagonFill

} from "react-icons/bs";

const 

Notice = ( {

    children,

    type = "default"

} ) => {

    /** @returns */

    return ( <div className={ `notice ${ type }` }>

        <div className="icon">

            { type === "default" && <BsFillQuestionCircleFill size={ 24 } /> }

            { type === "danger" && <BsFillExclamationOctagonFill size={ 24 } /> }

            { type === "success" && <BsCheckCircleFill size={ 24 } /> }

            { type === "info" && <BsInfoCircleFill size={ 24 } /> }

            { type === "warning" && <BsFillExclamationTriangleFill size={ 24 } /> }

            { type !== "default" && type !== "danger" && type !== "success" && type !== "info" && type !== "warning" && 

                <BsFillQuestionCircleFill size={ 24 } />
            
            }

        </div>

        <p>{ children }</p>

    </div> );

}

/** @exports Notice */
 
export default Notice;