/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name MANAGERS */

MANAGERS = gql`query fetchManagers (

    $role: String

    $stringSearch: String

    $after: String

    $before: String

    $first: Int

    $last: Int

) {

    adminUsers (

        role: $role

        stringSearch: $stringSearch

        after: $after

        before: $before

        first: $first

        last: $last

    ) {

        edges {

            node {

                blocked

                createdAt

                deleted

                email

                firstName

                lastName

                id

                permissions {

                    adminPermissions

                }

                role

                updatedAt

            }

        }

        pageInfo { 
            
            hasNextPage 
            
            hasPreviousPage 
            
            startCursor 
            
            endCursor 
        
        }

    }

}`,

/** @name MANAGER */

MANAGER = gql`query fetchManager (

    $id: ID!

) { adminUser ( id: $id ) {


    firstName

    lastName

    blocked

    createdAt

    email

    id

    permissions {

        adminPermissions

    }

    role

    updatedAt

} }`,

/** @name MANAGER_PREVIEW */

MANAGER_PREVIEW = gql`query fetchManagerPreview (

    $id: ID!

) { adminUser ( id: $id ) {

    firstName

    lastName

    createdAt

    deleted

    email

    id

    role

    updatedAt

} }`,

/** @name MANAGERS_ROLE_ENUM */

MANAGERS_ROLE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "RolesEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    MANAGERS,

    MANAGER,

    MANAGER_PREVIEW,

    MANAGERS_ROLE_ENUM

}