import { gql } from '@apollo/client';

const 

/** @description create new appointment */

CREATE_APPOINTMENT = gql`mutation CreateAppointment (

    $autoNumber: String!

    $autoBrand: String!

    $autoModel: String!

    $fullName: String!

    $phoneNumber: String!

    $diskDiameter: String

    $email: String!

    $notes: String

    $transportType: String!

    $tiresStoredNotes: String

    $tiresBoughtNotes: String

    $tiresStored: Boolean!

    $tiresBought: Boolean!

    $startTime: ISO8601DateTime!

    $endTime: ISO8601DateTime!
    
) {

    adminCreateAppointment (

        input: {

            appointmentsInput: {

                autoNumber: $autoNumber

                autoBrand: $autoBrand

                autoModel: $autoModel

                fullName: $fullName

                phoneNumber: $phoneNumber

                diskDiameter: $diskDiameter

                email: $email

                notes: $notes

                transportType: $transportType

                tiresStoredNotes: $tiresStoredNotes

                tiresBoughtNotes: $tiresBoughtNotes

                tiresStored: $tiresStored

                tiresBought: $tiresBought

                startTime: $startTime

                endTime: $endTime

            }

        }

    ) {

        activerecordErrors

        appointment {

            id

        }

    }

}`,

/** @description update existing appointment */

UPDATE_APPOINTMENT = gql`mutation UpdateAppointment (

    $id: ID!

    $autoNumber: String!

    $autoBrand: String!

    $autoModel: String!

    $fullName: String!

    $phoneNumber: String!

    $diskDiameter: String

    $email: String!

    $notes: String

    $transportType: String!

    $tiresStoredNotes: String

    $tiresBoughtNotes: String

    $tiresStored: Boolean!

    $tiresBought: Boolean!

    $startTime: ISO8601DateTime!

    $endTime: ISO8601DateTime!
    
) {

    adminUpdateAppointment (

        input: {

            id: $id

            appointmentsInput: {

                autoNumber: $autoNumber

                autoBrand: $autoBrand

                autoModel: $autoModel

                fullName: $fullName

                phoneNumber: $phoneNumber

                diskDiameter: $diskDiameter

                email: $email

                notes: $notes

                transportType: $transportType

                tiresStoredNotes: $tiresStoredNotes

                tiresBoughtNotes: $tiresBoughtNotes

                tiresStored: $tiresStored

                tiresBought: $tiresBought

                startTime: $startTime

                endTime: $endTime

            }

        }

    ) {

        appointment {

            autoBrand

            autoModel

            autoNumber

            destroyUuid

            diskDiameter

            email

            endTime

            fullName

            id

            notes

            phoneNumber

            serviceBox

            startTime

            tiresBought

            tiresBoughtNotes

            tiresStored

            tiresStoredNotes

            transportType

        }

    }

}`,

/** @description delete existing appointment */

DELETE_APPOINTMENT = gql`mutation DeleteAppointment (

    $id: String!

) {

    adminDeleteAppointment (

        input: {

            id: $id

        }

    ) {

        appointment {

            id

        }

        destroyed

    }

}`;

/** @export mutations */

export { 
    
    CREATE_APPOINTMENT, 
    
    UPDATE_APPOINTMENT, 
    
    DELETE_APPOINTMENT 

}