/** @module             React */

import { 
    
    createContext, 
    
    useEffect, 
    
    useReducer }        from "react";

/** @module             Router */

import { Outlet }       from "react-router-dom";

/** @module             Apollo */

import { useQuery }     from "@apollo/client";

import { CURRENT_USER } from "../Queries/Authentification";

/** @exports AuthContext */

export const AuthContext = createContext ();

/**
 * 
 * @param { Boolean } state 
 * 
 * @param { String } action 
 * 
 * @exports authReducer
 * 
 * @returns authorization reducer data
 * 
 */

export const authReducer = ( state, action ) => {

    switch ( action.type ) {

        case "LOGIN" : return { user: action.payload, pending: action.state }

        case "LOGOUT": return { user: null, pending: false }

        default: return state;

    }

}

/** @exports AuthContextProvider */

export const AuthContextProvider = () => { const

    { data, loading, error } = useQuery ( CURRENT_USER ),

    [ state, dispatch ] = useReducer ( authReducer, {

        user: null,

        pending: true

    } );

    /**
     * 
     * @event useEffect
     * 
     * @description once user validation complete - update context object with user data
     * 
     */

    useEffect ( () => {

        if ( ! loading ) {

            dispatch ( { type: "LOGIN", payload: ( error ) ? false : data.currentUser, state: loading } );
    
        }

    }, [ loading, error, data ] );

    /** @returns */

    return ( <AuthContext.Provider value={ { ...state, dispatch } }>

        <Outlet />

    </AuthContext.Provider> );

}