import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function ContactsInformation
 * 
 * @description Export contact information of the company as a React Component
 * 
 * @param { Object } contacts - contact information of the company
 * 
 * @returns { JSX.Element }
 * 
 */

export default function ContactsInformation ( { contacts } ) {

    /**
     * 
     * @description
     * 
     * Render contact information of the company as a React Component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View>

            <Text style={ { marginBottom: 5, fontWeight: 500 } }>Kontakti</Text>

            <Text>Veikals: 

                <Text style={ { textDecoration: "underline" } }> { contacts?.mobilePhoneNr || "-" }</Text>

            </Text>

            <Text>Serviss: 

                <Text style={ { textDecoration: "underline" } }> { contacts?.serviceNumber || "-" }</Text>

            </Text>

            <Text>E-Pasts: { contacts?.email || "-" }</Text>

        </View>

    );

};