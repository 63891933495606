/** @module                 Hooks */

import { useId, useState }  from "react";

import { useTranslation }   from "react-i18next";

/** @module                 React-Icons */

import { AiOutlineSearch }  from "react-icons/ai";

import { ImSpinner5 }       from "react-icons/im";

const 

/**
 * 
 * @param { Function } formAction
 * 
 * @param { Boolean } state
 * 
 * @returns actions component search input
 * 
 */

ActionSearch = ( { formAction = false, state = false } ) => { const 

    { t } = useTranslation (),
    
    ID = useId (),

    [ value, setValue ] = useState ( "" );

    /** @returns */

    return ( <form className="action-search"  onSubmit={ e => formAction ( e ) }>

        <input 
        
            placeholder={ `${ t ( "search" ) }...` } 

            type="text"
            
            id={ ID }
            
            name="stringSearch"
            
            value={ value }
            
            onChange={ e => setValue ( e.target.value ) }

            disabled={ state }
            
        />

        <button disabled={ state }>

            { state ? 
                
                <ImSpinner5 className="spinner" /> 
            
            : 

                <AiOutlineSearch />

            }

        </button>

    </form> );

}

/** @exports ActionSearch */
 
export default ActionSearch;