import { useState } from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";

/**
 * 
 * @exports CartItem
 * 
 * @description CartItem component.
 * 
 * @param { Object } props - The properties of the component.
 * 
 * @param { Object } props.item - The item object.
 * 
 * @param { function } props.remove - The remove function.
 * 
 * @example <CartItem item={ item } remove={ removeFromCart } />
 * 
 */

export default function CartItem ( { item, remove, update } ) { const 

    [ quantity, setQuantity ] = useState ( item.count ),

    onQuantityChange = ( e ) => {

        if ( e.target.value === "" ) return;

        if ( parseInt ( e.target.value ) < 0 ) return;

        if ( parseInt ( e.target.value ) > item.maxQuantity ) return;

        setQuantity ( parseInt ( e.target.value ) );
        
        update ( item.id, parseInt ( e.target.value ) );

    };

    /** @returns { JSX.Element } CartItem */

    return (

        <tr key={ item.id }>

            <td>

                { item.product.manufacturer.name.toUpperCase () }

            </td>

            <td>

                { item.product.model }

            </td>

            <td className="quantity">

                <input type="number" value={ quantity } onChange={ onQuantityChange } />

            </td>

            <td>

                &euro; { item.price * item.count }

            </td>

            <td>

                <span onClick={ () => remove ( item.id ) } className="standalone_remove_action">

                    <AiOutlineCloseCircle size={ 20 } />

                </span>

            </td>

        </tr>

    );

};