import { useContext } from "react";

import { PagenameContext } from "../../Contexts/Pagename";

const Specifications = ( { name } ) => { const

    { setPagename } = useContext ( PagenameContext );

    setPagename ( name );

    /** @return Specifications page */

    return ( <section className="specifications">

        Specifications page

    </section> );

}

/** @exports Specifications */
 
export default Specifications;