const sortable_fields = [ 
    
    "fuelEfficiency", 
    
    "loadIndex", 
    
    "speedRating", 
    
    "price", 
    
    "originalPrice", 
    
    "priceGroupA", 
    
    "priceGroupB", 
    
    "priceGroupC" 

];

export { sortable_fields }