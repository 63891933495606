// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__lHSrF ul {\n    display: grid;\n    grid-auto-flow: column;\n    list-style: none;\n    grid-gap: 15px;\n\n    padding: 0;\n    margin: 0;\n}\n\n.styles_buttonHidden__Nxl7m {\n    opacity: 0.7;\n}\n\n.styles_buttonHidden__Nxl7m > a {\n    cursor: not-allowed;\n}", "",{"version":3,"sources":["webpack://./src/Components/Common/Pagination/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;;IAEd,UAAU;IACV,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container ul {\n    display: grid;\n    grid-auto-flow: column;\n    list-style: none;\n    grid-gap: 15px;\n\n    padding: 0;\n    margin: 0;\n}\n\n.buttonHidden {\n    opacity: 0.7;\n}\n\n.buttonHidden > a {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__lHSrF",
	"buttonHidden": "styles_buttonHidden__Nxl7m"
};
export default ___CSS_LOADER_EXPORT___;
