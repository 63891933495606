// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1wDDV {\n    display: grid;\n    grid-row-gap: 5px;\n\n    align-content: start;\n}\n\n.styles_containerFixedHeight__92d2X {\n    height: 90px;\n}\n\n/* --- */\n\n.styles_labels__wktEb {\n    display: grid;\n    grid-auto-flow: column;\n    align-items: center;\n    justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Common/Forms/input/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;;IAEjB,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".container {\n    display: grid;\n    grid-row-gap: 5px;\n\n    align-content: start;\n}\n\n.containerFixedHeight {\n    height: 90px;\n}\n\n/* --- */\n\n.labels {\n    display: grid;\n    grid-auto-flow: column;\n    align-items: center;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1wDDV",
	"containerFixedHeight": "styles_containerFixedHeight__92d2X",
	"labels": "styles_labels__wktEb"
};
export default ___CSS_LOADER_EXPORT___;
