/** @module                         Hooks */

import { Fragment, useState }       from "react";

import useFetch                     from "../../../../Hooks/useFetch";

/** @module                         Queries */

import { SUPPLIERS }                from "../../../../Queries/Suppliers";

import { SUPPLIER }                 from "../../../../Queries/Suppliers";

/** @module                         React-Icons */

import { AiOutlineCloseCircle }     from "react-icons/ai";

/** @module                         Components */

import Fieldset                     from "../../Global/Forms/Fieldset";

import Button                       from "../../Global/Forms/Button";

import CenterLoader                 from "../../Loaders/CenterLoader";

import Pagination                   from "../../Global/Table/Pagination";

const 

Vendor = ( { __, state, changeAction } ) => { const 

    /** @constant { boolean } modal */

    [ modal, setModal ] = useState ( false ),

    /** @event handleClick */

    handleClick = ( e ) => { e.preventDefault ();

        setModal ( ! modal );

    }

    /** @returns Vendor */

    return (

        <Fieldset legend={ __ ( "vendor" ) }>

            {

                state.vendorId != "" ?

                <SelectedVendor id={ state.vendorId } __={ __ } />

                :

                <div className="fieldset_information">

                    { __ ( "missing_vendor" ) }

                </div>

            }

            { 
            
                modal && 
                
                    <Modal 
                    
                        modal={ { view: modal, set: setModal } } 
                        
                        state={ state } 
                        
                        changeAction={ changeAction }
                        
                        __={ __ } 
                    
                /> 
                
            }

            <button onClick={ handleClick }>

                { __ ( "vendors" ) }

            </button>

        </Fieldset>

    );

},

/**
 * 
 * @param { Object } id
 * 
 * @param { Function } __
 * 
 * @returns SelectedVendor
 * 
 * @description fetches selected vendor
 * 
 */

SelectedVendor = ( { id, __ } ) => { const 

    /** @constant { Object } data */

    { data, loading } = useFetch ( SUPPLIER, { id: id } );

    /** @returns SelectedVendor */

    return (

        loading ? <CenterLoader /> :

        <div className="attribute">
            
            <div className="name">

                { __ ( "vendor" ) }

            </div>
            
            <div className="value">
                
                { data.adminVendor.name }
                
            </div>
                
        </div>

    );

},

/**
 * 
 * @param { Object } modal
 * 
 * @param { Object } state
 * 
 * @param { Function } changeAction
 * 
 * @param { Function } __
 * 
 * @returns Modal
 * 
 */

Modal = ( { modal, state, changeAction, __ } ) => { const 

    /** @constant { Object } vendors */

    { loading, data, refetch } = useFetch ( SUPPLIERS, { first: 12 } ),

    /**
     * 
     * @param { Object } e
     * 
     * @returns state update
     * 
     * @description updates state
     * 
     * @event onClick
     * 
     */

    updateState = ( e ) => {

        e.preventDefault ();

        changeAction ( { target: { name: "vendorId", value: e.currentTarget.getAttribute ( "data-id" ) } } );

    },

    /**
     * 
     * @param { Object } e
     * 
     * @returns modal controller
     * 
     * @description toggles modal
     * 
     * @event onClick
     * 
     */

    modalController = e => { e.preventDefault ();

        modal.set ( ! modal.view );

    },

    /**
     * 
     * @param { Object } e
     * 
     * @returns modal container controller
     * 
     * @description closes modal
     * 
     * @event onClick
     * 
     */

    modalContainerController = e => { 

        if ( 
            
            e.target.classList.contains ( "product_modal_container" ) 
            
        || 
            
            e.target.classList.contains ( "inner__container" ) ) 
            
                modal.set ( ! modal.view );

    };

    /** @returns Modal */

    return (

        <div className="product_modal_container" onClick={ e => modalContainerController ( e ) }>

            <div className="inner__container">

                <div className="inner__content">

                    <div className="inner__header">

                        <span 
                        
                            onClick={ e => modalController ( e ) } 
                            
                            className="close">

                            <AiOutlineCloseCircle size={ 20 } />

                        </span>

                    </div>

                    <div className="body__basic">

                        {

                            loading ? <CenterLoader /> : 

                            <Fragment>

                                { ( typeof data != "undefined" ) &&
                
                                    <Pagination 
                                        
                                        pageinfo={ data.adminVendors.pageInfo } 

                                        fallbackCount={ 12 }

                                        cookie={ "vendorModalCollectionCount" }

                                        stringSearch={ true }
                                        
                                        action={ refetch } /> 
                                
                                }

                                <table>

                                    <thead>

                                        <tr>

                                            <th>#</th>

                                            <th>{ __ ( "vendor" ) }</th>

                                        </tr>

                                    </thead>

                                    <tbody>

                                        { ( typeof data != "undefined" ) && data.adminVendors.edges.map ( ( vendor ) => {

                                            return (

                                                <tr 
                                                
                                                    key={ vendor.node.id } 

                                                    data-id={ vendor.node.id }
                                                    
                                                    className={ state.vendorId == vendor.node.id ? "browsing" : "default" }
                                                    
                                                    onClick={ updateState } >

                                                    <td>

                                                        { vendor.node.id }

                                                    </td>

                                                    <td>

                                                        { vendor.node.name.toUpperCase () }

                                                    </td>

                                                </tr>

                                            );

                                        } ) }

                                    </tbody>

                                </table>

                                { ( typeof data != "undefined" ) &&
                
                                    <Pagination 
                                        
                                        pageinfo={ data.adminVendors.pageInfo } 

                                        fallbackCount={ 12 }

                                        cookie={ "vendorModalCollectionCount" }
                                        
                                        action={ refetch } /> 
                                
                                }

                            </Fragment>

                        }

                    </div>

                    <div className="inner__footer">

                        <Button
                        
                            text="close"

                            action={ e => modalController ( e ) }
                        
                        />

                        <Button

                            action={ e => modalController ( e ) }
                        
                            text="submit"
                        
                        />

                    </div>

                </div>

            </div>

        </div>

    );

}

/** @exports Vendor */
 
export default Vendor;