import React from "react";
import { IoMdDownload } from "react-icons/io";

import styles from "./styles.module.css";


const FileUploader = (props) => {
    if (!props.id) {
        return null;
    }

    /* --- */

    const onChange = (evt) => {
        const file = evt.target.files[0] || null;

        if (!file) {
            return;
        }

        props.onUpload(file);
    };

    /* --- */

    return (
        <div className={styles.container}>
            <label
                htmlFor={props.id}
                className={styles.button}
            >
                <div>
                    Select Image
                </div>
                <IoMdDownload />
            </label>
            <input
                id={props.id}
                type="file"
                className={styles.input}
                disabled={props.isDisabled}
                onChange={onChange}
            />
        </div>
    );
};

FileUploader.defaultProps = {
    id: "",
    onUpload: () => { },
    isDisabled: false,
};

export default FileUploader;
