const 

/**
 * 
 * @param {Object} data
 * 
 * @returns {OrderVendors}
 * 
 * @example <OrderVendors data={ data } />
 * 
 */

OrderVendors = ( { data } ) => { const 

    vendors = data.orderProductVendors.map ( ( 
        
        { product } ) => product.vendors.map ( ( { name } ) => name ) 
    
    ).flat (),

    uniqueVendors = [ ...new Set ( vendors ) ];

    /** @returns OrderVendors */

    return (

        <td data-title="order_vendors_list" className="order_vendors_list">

            { uniqueVendors.join ( ', ' ) }

        </td>
    );

}

/** @exports OrderVendors */
 
export default OrderVendors;