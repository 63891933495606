/**
 * 
 * @param { Object } object 
 * 
 * @description cleanses object from null objects
 * 
 * @returns optimizaed object
 * 
 */

export const nullCleaner = object => {

    for ( const key in object ) { 

        /** Remove NULL */

       if ( object[ key ] === null ) { delete object[ key ]; }

       /** Remove NaN integers */

       if ( isNaN (  object[ key ] ) && typeof object[key] === "number" ) { delete object[ key ];  }

       /** Remove empty arrays */

       if ( Array.isArray ( object[ key ] ) && object[ key ].length === 0 ) { delete object[ key ];  }
    
    }

    /** Return cleaned object */

    return object;   

}

/**
 * 
 * @param { Object } object 
 * 
 * @description cleanses object from null objects
 * 
 * @returns optimizaed object
 * 
 */

export const productCleaner = object => {

    let sample = {};

    for ( const key in object ) { 

        /** Remove NULL */

       if ( object[ key ] !== null ) {

            if ( object[ key ] !== "__typename" ) {

                sample = {...sample, [ key ]: object[key] }

            }

       }

    
    }

    /** Return cleaned object */

    return Object.entries ( sample );   

}