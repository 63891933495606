import styles from "../ProductPreview.module.css";

/**
 * 
 * @exports TextArea component.
 * 
 * @param { children, title }
 * 
 * @returns text area component.
 * 
 * @description this component is used to display text area sections.
 * 
 */

export default function TextArea ( { children, title } ) {

    /**
     * 
     * @returns text area component layout.
     * 
     */

    return (

        <div className={ styles.section }>

            <div className={ styles.sectionTitle }>

                { title }

            </div>

            { children }

        </div>

    );

};