// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderProducts_container__ayfYs > *:not( :last-child ) {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.OrderProducts_content__7MeiR > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.OrderProducts_manufacturer__7DyKv {\n\n    font-size: 0.75rem;\n\n    text-transform: uppercase;\n\n}\n\n.OrderProducts_model__jf1DS {\n\n    color: var( --invert-solid );\n\n}\n\n.OrderProducts_properties__xICdp {\n\n    text-wrap: nowrap;\n\n    font-size: 0.75rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Global/Table/Cells/OrderProducts.module.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;;AAEzB;;AAEA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,kBAAkB;;IAElB,yBAAyB;;AAE7B;;AAEA;;IAEI,4BAA4B;;AAEhC;;AAEA;;IAEI,iBAAiB;;IAEjB,kBAAkB;;AAEtB","sourcesContent":[".container > *:not( :last-child ) {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.content > *:not( :last-child ) {\n\n    margin-bottom: 0.25rem;\n\n}\n\n.manufacturer {\n\n    font-size: 0.75rem;\n\n    text-transform: uppercase;\n\n}\n\n.model {\n\n    color: var( --invert-solid );\n\n}\n\n.properties {\n\n    text-wrap: nowrap;\n\n    font-size: 0.75rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OrderProducts_container__ayfYs",
	"content": "OrderProducts_content__7MeiR",
	"manufacturer": "OrderProducts_manufacturer__7DyKv",
	"model": "OrderProducts_model__jf1DS",
	"properties": "OrderProducts_properties__xICdp"
};
export default ___CSS_LOADER_EXPORT___;
