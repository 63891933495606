/** @module                         React-Icons */

import { AiOutlineCloseCircle }     from "react-icons/ai";

const 

ModalBox = ( { children, controller, size = "default" } ) => { const 

    /** @event useState modal controller */

    { modal, setModal } = controller,

    /**
     * 
     * @param { Object } e
     * 
     * @returns modal controller
     * 
     * @description toggles modal
     * 
     * @event onClick
     * 
     */

    modalController = e => { e.preventDefault ();

        setModal ( ! modal );

    },

    /**
     * 
     * @param { Object } e
     * 
     * @returns modal container controller
     * 
     * @description closes modal
     * 
     * @event onClick
     * 
     */

    modalContainerController = e => { 

        if ( 
            
            e.target.classList.contains ( "modal_box_container" ) 
            
        || 
            
            e.target.classList.contains ( "modal_box__inner-container" ) ) 
            
                setModal ( ! modal );

    };

    /** @returns ModalBox */

    return (

        <div className="modal_box_container" onClick={ e => modalContainerController ( e ) }>

            <div className={ `modal_box__inner-container modal_box__${ size }` }>

                <div className="modal_box__inner-content">

                    <div className="modal_box_header">

                        <span 
                        
                            onClick={ e => modalController ( e ) } 
                            
                            className="close">

                            <AiOutlineCloseCircle size={ 20 } />

                        </span>

                    </div>

                    <div className="modal_box_body">

                        { children }

                    </div>

                </div>

            </div>

        </div>

    );

}
 
/** @exports ModalBox */

export default ModalBox;