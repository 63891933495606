// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n\n    --product-talbe-modal-border-color: var( --border-color );\n\n}\n\n[data-theme=\"light\"] {\n\n    --product-talbe-modal-border-color: var( --border-color );\n\n}\n\n.Columns_container__tDXJK {\n\n    padding: 1rem;\n\n}\n\n.Columns_container__tDXJK > *:not( :last-child ) {\n\n    border-bottom: 1px solid var( --product-talbe-modal-border-color );\n\n    padding-bottom: 1rem;\n\n    margin-bottom: 1rem;\n\n}\n\n.Columns_collection__WdLJ\\+ > *:not( :last-child ) > * {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.Columns_draggable__oeQNL {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: nowrap;\n\n    align-items: center;\n\n}\n\n.Columns_draggable__oeQNL > *:not( :last-child ) {\n\n    margin-right: 0.5rem;\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Products/Collection/Modals/Tables/Columns.module.css"],"names":[],"mappings":"AAAA;;IAEI,yDAAyD;;AAE7D;;AAEA;;IAEI,yDAAyD;;AAE7D;;AAEA;;IAEI,aAAa;;AAEjB;;AAEA;;IAEI,kEAAkE;;IAElE,oBAAoB;;IAEpB,mBAAmB;;AAEvB;;AAEA;;IAEI,qBAAqB;;AAEzB;;AAEA;;IAEI,aAAa;;IAEb,mBAAmB;;IAEnB,iBAAiB;;IAEjB,mBAAmB;;AAEvB;;AAEA;;IAEI,oBAAoB;;AAExB","sourcesContent":[":root {\n\n    --product-talbe-modal-border-color: var( --border-color );\n\n}\n\n[data-theme=\"light\"] {\n\n    --product-talbe-modal-border-color: var( --border-color );\n\n}\n\n.container {\n\n    padding: 1rem;\n\n}\n\n.container > *:not( :last-child ) {\n\n    border-bottom: 1px solid var( --product-talbe-modal-border-color );\n\n    padding-bottom: 1rem;\n\n    margin-bottom: 1rem;\n\n}\n\n.collection > *:not( :last-child ) > * {\n\n    margin-bottom: 0.5rem;\n\n}\n\n.draggable {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: nowrap;\n\n    align-items: center;\n\n}\n\n.draggable > *:not( :last-child ) {\n\n    margin-right: 0.5rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Columns_container__tDXJK",
	"collection": "Columns_collection__WdLJ+",
	"draggable": "Columns_draggable__oeQNL"
};
export default ___CSS_LOADER_EXPORT___;
