import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import countries from "../../Lib/countries.js";

import Input from "./../../Components/Common/Forms/input/index.js";
import Textarea from "./../../Components/Common/Forms/Textarea/index.js";
import ButtonUnderline from "./../../Components/Common/ButtonUnderline/index.js";
import Select from "./../../Components/Common/Select/index.js";

import styles from "./styles.module.css";


const PREFIX = "actualAddress";

const getName = (name) => {
    return `${PREFIX}${name}`;
};

const FIELDS = {
    country: getName("Country"),
    city: getName("City"),
    address: getName("Address"),
    postCode: getName("PostCode"),
    comment: getName("Comment"),
};

const INITIAL_VALUES = {
    [FIELDS.country]: "",
    [FIELDS.city]: "",
    [FIELDS.address]: "",
    [FIELDS.postCode]: "",
    [FIELDS.comment]: "",
};

/* --- */

const FormClientEditActualAddress = (props) => {
    const { t } = useTranslation();

    /* --- */

    const validateForm = (values) => {
        const errors = {};

        if (!values[FIELDS.country]) {
            errors[FIELDS.country] = "Country is required";
        }

        if (!values[FIELDS.city]) {
            errors[FIELDS.city] = "City is required";
        }

        if (!values[FIELDS.address]) {
            errors[FIELDS.address] = "Address is required";
        }

        if (!values[FIELDS.postCode]) {
            errors[FIELDS.postCode] = "Postal code is required";
        }

        return errors;
    };

    /* --- */

    const isDisabled = (disabled = false) => {
        return !props.isEditable || disabled;
    };

    /* --- */

    const renderForm = (formMethods) => {
        const {
            handleSubmit,
            values,
            errors,
            handleChange,
            handleBlur,
        } = formMethods;

        const {
            country,
            city,
            address,
            postCode,
            comment,
        } = FIELDS;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <div className={styles.header}>
                    <ButtonUnderline
                        label="Actual address"
                    />
                </div>
                <Select
                    label={t("country")}
                    options={countries.getCountriesOptions()}
                    selected={values[country]}
                    error={errors[country]}
                    onSelect={(value) => {
                        formMethods.setFieldValue(country, value);
                    }}
                    isDisabled={isDisabled()}
                    isFixedHeight
                />
                <Input
                    name={city}
                    label={t("city")}
                    placeholder="Riga"
                    value={values[city]}
                    error={errors[city]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}
                />
                <div className={styles.addressColumn}>
                    <Input
                        name={address}
                        label={t("address")}
                        placeholder="Dzirnavu iela 3"
                        error={errors[address]}
                        value={values[address]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                    <Input
                        name={postCode}
                        label={t("postCode")}
                        placeholder="LV-0000"
                        value={values[postCode]}
                        error={errors[postCode]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isDisabled()}
                    />
                </div>
                <Textarea
                    label={t("comment")}
                    name={comment}
                    placeholder="darba laiks no 9:00 -17:00 zvanīt pie vārtiem  pirms piegādes 30min..."
                    value={values[comment]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isDisabled={isDisabled()}

                />
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            innerRef={props.formRef}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

FormClientEditActualAddress.defaultProps = {
    formRef: null,
    initialValues: INITIAL_VALUES,
    onSubmit: () => { },
    isEditable: false,
};

export {
    PREFIX,
    INITIAL_VALUES,
    FIELDS,
};

export default FormClientEditActualAddress;
