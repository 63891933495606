/** @module                 React */

import { useState }         from "react";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { UPDATE_SERVICE }   from "../../Mutations/Services";

const 

/** 
 * 
 * @returns useUpdatePost hook for updating existing service
 * 
 */

useEditService = () => { const 

    /** @hooks */

    [ loading,  setLoading  ] = useState ( false ),

    [ errors,   setErrors   ] = useState ( false ),

    [ success,  setSuccess  ] = useState ( false ),

    /** @mutations */

    [ UpdateService ] = useMutation ( UPDATE_SERVICE, {

        /** @event onComplete */

        onCompleted ( data ) {

            setSuccess ( true );

            setLoading ( false );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { String } title
     * 
     * @param { String } content
     * 
     * @param { String } description
     * 
     * @param { Boolean } isPublished
     * 
     * @param { Boolean } tiremarketOnly
     * 
     * @param { String } language
     * 
     * @returns update service action
     * 
     */

    updateService = ( id, title, content, image, isActive, tiremarketOnly, language ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true ); setSuccess ( false );

        if ( ! title || ! content || ! language ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        /** @mutation */

        UpdateService ( { variables: {

            id,

            title,

            content,

            image: ( typeof image !== "object" ) ? undefined : image,

            isActive,

            tiremarketOnly,

            language
    
        } } );

    }

    /** @returns */

    return { updateService, loading, errors, success }

};

/** @exports useEditService */

export default useEditService;