/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 GraphQL */

import { useQuery }         from "@apollo/client";

/** @module                 Contexts */

import useAuthorization     from "./Authorization/useAuthorization";

/**
 * 
 * @param { * } query 
 * 
 * @param { object } attributes 
 * 
 * @returns graphql query object
 * 
 */

const 

useFetch = ( query, attributes = {}, cache = "cache-first", networkNotify = false ) => { const 
  
  navigate = useNavigate (),

  { dispatch } = useAuthorization (),
    
  { loading, data, refetch, variables } = useQuery ( query, { variables: attributes, fetchPolicy: cache, notifyOnNetworkStatusChange: networkNotify, errorPolicy: 'all', onError: error => {

    if ( error.networkError && error.networkError.statusCode === 401 ) {

      dispatch ( { type: "LOGOUT", state: false } );

      setTimeout ( () => { navigate ( "/" ) }, 100 );

    }

  } } );

  /** @returns */

  return { loading, data, variables, refetch }

}

/** @exports useFetch */
 
export default useFetch;