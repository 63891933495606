const AsideEdit = ( { children, aside } ) => {

    /** @returns */

    return ( <div className="aside-edit">

        <div className="content">

            { children }

        </div>

        { aside }

    </div> );

}

/** @exports AsideEdit */
 
export default AsideEdit;