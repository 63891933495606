// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_form__2cBDp {\n    display: grid;\n    grid-gap: 20px;\n}\n\n/* --- */\n\n.styles_rowDeleted__olkjO {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n.styles_column__V4Wxq {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.styles_rowRemoveLine__iKzGE {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n\n    margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/Forms/client-edit-bank-details/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA,QAAQ;;AAER;IACI,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,cAAc;;IAEd,mBAAmB;;IAEnB,mBAAmB;AACvB","sourcesContent":[".form {\n    display: grid;\n    grid-gap: 20px;\n}\n\n/* --- */\n\n.rowDeleted {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n.column {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n.rowRemoveLine {\n    display: grid;\n    grid-template-columns: auto max-content;\n    grid-gap: 20px;\n\n    align-items: center;\n\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "styles_form__2cBDp",
	"rowDeleted": "styles_rowDeleted__olkjO",
	"column": "styles_column__V4Wxq",
	"rowRemoveLine": "styles_rowRemoveLine__iKzGE"
};
export default ___CSS_LOADER_EXPORT___;
