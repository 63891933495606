import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useRemove from "../../../../../Hooks/Mutations/useRemove";

import { DELETE_ORDER } from "../../../../../Mutations/Orders";

import Fieldset from "../../../Global/Forms/Fieldset";

import { useTranslation } from "react-i18next";

import ConfimationModal from "../../../Global/Modals/Confirmation/ConfirmationModal";

/**
 * 
 * @exports EditAction 
 * 
 * @description EditAction component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.errors - The errors object.
 * 
 * @param { Boolean } props.loading - The loading state.
 * 
 * @example <EditAction errors={ errors } loading={ loading } />
 * 
 * @returns { JSX.Element } EditAction
 * 
 */

export default function EditAction ( { errors, loading, complete, order } ) { const 

    { t : __ } = useTranslation (),

    navigate = useNavigate (),

    { removeMutation, loading : loading_removal, complete : removal_complete } = useRemove ( DELETE_ORDER ),

    remove = e => {

        e.preventDefault ();

        if ( ! order.id ) { return }

        removeMutation ( { id: order.id } );

    };

    useEffect ( () => {

        ( removal_complete === true ) && 
        
            navigate ( "/app/orders" );

    }, [ removal_complete ] );

    /** @returns { JSX.Element } EditAction */

    return (

        <Fieldset legend={ "actions" }>

            <div className="attribute">

                <div className="name">

                    { __ ( "createdAt" ) }

                </div>

                <div className="value">

                    { new Date ( order.createdAt ).toLocaleDateString () }

                </div>

            </div> 

            <div className="attribute">

                <div className="name">

                    { __ ( "updatedAt" ) }

                </div>

                <div className="value">

                    { new Date ( order.updatedAt ).toLocaleString () }

                </div>

            </div> 

            <div className="attribute">

                <div className="name">

                    { __ ( "lastEditedBy" ) }

                </div>

                <div className="value">

                    { order?.lastEditedBy || "-" }

                </div>

            </div> 

            <div className="attribute">

                <div className="name">

                    { __ ( "orderId" ) }

                </div>

                <div className="value">

                    #{ `${ order.id } ${ order?.invoiceNumber ? "(" + order.invoiceNumber + ")" : "" }` }

                </div>

            </div>

            { complete && ! errors?.message &&
                
                <div className="success">

                    <div className="success_message">

                        { __ ( "successfully_updated" ) }

                    </div>

                </div> 
            
            }

            {

                errors?.message &&

                <div className="errors">

                    <div className="error">

                        { errors?.message || "Internal Server Error" }

                    </div>

                </div>

            }

            <div className={ "mutation-actions default" }>

                <button className="button" disabled={ loading }>

                    { __ ( "submit" ) }

                </button>

                <ConfimationModal 
                
                    description={ __ ( "confirm_delete_order_desc" ) }
                    
                    loading={ loading_removal }
                    
                    action={ remove }>

                        <button 
                    
                            className="button remove" 

                            type="button"
                        
                            disabled={ loading_removal }>

                                { __ ( "delete" ) }

                        </button>

                </ConfimationModal>

            </div>

        </Fieldset>

    );

};