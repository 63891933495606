import { Text, View } from '@react-pdf/renderer';

export default function DocumentCategories () {

    return (

        <View style={ { flexDirection: "row", gap: 5, alignItems: "center" } }>

            <Text style={ { flex: 1, textAlign: "center", fontSize: 11, fontWeight: 500 } }>RIEPAS</Text>

            <Text style={ { flex: 1, textAlign: "center", fontSize: 11, fontWeight: 500 } }>DISKI</Text>

            <Text style={ { flex: 1, textAlign: "center", fontSize: 11, fontWeight: 500 } }>AKUMULATORI</Text>

            <Text style={ { flex: 1, textAlign: "center", fontSize: 11, fontWeight: 500 } }>SERVISS</Text>

            <Text style={ { flex: 1, textAlign: "center", fontSize: 11, fontWeight: 500 } }>RIEPU MAIŅA</Text>

        </View>

    );

};