/** @module                         React */

import { Fragment }                 from "react";

/** @module                         React-Router */

import { Link }                     from "react-router-dom";

/** @module                         Apollo */

import useFetch                     from "../../../../../../Hooks/useFetch";

import { PRODUCT_MAX }              from "../../../../../../Queries/Products";

/** @module                         Components */

import CenterLoader                 from "../../../../Loaders/CenterLoader";

import { productCleaner }           from "../../../../Utils/NullCleaner";

/** @module                         Utilities */

import { manufacturerPermalink }    from "../../../../Utils/Permalinks";

import { vendorPermalink }          from "../../../../Utils/Permalinks";

/**
 * 
 * @param { Object } atts 
 * 
 * @returns single product data modal component
 * 
 */

const ProductFields = ( { atts } ) => { const

    /** @event useFetch */

    { data, loading } = useFetch ( PRODUCT_MAX, { id: atts.productId } );

    /** @returns */

    return ( ( loading ) ? <CenterLoader /> : <Fields productData={ data.product.edges[0].node } /> );

}

const Fields = ( { productData } ) => {

    return ( <div className="modal-settings-container">

        { productCleaner ( productData ).map ( ( [ key, value ], index ) => <Fragment key={ index }>

            <ManufacturerAttribute      atts={ [ key, value ] } />
            
            <SpecificationsAttribute    atts={ [ key, value ] } />

            <VendorsAttribute           atts={ [ key, value ] } />

            <ProductVendorsAttribute    atts={ [ key, value ] } />

            <TextAttribute              atts={ [ key, value ] } />

        </Fragment> ) }
    
    </div> )

};

const TextAttribute = ( { atts } ) => { const [ key, value ] = atts;

    return ( ( key !== "__typename" && typeof value !== "object" ) && 
    
        <div className="modal-attribute">

            <div className="modal-attribute-name">

                { key }

            </div>

            <div className="modal-attribute-value">

                { typeof value === "boolean" ? ( value ) ? "Yes" : "No" : value }

            </div>
        
        </div> 
    
    )

}

const ManufacturerAttribute = ( { atts } ) => { const [ key, value ] = atts;

    return ( typeof value === "object" && <Fragment>

        { key === "manufacturer" && <div className="modal-attribute">

            <div className="modal-attribute-name">

                { key }

            </div>

            <div className="modal-attribute-value">

                <Link to={ manufacturerPermalink ( value.id ) }>{ value.name }</Link>

            </div>

        </div> }

    </Fragment> )

}

const SpecificationsAttribute = ( { atts } ) => { const [ key, value ] = atts;

    return ( typeof value === "object" && <Fragment>

        { key === "specifications" && <div className="modal-attribute">

            <div className="modal-attribute-name">

                { key }

            </div>

            <div className="modal-attribute-value">

                { value.join ( ',' ) }

            </div>

        </div> }

    </Fragment> )

}
 
const VendorsAttribute = ( { atts } ) => { const [ key, value ] = atts;

    return ( typeof value === "object" && <Fragment>

        { key === "vendors" && <div className="modal-attribute">

            <div className="modal-attribute-name">

                { key }

            </div>

            <div className="modal-attribute-value">

                { value.map ( ( vend, index ) => <Link to={ vendorPermalink ( vend.id ) } key={index}>
                    
                    { vend.name }
                    
                </Link> ) }

            </div>

        </div> }

    </Fragment> )

}

const ProductVendorsAttribute = ( { atts } ) => { const [ key, value ] = atts;

    return ( typeof value === "object" && <Fragment>

        { key === "productVendors" && productCleaner ( value[ 0 ] ).slice ( 1 ).map ( ( [ vendorKey, vendorValue ], index ) => 
        
            <div className="modal-attribute" key={ index }>
                    
                <div className="modal-attribute-name">

                    { vendorKey }

                </div>

                <div className="modal-attribute-value">

                    { vendorValue }

                </div>
                
            </div>
        
        ) }

    </Fragment> )

}

export default ProductFields;