/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

/** @module                         Utilities */

import { productCleaner }           from "../../../../Utils/NullCleaner";

/** @module                         Components */

import { Fragment }                 from "react";

/**
 *  
 * @param { Boolean } preview
 * 
 * @returns order product preview
 * 
 * @description displays order product preview
 * 
 */

const 

OrderProductPreview = ( { preview } ) => { const { t } = useTranslation ();

    /** @returns */

    return (

        <div className="body__pane accented rounded_left preview">

            {

                preview &&

                productCleaner ( preview ).map ( ( [ key, value ], index ) => 
                
                    <Fragment key={ index }>

                        <TextAttribute atts={ [ key, value ] } __={ t } />

                    </Fragment> 
                
                )

            }

        </div>

    );

},

/**
 * 
 * @param { Array } atts
 * 
 * @param { Function } __
 * 
 * @returns single attribute component
 * 
 */

TextAttribute = ( { atts, __ } ) => { const 
    
    [ key, value ] = atts,

    vendorQty = [ "qty", "qtyDays", "qtyHour", "qtyWeeks" ];

    /** @returns */

    return ( 
        
        ( 
            
            key !== "__typename" &&

            key !== "currentImage" &&

            key !== "hashId" &&

            key !== "originalImageUrl" &&

            key !== "uid" &&

            key !== "viewCount" &&

            key !== "viewUniqCount" &&

            key !== "createdAt" &&

            key !== "updatedAt" &&

            key !== "visible" &&

            key !== "deleted" &&

            key !== "image" &&

            key !== "manufacturerId" &&

            key !== "scraper" &&

            key !== "productType" &&

            key !== "vehicleType" &&

            key !== "productVendors" &&

            key !== "specifications" &&

            key !== "vendors" &&

            key !== "prices" &&

            key !== "priceList" &&

                <div className="preview_attribute">

                    <div className="name">

                        { __ ( key ) }:&nbsp;

                    </div>

                    <div className="value">

                        { 
                        
                            typeof value === "boolean" ? 
                            
                                ( value ) ? __ ( "yes" ) : __ ( "no" ) 
                                
                            : typeof value === "number" ? 
                            
                                value 
                                
                            : typeof value === "string" ?

                                value.toUpperCase ()

                            : typeof value === "object" ?

                                key === "manufacturer" ? value.name.toUpperCase () : JSON.stringify ( value )

                            : typeof value
                            
                        }

                    </div>

                </div>

        )

        || ( key === "productVendors" && 

            vendorQty.map ( ( qty, index ) => 

                ( value.reduce ( ( acc, cur ) => acc + cur[ qty ], 0 ) > 0 ) &&

                    <div className="preview_attribute" key={ `quntities-${ index }` }>
                    
                        <div className="name">

                            { __ ( qty ) }:&nbsp;

                        </div>

                        <div className="value">

                            { value.reduce ( ( acc, cur ) => acc + cur[qty], 0 ) }
                            
                        </div>

                    </div>

            )
            
        )

        || ( key === "specifications" &&

            <Fragment>

                <div className="preview_attribute">

                    <div className="name">

                        { __ ( "specifications" ) }:&nbsp;

                    </div>

                    <div className="value">

                        { value.length > 0 ? __ ( "yes" ) : __ ( "no" ) }

                    </div>

                </div>

                { value.map ( ( spec, index ) =>

                    <div className="preview_attribute" key={ `specification-${ index }` }>

                        <div className="name">

                            { index + 1 }:&nbsp;

                        </div>

                        <div className="value">

                            { spec }

                        </div>

                    </div>

                ) }

            </Fragment>

        )

    )

}

/** @exports OrderProductPreview */
 
export default OrderProductPreview;