/** @module                 React */

import { useState }         from "react";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

const 

/** 
 * 
 * @returns useRemove hook for removing entry
 * 
 */

useRemove = ( MUTATION ) => { const 

    /** @hooks */

    [ loading,  setLoading  ]       = useState ( false ),

    [ errors,   setErrors   ]       = useState ( false ),

    [ complete, setComplete ]       = useState ( false ),

    /** @mutations */

    [ RemoveEntry ] = useMutation ( MUTATION, {

        /** @event onComplete */

        onCompleted () {

            setLoading ( false );

            setComplete ( true );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { Object } data
     * 
     * @returns remove entry action
     * 
     */

    removeMutation = ( data ) => {

        /** @override hook states */

        setErrors ( false ); setLoading ( true ); 

        /** @mutation */

        RemoveEntry ( { variables: data } );

    }

    /** @returns */

    return { removeMutation, loading, complete, errors }

};

/** @exports useRemove */

export default useRemove;