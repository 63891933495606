/** @module                         Hooks */

import { useTranslation }           from "react-i18next";

import { usePagename }              from "../../../Hooks/usePagename";

import { useState, useEffect }      from "react";

import useFetch                     from "../../../Hooks/useFetch";

import { useParams, useNavigate }   from "react-router-dom";

import useUpdate                    from "../../../Hooks/Mutations/useUpdate";

import useRemove                    from "../../../Hooks/Mutations/useRemove";

/** @module                         GQL */

import { 
    
    DELETE_VENDOR, 
    
    UPDATE_VENDOR }                 from "../../../Mutations/Vendors";

import { SUPPLIER }                 from "../../../Queries/Suppliers";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import AsideEdit                    from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import Checkbox                     from "../../../Components/Dashboard/Global/Forms/Checkbox";

import ComponentLoader              from "../../../Components/Dashboard/Loaders/ComponentLoader";

import Attributes                   from "../../../Components/Dashboard/Global/Forms/Fieldset/Attributes";

import { Input }                    from "../../../Components/Dashboard/Global/Forms/Inputs_Spread";

const 

/** @constant DEFINITIONS */

DEFINITIONS = {

    query                           : SUPPLIER,

    update_query                    : UPDATE_VENDOR,

    delete_query                    : DELETE_VENDOR,

    paramId                         : "vendorId",

    entryName                       : "adminVendor",

    collection_url                  : "/app/shop/suppliers",

    state_collection                : [ 
        
        "name", 
    
        "addPvn", 
    
        "visible", 
    
        "id",

        "markup"
    
    ]

},

/**
 * 
 * @param { String } name
 * 
 * @returns update mutation
 * 
 */

UpdateVendor = ( { name } ) => { usePagename ( name ); const 

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading, data } = useFetch ( DEFINITIONS.query, { 

        id: useParams () [ DEFINITIONS.paramId ]

    } );

    /** @returns */

    return (

        loading ? <ComponentLoader /> :

        <UpdateEntry entry={ data [ DEFINITIONS.entryName ] } />

    );

},

/**
 * 
 * 
 * @param { Object } entry
 * 
 * @returns update mutation
 * 
 */

UpdateEntry = ( { entry } ) => { const 
    
    { t } = useTranslation (),

    /** @callback useUpdate update mutation hook */

    { 
        
        updateMutation, 
        
        loading, 
        
        complete, 
        
        errors, 
        
        setErrors 
    
    } = useUpdate ( DEFINITIONS.update_query ),

    /** @event useState form fields */

    [ state, setState ] = useState ( generateEntryState ( entry, DEFINITIONS.state_collection ) ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event onSubmit 
     * 
     */

    onSubmit = e => { e.preventDefault (); 

        const { name, value, type, checked } = e.target;

        if ( ! state.name ) { return setErrors ( true ); }

        // Parse markup into Int

        state.markup = parseInt ( state.markup );
        
        updateMutation ( state );
    
    },

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setState ( prev => ( { ...prev, [ name ]: checked } ) );

        setState ( prev => ( { ...prev, [ name ]: value } ) );

    };

    /** @returns */

    return (

        <section className="mutations update_entry">

            <Breadcrumbs />

            <form onSubmit={ onSubmit }>

                <AsideEdit aside={ 
                
                    <Sidebar 
                    
                        __={ t } 
                        
                        state={ state } 

                        entry={ entry }
                        
                        changeAction={ handleChange } 

                        errors={ errors }

                        pending={ loading }

                        success={ complete }
                        
                    /> 
                    
                }>

                    <Fieldset legend={ t ( "title" ) }>

                        <div className="input-group">

                            <input
                            
                                type="text"

                                value={ state.name }

                                onChange={ handleChange }

                                name="name"
                            
                            />

                        </div>

                    </Fieldset>

                </AsideEdit>

            </form>

        </section>

    );

},

/**
 * 
 * @param { Object } props
 * 
 * @returns sidebar component
 * 
 */

Sidebar = ( { 
    
    __, 
    
    state           = "", 

    entry           = {},
    
    changeAction    = () => { return },

    errors          = false,

    pending         = false,

    success         = false

} ) => { const 
    
    navigate = useNavigate (),

    /** @callback useUpdate update mutation hook */

    { 
        
        removeMutation, 
        
        loading, 
        
        complete
    
    } = useRemove ( DEFINITIONS.delete_query ),

    /**
     * 
     * @param { Object } e
     * 
     * @description remove entry action
     * 
     * @returns redirect to previous page
     * 
     */

    removeAction = e => { e.preventDefault ();

        if ( ! state.id ) { return }
        
        removeMutation ( { id: state.id } );

    };

    /** @event useEffect */

    useEffect ( () => {

        ( complete === true ) && 
        
            navigate ( DEFINITIONS.collection_url );

    }, [ complete ] );

    /** @returns */

    return (

        <aside>

            <Fieldset legend={ __ ( "actions" ) }>

                { success && ! errors &&
                
                    <div className="success">

                        <div className="success_message">

                            { __ ( "successfully_updated" ) }

                        </div>

                    </div> 
                
                }

                { errors && <div className="errors">

                    <div className="error">

                        { __ ( "error_ocurred" ) }

                    </div>

                </div> }

                <Attributes details={ [

                    { name: __ ( "createdAt" ), value: new Date ( entry.createdAt ).toLocaleString () },

                    { name: __ ( "updatedAt" ), value: new Date ( entry.updatedAt ).toLocaleString () },

                ] } />

                <div className={ `mutation-actions default` }>

                    <button className="button add" disabled={ pending || loading }>

                        { __ ( "submit" ) }

                    </button>

                    <button 
        
                        className="button remove" 

                        type="button"
                    
                        disabled={ pending || loading } 
                    
                        onClick={ removeAction } >

                            { __ ( "delete" ) }

                    </button> 

                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "options" ) }>

                <Checkbox 
                
                    id="addPvn" 
                
                    text={ __ ( "addPvn" ) } 
                
                    state={ state.addPvn } 
                
                    action={ changeAction } />

                <Checkbox 
                
                    id="visible" 
                
                    text={ __ ( "visible" ) } 
                
                    state={ state.visible } 
                
                    action={ changeAction } />

            </Fieldset>

            <Fieldset legend={ __ ( "markup" ) }>

                 <Input
                    
                        value={ state.markup } 

                        action={ changeAction }

                        name="markup"
                        
                    />

            </Fieldset>

        </aside>

    );

},

/**
 * 
 * @param { Object } entry
 * 
 * @param { Array } collection
 * 
 * @returns state object
 * 
 */

generateEntryState = ( entry, collection ) => { let 

    state = {};

    collection.forEach ( item => {

        state [ item ] = entry [ item ];

    } );

    return state;

};

/** @exports UpdateVendor */
 
export default UpdateVendor;