/** @module                         Hooks */

import { usePagename }              from "../../../Hooks/usePagename";

import { useTranslation }           from "react-i18next";

import { useRef, useState }         from "react";

import useCreateService             from "../../../Hooks/Services/useCreateService";

/** @module                         Apollo */

import { SERVICES_LANGUAGE_ENUM }   from "../../../Queries/Services";

import useFetch                     from "../../../Hooks/useFetch";

/** @module                         Components */

import Breadcrumbs                  from "../../../Components/Dashboard/Breadcrumbs";

import ComponentLoader              from "../../../Components/Dashboard/Loaders/ComponentLoader";

import AsideEdit                    from "../../../Components/Dashboard/Global/Layouts/Mutations/Aside";

import { Input }                    from "../../../Components/Dashboard/Global/Forms/Inputs";

import Fieldset                     from "../../../Components/Dashboard/Global/Forms/Fieldset";

import Sidebar                      from "../../../Components/Dashboard/Content/Services/Add/Sidebar";

import { Editor }                   from '@tinymce/tinymce-react';

const 

/**
 * 
 * @param { String } name
 * 
 * @returns add service page component
 * 
 */

AddService = ( { name } ) => { const 

    { data, loading } = useFetch ( SERVICES_LANGUAGE_ENUM ),

    { t } = useTranslation ();

    /** @event usePagename */

    usePagename ( t ( name ) );

    /** @returns */

    return ( <section className="mutations add service">

        <Breadcrumbs data={ [

            { name: t ( "services"  ), uri: "/app/content/services"       },

            { name: t ( name        ), uri: `/app/content/services/add`    }

        ] } />

        { loading ? <ComponentLoader /> : <Component data={ data } __={ t } /> }

    </section> );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Function } __
 * 
 * @returns add servuce page content component
 * 
 */

Component = ( { data, __ } ) => { const 

    /** @event          useState */

    [ title,            setTitle            ] = useState ( "" ),

    [ content,          setContent          ] = useState ( "" ),

    [ language,         setLanguage         ] = useState ( data.__type.enumValues[0].name ),

    [ isActive,         setIsActive         ] = useState ( true ),

    [ image,            setImage            ] = useState ( "" ),          

    [ tiremarketOnly,   setTiremarketOnly   ] = useState ( false ),

    /** @event          useRef */

    titleRef            = useRef ( null ),

    isActiveRef         = useRef ( null ),

    tiremarketOnlyRef   = useRef ( null ),
    
    languageRef         = useRef ( null ),

    imageRef            = useRef ( null ),
    
    submitRef           = useRef ( null ),

    /** @module         Hooks */

    { 
        
        createService, 
        
        errors 
    
    } = useCreateService (),

    /** @event onSubmit */

    submitService = () => {

        createService (

            title,

            content,

            image,

            isActive,

            tiremarketOnly,

            language

        );

    }

    /** @returns */

    return ( 
    
        <AsideEdit aside={ 
        
            <Sidebar 
            
                data={ data.__type.enumValues } 
                
                details={ 
                    
                    {

                        isActive : { 
                            
                            view: isActive,         
                            
                            set: setIsActive,       
                            
                            reference: isActiveRef        
                        
                        },

                        image : { 
                            
                            view: image,         
                            
                            set: setImage,       
                            
                            reference: imageRef        
                        
                        },
                        
                        tiremarketOnly : { 
                            
                            view: tiremarketOnly,   
                            
                            set: setTiremarketOnly, 
                            
                            reference: tiremarketOnlyRef  
                        
                        },

                        language : { 
                            
                            view: language, 
                            
                            set: setLanguage, 
                            
                            reference: languageRef 
                        
                        },

                        submit : { 
                            
                            reference: submitRef, 
                            
                            action: submitService 
                        
                        }
                
                    } 
            
                } 
            
                errors={ errors } /> 
        
        }>

        <Fieldset legend={ __ ( "title" ) }>

            <Input
                
                value={ title }

                action={ e => setTitle ( e ) }

                ref={ titleRef }

            />

        </Fieldset>

        <Fieldset legend={ __ ( "content" ) }>

            <Editor 

                apiKey="2nr6f475ij0nazn91dwmt3x6fpevnv36no701uj3ap6wgzfn"
            
                onEditorChange={ ( newValue ) => setContent ( newValue ) } 

                value={ content }

                plugins={ [

                    "charmap",

                    "emoticons",

                    "image",

                    "insertdatetime",

                    "link",

                    "lists",

                    "media",

                    "preview",

                    "searchreplace",

                    "table",

                    "visualblocks",

                    "visualchars",

                    "wordcount"

                ] }

                init={ {

                    selector:'textarea',

                    skin: "oxide-dark",

                    content_css: "dark",

                    image_title: true,

                    automatic_uploads: true,

                    file_picker_types: 'image',

                    file_picker_callback: function (cb, value, meta) {

                        var input = document.createElement('input');

                        input.setAttribute('type', 'file');

                        input.setAttribute('accept', 'image/*');

                        input.onchange = function () {

                        var file = this.files[0];

                        var reader = new FileReader();

                        reader.onload = function () {

                            var id = 'blobid' + (new Date()).getTime();
                            var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            cb(blobInfo.blobUri(), { title: file.name });
                        };

                        reader.readAsDataURL(file);
                        
                        };

                        input.click();
                    },

                } }
                
            />

        </Fieldset>
        
    </AsideEdit> );

}

/** @exports AddService */
 
export default AddService;