import { gql } from '@apollo/client';

const 

/** @description create new product */

CREATE_PRODUCT = gql`mutation CreateProduct (

    $productType: ProductTypesEnum!,

    $manufacturerId: Int!,

    $vendorId: Int!,

    $image: Upload,

    $price: String!,

    $qty: Int,

    $qtyHours: Int,

    $qtyDays: Int,

    $qtyWeeks: Int,

    $model: String,

    $width: Int,

    $height: Int,

    $diameter: Int,

    $loadIndex: Int,

    $speedRating: String,

    $season: String,

    $runFlat: Boolean,

    $studs: Boolean,

    $radius: Float,

    $offset: Float,

    $boltsIncluded: Boolean,

    $boltPattern: String,

    $centreBore: Int,

    $pitchCircleDiameter: Float,

    $voltage: Float,

    $capacity: Float,

    $current: Float

) {

    adminCreateProduct (

        input: {

            productInput: {

                productType: $productType,

                manufacturerId: $manufacturerId,

                vendorId: $vendorId,

                image: $image,

                price: $price,

                qty: $qty,

                qtyHours: $qtyHours,

                qtyDays: $qtyDays,

                qtyWeeks: $qtyWeeks,

                model: $model,

                width: $width,

                height: $height,

                diameter: $diameter,

                loadIndex: $loadIndex,

                speedRating: $speedRating,

                season: $season,

                runFlat: $runFlat,

                studs: $studs,

                radius: $radius,

                offset: $offset,

                boltsIncluded: $boltsIncluded,

                boltPattern: $boltPattern,

                centreBore: $centreBore,

                pitchCircleDiameter: $pitchCircleDiameter,

                voltage: $voltage,

                capacity: $capacity,

                current: $current

            }

        }

    ) {

        product {

            id

        }

    }

}`,

/** @description update existing product */

UPDATE_PRODUCT = gql`mutation UpdateProduct (

    $id: ID!,

    $productType: ProductTypesEnum!,

    $manufacturerId: Int!,

    $vendorId: Int!,

    $image: Upload,

    $price: String!,

    $qty: Int,

    $qtyHours: Int,

    $qtyDays: Int,

    $qtyWeeks: Int,

    $model: String,

    $width: Int,

    $height: Int,

    $diameter: Int,

    $loadIndex: Int,

    $speedRating: String,

    $season: String,

    $runFlat: Boolean,

    $studs: Boolean,

    $radius: Float,

    $offset: Float,

    $boltsIncluded: Boolean,

    $boltPattern: String,

    $centreBore: Int,

    $pitchCircleDiameter: Float,

    $voltage: Float,

    $capacity: Float,

    $current: Float

) {

    adminUpdateProduct (

        input: {

            id: $id,

            productInput: {

                productType: $productType,

                manufacturerId: $manufacturerId,

                vendorId: $vendorId,

                image: $image,

                price: $price,

                qty: $qty,

                qtyHours: $qtyHours,

                qtyDays: $qtyDays,

                qtyWeeks: $qtyWeeks,

                model: $model,

                width: $width,

                height: $height,

                diameter: $diameter,

                loadIndex: $loadIndex,

                speedRating: $speedRating,

                season: $season,

                runFlat: $runFlat,

                studs: $studs,

                radius: $radius,

                offset: $offset,

                boltsIncluded: $boltsIncluded,

                boltPattern: $boltPattern,

                centreBore: $centreBore,

                pitchCircleDiameter: $pitchCircleDiameter,

                voltage: $voltage,

                capacity: $capacity,

                current: $current

            }

        }

    ) {

        product {

            id

        }

    }

}`,

/** @description delete existing product */

DELETE_PRODUCT = gql`mutation DeleteProduct (

    $id: ID!

) {

    adminDeleteProduct (

        input: {

            id: $id

        }

    ) {

        product {

            id

        }

    }

}`,


/** @description delete products collection */

DELETE_PRODUCTS_COLLECTION = gql`mutation DeleteProductsCollection (

    $runnerId: ID!

    $productType: String!

) {

    adminDeleteCollectionProducts (

        input: {

            runnerId: $runnerId

            productType: $productType

        }

    ) {

        result 
    }

}`;



/** @export mutations */

export { 
    CREATE_PRODUCT, 
    UPDATE_PRODUCT, 
    DELETE_PRODUCT, 
    DELETE_PRODUCTS_COLLECTION
}