import { useRef, useState, forwardRef, useId, Fragment } from "react";

import { SEARCH_ATTRIBUTES } from "../../../../../../Queries/Products";

import useFetch from "../../../../../../Hooks/useFetch";

import CenterLoader from "../../../../Loaders/CenterLoader";

import useTiresContext from "../../../../../../Hooks/Products/useProductsContext";

import { floatSort, intSort, textUpperCase, textCapital } from "../../../../Utils/searchAttributes";

const 

TireFilters = () => { const 

    { variables, refetch } = useTiresContext (),

    { data, loading } = useFetch ( SEARCH_ATTRIBUTES ),

    /** @event useState */

    [ type,         setType         ] = useState ( ( variables.vehicleType === "undefined" ) ? "default" : variables.vehicleType ),

    [ width,        setWidth        ] = useState ( ( variables.width === "undefined" ) ? "default" : variables.width ),

    [ height,       setHeight       ] = useState ( ( variables.height === "undefined" ) ? "default" : variables.height ),
    
    [ diameter,     setDiameter     ] = useState ( ( variables.diameter === "undefined" ) ? "default" : variables.diameter ),

    [ season,       setSeason       ] = useState ( ( variables.season === "undefined" ) ? "default" : variables.season ),

    [ manufacturer, setManufacturer ] = useState ( ( variables.manufacturer === "undefined" ) ? "default" : variables.manufacturer ),

    [ economy,      setEconomy      ] = useState ( ( variables.fuelEfficiency === "undefined" ) ? "default" : variables.fuelEfficiency ),

    [ grip,         setGrip         ] = useState ( ( variables.wetBreakingEfficiency === "undefined" ) ? "default" : variables.wetBreakingEfficiency ),

    [ noise,        setNoise        ] = useState ( ( variables.noiseIndex === "undefined" ) ? "default" : variables.noiseIndex ),

    [ load,         setLoad         ] = useState ( ( variables.loadIndex === "undefined" ) ? "default" : variables.loadIndex ),

    [ speed,        setSpeed        ] = useState ( ( variables.speedRating === "undefined" ) ? "default" : variables.speedRating ),

    [ oem,          setOEM          ] = useState ( ( variables.specifications === "undefined" ) ? "default" : variables.specifications ),

    /** @references */

    typeRef             = useRef ( null ),

    widthRef            = useRef ( null ),

    heightRef           = useRef ( null ),

    diameterRef         = useRef ( null ),

    seasonRef           = useRef ( null ),

    manufacturerRef     = useRef ( null ),

    economyRef          = useRef ( null ),

    gripRef             = useRef ( null ),

    noiseRef            = useRef ( null ),

    loadRef             = useRef ( null ),

    speedRef            = useRef ( null ),

    oemRef              = useRef ( null );

    /** @returns */

    return ( <div className="modal-settings-container">

        { loading  ? <CenterLoader /> : <Fragment>

            <SelectInput 
                
                text="Type"
            
                value={ type } 
            
                action={ setType } 

                update={ refetch }

                query="vehicleType"
            
                ref={ typeRef }

                datatype="String"
                
                options={ data.searchAttributes.tires[ 0 ].vehicle_types } />

            <SelectInput 
                
                text="Width"
            
                value={ width } 
            
                action={ setWidth } 

                update={ refetch }

                query="width"
            
                ref={ widthRef }

                datatype="Integer"
                
                options={ intSort ( data.searchAttributes.tires[ 0 ].widths, "mm" ) } />

            <SelectInput 
                
                text="Height"
            
                value={ height } 
            
                action={ setHeight } 

                update={ refetch }

                query="height"
            
                ref={ heightRef }

                datatype="Float"
                
                options={ intSort ( data.searchAttributes.tires[ 0 ].heights, "%" ) } />

            <SelectInput 
                
                text="Diameter"
            
                value={ diameter } 
            
                action={ setDiameter } 

                update={ refetch }

                query="diameter"
            
                ref={ diameterRef }

                datatype="Float"
                
                options={ floatSort ( data.searchAttributes.tires[ 0 ].diameters, 'Inch' ) } />

            <SelectInput 
                
                text="Season"
            
                value={ season } 
            
                action={ setSeason } 

                update={ refetch }

                query="season"
            
                ref={ seasonRef }

                datatype="String"
                
                options={ textUpperCase ( data.searchAttributes.tires[ 0 ].seasons ) } />

            <SelectInput 
                
                text="Manufacturer"
            
                value={ manufacturer } 
            
                action={ setManufacturer } 

                update={ refetch }

                query="manufacturer"
            
                ref={ manufacturerRef }

                datatype="String"
                
                options={ textCapital ( data.searchAttributes.tires[ 0 ].manufacturers ) } />

            <SelectInput 
                
                text="Fuel Efficiency"
            
                value={ economy } 
            
                action={ setEconomy } 

                update={ refetch }

                query="fuelEfficiency"
            
                ref={ economyRef }

                datatype="String"
                
                options={ textUpperCase ( data.searchAttributes.tires[ 0 ].fuel_efficiencies ) } />

            <SelectInput 
                
                text="Grip"
            
                value={ grip } 
            
                action={ setGrip } 

                update={ refetch }

                query="wetBreakingEfficiency"
            
                ref={ gripRef }

                datatype="String"
                
                options={ textUpperCase ( data.searchAttributes.tires[ 0 ].wet_grip ) } />

            <SelectInput 
                
                text="Noise Index"
            
                value={ noise } 
            
                action={ setNoise } 

                update={ refetch }

                query="noiseIndex"
            
                ref={ noiseRef }

                datatype="String"
                
                options={ intSort ( data.searchAttributes.tires[ 0 ].noise_indexes, "dB" ) } />

            <SelectInput 
                
                text="Load Index"
            
                value={ load } 
            
                action={ setLoad } 

                update={ refetch }

                query="loadIndex"

                datatype="String"
            
                ref={ loadRef }
                
                options={ intSort ( data.searchAttributes.tires[ 0 ].load_indexes ) } />

            <SelectInput 
                
                text="Speed Index"
            
                value={ speed } 
            
                action={ setSpeed } 

                update={ refetch }

                query="speedRating"
            
                ref={ speedRef }

                datatype="String"
                
                options={ textUpperCase ( data.searchAttributes.tires[ 0 ].speed_ratings ) } />

            <SelectInput 
                
                text="Specifications"
            
                value={ oem } 
            
                action={ setOEM } 

                update={ refetch }

                query="specifications"
            
                ref={ oemRef }

                datatype="String"
                
                options={ data.searchAttributes.tires[ 0 ].specifications } />

        </Fragment> }
        
    </div> );

},

/**
 * 
 * @param { String } text
 * 
 * @param { String } type
 * 
 * @param { State } value
 * 
 * @param { SetStateAction } action
 * 
 * @param { RefObject } ref
 * 
 * @returns select input component
 * 
 */

SelectInput = forwardRef ( ( { 
    
        text        = "Unknown", 
        
        value       = "default", 
        
        action      = () => {}, 
        
        options     = {}, 
        
        update      = () => {}, 
        
        query       = "undefined", 
        
        datatype    = "String"

    }, ref ) => { const ID = useId ();

    /** @returns */

    return ( <div className="input-group">

        <label htmlFor={ ID } className="focused">
            
            { text }
            
        </label>

        <select onChange={ e => { action ( e.target.value );

            ( e.target.value === "default" ) ? 
            
                update ( { 
                    
                    first       : JSON.parse ( localStorage.getItem ( "productsCollectionCount" ) ) || 12, 
                    
                    last        : undefined, 
                    
                    before      : undefined, 
                    
                    after       : undefined, 
                    
                    [ query ]   : undefined } )
            
            :

                update ( { 
                    
                    first       : JSON.parse ( localStorage.getItem ( "productsCollectionCount" ) ) || 12, 
                    
                    last        : undefined, 
                    
                    before      : undefined, 
                    
                    after       : undefined, 
                    
                    [ query ]   : ( datatype === "Float" ) ? 
                    
                        parseFloat ( e.target.value ) 
                        
                            : 
                            
                        ( datatype === "Integer" ) ? 
                        
                            parseInt ( e.target.value ) 
                            
                                : 
                                
                            e.target.value } );

        } } id={ ID } ref={ ref } defaultValue={ value }>

            <option value="default">-- Select --</option>

            { options.map ( ( option ) => <option 
            
                value={ option.value } 
            
                key={ option.value }>

                    { option.text }

            </option> ) }

        </select>

    </div> ) 

} );

/** @exports TireFilters */
 
export default TireFilters;