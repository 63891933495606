/** @module Hooks */

import { useTranslation } from "react-i18next";

/** @import icons */

import { 
    
    BsFillPieChartFill, 
    
    BsFillBasketFill, 
    
    BsFillPinAngleFill, 
    
    BsUiChecks 

    } from "react-icons/bs";

import { 
    
    GiCarWheel, 
    
    GiTwoCoins 

    } from "react-icons/gi";

import { 
    
    FaUsers, 
    
    FaBoxes, 
    
    FaTools, 
    
    FaPencilAlt, 
    
    FaTruckLoading, 
    
    FaIndustry,

    FaMoneyBill,

    FaHandshake,

    FaCookieBite,

    FaUserAlt,
    
    FaTags

    } from "react-icons/fa";

import { 
    
    MdAdminPanelSettings, 
    
    MdContacts,

    MdPrivacyTip

    } from "react-icons/md";

import { 
    
    ImPriceTags 

    } from "react-icons/im";

import { IoSettings } from "react-icons/io5";

import { 
    
    BiSearchAlt, 
    
    BiImport, 
    
    BiExport, 
    
    BiWorld,

    } from "react-icons/bi";

import { 
    
    FiPackage 

    } from "react-icons/fi";

import { 
    
    AiFillShop, 
    
    AiFillTag, 
    
    AiFillCar, 
    
    AiFillBank, 
    
    AiOutlineInfoCircle,

    AiFillQuestionCircle,

    } from "react-icons/ai";

import { 
    
    RiPagesFill, 
    
    RiGalleryFill 

    } from "react-icons/ri";

import { 
    
    IoLanguage 

    } from "react-icons/io5";

import { 
    
    TbArrowAutofitWidth, 
    
    TbListCheck, 
    
    TbPackgeImport, 
    
    TbTruckDelivery 

    } from "react-icons/tb";

import { MdOutlineManageAccounts } from "react-icons/md";


/** @returns Dashboard aside navigation */

const Navigation = () => { const { t } = useTranslation (),

    navlist =  [

        { type: 0, icon: <BsFillPieChartFill />, name: t ( "statistics" ), URI: "/app/dashboard" },

        { type: 0, icon: <BsFillBasketFill />, name: t ( "orders" ), URI: "/app/orders" },

        { type: 0, icon: <GiCarWheel />, name: t ( "montage" ), URI: "/app/montage" },

        { type: 1, icon: <FiPackage />, name: t ( "products" ), collection: [

            // { icon: <BiSearchAlt />, name: "Search", URI: "/app/products/search" },

            { icon: <FaBoxes />, name: t ( "collection" ), URI: "/app/products/tire" },

            // { icon: <AiFillTag />, name: "Sale", URI: "/app/products/sale" },

            { icon: <BiImport />, name: t ( "imports" ), URI: "/app/products/import" },

            // { icon: <BiExport />, name: "Export", URI: "/app/products/export" }

        ] },

        { type: 1, icon: <AiFillShop />, name: t ( "shop" ), collection: [

            { icon: <TbTruckDelivery />, name: t ( "suppliers" ), URI: "/app/shop/suppliers" },

            { icon: <FaTools />, name: t ( "manufacturers" ), URI: "/app/shop/manufacturers" },

            { icon: <FaTags />, name: t ( "markups" ), URI: "/app/shop/markups/global/default" },

            // { icon: <TbListCheck />, name: "Applications", URI: "/app/shop/applications" },

            // { icon: <BsUiChecks />, name: "Specifications", URI: "/app/shop/specifications" },

            // { icon: <AiOutlineInfoCircle />, name: "Additional information", URI: "/app/shop/additional-information" },

            // { icon: <TbArrowAutofitWidth />, name: "Sizes", URI: "/app/shop/sizes" },

            // { icon: <FaIndustry />, name: "Industries", URI: "/app/shop/industries" },

            // { icon: <BiWorld />, name: "Countries", URI: "/app/shop/countries" },

            // { icon: <AiFillBank />, name: "Banks", URI: "/app/shop/banks" },

            // { icon: <GiTwoCoins />, name: "Currencies", URI: "/app/shop/currencies" },

            // { icon: <TbPackgeImport />, name: "Direct suppliers", URI: "/app/shop/direct-suppliers" },

            // { icon: <FaTruckLoading />, name: "Delivery", URI: "/app/shop/delivery" },

            // { icon: <IoLanguage />, name: "Languages", URI: "/app/shop/languages" }

        ] },

        // { type: 0, icon: <FaUsers />, name: "Clients", URI: "/app/clients" },

        // { type: 0, icon: <FaUserAlt />, name: t ( "customers" ), URI: "/app/customers" },

        { type: 0, icon: <MdAdminPanelSettings />, name: t ( "managers" ), URI: "/app/managers/active" },

        { type: 0, icon: <MdOutlineManageAccounts />, name: t ( "clients" ), URI: "/app/clients" },

        // { type: 0, icon: <ImPriceTags />, name: "Pricing", URI: "/app/prices" },

        { type: 1, icon: <BsFillPinAngleFill />, name: t ( "content" ), collection: [

            { icon: <FaPencilAlt />, name: t ( "posts" ), URI: "/app/content/posts" },

            // { icon: <RiPagesFill />, name: "Pages", URI: "/app/content/pages" },

            { icon: <AiFillCar />, name: t ( "services" ), URI: "/app/content/services" },

            // { icon: <RiGalleryFill />, name: "Gallery", URI: "/app/content/gallery" }

        ] },

        { type: 0, icon: <RiPagesFill />, name: t ( "pages" ), URI: "/app/pages" },

        { type: 1, icon: <MdContacts />, name: t ( "contacts" ), collection: [

            { icon: <AiFillShop />, name: t ( "shop" ), URI: "/app/contacts" },

            { icon: <FaUsers />, name: t ( "team" ), URI: "/app/contacts/team" }

        ] },

        { type: 0, icon: <IoSettings />, name: t ( "settings" ), URI: "/app/settings" }

    ];

    return navlist;

}

/** @export Navigation */

export default Navigation;