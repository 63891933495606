import styles                   from "./Styles.module.css";

import { useTranslation }       from "react-i18next";

/** @module                     Components */

import { Fragment }             from "react";

import Fieldset                 from "../../Global/Forms/Fieldset";

import { LabeledInput }         from "../../Global/Forms/Inputs_Spread";

const 

/**
 * 
 * @param { Object } state
 * 
 * @param { Function } action
 * 
 * @param { Function } __
 * 
 * @returns customer component
 * 
 * @description component for customer data
 * 
 */

Customer = ( { state, update } ) => { const 

    { t : __ } = useTranslation (),

    business_fields = [

        { name: "companyName", size: "1 / 5" },

        { name: "regNr", size: "5 / 7" },

        { name: "pvnRegNr", size: "7 / 9" },

        { name: "bank", size: "1 / 5" },

        { name: "bankSwift", size: "5 / 6" },

        { name: "bankIban", size: "6 / 10" }

    ],

    customer_fields = [

        { name: "firstName", size: "1 / 3" },

        { name: "lastName", size: "3 / 5" },

        { name: "customerIdCode", size: "5 / 7" },

        { name: "mobilePhoneNr", size: "1 / 3" },

        { name: "email", size: "3 / 5" },
    ];

    return (

        <Fragment>

            <Fieldset legend={ __ ( "company" ) }>

                <div className={ styles.grid }>

                    { business_fields.map ( ( field, i ) => 

                        <div style={ { gridColumn: field.size } } key={ i }>

                            <LabeledInput
                                    
                                label={ __ ( field.name ) }

                                id={ field.name }
                                
                                value={ state [ field.name ] } 

                                action={ e => update ( { ...state, [ field.name ]: e.target.value } ) }

                                name={ field.name }
                                
                            />

                        </div>

                    ) }


                </div>

            </Fieldset>

            <Fieldset legend={ __ ( "customer" ) }>

                <div className={ styles.grid }>

                    { customer_fields.map ( ( field, i ) => 

                        <div style={ { gridColumn: field.size } } key={ i }>

                            <LabeledInput
                                    
                                label={ __ ( field.name ) }

                                id={ field.name }
                                
                                value={ state [ field.name ] } 

                                action={ e => update ( { ...state, [ field.name ]: e.target.value } ) }

                                name={ field.name }
                                
                            />

                        </div>

                    ) }

                </div>

            </Fieldset>

        </Fragment>

    );

}

/** @exports Customer */
 
export default Customer;