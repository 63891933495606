import { Text, View } from '@react-pdf/renderer';

/**
 * 
 * @exports function Hours
 * 
 * @description Export working hours of the company as a React Component
 * 
 * @param { Object } hours - working hours of the company
 * 
 * @returns { JSX.Element }
 * 
 */

export default function Hours ( { hours } ) {

    /**
     * 
     * @description
     * 
     * Render working hours of the company as a React Component
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <View>

            <Text style={ { marginBottom: 5, fontWeight: 500 } }>Darba laiks</Text>

            { /** @description Generate working hours from the data object and map them to the view */

                generateWorkingHours ( hours ).map ( ( entry, index ) => 

                    <Text key={ index }>{ entry.days.join ( "." ) }: { entry.time }</Text>

                )

            }

        </View>

    );

};

function generateWorkingHours ( data ) {

    if ( ! data ) return;

    let uniqueValues = Object.values ( data ).filter ( ( value, index, self ) => self.indexOf ( value ) === index );

    const renameDays = {

        monday: "P",

        tuesday: "O",

        wednesday: "T",

        thursday: "C",

        friday: "P",

        saturday: "Sestdiena",

        sunday: "Svētdiena"

    };

    let workingHours = uniqueValues.map ( value => {

        return {

            time: value,

            days: Object.keys ( data ).filter ( key => data [ key ] === value ).map ( key => renameDays [ key ] )

        };

    } );

    return workingHours;

};