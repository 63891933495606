import { useId } from "react";

import { useProductsCount } from "../../../../../../Hooks/Products/useProductsCount";

import useTiresContext from "../../../../../../Hooks/Products/useProductsContext";

const TireSettings = () => { const 

    { variables, refetch } = useTiresContext (),

    { itemsCount, setItemsCount } = useProductsCount (),

    ID = useId ();

    /** @returns */

    return ( <div className="modal-settings-container">

        <div className="input-group">

            <label htmlFor={ ID } className="focused">
                
                Results count
                
            </label>

            <select id={ ID } defaultValue={ itemsCount } onChange={ e => {

                setItemsCount ( parseInt ( e.target.value ) );

                refetch ( 
                    
                    { ...variables, 
                    
                        first: parseInt ( e.target.value ),

                        last: undefined,

                        before: undefined,

                        after: undefined

                    } 
                    
                );

            } }>

                <option value={ 4 }>4</option>

                <option value={ 8 }>8</option>

                <option value={ 12 }>12</option>

                <option value={ 20 }>20</option>

                <option value={ 28 }>28</option>

                <option value={ 36 }>36</option>

                <option value={ 44 }>44</option>

                <option value={ 52 }>52</option>

                <option value={ 60 }>60</option>

            </select>

        </div>

    </div> );

}

/** @exports TireSettings */
 
export default TireSettings;