/** @module                 React */

import { useState }         from "react";

/** @module                 Router */

import { useNavigate }      from "react-router-dom";

/** @module                 Apollo */

import { useMutation }      from "@apollo/client";

import { DELETE_SERVICE }   from "../../Mutations/Services";

const 

/** 
 * 
 * @returns useDeleteService hook for deleting existing service
 * 
 */

useDeleteService = () => { const 

    /** @hooks */

    navigate                    = useNavigate (),

    [ loading,  setLoading  ]   = useState ( false ),

    [ errors,   setErrors   ]   = useState ( false ),

    /** @mutations */

    [ DeleteService ] = useMutation ( DELETE_SERVICE, {

        /** @event onComplete */

        onCompleted ( data ) {

            setLoading ( false );

            return navigate ( `/app/content/services` );

        },

        /** @event onError */

        onError ( err ) { setLoading ( false ); setErrors ( true ); }

    } ),

    /** 
     * 
     * @param { Number } id
     * 
     * @returns delete service action
     * 
     */

    deleteService = ( id ) => {

        /** @validation */

        setErrors ( false ); setLoading ( true );

        if ( ! id ) {

            setErrors   ( true  );

            setLoading  ( false );

            return;

        };

        /** @mutation */

        DeleteService ( { variables: { id } } );

    }

    /** @returns */

    return { deleteService, loading, errors }

}

/** @exports useDeleteService */

export default useDeleteService;