import React from "react";
import { Formik } from "formik";

import { IoMdSearch } from "react-icons/io";

import useDebounce from "../../Hooks/use-debounce.js";

import Input from "./../../Components/Common/Forms/input/index.js";
import Button from "../../Components/Common/Button/index.js";

import styles from "./styles.module.css";


const FormSearch = (props) => {
    const onSearchWithDebounce = useDebounce(props.onSearch, 200);

    /* --- */

    const renderForm = (formMethods) => {
        const {
            values,
            handleSubmit,
            handleBlur,
            handleChange,
        } = formMethods;

        return (
            <form
                onSubmit={handleSubmit}
                className={styles.form}
            >
                <Input
                    name="search"
                    placeholder="Search..."
                    value={values.search}
                    onChange={(evt) => {
                        handleChange(evt);

                        const value = evt.target.value || "";
                        onSearchWithDebounce(value);
                    }}
                    onBlur={handleBlur}
                    isAutoHeight
                />
                <Button
                    icon={IoMdSearch}
                    onClick={() => {
                        props.onSearch(values.search);
                    }}
                    isBlue
                />
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

FormSearch.defaultProps = {
    initialValues: {},
    onSubmit: () => { },
    onSearch: () => { },
};

export default FormSearch;
