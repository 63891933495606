/** @module                     React-Router */

import { useNavigate }          from "react-router-dom";

/** @module                     React-Icons */

import { FiExternalLink } from "react-icons/fi";

const 

/**
 * 
 * @param { Object } product
 * 
 * @returns table edit entry action
 * 
 */

ExternalUrl = ( { route } ) => { const navigate = useNavigate (),

    /** @returns navigate to correct edit entry page */

    editProduct = () => navigate ( route );

    /** @returns */

    return ( <a href={ route } target="_blank" className="external_table_link">
        
        <FiExternalLink />
        
    </a> );

};

/** @exports ExternalUrl */
 
export default ExternalUrl;