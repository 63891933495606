/** @module                     Hooks */

import { useTranslation }       from "react-i18next";

import { useEffect }            from "react";

/** @module                     Apollo */

import useFetch                 from "../../../../Hooks/useFetch";

import { APPOINTMENT_LIST }     from "../../../../Queries/Montage";

/** @module                     Utilities */

import { enumOptions }          from "../../Utils/enumOptions";

import { 
    
    yearList, 
    
    monthOptions, 
    
    dayListOptions, 
    
    dayAppointments, 
    
    isoConvertner, 
    
    extractDate, 
    
    extractTime }               from "../../Utils/Datetime";

/** @module                     Components */

import Fieldset                 from "../../Global/Forms/Fieldset";

import Select                   from "../../Global/Forms/Select";

import MutationActions          from "../../Global/Layouts/Mutations/MutationActions";

import Checkbox                 from "../../Global/Forms/Checkbox";

import Attributes               from "../../Global/Forms/Fieldset/Attributes";

const 

/**
 * 
 * @param { Object } details
 * 
 * @returns edit appointment sidebar component
 * 
 */

Sidebar = ( { details } ) => { const { t } = useTranslation (),

    { loading, data, refetch } = useFetch ( APPOINTMENT_LIST, { 
        
        transportType   : details.transportType.view,

        year            : details.year.view,

        month           : details.month.view,

        day             : details.day.view
    
    } ),

    /** @event              onClick */

    updateTiresStored       = e => details.tiresStored.set      ( e.target.checked ),

    updateTiresBought       = e => details.tiresBought.set      ( e.target.checked ),

    updateAppointmentTime   = e => { details.time.set ( e ); const 

        timeConverter = isoConvertner ( e, details.year.view, details.month.view, details.day.view );

        details.startTime.set ( timeConverter[ 0 ].appointmentStarts );

        details.endTime.set ( timeConverter[ 0 ].appointmentEnds );

    };

    /** @event useEffect */

    useEffect ( () => {

        if ( details.day.view ) { 
            
            refetch ( {

                transportType: details.transportType.view,

                year: parseInt ( details.year.view ),

                month: parseInt ( details.month.view ),

                day: parseInt ( details.day.view )

            } );

            details.time.set ( "" );

            details.startTime.set ( "" );

            details.endTime.set ( "" );
    
        }

    }, [ details.year.view, details.month.view, details.day.view, details.transportType.view ] );

    return ( <aside>

        <Fieldset legend={ t ( "actions" ) }>

            <Attributes details={ [

                { name: t ( "date" ), value: extractDate ( details.dataPlaceholder.start ) },

                { name: t ( "start" ), value: extractTime ( details.dataPlaceholder.start ) },

                { name: t ( "end" ), value: extractTime ( details.dataPlaceholder.end ) },

                { name: t ( "serviceBox" ), value: details.dataPlaceholder.box },

            ] } />

            <MutationActions 

                remove={ true }

                submitAction={ details.submit.action }

                removeAction={ details.remove.action }

                references={ [ details.submit.reference, details.remove.reference ] } />

        </Fieldset>

        <Fieldset legend={ t ( "service" ) }>

            <Select 
                    
                id="transportType"
                
                action={ e => details.transportType.set ( e ) }
                
                options={ [

                    { name: t ( "car" ), value: "car" },

                    { name: t ( "motorcycle" ), value: "motorcycle" },

                    { name: t ( "commercial" ), value: "commercial" },

                    { name: t ( "cooler" ), value: "cooler" },

                ] }
                
                value={ details.transportType.view }
                
                ref={ details.transportType.reference } />

        </Fieldset>

        <Fieldset legend={ t ( "appointment_time" ) }>

            <Select 
                    
                id="year"
                
                action={ e => details.year.set ( e ) }
                
                options={ enumOptions ( yearList () ) }
                
                value={ details.year.view }
                
                ref={ details.year.reference } />

            <Select 
                    
                id="month"
                
                action={ e => details.month.set ( e ) }
                
                options={ monthOptions ( t ) }
                
                value={ details.month.view }
                
                ref={ details.month.reference } />

            <Select 
                    
                id="day"
                
                action={ e => details.day.set ( e ) }
                
                options={ dayListOptions ( details.year.view, details.month.view, false ) }
                
                value={ details.day.view }
                
                ref={ details.day.reference } />

            { details.day.view && ! loading &&

                <Select 
                        
                    id="time"
                    
                    action={ e => updateAppointmentTime ( e ) }
                    
                    options={ dayAppointments ( data ) }
                    
                    value={ details.time.view }
                    
                    ref={ details.time.reference } />

            }

        </Fieldset>

        <Fieldset legend={ t ( "options" ) }>

            <Checkbox 
                
                id="tiresStored" 
            
                text={ t ( "tiresStored" ) }  
            
                value={ details.tiresStored.view } 
            
                state={ details.tiresStored.view } 
            
                action={ updateTiresStored }
                
                ref={ details.tiresStored.reference } />

            <Checkbox 
            
                id="tiresBought" 
            
                text={ t ( "tiresBought" ) } 
            
                value={ details.tiresBought.view } 
            
                state={ details.tiresBought.view } 
            
                action={ updateTiresBought }
                
                ref={ details.tiresBought.reference } />

        </Fieldset>

    </aside> );

}

/** @exports Sidebar */
 
export default Sidebar;