/** @module                         Hooks */

import useFetch                     from "../../../../Hooks/useFetch";

/** @module                         Queries */

import { PRODUCT_TYPES_ENUM }       from "../../../../Queries/Products";

/** @module                         Utilities */

import { enumOptions }              from "../../Utils/enumOptions";

/** @module                         Components */

import Fieldset                     from "../../Global/Forms/Fieldset";

import SelectSpread                 from "../../Global/Forms/Select_Spread";

import CenterLoader                 from "../../Loaders/CenterLoader";

const 

ProductType = ( { __, state, action } ) => { const 

    /** @constant { Object } data */

    { data, loading: typesLoading } = useFetch ( PRODUCT_TYPES_ENUM );

    /** @returns ProductType */

    return (

        <Fieldset legend={ __ ( "type" ) }>

            { typesLoading ? <CenterLoader /> :

                <SelectSpread
                
                    id="productType"

                    action={ action }

                    options={ ( ! typesLoading ) ? enumOptions ( data.__type.enumValues ) : [] }

                    i18n={ __ }

                    value={ state.productType }

                    defValue={ state.productType }

                    loading={ typesLoading }
                
                />

            }

        </Fieldset>

    );

}

/** @exports ProductType */
 
export default ProductType;