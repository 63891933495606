/** @module                         Hooks */

import { Navigate, useParams }      from "react-router-dom";

const 

/**
 * 
 * @returns fallback for edit page
 * 
 */

EditFallback = () => { const { type, language } = useParams ();

    /** @returns */

    return (

        <Navigate to={ `/app/pages/${ type }/${ language }` } />

    );

}

/** @exports EditFallback */
 
export default EditFallback;