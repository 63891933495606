/** @module                 React */

import React, 

    { createContext }       from "react";

/** @module                 React-Router-Dom */

import { Outlet }           from "react-router-dom";

/** @module                 Apollo */

import { PRODUCTS }         from "../../Queries/Products";

import useFetch             from "../../Hooks/useFetch";

import { useProductsCount } from "../../Hooks/Products/useProductsCount";

/** @returns context */

export const TiresContext = createContext ();

/**
 * 
 * @returns tires context provider
 * 
 */

export const TiresContextProvider = () => { const 

    { itemsCount } = useProductsCount (),

    { 
        
        data, 
        
        loading, 
        
        variables, 
        
        refetch 
    
    } = useFetch ( PRODUCTS, 
        
        { 
            
            first: itemsCount, 
            
            productType: "tire" 
        
        } 
        
    );

    return ( <TiresContext.Provider value={ 
        
        { 
            
            data, 
            
            loading, 

            variables,
            
            refetch
            
        } 
        
    }>

        <Outlet />

    </TiresContext.Provider> );

}