import styles from './ProductsVendor.module.css';

/**
 * 
 * @exports ProductsVendor
 * 
 * @description The products vendor component
 * 
 * @param { Object } props - The component props
 * 
 * @param { Object } props.product - The product object
 * 
 * @returns { React.ReactElement }
 * 
 * @example <ProductsVendor product={ product } />
 * 
 */

export default function ProductsVendor ( { product } ) {

    /**
     * 
     * @description 
     * 
     * Render the products vendor prices and vendor name
     * 
     * @returns { React.ReactElement }
     * 
     */

    return (

        <td className={ styles.container }>

            <div className={ styles.vendorName }>

                { product.productVendorId ? product.vendorName : product.product.vendorNames.join ( ", " ) }

            </div>

            <div className={ styles.priceVAT }>

                &euro; { ( parseFloat ( product?.product?.prices[ 0 ]?.originalPrice || 0 ) * ( 1 + 0.21 ) ).toFixed ( 2 ) || "-" } (ar PVN)

            </div>

            <div className={ styles.price }>
                
                &euro; { parseFloat ( product?.product?.prices[ 0 ]?.originalPrice || 0 ).toFixed ( 2 ) || "-" }
                
            </div>

        </td>

    );

};