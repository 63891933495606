import { gql } from '@apollo/client';

const 

/** @description create new manufacturer */

CREATE_MANUFACTURER = gql`mutation CreateManufacturer (

    $productType    : ManufacturerProductTypesEnum!

    $name           : String!

    $markup         : Float

) {

    adminCreateManufacturer (

        input: {

            productType: $productType,

            manufacturerInput: {

                name        : $name

                markup      : $markup

            }

        }

    ) {

        manufacturer {

            id

        }

    }

}`,

/** @description update existing manufacturer */

UPDATE_MANUFACTURER = gql`mutation UpdateManufacturer (

    $name                     : String!

    $id                       : ID!

    $markup                   : Float

    $attributedMarkup         : JSON

    $markupGroupA             : Float

    $attributedMarkupGroupA   : JSON

    $markupGroupB             : Float

    $attributedMarkupGroupB   : JSON

    $markupGroupC             : Float

    $attributedMarkupGroupC   : JSON

) {

    adminUpdateManufacturer (

        input: {

            id: $id,

            manufacturerInput: {

                name                      : $name

                markup                    : $markup

                attributedMarkup          : $attributedMarkup

                markupGroupA              : $markupGroupA

                attributedMarkupGroupA    : $attributedMarkupGroupA

                markupGroupB              : $markupGroupB

                attributedMarkupGroupB    : $attributedMarkupGroupB

                markupGroupC              : $markupGroupC

                attributedMarkupGroupC    : $attributedMarkupGroupC

            }

        }

    ) {

        manufacturer {

            id

            name

            markup

            attributedMarkup

            markupGroupA

            attributedMarkupGroupA

            markupGroupB

            attributedMarkupGroupB

            markupGroupC

            attributedMarkupGroupC

            summerMarkup

            winterMarkup

            updatedAt

        }

    }

}`,

/** @description delete existing manufacturer */

DELETE_MANUFACTURER = gql`mutation DeleteManufacturer (

    $id: ID!

) {

    adminDeleteManufacturer (

        input: {

            id: $id

        }

    ) {

        manufacturer {

            id

        }

    }

}`;

/** @export mutations */

export { CREATE_MANUFACTURER, UPDATE_MANUFACTURER, DELETE_MANUFACTURER }