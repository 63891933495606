/** @module Hooks */

import { usePagename }      from "../../Hooks/usePagename";

import useFetch             from "../../Hooks/useFetch";

import { useTranslation }   from "react-i18next";

/** @module Queries */

import { MANAGERS }         from "../../Queries/Managers";

/** @module                 Components */

import Breadcrumbs          from "../../Components/Dashboard/Breadcrumbs";

import ComponentLoader      from "../../Components/Dashboard/Loaders/ComponentLoader";

import Pagination           from "../../Components/Dashboard/Global/Table/Pagination";

import Table                from "../../Components/Dashboard/Managers/Table/Table";

import Actions              from "../../Components/Dashboard/Managers/Actions";

const 

/**
* 
* @param { String } name
* 
* @returns managers page component
* 
*/

Managers = ( { name } ) => { const { t } = useTranslation (),

    { loading, data, variables, refetch } = useFetch ( MANAGERS, { 
        
        first: JSON.parse ( localStorage.getItem ( "managersCollectionCount" ) ) || 12
    
    }, "cache-and-network" );

    /** @event usePagename */

    usePagename ( t ( name ) );

    /** @return Managers page */

    return ( <section className="managers">

        <Breadcrumbs data={ [

            { name: t ( "managers" ), uri: "/app/managers/active" }

        ] } />

        { ( loading ) ? <ComponentLoader size={ 1 } /> : <Actions refetch={ refetch } variables={ variables } /> }

        { ! loading && <Pagination pageinfo={ data.adminUsers.pageInfo } action={ refetch } cookie="managersCollectionCount" stringSearch={ true } /> }

        { ( loading ) ? <ComponentLoader /> : <Table users={ data } /> }

        { ! loading && <Pagination pageinfo={ data.adminUsers.pageInfo } action={ refetch } cookie="managersCollectionCount" /> }

    </section> );

}

/** @exports Managers */
 
export default Managers;