/** @module React */

import { Fragment }         from "react";

import { Link }             from "react-router-dom";

/** @module GraphQL */

import { PRODUCTS }         from "../../../../Queries/Products";

import useFetch             from "../../../../Hooks/useFetch";

/** @module Icons */

import { MdOutlineImage }   from "react-icons/md";

import { 
    
    AiFillEye, 
    
    AiOutlineEdit }         from "react-icons/ai";

import { BsTrash }          from "react-icons/bs";

/**
 * 
 * @param { string } type 
 * 
 * @returns products (tires) table
 * 
 */

const 

Table = ( { type } ) => { const { data, loading } = useFetch ( PRODUCTS, { first: 12, productType: type } );

    /** @returns */

    return ( <Fragment>

        { ( loading ) ? ( <div>Loading...</div> ) : ( <table>

            <thead>

                <tr>

                    <th><MdOutlineImage /></th>

                    <th>Manufacturer</th>

                    <th>Model</th>

                    { type !== "battery" && 

                        <th>Size</th>

                    }

                    { type === "tire" && <th>SI</th> }

                    <th>Supplier &euro;</th>

                    <th>Sale &euro;</th>

                    <th>Local</th>

                    <th>24H</th>

                    <th>1 - 3 D</th>

                    <th>5 - 7 D</th>

                    <th>Supplier</th>

                    <th>Actions</th>

                </tr>

            </thead>

            { ( typeof data != "undefined" ) && <tbody>

                { data.adminMultiFieldSearch.edges.map ( ( product, index ) => <tr key={ index }>

                    <td data-title="image">

                        <div className="table-image">

                            <img alt={ product.node.model } src={ product.node.currentImage } />

                        </div>

                    </td>

                    <td data-title="manufacturer">

                        <Link to={ `/app/products/manufacturer/${ product.node.manufacturerId }` }>{ product.node.manufacturer.name.toUpperCase () }</Link>

                    </td>

                    <td data-title="model">

                        { product.node.model }

                    </td>

                    { type !== "battery" && 

                    <td data-title="size">

                        { `${ product.node.width } | ${ product.node.height } | R${ product.node.diameter }` }

                    </td> }

                    { type === "tire" && <td data-title="speedIndex">

                        { product.node.speedRating.toUpperCase () }

                    </td> }

                    <td data-title="supplier_price">--</td>

                    <td data-title="sale_price">--</td>

                    <td data-title="local_quantity">

                        { quantityUtility ( parseInt ( product.node.productVendors[0].qty ) ) }

                    </td>

                    <td data-title="24H">

                        { quantityUtility ( parseInt ( product.node.productVendors[0].qtyHour ) ) }

                    </td>

                    <td data-title="1_3_days">

                         { quantityUtility ( parseInt ( product.node.productVendors[0].qtyDays ) ) }

                    </td>

                    <td data-title="5_7_days">

                         { quantityUtility ( parseInt ( product.node.productVendors[0].qtyWeeks ) ) }

                    </td>

                    <td data-title="supplier">--</td>

                    <td data-title="actions" className="actions">

                        <button className="view"><AiFillEye /></button>

                        <button className="edit"><AiOutlineEdit /></button>

                        <button className="delete"><BsTrash /></button>

                    </td>

                </tr> ) }

            </tbody> }

        </table> ) }

    </Fragment> );

}

/** @return */

const quantityUtility = ( quantity ) => {

    if ( ! quantity ) return <span className="color-alert">-</span>

    if ( quantity <= 4 && quantity > 0 ) return <span className="color-warning">{ quantity }</span>

    if ( quantity > 4 ) return <span className="color-success">{ quantity }</span>

} 
 
/** @exports Table */

export default Table;